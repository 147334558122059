import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';

interface ISidebarStoreState {
  isAnimating: boolean;
  isFloating: boolean;
  isCollapsed: boolean;
  editSidebar<Key extends keyof ISidebarStoreState>(
    key: Key,
    payload: ISidebarStoreState[Key]
  ): void;
}

export const useSidebarStore = create(
  persist<ISidebarStoreState>(
    (set) => ({
      isAnimating: false,
      isFloating: false,
      isCollapsed: false,
      editSidebar: (key, payload) =>
        set((state) => ({
          ...state,
          [key]: payload
        }))
    }),
    {
      name: 'persisted-sidebar-store',
      merge: (persistedState, currentState) => {
        return {
          ...currentState,
          ...(persistedState as ISidebarStoreState),
          // Prevent persisting collapsed state
          isCollapsed: (persistedState as ISidebarStoreState).isFloating
            ? true
            : currentState.isCollapsed
        };
      }
    }
  )
);

export const useShallowSidebarStore = (keys: (keyof ISidebarStoreState)[]) =>
  useShallowStoreGeneric(useSidebarStore, keys);
