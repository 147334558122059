import { SearchType } from '__generated__/graphql';
import { CircleCheckIcon } from 'assets/harmonic-icons';
import { Badge } from 'common/components/Badge';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import useDashboardLocation, { EntityType } from 'hooks/useDashboardLocation';
import analytics, { CustomTrackEvent } from 'utils/analytics';

const NetNewBadge = ({ netNewId }: { netNewId: number }) => {
  const { onClearNetNewById } = useNetNewCounts();
  const { entityType, urn } = useDashboardLocation();
  if (!netNewId || !urn || !entityType) return null;

  const searchType =
    entityType === EntityType.COMPANY
      ? SearchType.COMPANIES_LIST
      : SearchType.PERSONS;

  return (
    <div className="ml-[28px] flex mt-g30 group w-fit">
      <div className="flex group-hover:hidden">
        <Badge label="New" color="expressive-37" intensity="subtle" />
      </div>
      <div
        className="hidden group-hover:flex cursor-pointer"
        onClick={() => {
          if (!netNewId || !urn) return;
          onClearNetNewById({
            id: netNewId,
            savedSearchUrn: urn,
            type: searchType
          });

          analytics.trackCustomEvent({
            event: CustomTrackEvent.DISMISS_NET_NEW_ENTITY_CLICKED,
            properties: {
              savedSearchUrn: urn,
              type: searchType
            }
          });
        }}
      >
        <Badge
          leadingIcon={CircleCheckIcon}
          label="Dismiss"
          color="expressive-37"
          intensity="subtle"
        />
      </div>
    </div>
  );
};

export default NetNewBadge;
