import { Popover, PopoverOrigin } from '@material-ui/core';
import { PlusIcon } from 'assets/harmonic-icons';
import CircleCheckIcon from 'assets/harmonic-icons/circle-check';
import CircleMinus from 'assets/harmonic-icons/circle-minus';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import ListItem, {
  ListType,
  ListVariant
} from 'harmonic-components/ListItem/ListItem';
import {
  UseAddEntityListModalOnSuccessProps,
  useAddEntityListModal
} from 'hooks/useAddEntityListModal';
import useFetchWatchlists from 'hooks/useFetchWatchlists';
import {
  EntityListType,
  INITIAL_SEARCH_MODEL
} from 'interfaces/SearchModel/Search';
import { useMemo, useState } from 'react';
import { UserWatchlistType } from 'utils/watchlist';
import { useWatchlistActions } from '../../../hooks/useWatchlistActions';
import { HarmonicLoader } from '../ResultsWrapper/LoadingOverlay';

interface ToggleCompanyListsDropdownProps {
  companyIds: number[];
  shouldNavigate?: boolean;
  open?: boolean;
  anchorEl?: HTMLElement | null;
  onClose?: () => void;
  anchorOrigin?: PopoverOrigin;
  inverted?: boolean;
  className?: string;
  variant?: ListVariant.checkboxes | ListVariant.default;
  onClick?: (key: string) => void;
}

const ToggleCompanyListsDropdown: React.FC<ToggleCompanyListsDropdownProps> = ({
  companyIds,
  shouldNavigate,
  open = false,
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  inverted,
  className = '-mt-p30',
  variant = ListVariant.checkboxes,
  onClick = Function.prototype
}) => {
  const [loadingKeys, setLoadingKeys] = useState<string[]>([]);
  const {
    addCompaniesToWatchlist,
    removeCompaniesFromWatchlist,
    getWatchlistContainingCompanies
  } = useWatchlistActions();
  const { watchlists: companyWatchlists, loading } = useFetchWatchlists();
  const [filterTerm, setFilterTerm] = useState('');

  const onCompanyListCreatedSuccess = async ({
    entityListId
  }: UseAddEntityListModalOnSuccessProps) => {
    if (variant === ListVariant.default) {
      await onClick(entityListId);
      return;
    }
    await addCompaniesToWatchlist({
      watchlistId: entityListId,
      shouldNavigate,
      companyIds: companyIds
    });
  };

  const { showAddEntityListModal: showAddCompanyListModal } =
    useAddEntityListModal({
      disableNavigation: variant === ListVariant.default,
      entityType: EntityListType.COMPANY_WATCHLIST,
      searchQuery: INITIAL_SEARCH_MODEL,
      onSuccess: onCompanyListCreatedSuccess,
      disableToast: variant === ListVariant.default
    });

  const selectedLists = getWatchlistContainingCompanies(
    companyWatchlists,
    companyIds
  );

  //User Managed watchlist appears at bottom, selected lists at the top
  const typeFilteredWatchlists = useMemo(
    () =>
      companyWatchlists?.filter(
        (watchlist) =>
          watchlist?.userWatchlistType ===
            UserWatchlistType.USER_MANAGED_WATCHLIST ||
          watchlist?.userWatchlistType ===
            UserWatchlistType.CUSTOMER_MANAGED_WATCHLIST
      ),
    [companyWatchlists]
  );

  const filteredCompanyWatchlists = useMemo(
    () =>
      typeFilteredWatchlists
        ?.filter((list) =>
          list?.name.toLowerCase().includes(filterTerm.toLowerCase())
        )
        .sort((a, b) => {
          const aSelected = selectedLists?.includes(a?.id ?? '');
          const bSelected = selectedLists?.includes(b?.id ?? '');
          return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
        }),
    [typeFilteredWatchlists, filterTerm, selectedLists]
  );

  const onChange = async (key?: string) => {
    if (!key) return;
    try {
      if (!selectedLists?.includes(key)) {
        setLoadingKeys((prev) => {
          return prev.concat(key);
        });
        await addCompaniesToWatchlist({
          companyIds,
          shouldNavigate,
          watchlistId: key
        });
      } else {
        await removeCompaniesFromWatchlist({
          watchlistId: key,
          companyIds,
          confirmBeforeRemoval: true,
          onConfirmRemoval: async () => {
            setLoadingKeys((prev) => {
              return prev.concat(key);
            });
          }
        });
      }
    } finally {
      setLoadingKeys((prev) => {
        return prev.filter((k) => k !== key);
      });
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      elevation={0}
      className="mt-1"
      classes={{
        paper:
          // HACK: Need to add padding to we create room for the children shadow to show
          // Otherwise it gets cut off
          classNames(
            'rounded-none bg-transparent border-transparent p-p30 -ml-p30',
            className
          )
      }}
      disableAutoFocus
      disableEnforceFocus
    >
      <div
        className={classNames(
          'w-64 overflow-hidden',
          !inverted && 'visible-scrollbar',
          inverted && 'inverted-scrollbar'
        )}
      >
        <Dropdown
          onFilterTermChange={(value) => setFilterTerm(value)}
          filterTerm={filterTerm}
          filterPlaceholder="Search"
          dataTestId="AddCompanyToWatchlist-Dropdown"
          inverted={inverted}
        >
          <div
            className={classNames(
              'overflow-y-scroll py-p20 w-full',
              inverted && 'text-white'
            )}
          >
            {loading && <HarmonicLoader showText={false} />}
            {filteredCompanyWatchlists?.map((list) => {
              let primaryIcon = undefined;
              if (list?.userRelevanceScoreInfluenceType === 'DISSIMILAR') {
                primaryIcon = () => (
                  <CircleMinus className="w-4 h-4 text-content-weak" />
                );
              }
              if (list?.userRelevanceScoreInfluenceType === 'SIMILAR') {
                primaryIcon = () => (
                  <CircleCheckIcon className="w-4 h-4 text-yellow" />
                );
              }

              const listItemProps = {
                type: inverted ? ListType.inverted : ListType.default,
                testId: `list-item-${list?.id}`,
                label: list?.name || '',
                value: list?.id || '',
                selected: selectedLists?.includes(list?.id ?? ''),
                primaryIcon,
                primaryIconTooltip:
                  list?.userRelevanceScoreInfluenceType === 'SIMILAR'
                    ? 'This is a Recommend list'
                    : list?.userRelevanceScoreInfluenceType === 'DISSIMILAR'
                    ? 'This is a Do not Recommend list'
                    : undefined,

                loading: loadingKeys.includes(list?.id ?? '')
              };

              if (variant === ListVariant.default) {
                return (
                  <ListItem
                    {...listItemProps}
                    key={list?.id}
                    variant={ListVariant.default}
                    onClick={() => onClick(list?.id)}
                  />
                );
              }

              return (
                <ListItem
                  {...listItemProps}
                  key={list?.id}
                  variant={ListVariant.checkboxes}
                  onChange={() => onChange(list?.id)}
                />
              );
            })}
          </div>
          <div
            className={classNames(
              'w-full h-[2px]',
              !inverted && 'bg-border',
              inverted && 'bg-border-inverted-weak'
            )}
          />
          <ListItem
            type={inverted ? ListType.inverted : ListType.default}
            variant={ListVariant.default}
            primaryIcon={PlusIcon}
            label="Add to new list"
            onClick={showAddCompanyListModal}
            value="add-to-new-list"
          />
        </Dropdown>
      </div>
    </Popover>
  );
};

export default ToggleCompanyListsDropdown;
