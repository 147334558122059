import { useQuery } from '@apollo/client';
import {
  ConnectionIntegrationType,
  GetCurrentUserQuery
} from '__generated__/graphql';
import HarmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import SyncingStateIcon from 'assets/harmonic-icons/syncing-state.svg';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useMemo } from 'react';
import ConnectGoogleCard from './Network/ConnectGoogleCard';
import ConnectLinkedInCard from './Network/ConnectLinkedInCard';
import Shimmer from './Shimmer';

interface NoConnectionsPageContentProps {
  isLinkedinIntegrated: boolean;
  isGmailIntegrated: boolean;
  isLoading: boolean;
  onGoogleSuccess: () => void;
}

export const NoConnectionsPageContent = ({
  isLinkedinIntegrated,
  isGmailIntegrated,
  isLoading,
  onGoogleSuccess
}: NoConnectionsPageContentProps) => {
  // If the list is empty but at least one integration is enabled, we show a syncing state
  const isSyncing = isLinkedinIntegrated || isGmailIntegrated;

  return (
    <div className="w-full h-full flex justify-center">
      <div className="flex flex-col items-center gap-g60 -mt-24">
        <img
          src={isSyncing ? SyncingStateIcon : HarmonicLogo}
          style={{ width: 60, height: 60 }}
        />
        <div className="flex flex-col items-center gap-g40 self-stretch mb-2">
          <p className="text-content-strong text-center typography-title-extraSmall">
            {isSyncing
              ? 'Sync in progress...'
              : 'Integrate your network in a few clicks'}
          </p>
          <p className="text-content-weak typography-paragraph-default-default">
            {isSyncing ? (
              <div>
                This may take up to 24 hours.
                <br />
                {isLinkedinIntegrated && !isGmailIntegrated
                  ? 'Complete your setup by connecting your email and calendar.'
                  : !isLinkedinIntegrated && isGmailIntegrated
                  ? 'Complete your setup by connecting your LinkedIn.'
                  : ''}
              </div>
            ) : (
              <p>
                Map everyone you&apos;ve emailed, met, or connected with on
                LinkedIn onto the complete startup database.
              </p>
            )}
          </p>
        </div>
        {isLoading ? (
          <div className="flex flex-col gap-g60">
            <Shimmer className="w-[584px] h-[139px] rounded-md" />
            <Shimmer className="w-[584px] h-[139px] rounded-md" />
          </div>
        ) : (
          <>
            {!isLinkedinIntegrated && <ConnectLinkedInCard />}
            {!isGmailIntegrated && (
              <ConnectGoogleCard onSuccess={onGoogleSuccess} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const NoConnectionsPage = () => {
  const {
    data: userData,
    loading: integrationStateLoading,
    refetch
  } = useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first'
  });
  const integrationStatus =
    userData?.getCurrentUser?.connectionsIntegrationStatus;

  const isLinkedinIntegrated = useMemo(
    () =>
      integrationStatus?.find(
        (integ) => integ?.type === ConnectionIntegrationType.LINKEDIN
      )?.isEnabled ?? false,
    [integrationStatus]
  );
  const isGmailIntegrated = useMemo(
    () =>
      integrationStatus?.find(
        (integ) => integ?.type === ConnectionIntegrationType.GOOGLE
      )?.isEnabled ?? false,
    [integrationStatus]
  );

  return (
    <NoConnectionsPageContent
      isLinkedinIntegrated={isLinkedinIntegrated}
      isGmailIntegrated={isGmailIntegrated}
      isLoading={integrationStateLoading}
      onGoogleSuccess={refetch}
    />
  );
};

export default NoConnectionsPage;
