import {
  FilterArrayComparator,
  INITIAL_PEOPLE_SORT,
  INITIAL_SEARCH_MODEL
} from 'interfaces/SearchModel/Search';
import { useMemo } from 'react';
import useTableStore from 'stores/tableStore';
import useDashboardLocation from './useDashboardLocation';
import useFetchSinglePeopleWatchlist from './useFetchSinglePeopleWatchlist';

import {
  GetWatchlistWithPeopleQueryVariables,
  PeopleWatchlist
} from '__generated__/graphql';
import { cloneDeep, get } from 'lodash';
import useStore from 'stores/zustandStore';
import { getSelectedPersonNamedViewIndex } from 'utils/namedViews';
import { getModifiedPeopleSortValueAfterValidationCheck } from 'utils/sort';
import { camelize } from 'utils/utilities';

export const usePeopleWatchlistSearchVariables =
  (): GetWatchlistWithPeopleQueryVariables => {
    const { urn } = useDashboardLocation();
    const personListSelectedNamedView = useStore(
      (state) => state.personListSelectedNamedView
    );

    const { peopleWatchlist } = useFetchSinglePeopleWatchlist();

    const personListLocalSearchTerm = useTableStore(
      (state) => state.personListLocalSearchTerm
    );
    const selectedNamedViewIndex =
      getSelectedPersonNamedViewIndex(
        peopleWatchlist as PeopleWatchlist,
        personListSelectedNamedView
      ) ?? 0;

    const currentNamedViewSearchQuery =
      peopleWatchlist?.namedViews?.[selectedNamedViewIndex]?.searchQuery;

    const formattedCurrentNamedViewSearchQuery = useMemo(() => {
      let currentSearchQuery = cloneDeep(camelize(INITIAL_SEARCH_MODEL));

      if (currentNamedViewSearchQuery) {
        currentSearchQuery = currentNamedViewSearchQuery;
      }

      if (
        personListLocalSearchTerm &&
        personListLocalSearchTerm?.[peopleWatchlist?.entityUrn]
      ) {
        return {
          ...currentSearchQuery,
          filterGroup: {
            ...currentSearchQuery.filterGroup,
            filters: [
              ...currentSearchQuery.filterGroup.filters,
              {
                field: 'person_name',
                filterValue:
                  personListLocalSearchTerm?.[peopleWatchlist?.entityUrn],
                comparator: FilterArrayComparator.SUBSTRING_MATCH
              }
            ]
          }
        };
      }
      return currentSearchQuery;
    }, [currentNamedViewSearchQuery, personListLocalSearchTerm]);

    const formattedSort = useMemo(() => {
      return [
        getModifiedPeopleSortValueAfterValidationCheck(
          currentNamedViewSearchQuery?.sort?.[0]?.sortField,
          currentNamedViewSearchQuery?.sort?.[0]?.descending,
          currentNamedViewSearchQuery?.sort?.[0]?.sortContextValues,
          peopleWatchlist?.customFields
        )
      ];
    }, [currentNamedViewSearchQuery, peopleWatchlist?.customFields]);

    const sortDescending = get(
      formattedSort,
      '[0].descending',
      INITIAL_PEOPLE_SORT.sortDescending
    );

    const sortCustomFieldUrn = get(
      formattedSort,
      '[0].sortContextValues.customFieldUrn',
      undefined
    );

    return {
      idOrUrn: urn ?? '',
      sortField: formattedSort?.[0].sortField || INITIAL_PEOPLE_SORT.sortField,
      sortDescending,
      searchQuery: formattedCurrentNamedViewSearchQuery,
      first: null,
      after: null,
      sortCustomFieldUrn
    };
  };
