import * as React from 'react';

/*
 * Accepts a function that handles closing a component whenever anywhere in the backdrop is clicked.
 * It returns a ref to be passed to the component/modal.
 * This can be used to control hiding modals/popups or dialogues.
 */
export const useOutsideClick = (
  closeComponent: () => void
): React.RefObject<HTMLDivElement> => {
  const componentToCloseRef = React.useRef<HTMLDivElement>(null);

  const handleClose = React.useCallback(
    (e: MouseEvent) => {
      const componentToClose = componentToCloseRef.current;
      if (
        !componentToClose ||
        !componentToClose?.contains(e.target as Element)
      ) {
        closeComponent();
      }
    },
    [closeComponent]
  );

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClose);
    return () => window.removeEventListener('mousedown', handleClose);
  }, [handleClose]);

  return componentToCloseRef;
};

/*
 * Uses the click position to determine if the click is outside the component.
 */
export const useOutsideClickPosition = (
  closeComponent: () => void
): React.RefObject<HTMLDivElement> => {
  const componentToCloseRef = React.useRef<HTMLDivElement>(null);

  const handleClose = React.useCallback(
    (e: MouseEvent) => {
      const componentToClose = componentToCloseRef.current;
      if (!componentToClose) return;

      const rect = componentToClose.getBoundingClientRect();
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;

      const isClickPositionInside =
        e.clientX >= rect.left + scrollX &&
        e.clientX <= rect.right + scrollX &&
        e.clientY >= rect.top + scrollY &&
        e.clientY <= rect.bottom + scrollY;

      if (!isClickPositionInside) {
        closeComponent();
      }
    },
    [closeComponent]
  );

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClose);
    return () => window.removeEventListener('mousedown', handleClose);
  }, [handleClose]);

  return componentToCloseRef;
};

export const useDocumentTitle = (title: string): void => {
  React.useMemo(() => {
    document.title = title;
  }, [title]);
};
