// This header is part of our new flow to fetch columnOrder from postgres.
// When it's fully rulled out, this hook should be cleaned up to remove the old flow

import {
  CompanyWatchlist,
  GetCompanyWatchlistQuery,
  GetPeopleWatchlistQuery,
  PeopleWatchlist
} from '__generated__/graphql';
import { ColumnConfig } from 'interfaces/ColumnsConfig';
import CompanyColumnsConfig, {
  CompanyColumnsUrnToValueMap,
  CompanyColumnsValueToObjectMap
} from 'interfaces/CompanyColumnsConfig';
import { ColumnHeaderOrder } from 'interfaces/Grid';
import PersonColumnsConfig, {
  PersonColumnsUrnToValueMap,
  PersonColumnsValueToObjectMap
} from 'interfaces/PersonColumnsConfig';
import { isNil } from 'lodash';

export const getSelectedCompanyNamedViewIndex = (
  companyWatchlist?:
    | Partial<CompanyWatchlist>
    | GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
    | null,
  companyListSelectedNamedView?: Record<string, string> | null
): number => {
  const selectedNamedViewUrn =
    companyListSelectedNamedView?.[companyWatchlist?.entityUrn ?? ''];
  if (!selectedNamedViewUrn) return 0;
  return (
    companyWatchlist?.namedViews?.findIndex(
      (view) => view?.entityUrn === selectedNamedViewUrn
    ) ?? 0
  );
};

export const getSelectedPersonNamedViewIndex = (
  peopleWatchlist?:
    | PeopleWatchlist
    | GetPeopleWatchlistQuery['getPeopleWatchlistByIdOrUrn']
    | null,
  personListSelectedNamedView?: Record<string, string> | null
): number => {
  const selectedNamedViewUrn =
    personListSelectedNamedView?.[peopleWatchlist?.entityUrn ?? ''];
  if (!selectedNamedViewUrn) return 0;
  return (
    peopleWatchlist?.namedViews?.findIndex(
      (view) => view?.entityUrn === selectedNamedViewUrn
    ) ?? 0
  );
};

export const getCompanyWatchlistHeadersFromNamedViews = (
  companyWatchlist: Partial<CompanyWatchlist>,
  selectedNamedViewIndex: number
) => {
  const customFieldHeaders = companyWatchlist?.customFields ?? [];
  const CustomFieldHeadersUrnToValueMap: Record<
    string,
    Partial<ColumnConfig>
  > = {};
  customFieldHeaders.forEach((header) => {
    CustomFieldHeadersUrnToValueMap[header.urn] = {
      label: header.name,
      value: header.urn
    };
  });

  const CompanyColumnsCombinedUrnToValueMap = {
    ...CustomFieldHeadersUrnToValueMap,
    ...CompanyColumnsUrnToValueMap
  };

  let columnUrns =
    companyWatchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ??
    [];
  if (
    companyWatchlist?.namedViews?.[selectedNamedViewIndex]?.localVisibleColumns
  ) {
    columnUrns =
      companyWatchlist?.namedViews?.[selectedNamedViewIndex]
        ?.localVisibleColumns ?? [];
  }
  const filteredColumnUrns = columnUrns.filter(
    (urn) => urn !== CompanyColumnsConfig.NAME.urn
  );
  let columnHeaders = filteredColumnUrns.map((urn) => ({
    id: CompanyColumnsCombinedUrnToValueMap[urn as string]?.value,
    name: CompanyColumnsCombinedUrnToValueMap[urn as string]?.label,
    isVisibleOnTable: true
  })) as ColumnHeaderOrder[];
  columnHeaders = [
    {
      id: CompanyColumnsConfig.NAME.value,
      name: CompanyColumnsConfig.NAME.label,
      isVisibleOnTable: true
    },
    ...columnHeaders
  ];
  return columnHeaders;
};
export const getPeopleWatchlistHeadersFromNamedViews = (
  peopleWatchlist: Partial<PeopleWatchlist>,
  selectedNamedViewIndex: number
) => {
  const customFieldHeaders = peopleWatchlist?.customFields ?? [];
  const CustomFieldHeadersUrnToValueMap: Record<
    string,
    Partial<ColumnConfig>
  > = {};
  customFieldHeaders.forEach((header) => {
    CustomFieldHeadersUrnToValueMap[header.urn] = {
      label: header.name,
      value: header.urn
    };
  });

  const PersonColumnsCombinedUrnToValueMap = {
    ...CustomFieldHeadersUrnToValueMap,
    ...PersonColumnsUrnToValueMap
  };

  let columnUrns =
    peopleWatchlist?.namedViews?.[selectedNamedViewIndex]?.visibleColumns ?? [];
  if (
    !isNil(
      peopleWatchlist?.namedViews?.[selectedNamedViewIndex]?.localVisibleColumns
    )
  ) {
    columnUrns =
      peopleWatchlist?.namedViews?.[selectedNamedViewIndex]
        ?.localVisibleColumns ?? [];
  }
  const filteredColumnUrns = columnUrns.filter(
    (urn) => urn !== PersonColumnsConfig.FULL_NAME.urn
  );
  let columnHeaders = filteredColumnUrns.map((urn) => ({
    id: PersonColumnsCombinedUrnToValueMap[urn as string]?.value,
    name: PersonColumnsCombinedUrnToValueMap[urn as string]?.label,
    isVisibleOnTable: true
  })) as ColumnHeaderOrder[];

  columnHeaders = [
    {
      id: PersonColumnsConfig.FULL_NAME.value,
      name: PersonColumnsConfig.FULL_NAME.label,
      isVisibleOnTable: true
    },
    ...columnHeaders
  ];

  return columnHeaders;
};

export const getCompanySavedSearchColumnOrders = (namedViews: string[]) => {
  const filteredNamedViews = namedViews.filter(
    (view) => view !== CompanyColumnsConfig.NAME.urn
  );
  let columnHeaders = filteredNamedViews.map((urn) => ({
    id: CompanyColumnsUrnToValueMap[urn as string]?.value,
    name: CompanyColumnsUrnToValueMap[urn as string]?.label,
    isVisibleOnTable: true
  })) as ColumnHeaderOrder[];

  columnHeaders = [
    {
      id: CompanyColumnsConfig.NAME.value,
      name: CompanyColumnsConfig.NAME.label,
      isVisibleOnTable: true
    },
    ...columnHeaders
  ];

  return columnHeaders;
};

export const getPeopleSavedSearchColumnOrders = (namedViews: string[]) => {
  const filteredNamedViews = namedViews.filter(
    (view) => view !== PersonColumnsConfig.FULL_NAME.urn
  );
  let columnHeaders = filteredNamedViews.map((urn) => ({
    id: PersonColumnsUrnToValueMap[urn as string]?.value,
    name: PersonColumnsUrnToValueMap[urn as string]?.label,
    isVisibleOnTable: true
  })) as ColumnHeaderOrder[];

  columnHeaders = [
    {
      id: PersonColumnsConfig.FULL_NAME.value,
      name: PersonColumnsConfig.FULL_NAME.label,
      isVisibleOnTable: true
    },
    ...columnHeaders
  ];

  return columnHeaders;
};

export const convertCompanyColumnsConfigToUrns = (
  columns: ColumnHeaderOrder[]
) => {
  return columns
    .filter((col) => col.id)
    .map((column) => {
      return CompanyColumnsValueToObjectMap[column.id]?.urn;
    })
    .filter((col) => col);
};

export const convertCompanyListColumnsConfigToUrns = (
  columns: ColumnHeaderOrder[]
) => {
  return columns
    .filter((col) => col.id)
    .map((column) => {
      if (column.id.startsWith('urn:harmonic:company_list_custom_field')) {
        return column.id;
      }
      return CompanyColumnsValueToObjectMap[column.id]?.urn;
    })
    .filter((col) => col);
};

export const convertPeopleListColumnsConfigToUrns = (
  columns: ColumnHeaderOrder[]
) => {
  return columns
    .filter((col) => col.id)
    .map((column) => {
      if (column.id.startsWith('urn:harmonic:person_list_custom_field')) {
        return column.id;
      }
      return PersonColumnsValueToObjectMap[column.id]?.urn;
    })
    .filter((col) => col);
};

export const convertPersonColumnsConfigToUrns = (
  columns: ColumnHeaderOrder[]
) => {
  return columns
    .filter((col) => col.id)
    .map((column) => {
      return PersonColumnsValueToObjectMap[column.id]?.urn;
    })
    .filter((col) => col);
};

export const COMPANY_WATCHLIST_GRID_VIEW_DEFAULT_COLUMNS = [
  'urn:harmonic:company_field:company_description',
  'urn:harmonic:company_field:company_highlights',
  'urn:harmonic:company_field:company_website_url',
  'urn:harmonic:company_field:company_team',
  'urn:harmonic:company_field:company_customers_connections',
  'urn:harmonic:company_field:company_customers_connections_last_email_at',
  'urn:harmonic:company_field:company_customers_connections_last_meeting_at',
  'urn:harmonic:company_field:company_team_notes',
  'urn:harmonic:company_field:company_investor_names',
  'urn:harmonic:company_field:company_funding_total',
  'urn:harmonic:company_field:company_last_funding_type',
  'urn:harmonic:company_field:company_last_funding_date',
  'urn:harmonic:company_field:company_headcount',
  'urn:harmonic:company_field:company_headcount_percent_change_90d_ago'
];

export const COMPANY_WATCHLIST_KANBAN_VIEW_DEFAULT_COLUMNS = [
  'urn:harmonic:company_field:company_description',
  'urn:harmonic:company_field:company_website_url',
  'urn:harmonic:company_field:company_funding_total',
  'urn:harmonic:company_field:company_last_funding_date',
  'urn:harmonic:company_field:company_web_traffic_value_90d_ago',
  'urn:harmonic:company_field:company_team_notes'
];

export const convertCompanyListNamedViewIdToUrn = (
  namedViewId: string | number
) => {
  return `urn:harmonic:company_list_named_view:${namedViewId}`;
};
export const convertPersonListNamedViewIdToUrn = (
  namedViewId: string | number
) => {
  return `urn:harmonic:person_list_named_view:${namedViewId}`;
};

const SELECTED_NAMED_VIEW_QUERY_STRING = 'selectedNamedView';
export const getSelectedNamedViewIdByQueryParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const selectedNamedView = urlParams.get(SELECTED_NAMED_VIEW_QUERY_STRING);
  return selectedNamedView;
};

export const setSelectedNamedViewIdQueryParam = (
  namedViewId: string | number | null
) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(SELECTED_NAMED_VIEW_QUERY_STRING, `${namedViewId}`);
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.pushState({}, '', newUrl);
};

export const removeSelectedNamedViewIdQueryParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(SELECTED_NAMED_VIEW_QUERY_STRING);
  let newUrl = window.location.pathname;
  if (urlParams.toString()) {
    newUrl += `?${urlParams.toString()}`;
  }
  window.history.pushState({}, '', newUrl);
};
