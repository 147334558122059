/* eslint-disable testing-library/render-result-naming-convention */
import { ITableHeader } from 'interfaces/Grid';
import { SortContext, SortGroup } from 'utils/sort';
import {
  CommonCellRenderer,
  CommonCellRendererProps
} from '../CellRenderers/CommonCellRenderer';
import {
  COMPANY_HEADER_DESCRIPTION,
  COMPANY_HEADER_EXTERNAL_DESCRIPTION,
  COMPANY_HEADER_LEGAL_NAME,
  CommonCompanyDetailHeaders,
  GrowthMetricsHeaders
} from './CommonHeaders';
import { formatHeadersForCellRenderers } from './utils';

const COMPANY_HEADER_ROW_ACTIONS: ITableHeader = {
  field: 'company.row_actions',
  cellRenderer: 'CompanyNameWithCheckboxCellRenderer',
  headerName: 'Name',
  initialPinned: true,
  headerComponent: 'BulkSelectCheckboxRenderer',
  minWidth: 235,
  suppressSizeToFit: true,
  valueGetter: (params) => params.data,
  sortable: true,
  searchModelIdentifier: 'company_sortable_name',
  sortContext: {
    sortGroup: SortGroup.COMPANY_NAME
  }
};

const COMPANY_HEADER_ID: ITableHeader = {
  field: 'company.id',
  headerName: 'ID',
  hide: true,
  width: 70,
  sortable: false,
  filterModelIdentifier: 'company_id'
};

const COMPANY_HEADER_RELEVANCE_SCORE: ITableHeader = {
  field: 'company.relevance_score',
  headerName: 'Relevance Score',
  initialWidth: 250,
  hide: false,
  cellRenderer: 'RelevanceScoreCellRenderer',
  customTooltip: 'Configure relevance score in Settings',
  sortable: true,
  searchModelIdentifier: 'relevance_score',
  filterModelIdentifier: 'relevance_score',
  sortContext: {
    sortGroup: SortGroup.MOST_RELEVANT
  }
};

const COMPANY_HEADER_CREATED_AT: ITableHeader = {
  field: 'company.created_at',
  headerName: 'Date added to Harmonic',
  hide: true,
  initialWidth: 240,
  cellRenderer: 'DateCellRenderer',
  sortable: true,
  searchModelIdentifier: 'company_created_at',
  filterModelIdentifier: 'company_created_at',
  sortContext: {
    sortGroup: SortGroup.MOST_RECENTLY_ADDED
  }
};

const COMPANY_HEADER_ENTRY_CREATED_AT: ITableHeader = {
  field: 'company.entry_created_at',
  headerName: 'Date added to list',
  hide: true,
  initialWidth: 250,
  cellRenderer: 'DateCellRenderer',
  sortable: true,
  searchModelIdentifier: 'company_entry_created_at',
  sortContext: {
    sortGroup: SortGroup.DATE_ADDED_TO_LIST
  }
};

const COMPANY_HEADER_ENTRY_ADDED_BY: ITableHeader = {
  field: 'company.added_by',
  headerName: 'Added to list by',
  hide: true,
  initialWidth: 250,
  cellRenderer: 'UserCellRenderer',
  sortable: true,
  searchModelIdentifier: 'company_entry_added_by',
  sortContext: {
    sortGroup: SortGroup.ADDED_BY
  }
};

const COMPANY_HEADER_UPDATED_AT: ITableHeader = {
  field: 'company.updated_at',
  headerName: 'Updated Date',
  hide: true,
  initialWidth: 200,
  cellRenderer: 'DateCellRenderer',
  sortable: true,
  searchModelIdentifier: 'company_updated_at',
  filterModelIdentifier: 'company_updated_at',
  sortContext: {
    sortGroup: SortGroup.UPDATED_AT
  }
};

const CommonCompanyHeaders: Array<ITableHeader> = [
  COMPANY_HEADER_ROW_ACTIONS,
  COMPANY_HEADER_ID,
  COMPANY_HEADER_RELEVANCE_SCORE,
  COMPANY_HEADER_LEGAL_NAME,
  COMPANY_HEADER_DESCRIPTION,
  COMPANY_HEADER_EXTERNAL_DESCRIPTION,
  COMPANY_HEADER_CREATED_AT,
  COMPANY_HEADER_ENTRY_CREATED_AT,
  COMPANY_HEADER_ENTRY_ADDED_BY,
  COMPANY_HEADER_UPDATED_AT
];

const _CompanyHeaders: Array<ITableHeader> = [
  ...CommonCompanyHeaders,
  ...CommonCompanyDetailHeaders,
  ...GrowthMetricsHeaders.map((header) => ({ ...header, hide: true }))
];

/*
  CommonCompanyHeadersByField is a map of header fields that are returned by the query without extended data.
*/
const CommonCompanyHeadersByField = formatHeadersForCellRenderers({
  headers: CommonCompanyHeaders,
  excludeHeaders: [COMPANY_HEADER_RELEVANCE_SCORE]
});

const CompanyHeaders = _CompanyHeaders.map((header) => {
  return {
    ...header,
    cellRenderer: (params: CommonCellRendererProps) => (
      <CommonCellRenderer
        header={header}
        headers={CommonCompanyHeadersByField}
        {...params}
      />
    )
  } as ITableHeader;
});

export const companySortableHeaderFields = [
  'company_id',
  ...CompanyHeaders.filter(
    (header) => header.sortable && header.searchModelIdentifier
  ).map((header) => header.searchModelIdentifier as string)
];

export const SortContextByCompanyField = [
  ...CompanyHeaders,
  // We do not have a column for featured, but we still sort by it.
  {
    sortable: true,
    searchModelIdentifier: 'featured',
    sortContext: {
      sortGroup: SortGroup.FEATURED
    }
  },

  // Custom fields
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_text',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_TEXT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_number',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_NUMBER
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_date',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_DATE
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_single_select',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_SINGLE_SELECT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_multi_select',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_MULTI_SELECT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_person',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_PERSON
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_status',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_STATUS
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_checkbox',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_CHECKBOX
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'company_custom_field_url',
    sortContext: {
      sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_URL
    }
  }
].reduce((acc, header) => {
  acc[header.searchModelIdentifier as string] =
    header.sortContext as unknown as SortContext;
  return acc;
}, {} as Record<string, SortContext>);

export default CompanyHeaders;
