import { CalendarDatePickerIcon, CrossIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import {
  ReactElement,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState
} from 'react';
interface DatePickerInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  className?: string;
  borderOverrideClasses?: string;
  minHeight?: number;
  initialFocus?: boolean;
  autoFocus?: boolean;
  native?: boolean;
  inputPlaceholder?: string;
  isClearable?: boolean;
}

const DatePickerInput = forwardRef(
  (
    {
      value,
      onChange,
      autoFocus,
      native = true,
      inputPlaceholder,
      isClearable = true,
      ...props
    }: DatePickerInputProps,
    ref: Ref<HTMLDivElement>
  ): ReactElement => {
    const [isFocused, setIsFocused] = useState(Boolean(props.initialFocus));
    const paddingX = `calc(var(--spacing-p40) + 16px + var(--gap-g10))`;
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (autoFocus) inputRef?.current?.focus();
    }, [autoFocus]);

    return (
      <div
        ref={ref}
        {...props}
        className={classNames(props.className, 'relative')}
      >
        <div className="bg-surface-default">
          <div
            data-open={isFocused}
            style={{
              minHeight: props.minHeight,
              paddingLeft: paddingX,
              paddingRight: paddingX
            }}
            className={classNames(
              'py-p40 relative border border-solid typography-label w-full',
              'bg-int-overlay-secondary-enabled placeholder-content-muted',
              'text-content-default rounded-br20',
              !props.borderOverrideClasses &&
                classNames(
                  'border-int-outline-secondary-enabled',
                  // Hover state
                  'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover',
                  // Active state
                  'active:border-int-outline-secondary-pressed',
                  // Focus state
                  'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled',
                  'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled',
                  // Disabled state
                  'disabled:border-int-outline-secondary-disabled'
                ),
              // Hover state
              'hover:text-input-value-hover',
              // Active state
              'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed',
              // Focus state
              'focus-visible:bg-int-overlay-secondary-enabled',
              'data-[open=true]:bg-int-overlay-secondary-enabled',
              // Disabled state
              'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled',
              props.borderOverrideClasses
            )}
          >
            <div
              className={classNames('absolute top-0 left-0 bottom-0 pl-p40', {
                'flex items-center': !props.minHeight,
                'pt-p40 mt-p10': props.minHeight
              })}
            >
              <CalendarDatePickerIcon className="text-content-weak w-4 h-4" />
            </div>
            <input
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              {...{ type: native ? 'date' : 'number' }}
              ref={inputRef}
              placeholder={inputPlaceholder}
              value={value}
              onChange={onChange}
              autoFocus={autoFocus}
              className="bg-int-overlay-secondary-enabled custom-text-input"
              data-testid="DatePickerInput"
            />
          </div>
        </div>
        {isClearable && (
          <div className="absolute right-0 top-[10px] pr-p40 ">
            <IconButton
              onClick={() =>
                onChange({
                  target: {
                    value: ''
                  }
                } as React.ChangeEvent<HTMLInputElement>)
              }
              icon={CrossIcon}
              type="secondary"
              emphasis="low"
              size="tiny"
            />
          </div>
        )}
      </div>
    );
  }
);

export default DatePickerInput;
