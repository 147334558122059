import { uniqBy } from 'lodash';

interface Connection<T, U extends string> {
  edges: (T | undefined | null)[];
  totalCount: number;
  currentCount?: number;
  __typename?: U;
}

export const listKeyArgs = (args: Record<string, unknown> | null) => {
  const searchQuery = {
    ...(args?.searchQuery || {
      pagination: {}
    })
  };
  delete searchQuery.pagination;
  const keyWithoutPagination = {
    searchQuery,
    sortDescending: args?.sortDescending,
    sortField: args?.sortField,
    sortContextValues: args?.sortContextValues
  };
  return JSON.stringify(keyWithoutPagination);
};
/**
 * Merge logic:
 * We want to write any incoming results to the proper location in the cache, depending
 * on which page and page size we are fetching. I.e. we need to make sure not to overwrite
 * the first page of results if the incoming data is for the second page.
 */
export function paginationMerge<T extends { cursor: string }, U extends string>(
  existing: Connection<T, U> | undefined,
  incoming: Connection<T, U>,
  page = 0,
  size = 10
): Connection<T, U> {
  const pageStart = page * size;
  const merged = existing?.edges ? existing.edges.slice(0) : []; // Slice to create a mutable copy

  if (incoming.edges) {
    for (let i = 0; i < incoming.edges.length; ++i) {
      merged[pageStart + i] = incoming.edges[i];
    }
  }

  // We should check that there arent duplicates in the merged list. if there are that could indicate theres a race condition.
  // Example race condition: 2 pages, (20 edges in cache) -> Hide the second to last company on first page from search ->
  // Will kick off a refetch of searchCompanies for page 2 (edges 10-19) -> before that refetch returns hide the second to last company again ->
  // once original refetch is returned the are duplicate companies at edge 9 and 10.
  // Reason the above merge slice only cares about its window, not the entire list.
  const uniqMerged = uniqBy(merged, 'cursor'); // When removing duplicate elements from the merged list, the read function may kick off another refetch.
  return {
    ...incoming,
    edges: uniqMerged
  };
}

export const paginationRead = <T, U extends string>(
  existing: Connection<T | undefined | null, U> | null | undefined,
  page = 0,
  size = 10
): Connection<T | undefined | null, U> | undefined => {
  if (!existing) return undefined;

  const trueResultsCount = existing.totalCount;
  const cachedResultsCount = existing.edges.length;

  const pageStart = page * size;
  const lastResultIndex = Math.min(pageStart + size, trueResultsCount);

  const missingResults: boolean = lastResultIndex > cachedResultsCount;

  if (missingResults) {
    // Missing expected number of results, fetch from network
    return undefined;
  }

  for (let i = pageStart; i < lastResultIndex; i++) {
    if (existing.edges[i] === undefined) {
      // Missing intermediate edge, fetch from network
      return undefined;
    }
  }

  return {
    ...existing,
    edges: existing.edges.slice(pageStart, lastResultIndex),
    // currentCount is the number of results in the cache, not the total number of results
    currentCount: existing.edges.length
  };
};
