import InternalLink from 'components/common/InternalLink';
import { ICompany } from 'interfaces/DataModel/Company';
import { IHighlight } from 'interfaces/DataModel/Highlight';
import { get, uniqBy } from 'lodash';
import { shouldLinkCompanyId } from 'utils/company';
import { EventLocations } from './analytics';
import { HighlightCategory, HighlightCategoryMap } from './constants';

export const getCompanyHighlights = (
  value: {
    text: string;
    category: string;
  }[],
  company?: ICompany
) => {
  const list: { category: string; count: number; tooltip: JSX.Element }[] = [];

  // Primary Highlights
  uniqBy(value, 'text')?.forEach(
    (highlight: { text: string; category: string }) => {
      if (highlight.text && !highlight.category.includes('_')) {
        const splittedHighlightText = highlight?.text?.split(':');
        const tooltip = shouldLinkCompanyId(company?.id) ? (
          <div>
            <InternalLink
              className="underline"
              to={{ pathname: `/dashboard/company/${company?.id}` }}
              openedFromLocation={EventLocations.GRID_TABLE_HIGHLIGHTS}
            >
              {splittedHighlightText?.[0]}:
            </InternalLink>
            {splittedHighlightText?.[1]}
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{highlight.text}</>
        );

        list.push({
          category: highlight.category,
          count: 1,
          tooltip
        });
      }
    }
  );

  // company's secondary highlights are employee highlights
  const secondaryHighlights = get(company, 'employee_highlights', []);
  const formattedSecondaryHighlights: IHighlight[] = secondaryHighlights?.map(
    (highlight) => {
      return {
        category:
          HighlightCategoryMap[
            highlight.category as keyof typeof HighlightCategory
          ] || highlight.category,
        text: highlight.text
      };
    }
  );
  const groupedHighlights = new Map<string, IHighlight[]>();
  if (formattedSecondaryHighlights) {
    formattedSecondaryHighlights
      ?.filter((highlight) => highlight && !highlight.category?.includes('_'))
      .forEach((highlight) => {
        if (highlight?.category) {
          groupedHighlights.set(
            highlight?.category,
            (groupedHighlights.get(highlight?.category) || []).concat([
              highlight
            ])
          );
        }
      });
  }

  // Aggregate primary and secondary highlights
  groupedHighlights.forEach((groupedHighlights, category) => {
    const companyId = company?.id;
    groupedHighlights = uniqBy(groupedHighlights, 'text');
    const tooltip = (
      <div className="flex flex-col w-full gap-g30">
        {groupedHighlights.map((highlight, index) => {
          return (
            <div className="w-full" key={`${companyId}-highlightLink-${index}`}>
              <>{highlight.text}</>
            </div>
          );
        })}
      </div>
    );

    list.push({
      category,
      count: groupedHighlights.length,
      tooltip
    });
  });
  return list;
};

export const getPeopleHighlights = (value: IHighlight[]) => {
  const list: { category: string; count: number }[] = [];
  const groupedHighlights = new Map<string, IHighlight[]>();
  if (value) {
    value
      .filter(
        (highlight: IHighlight) =>
          highlight && !highlight.category?.includes('_')
      )
      .forEach((highlight: IHighlight) =>
        groupedHighlights.set(
          highlight?.category,
          (groupedHighlights.get(highlight?.category) || []).concat([highlight])
        )
      );
  }

  groupedHighlights.forEach((groupedHighlights, category) => {
    list.push({
      category,
      count: groupedHighlights.length
    });
  });
  return list;
};
