import { useQuery } from '@apollo/client';
import {
  GetCompanyUserConnectionsV2_UserFragment,
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables
} from '__generated__/graphql';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import {
  BusinessIcon,
  ChevronGrabberVertical,
  GraduationCapIcon,
  LinkedInIcon
} from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import UserConnectionsDegree from 'components/Dashboard/CompanyV2/CompanyContact/UserConnectionsDegree';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import dayjs from 'dayjs';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Button from 'harmonic-components/Button/Button';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { PersonRole } from 'interfaces/DataModel/PersonV2';
import getPersonContactInformation from 'queries/getPersonContactInformation';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { shouldLinkCompanyId } from 'utils/company';
import { COMPANY_ROUTE, DASHBOARD_ROUTE, PERSON_ROUTE } from 'utils/constants';
import { getDurationText, isSafeHostedAsset } from 'utils/utilities';
import AddPeopleToWatchlist from '../AddPeopleToWatchlist';
import GetPersonEmailButton from '../ContactData/GetEmailButton';
import HighlightsChip from '../Highlights/HighlightsChip';
import Paper from '../Paper';
import PersonUserConnections from '../PersonUserConnections';

// For creating connecting dots with line
const MultiExperienceLineWrapper = styled.div`
  .nested-experience {
    position: relative;
  }

  .nested-experience:before {
    position: absolute;
    content: ' ○';
    margin-left: -35px;
    font-size: 22px;
    top: 4px;
    color: var(--color-border-strong);
  }
  .nested-experience:after {
    position: absolute;
    left: 0;
    content: '';
    top: 27.5px;
    border-left: 1.5px solid var(--color-border-strong);
    margin-left: -29.5px;
    height: 79%;
  }
  .nested-experience:first-of-type:after {
  }
  .nested-experience:last-of-type:after {
    height: 0%;
  }
`;

export interface PersonPastExperience {
  id: number;
  companyName: string;
  logoUrl?: string | null;
  highlights: string[];
  roles: PersonRole[];
}

export interface PersonCard {
  id: number;
  fullName: string;
  profilePictureUrl?: string | null;
  universityName?: string | null;
  currentStartDate?: string | null;
  highlights: string[];
  currentTitle: string;
  pastExperiences: PersonPastExperience[];
  linkedinUrl?: string | null;
  userConnections?: GetCompanyUserConnectionsV2_UserFragment[];
}

const HIGHLIGHT_CAP = 3;

const PersonCardV2: React.FC<PersonCard> = ({
  id,
  fullName,
  currentTitle,
  profilePictureUrl,
  universityName,
  highlights,
  pastExperiences,
  linkedinUrl,
  userConnections
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const pastExperiencesLength = pastExperiences.length;

  useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id
    }
  });

  const renderPastExperience = (experience: PersonPastExperience) => {
    const roles = experience?.roles ?? [];
    if (roles.length === 0) return null;
    const logoUrl = experience?.logoUrl;
    const containsMultipleRoles = experience?.roles?.length > 1;
    const currentRole = roles?.[0];
    let startDate = currentRole?.startDate;
    let endDate = currentRole?.endDate;
    const isCurrentPosition = currentRole?.isCurrentPosition;
    if (containsMultipleRoles) {
      startDate = roles?.[roles?.length - 1]?.startDate;
      endDate = roles?.[0].startDate;
    }
    const companyId = experience?.id;
    const companyRoute = `${DASHBOARD_ROUTE}${COMPANY_ROUTE}/${companyId}`;
    const durationText = getDurationText(startDate, endDate);

    const experienceHighlights =
      experience?.highlights?.map((highlight) => ({ category: highlight })) ??
      [];
    const formattedLogoUrl = isSafeHostedAsset(logoUrl ?? '')
      ? (logoUrl as string)
      : defaultCompanyLogo;
    return (
      <div className="bg-surface-nested rounded-br40 p-p40 border border-solid border-border">
        <div>
          <div
            className={classNames(
              'flex justify-between',
              !containsMultipleRoles && 'items-center'
            )}
          >
            <div className="flex gap-g50">
              <Link
                to={companyRoute}
                onClick={(e) => {
                  if (!shouldLinkCompanyId(companyId)) e.preventDefault();
                }}
                className={classNames(
                  !shouldLinkCompanyId(companyId) && 'cursor-default'
                )}
              >
                <Avatar src={formattedLogoUrl} size="huge" alt="Company logo" />
              </Link>
              <div className="flex flex-col justify-center">
                {!containsMultipleRoles && (
                  <>
                    <Link
                      to={companyRoute}
                      onClick={(e) => {
                        if (!shouldLinkCompanyId(companyId)) e.preventDefault();
                      }}
                      className={classNames(
                        !shouldLinkCompanyId(companyId) && 'cursor-default'
                      )}
                    >
                      <p className="flex items-center text-content-strong typography-label">
                        <p>{experience.companyName}</p>
                        {currentRole?.title && (
                          <>
                            <span className="mx-g40 text-content-muted">•</span>
                            <p>{currentRole?.title}</p>
                          </>
                        )}
                      </p>
                    </Link>
                    {(startDate || endDate) && (
                      <p
                        className={classNames(
                          'text-content-weak typography-label'
                        )}
                      >
                        {startDate && dayjs(startDate).format('MMM YYYY')}
                        {isCurrentPosition && ' - Present'}
                        {endDate && ` - ${dayjs(endDate).format('MMM YYYY')}`}
                        {durationText && (
                          <>
                            <span className="mx-g40 text-content-muted">•</span>
                            {durationText}
                          </>
                        )}
                      </p>
                    )}
                  </>
                )}
                {containsMultipleRoles && (
                  <>
                    <Link
                      to={companyRoute}
                      onClick={(e) => {
                        if (!shouldLinkCompanyId(companyId)) e.preventDefault();
                      }}
                      className={classNames(
                        !shouldLinkCompanyId(companyId) && 'cursor-default'
                      )}
                    >
                      <p
                        className={classNames(
                          'typography-label-default-strong text-content-strong'
                        )}
                      >
                        {experience.companyName}
                      </p>
                    </Link>
                    {durationText && (
                      <p
                        className={classNames(
                          'text-content-weak typography-label'
                        )}
                      >
                        {durationText}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <HighlightsChip
                highlights={experienceHighlights}
                limit={HIGHLIGHT_CAP}
              />
            </div>
          </div>
        </div>
        {containsMultipleRoles && (
          <MultiExperienceLineWrapper className="pl-p50 ml-g90 mt-p40 flex flex-col">
            {roles.map((role) => {
              const startDate = role.startDate
                ? dayjs(role.startDate).format('MMM YYYY')
                : '';
              return (
                <div key={role.title} className="nested-experience py-p40">
                  <p className="text-content-default typography-label-default-strong">
                    {role.title}
                  </p>
                  <p className="mt-p20 text-content-weak typography-label">
                    {startDate}
                  </p>
                </div>
              );
            })}
          </MultiExperienceLineWrapper>
        )}
      </div>
    );
  };

  const personRoute = `${DASHBOARD_ROUTE}${PERSON_ROUTE}/${id}`;
  const priorExperience = pastExperiences.find((exp) =>
    Boolean(exp.roles[0].endDate)
  );
  const priorExperienceCompany = priorExperience?.companyName ?? '';
  const priorExperienceCompanyLogo = isSafeHostedAsset(
    priorExperience?.logoUrl ?? ''
  )
    ? priorExperience?.logoUrl
    : '';
  const priorExperienceCompanyId = priorExperience?.id;

  return (
    <Paper>
      <div className="flex justify-between">
        <div className="flex gap-g50">
          <div className="pb-3">
            <AvatarWithHighlightBadge
              href={personRoute}
              src={profilePictureUrl ?? ''}
              highlights={highlights}
              personId={id}
            />
          </div>

          <div className="flex flex-col gap-g30">
            <div>
              <div className="flex flex-row justify-start gap-x-g20 items-end lg:items-center">
                <Link to={personRoute} className="shrink-0 basis-auto">
                  <p className="typography-label-large-strong text-content-strong">
                    <div className="flex items-center gap-x-g20">
                      {fullName}
                      <div className="lg:hidden">
                        {linkedinUrl && (
                          <IconButton
                            icon={(props: IconProps) => (
                              <LinkedInIcon
                                {...props}
                                applyCurrentColor={false}
                              />
                            )}
                            size="tiny"
                            type="secondary"
                            emphasis="low"
                            onClick={() => window.open(linkedinUrl)}
                          />
                        )}
                      </div>
                    </div>
                  </p>
                </Link>
                <div className="hidden lg:flex">
                  {linkedinUrl && (
                    <IconButton
                      icon={(props: IconProps) => (
                        <LinkedInIcon {...props} applyCurrentColor={false} />
                      )}
                      size="tiny"
                      type="secondary"
                      emphasis="low"
                      onClick={() => window.open(linkedinUrl)}
                    />
                  )}

                  {userConnections && userConnections.length > 0 && (
                    <div className="flex flex-row items-center gap-x-1">
                      <span className="text-content-weak">•</span>
                      <UserConnectionsDegree
                        userConnections={userConnections}
                      />
                    </div>
                  )}
                </div>
              </div>
              <p className={classNames('typography-label text-content-weak')}>
                {currentTitle}
              </p>
            </div>
            <div className="flex flex-col gap-g10">
              {priorExperience &&
                priorExperienceCompanyLogo &&
                priorExperienceCompanyId && (
                  <div className="flex flex-row gap-g30 items-center w-full justify-start">
                    <BusinessIcon className="text-content-weak w-4 h-4" />
                    <p className="typography-label text-content-weak text-nowrap">
                      Prior company:
                    </p>
                    <CompanyAttribute
                      internalLink
                      name={priorExperienceCompany}
                      src={priorExperienceCompanyLogo}
                      href={`/dashboard/company/${priorExperienceCompanyId}`}
                    />
                  </div>
                )}
              {universityName && (
                <p
                  className={classNames(
                    'typography-label flex gap-g30 text-content-default items-center'
                  )}
                >
                  <GraduationCapIcon className="text-content-weak w-4 h-4" />{' '}
                  <span>{universityName}</span>
                </p>
              )}
              {userConnections && userConnections.length > 0 && (
                <PersonUserConnections
                  target={{ name: fullName }}
                  userConnections={userConnections}
                  gap="g30"
                  iconFill="weak"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start gap-2">
          <div>
            <GetPersonEmailButton personId={id} size="compact" />
          </div>
          <div>
            <AddPeopleToWatchlist personIds={[id]} />
          </div>
        </div>
      </div>

      {isExpanded && (
        <div
          data-testid="PersonCard-Experience-Container"
          className="flex flex-col gap-g40 mb-p40 mt-p40"
        >
          {pastExperiences.map((experience, index) => {
            return (
              <div key={experience.id}>{renderPastExperience(experience)}</div>
            );
          })}
        </div>
      )}

      {pastExperiencesLength > 0 && (
        <div className="flex justify-center">
          <Button
            type="secondary"
            emphasis="low"
            leadingIcon={ChevronGrabberVertical}
            label={isExpanded ? 'Show less' : 'Show more'}
            onClick={() => setIsExpanded(!isExpanded)}
            dataTestId="PersonCard-Expand-Btn"
          />
        </div>
      )}
    </Paper>
  );
};

export default PersonCardV2;
