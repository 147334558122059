import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import React, { MutableRefObject, createContext, useState } from 'react';
import { CustomFieldsFragment } from '../../../__generated__/graphql';
import useFlags from '../../../hooks/useFlags';
import analytics, { CustomTrackEvent } from '../../../utils/analytics';
import { SPLITS } from '../../../utils/constants';
import { BoardColumnRefObject } from './BoardColumn';
import { getCustomFieldValue } from './utils';

export const NOT_APPLICABLE_FIELD_OPTION = {
  name: 'N/A',
  urn: 'n/a',
  color: 'informative'
};

interface BoardColumnOption {
  name: string;
  urn: string;
  color: string;
}

export interface BoardColumnProps {
  watchlistId: string;
  fieldUrn: string;
  option: BoardColumnOption;
}

export interface DragAndDropParams {
  watchlistEntryUrn: string;
  customFieldUrn: string;
  customFieldValueUrn: string | null;
  previousCustomFieldValueUrn: string | null;
  index: number;
}

export const BoardContext = createContext<{
  isDragging: boolean;
}>({
  isDragging: false
});

export const BoardView = ({
  watchlistId,
  customField,
  boardColumn,
  onDragEnd,
  columnRef
}: {
  watchlistId: string;
  customField: CustomFieldsFragment;
  boardColumn: (props: BoardColumnProps) => React.ReactElement;
  onDragEnd: (params: DragAndDropParams) => Promise<void>;
  columnRef?: MutableRefObject<BoardColumnRefObject>;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { enabled: enableKanbanViewRefetch } = useFlags(
    SPLITS.enableKanbanViewRefetch
  );

  if (customField.metadata?.__typename !== 'SelectListCustomFieldMetadata') {
    // TODO: This is an exception, the kanban view shouldn't be rendered
    // without on a custom field thar is not a select list type. Doing this hack
    // while we work on the prototype.
    return null;
  }

  const fieldUrn = customField.urn;
  const options = customField.metadata.options || [];

  const BoardColumn = boardColumn;

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = async (result: DropResult) => {
    const customFieldValue = result.destination?.droppableId;
    if (!customFieldValue) return;

    const previousCustomFieldUrn = result.source.droppableId;

    if (previousCustomFieldUrn === customFieldValue) return;

    analytics.trackCustomEvent({
      event: CustomTrackEvent.BOARD_VIEW_DROP_END,
      properties: {
        watchlistId: watchlistId,
        customFieldUrn: fieldUrn,
        customFieldValueUrn: customFieldValue,
        previousCustomFieldValueUrn: previousCustomFieldUrn
      }
    });

    setIsDragging(false);

    await onDragEnd?.({
      customFieldValueUrn: getCustomFieldValue(customFieldValue),
      previousCustomFieldValueUrn: getCustomFieldValue(previousCustomFieldUrn),
      watchlistEntryUrn: result.draggableId,
      customFieldUrn: customField?.urn,
      index: result.destination?.index || 0
    });

    // Refetch the column to receive the right order
    if (enableKanbanViewRefetch) {
      columnRef?.current?.[customFieldValue]?.refetch();
    }
  };

  const NOT_APPLICABLE_FIELD_OPTION = {
    name: `No ${customField.name}`,
    urn: 'n/a',
    color: 'neutral'
  };
  return (
    <div className="flex flex-col h-full py-2 px-1">
      <div className="flex w-full overflow-x-scroll">
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <BoardContext.Provider value={{ isDragging }}>
            <BoardColumn
              watchlistId={watchlistId}
              fieldUrn={fieldUrn}
              option={NOT_APPLICABLE_FIELD_OPTION}
            />
            {options?.map((option, i) => (
              <BoardColumn
                key={i}
                watchlistId={watchlistId}
                fieldUrn={fieldUrn}
                option={option}
              />
            ))}
          </BoardContext.Provider>
        </DragDropContext>
      </div>
    </div>
  );
};
