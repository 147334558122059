import { gql } from '@apollo/client';

export const SavedSearchFragment = gql`
  fragment FullSavedSearch on SavedSearch {
    __typename
    id
    entityUrn
    isPrivate
    name

    type
    creator {
      entityUrn
      email
      name
    }
    userOptions {
      isPinned
      lastViewedAt
    }
    search_type: type
    visibleColumns
  }
`;

export const SavedSearchFragmentWithoutColumns = gql`
  fragment SavedSearch on SavedSearch {
    __typename
    id
    entityUrn
    isPrivate
    name
    type
    creator {
      entityUrn
      email
      name
    }
    userOptions {
      isPinned
      lastViewedAt
    }
    search_type: type
    updatedAt
    createdAt
  }
`;
