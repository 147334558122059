import { ReactComponent as ConnectGoogleGraphic } from 'assets/banners/connect-google.svg';
import IntegrateGmailButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateGmailButton';
import useFlags from 'hooks/useFlags';
import { FC } from 'react';
import { SPLITS } from 'utils/constants';

const ConnectGoogleCard: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const { enabled: blockEmailIntegration } = useFlags(
    SPLITS.blockEmailIntegration
  );
  if (blockEmailIntegration) {
    return null;
  }
  return (
    <div className="border border-solid border-border rounded-lg space-y-2 w-[584px]">
      <div className="flex items-center gap-g60">
        <div>
          <ConnectGoogleGraphic />
        </div>
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col gap-g40">
            <p className="text-content-strong typography-label-default-strong text-left">
              Sync your Google calendar and inbox
            </p>
            <p className="text-content-default typography-paragraph-default-default">
              Track communications and meetings across your team
            </p>
          </div>
          <div className="w-min">
            <IntegrateGmailButton
              type="primary"
              labelOverride="Connect"
              onSuccess={onSuccess}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectGoogleCard;
