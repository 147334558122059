import classNames from 'classnames';
import { HarmonicIcon } from './type';

const DollarsIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <g fill="#7C8598" clipPath="url(#a)">
        <path
          d="M3.667 9.467a5.867 5.867 0 1 1 11.733 0 5.867 5.867 0 0 1-11.733 0M9.973 5.8a.44.44 0 0 0-.88 0v.62c-.327.05-.633.163-.884.362-.378.297-.575.741-.575 1.277 0 .624.254 1.048.64 1.333.311.229.706.36 1.022.466l.096.033.073.024c.35.119.612.207.805.35.164.12.282.283.282.624 0 .306-.102.478-.24.586-.152.12-.407.206-.779.206-.471 0-.773-.215-.923-.485a.44.44 0 1 0-.77.428c.253.452.691.781 1.253.894v.615a.44.44 0 0 0 .88 0v-.604c.327-.051.633-.165.885-.363.377-.298.574-.741.574-1.277 0-.624-.253-1.048-.64-1.333-.31-.229-.705-.36-1.02-.466l-.097-.033-.073-.024c-.35-.119-.612-.207-.806-.35-.164-.12-.282-.283-.282-.624 0-.306.103-.479.24-.586.153-.121.408-.206.78-.206.47 0 .773.215.923.485a.44.44 0 1 0 .769-.428 1.82 1.82 0 0 0-1.253-.895z"
          clipRule="evenodd"
          fillRule="evenodd"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M5.133.667a5.133 5.133 0 0 0-2.912 9.36 7.334 7.334 0 0 1 6.545-7.855A5.12 5.12 0 0 0 5.133.668"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
};

export default DollarsIcon;
