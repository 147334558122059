import { IListState } from 'interfaces/Collection';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  CompanyListNamedView,
  PersonListNamedView,
  TeamRole
} from '../__generated__/graphql';
import { mergeAppStore } from '../config/util';
import {
  FirebaseUserToken,
  HarmonicUser,
  IAuthState,
  UserStatusType
} from '../interfaces/Auth';
import { IDashboardState } from '../interfaces/Dashboard';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_SEARCH_MODEL,
  ISearchModel
} from '../interfaces/SearchModel/Search';

interface IAuthStateWithSetters extends IAuthState {
  setUser: (user: FirebaseUserToken | null) => void;
  setUserMetadata: (metadata: HarmonicUser) => void;
  setUserStatus: (status: UserStatusType) => void;
  setUserRole: (role: TeamRole) => void;
  setLoading: (loading: boolean) => void;
  setApiKey: (apiKey: string) => void;
  logout: () => void;
  setError: (error: string) => void;
  setNeedVerification: () => void;
  setSuccess: (success: string) => void;
}

interface IDashboardStateWithSetters extends IDashboardState {
  updateSearchModel: (searchModel: Partial<ISearchModel>) => void;
  setSearchModel: (searchModel: ISearchModel) => void;
  setSortField: (sortField: string) => void;
  setSortIsDescending: (sortIsDescending: boolean) => void;
  resetSelectedRowIds: () => void;
  resetDashboard: () => void;
}

interface ICollectionStateWithSetters extends IListState {
  setSortField: (sortField: string) => void;
  setSortIsDescending: (sortIsDescending: boolean) => void;
}

export type AppState = {
  auth: IAuthStateWithSetters;
  dashboard: IDashboardStateWithSetters;
  localSearch: ILocalSearchState;
  collection: ICollectionStateWithSetters;
};

const useAuthStore: StateCreator<AppState, [], [], IAuthStateWithSetters> = (
  set
) => ({
  user: null,
  isAuthenticated: false,
  loading: false,
  error: '',
  needVerification: false,
  success: '',
  status: undefined,
  role: undefined,
  apiKey: '',

  setUser: (user) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        user,
        isAuthenticated: !!user,
        loading: false
      }
    })),
  setUserMetadata: (metadata) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        userMetadata: {
          ...state.auth.userMetadata,
          ...metadata
        }
      }
    })),
  setUserStatus: (status) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        status
      }
    })),
  setUserRole: (role) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        role
      }
    })),
  setLoading: (loading) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        loading
      }
    })),
  setApiKey: (apiKey) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        apiKey
      }
    })),
  logout: () =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        user: null,
        isAuthenticated: false,
        loading: false,
        apiKey: ''
      }
    })),
  setError: (error) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        error
      }
    })),
  setNeedVerification: () =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        needVerification: true
      }
    })),
  setSuccess: (success) =>
    set((state) => ({
      ...state,
      auth: {
        ...state.auth,
        success
      }
    }))
});

const initialDashboardState: IDashboardState = {
  searchModel: INITIAL_SEARCH_MODEL,
  sortField: INITIAL_COMPANY_SORT.sortField,
  sortIsDescending: false
};

const useDashboardStore: StateCreator<
  AppState,
  [],
  [],
  IDashboardStateWithSetters
> = (set) => ({
  ...initialDashboardState,
  updateSearchModel: (searchModel) =>
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        searchModel: {
          ...state.dashboard.searchModel,
          ...searchModel
        }
      }
    })),
  setSearchModel: (searchModel) =>
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        searchModel
      }
    })),
  setSortField: (sortField) =>
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        sortField
      }
    })),
  setSortIsDescending: (sortIsDescending) =>
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        sortIsDescending
      }
    })),
  resetSelectedRowIds: () =>
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        selectedRowIds: []
      }
    })),
  resetDashboard: () => {
    set((state) => ({
      ...state,
      dashboard: {
        ...state.dashboard,
        ...initialDashboardState
      }
    }));
  }
});

const initialListState: IListState = {
  sortField: 'updatedAt',
  sortIsDescending: true
};

const useListStore: StateCreator<
  AppState,
  [],
  [],
  ICollectionStateWithSetters
> = (set) => ({
  ...initialListState,
  setSortField: (sortField) =>
    set((state) => ({
      ...state,
      collection: {
        ...state.collection,
        sortField
      }
    })),
  setSortIsDescending: (sortIsDescending) =>
    set((state) => ({
      ...state,
      collection: {
        ...state.collection,
        sortIsDescending
      }
    }))
});

export type LocalNamedView =
  | Pick<
      CompanyListNamedView,
      | 'name'
      | 'searchQuery'
      | 'visibleColumns'
      | 'displayType'
      | 'hideEmptyColumns'
      | 'groupByField'
    >
  | Pick<
      PersonListNamedView,
      | 'name'
      | 'searchQuery'
      | 'visibleColumns'
      | 'displayType'
      | 'hideEmptyColumns'
      | 'groupByField'
    >;

interface ILocalSearchState {
  searchByKey: Record<string, LocalNamedView | undefined>;
  updateLocalSearch: (key: string, payload: LocalNamedView) => void;
  removeLocalSearch: (key: string) => void;
}

const useLocalSearch: StateCreator<AppState, [], [], ILocalSearchState> = (
  set
) => ({
  searchByKey: {},
  updateLocalSearch: (key, payload) =>
    set((state) => ({
      ...state,
      localSearch: {
        ...state.localSearch,
        searchByKey: {
          ...state.localSearch.searchByKey,
          [key]: payload
        }
      }
    })),
  removeLocalSearch: (key) => {
    set((state) => ({
      ...state,
      localSearch: {
        ...state.localSearch,
        searchByKey: {
          ...(state.localSearch.searchByKey || {}),
          [key]: undefined
        }
      }
    }));
  }
});

export const useAppStore = create(
  persist<AppState>(
    (...args) => ({
      auth: useAuthStore(...args),
      dashboard: useDashboardStore(...args),
      localSearch: useLocalSearch(...args),
      collection: useListStore(...args)
    }),
    {
      name: 'app-store',
      merge: mergeAppStore,
      version: 1.1
    }
  )
);
