import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const UnlockedIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#484E5B"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M12 14V17M7.75 9.75V7C7.75 4.65279 9.65279 2.75 12 2.75C14.0926 2.75 15.832 4.26237 16.1847 6.25371M5.75 21.25H18.25C18.8023 21.25 19.25 20.8023 19.25 20.25V10.75C19.25 10.1977 18.8023 9.75 18.25 9.75H5.75C5.19772 9.75 4.75 10.1977 4.75 10.75V20.25C4.75 20.8023 5.19771 21.25 5.75 21.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnlockedIcon;
