import gql from 'graphql-tag';
import { COMPANY_LIST_NAMED_VIEW_SIDEBAR_FRAGMENT } from './getWatchlist';

export const GET_WATCHLIST_FRAGMENT = gql`
  fragment Watchlist on CompanyWatchlist {
    owner {
      ... on User {
        entityUrn
        name
        email
      }
      ... on Customer {
        identifier
        name
      }
    }
    id
    entityUrn
    name
    namedViews {
      ...CompanyListNamedViewSidebar
    }
    userWatchlistType
    userRelevanceScoreInfluenceType
    sharedWithTeam
    updatedAt
    createdAt
  }
  ${COMPANY_LIST_NAMED_VIEW_SIDEBAR_FRAGMENT}
`;

export const getCompanyWatchlists = gql`
  query GetUseFetchWatchlists {
    getCompanyWatchlistsForTeam {
      ...Watchlist
    }
  }
  ${GET_WATCHLIST_FRAGMENT}
`;
