import { SearchStateFilter, SortInfo } from '__generated__/graphql';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_PEOPLE_SORT,
  INITIAL_PEOPLE_WATCHLIST_SORT,
  INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY,
  INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY,
  SearchFilterGroupQuery
} from 'interfaces/SearchModel/Search';
import { FILTER_SESSION_STORE_VERSION_PREFIX } from 'utils/constants';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallowStoreGeneric } from './util';

interface IFilterStoreState {
  // Explore page filters
  companiesDashboardFilters?: SearchFilterGroupQuery;
  peopleDashboardFilters?: SearchFilterGroupQuery;

  // Saved search filters
  savedSearchFiltersCollection?: {
    [key in number]: SearchFilterGroupQuery;
  };

  /*
    Sort values:
    Explore pages: There is no stored values on the backend for explore pages so we store people/company explore separately locally
    Saved search pages: We refresh this state upon reading the saved search from the backend (see useFetchSingleSavedSearch.tsx). Save search sorts should never diverge from the backend state.
    Company watchlist pages: There is no stored values on the backend for watchlist pages so we store a single sort state for locally for company watchlists. Updating this will effect the sort state for all company watchlists.
  */
  companyExplorePageSort: SortInfo;
  peopleExplorePageSort: SortInfo;
  savedSearchPageSort?: SortInfo;
  peopleSavedSearchPageSort?: SortInfo;
  companyWatchlistPageSort: SortInfo;
  peopleWatchlistPageSort: SortInfo;
  // temporary state to trigger reset of list filter
  // List filter is part of different state. We don't have direct access to them
  // In new save UX, we are going to maintain different local states. We can directly udpate graphql then
  resetListFilter: boolean;
  // Saved search view states
  searchStates: Record<string, SearchStateFilter>;

  // Setters
  editFilterStore<Key extends keyof IFilterStoreState>(
    key: Key,
    payload: IFilterStoreState[Key]
  ): void;
  editSearchState(key: string, payload: SearchStateFilter): void;
  editSavedSearchFilterStore(
    key: number,
    payload: SearchFilterGroupQuery
  ): void;
}

const useFilterStore = create(
  persist<IFilterStoreState>(
    (set, get) => ({
      searchStates: {},
      companiesDashboardFilters: INITIAL_SEARCH_COMPANY_FILTER_GROUP_QUERY,
      peopleDashboardFilters: INITIAL_SEARCH_PEOPLE_FILTER_GROUP_QUERY,
      savedSearchFiltersCollection: {},
      companyExplorePageSort: {
        sortField: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
      },
      peopleExplorePageSort: {
        sortField: INITIAL_PEOPLE_SORT.sortField,
        descending: INITIAL_PEOPLE_SORT.sortDescending
      },
      companyWatchlistPageSort: {
        sortField: INITIAL_COMPANY_SORT.sortField,
        descending: INITIAL_COMPANY_SORT.sortDescending
      },
      peopleWatchlistPageSort: {
        sortField: INITIAL_PEOPLE_WATCHLIST_SORT.sortField,
        descending: INITIAL_PEOPLE_WATCHLIST_SORT.sortDescending
      },
      resetListFilter: false,
      editSearchState: (key, payload) =>
        set((state) => ({
          ...state,
          searchStates: { ...state.searchStates, [key]: payload }
        })),
      editFilterStore: (key, payload) =>
        set((state) => ({
          ...state,
          [key]: payload
        })),
      editSavedSearchFilterStore: (key: number, payload) => {
        const savedSearchFiltersCollection = get().savedSearchFiltersCollection;
        set((state) => ({
          ...state,
          savedSearchFiltersCollection: {
            ...savedSearchFiltersCollection,
            [key]: {
              ...savedSearchFiltersCollection?.[key],
              ...payload
            }
          }
        }));
      }
    }),
    {
      // Filter out any keys you don't want to persist to local storage
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['savedSearchFiltersCollection'].includes(key)
          )
        ) as IFilterStoreState,

      // Update it anytime you make some changes to schema
      version: 21,
      name: `${FILTER_SESSION_STORE_VERSION_PREFIX}filter-storage` // unique name
    }
  )
);

export const useShallowFilterStore = (keys: (keyof IFilterStoreState)[]) =>
  useShallowStoreGeneric(useFilterStore, keys);

export default useFilterStore;
