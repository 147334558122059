import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const Copy2Icon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#484E5B"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6654 2.16683C14.6654 1.70659 14.2923 1.3335 13.832 1.3335H6.16536C5.70513 1.3335 5.33203 1.70659 5.33203 2.16683V5.3335H2.16536C1.70513 5.3335 1.33203 5.70659 1.33203 6.16683V13.8335C1.33203 14.2937 1.70513 14.6668 2.16536 14.6668H9.83203C10.2923 14.6668 10.6654 14.2937 10.6654 13.8335V10.6668H13.832C14.2923 10.6668 14.6654 10.2937 14.6654 9.8335V2.16683ZM10.6654 9.66683H13.6654V2.3335H6.33203V5.3335H9.83203C10.2923 5.3335 10.6654 5.70659 10.6654 6.16683V9.66683Z"
      />
    </svg>
  );
};
export default Copy2Icon;
