import { useEffect, useLayoutEffect, useRef, useState } from 'react';

interface UseMultiSelectHiddenCountProps {
  inputOpen: boolean | undefined;
  // We don't want to run it on every type of multi select.
  runCalculation: boolean;
  selectedValues: string[];
}

export const useMultiSelectHiddenCount = ({
  inputOpen,
  runCalculation,
  selectedValues
}: UseMultiSelectHiddenCountProps) => {
  const [hiddenElementsCount, setHiddenElementsCount] = useState<number>(0);
  const [lastElementPosition, setLastElementsPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const updateHiddentCountAndLastElementPosition = () => {
    if (!containerRef.current || !runCalculation) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const children = Array.from(containerRef.current.children);
    const tagElements = children.filter(
      (child) => child.getAttribute('data-testid') === 'MultiSelect-Value'
    );
    let lastTagElementIndexWithinSingleLine = 0;
    for (let i = 0; i < tagElements.length; i++) {
      const element = tagElements[i];
      const elementRect = element?.getBoundingClientRect();
      if (
        elementRect.left < containerRect.right &&
        elementRect.top === containerRect.top
      ) {
        lastTagElementIndexWithinSingleLine = i;
      }
    }
    setHiddenElementsCount(
      tagElements.length - lastTagElementIndexWithinSingleLine - 1
    );
    const lastTagElement = tagElements[lastTagElementIndexWithinSingleLine];
    // Math to find the position of the last element where the "+X" button will be shown
    const lastElementPosition =
      lastTagElement?.getBoundingClientRect()?.x +
      lastTagElement?.getBoundingClientRect()?.width +
      4 -
      containerRect?.x;
    setLastElementsPosition(lastElementPosition);
  };

  useEffect(() => {
    if (!runCalculation) return;

    const resizeObserver = new ResizeObserver(() => {
      updateHiddentCountAndLastElementPosition();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [runCalculation]);

  useLayoutEffect(() => {
    updateHiddentCountAndLastElementPosition();
  }, [
    inputOpen,
    runCalculation,
    selectedValues,
    updateHiddentCountAndLastElementPosition
  ]);

  return {
    hiddenElementsCount,
    lastElementPosition,
    containerRef
  };
};
