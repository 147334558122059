import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { colorOptions } from 'components/Dashboard/Grids/EditField/SelectOptions/EditOptionItem';
import useTeamMembers from 'hooks/useTeamMembers';
import React, { createContext, useState } from 'react';
import {
  CustomFieldsFragment,
  ListCustomFieldType,
  SelectListCustomFieldMetadata
} from '../../../__generated__/graphql';
import analytics, { CustomTrackEvent } from '../../../utils/analytics';
import { getCustomFieldValue } from './utils';

export const NOT_APPLICABLE_FIELD_OPTION = {
  name: 'N/A',
  urn: 'n/a',
  color: 'informative'
};

interface BoardColumnOption {
  name: string;
  urn: string;
  color: string;
}

export interface BoardColumnProps {
  watchlistId: string;
  fieldUrn: string;
  option: BoardColumnOption;
  fieldType: ListCustomFieldType;
}

export interface DragAndDropParams {
  watchlistEntryUrn: string;
  customFieldUrn: string;
  customFieldValueUrn: string | null;
  previousCustomFieldValueUrn: string | null;
  index: number;
  customFieldType: ListCustomFieldType;
}

export const BoardContext = createContext<{
  isDragging: boolean;
}>({
  isDragging: false
});

export const BoardView = ({
  watchlistId,
  customField,
  boardColumn,
  onDragEnd
}: {
  watchlistId: string;
  customField: CustomFieldsFragment;
  boardColumn: (props: BoardColumnProps) => React.ReactElement;
  onDragEnd: (params: DragAndDropParams) => Promise<void>;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
  const formattedTeamMembers =
    activeTeamMembers
      ?.map((member, index) => ({
        color: colorOptions[index % colorOptions.length].color,
        urn: member?.user?.entityUrn as string,
        name: member?.user?.name as string
      }))
      .filter((val) => val.name)
      .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  if (
    customField.metadata?.__typename !== 'SelectListCustomFieldMetadata' &&
    customField.metadata?.__typename !== 'PersonListCustomFieldMetadata'
  ) {
    // TODO: This is an exception, the kanban view shouldn't be rendered
    // without on a custom field thar is not a select list type. Doing this hack
    // while we work on the prototype.
    return null;
  }

  const fieldUrn = customField.urn;
  let options =
    ((customField.metadata as SelectListCustomFieldMetadata)
      ?.options as BoardColumnOption[]) ?? [];
  if (customField.type === ListCustomFieldType.PERSON) {
    options = formattedTeamMembers;
  }

  const BoardColumn = boardColumn;

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = async (result: DropResult) => {
    const customFieldValue = result.destination?.droppableId;
    if (!customFieldValue) return;

    const previousCustomFieldUrn = result.source.droppableId;

    if (previousCustomFieldUrn === customFieldValue) return;

    analytics.trackCustomEvent({
      event: CustomTrackEvent.BOARD_VIEW_DROP_END,
      properties: {
        watchlistId: watchlistId,
        customFieldUrn: fieldUrn,
        customFieldValueUrn: customFieldValue,
        previousCustomFieldValueUrn: previousCustomFieldUrn
      }
    });

    setIsDragging(false);

    await onDragEnd?.({
      customFieldValueUrn: getCustomFieldValue(customFieldValue),
      previousCustomFieldValueUrn: getCustomFieldValue(previousCustomFieldUrn),
      watchlistEntryUrn: result.draggableId,
      customFieldUrn: customField?.urn,
      index: result.destination?.index || 0,
      customFieldType: customField.type
    });
  };

  const NOT_APPLICABLE_FIELD_OPTION = {
    name: `No ${customField.name}`,
    urn: 'n/a',
    color: 'neutral'
  };

  return (
    <div className="flex flex-col h-full py-2 px-1">
      <div className="flex w-full overflow-x-scroll">
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <BoardContext.Provider value={{ isDragging }}>
            <BoardColumn
              watchlistId={watchlistId}
              fieldUrn={fieldUrn}
              option={NOT_APPLICABLE_FIELD_OPTION}
              fieldType={customField.type}
            />
            {options?.map((option, i) => (
              <BoardColumn
                key={i}
                watchlistId={watchlistId}
                fieldUrn={fieldUrn}
                option={option}
                fieldType={customField.type}
              />
            ))}
          </BoardContext.Provider>
        </DragDropContext>
      </div>
    </div>
  );
};
