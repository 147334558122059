import { TeamRole } from '__generated__/graphql';
import { useAuthState } from './useAppState';

export enum Permission {
  ExportCsv = 'EXPORT_CSV',
  PeopleSearch = 'PEOPLE_SEARCH',
  PeopleList = 'PEOPLE_LIST',
  PersonProfile = 'PERSON_PROFILE',
  // Lite user and Member user terms may change.
  CanInviteStandardUser = 'CAN_INVITE_STANDARD_USER',
  CanInviteEnterpriseUser = 'CAN_INVITE_ENTERPRISE_USER',
  PauseUnpauseUser = 'CAN_PAUSE_UNPAUSE_USER',
  EditRole = 'EDIT_ROLE',
  ManageApiKey = 'MANAGE_API_KEY',
  CreateApiKey = 'CREATE_API_KEY',
  DeleteApiKey = 'DELETE_API_KEY'
}

const UserPermissions: Record<TeamRole, Permission[]> = {
  //Enterprise Admin
  [TeamRole.ADMIN]: [
    Permission.ExportCsv,
    Permission.PeopleSearch,
    Permission.PeopleList,
    Permission.PersonProfile,
    Permission.CanInviteStandardUser,
    Permission.CanInviteEnterpriseUser,
    Permission.PauseUnpauseUser,
    Permission.EditRole,
    Permission.CreateApiKey,
    Permission.DeleteApiKey,
    Permission.ManageApiKey
  ],
  // Limited Admin(Standard User Admin)
  [TeamRole.LIMITED_ADMIN]: [
    Permission.CanInviteStandardUser,
    Permission.PauseUnpauseUser,
    Permission.PeopleSearch,
    Permission.PeopleList,
    Permission.PersonProfile
  ],
  // Enterprise User
  [TeamRole.MEMBER]: [
    Permission.ExportCsv,
    Permission.PeopleSearch,
    Permission.PeopleList,
    Permission.PersonProfile,
    Permission.CanInviteStandardUser
  ],
  // Standard User
  [TeamRole.LIMITED]: [
    Permission.CanInviteStandardUser,
    Permission.PeopleSearch,
    Permission.PeopleList,
    Permission.PersonProfile
  ]
};

interface UsePermissionsHookResult {
  userHasPermission: (permission: Permission) => boolean;
}
const usePermissions = (): UsePermissionsHookResult => {
  const auth = useAuthState();

  const userHasPermission = (permission: Permission): boolean => {
    if (!auth.isAuthenticated) return false;

    if (auth?.role === TeamRole.ADMIN) return true;
    return UserPermissions?.[auth.role as TeamRole]?.includes(permission);
  };
  return {
    userHasPermission
  };
};

export default usePermissions;
