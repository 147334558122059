import { TextareaAutosize } from '@material-ui/core';
import {
  DateListCustomFieldMetadataFragment,
  ListCustomFieldType,
  NumberListCustomFieldMetadataFragment,
  SelectListCustomFieldMetadataFragment,
  SelectListCustomFieldValueOption
} from '__generated__/graphql';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import {
  CompanyWatchlistGridContext,
  WatchlistGridTeamMember
} from 'components/common/ResultsWrapper/GridResultsView';
import SingleDatePicker, {
  InputDateFormat
} from 'harmonic-components/DatePicker/SingleDatePicker';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { InputRef } from 'harmonic-components/Select/MultiSelect';
import SelectListItem from 'harmonic-components/Select/SelectListItem';
import useTeamMembers from 'hooks/useTeamMembers';
import { MetadataValue } from 'interfaces/CustomField';
import { isNil } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { ColorShorthand } from 'utils/design';
import {
  formatNumberListCustomFieldValue,
  isNumberAndNaN,
  parseNumberListCustomFieldValue
} from 'utils/utilities';
import { getNthColor } from '../../../../utils/colors';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';
import { LoadingCellRenderer } from './CellRenderers';
import { CustomFieldSelect } from './components/CustomFieldSelect';
import { formatSelectListCustomFieldMetadata } from './utils';

interface ICellEditorReactComp {
  values: string[];
  getValue: () => string[];
  value: string;
  onValueChange: (value: string | string[] | null) => void;
  size?: 'small';
}

export const DEFAULT_HEIGHT = 54;

export type CustomFieldsCellRendererParams<T> = ICellRendererParams<T> & {
  showEmptyValue?: boolean;
  emptyValueText?: string;
  size?: 'small';
};

const CustomFieldSelectListItem = ({
  option,
  onClick,
  selected
}: {
  option: SelectListCustomFieldValueOption;
  onClick: (option: SelectListCustomFieldValueOption) => void;
  selected: boolean;
}) => {
  const handleClick = useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  return (
    <SelectListItem
      label={option.name}
      value={option.urn}
      key={option.urn}
      onClick={handleClick}
      color={option.color as ColorShorthand}
      variant={ListVariant.tag}
      selected={selected}
    />
  );
};

export const CustomColumnSingleSelectCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      SelectListCustomFieldMetadataFragment,
    ref
  ) => {
    const { onValueChange } = props;
    const [value, setValue] = React.useState(props.value);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleChange = useCallback(
      (newValue: string | null) => {
        setValue(newValue);
        onValueChange?.(newValue);
        setSearchTerm('');
      },
      [onValueChange]
    );

    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const gridContext = props.context as CompanyWatchlistGridContext;

    const handleUpdateOptions = useCallback(
      async (newOption?: string) => {
        const newOptions = formatSelectListCustomFieldMetadata({
          newOption,
          options
        });

        if (props.colDef.headerName && gridContext.watchlistUrn) {
          const result = await gridContext.updateCustomField(
            {
              watchlistUrn: gridContext.watchlistUrn as string,
              customFieldUrn: props.colDef.field as string,
              name: props.colDef.headerName,
              fieldType: ListCustomFieldType.SINGLE_SELECT,
              metadata: newOptions as MetadataValue
            },
            {
              updateEditColumnsConfig: false
            }
          );

          const options = (
            result?.data?.updateCompanyWatchlistCustomField
              .metadata as SelectListCustomFieldMetadataFragment
          ).options;
          setOptions(options);
          const newValue = options[options.length - 1].urn;
          handleChange(newValue);
        }
      },
      [options, props.colDef, gridContext, handleChange]
    );

    const handleClick = useCallback(
      (option: SelectListCustomFieldValueOption) => {
        handleChange(option.urn);
        setTimeout(() => {
          props.api.stopEditing();
        }, 0);
      },
      [props.api, handleChange]
    );

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <CustomFieldSelect
            options={options}
            api={props.api}
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value ? [value] : undefined}
            onAddNewOption={handleUpdateOptions}
            onRemove={() => {
              handleChange(null);
            }}
            size={props.size}
          >
            {filteredOptions.map((option) => {
              return (
                <CustomFieldSelectListItem
                  key={option.urn}
                  option={option}
                  selected={option.urn === value}
                  onClick={handleClick}
                />
              );
            })}
          </CustomFieldSelect>
        </div>
      </div>
    );
  }
);

export const CustomColumnPersonSelectCellEditor = React.forwardRef(
  (props: ICellEditorReactComp & ICellEditorParams, ref) => {
    const { activeTeamMembers } = useTeamMembers({ fetchPolicy: 'cache-only' });
    const options = useMemo(() => {
      return (
        activeTeamMembers?.map((member, i) => ({
          urn: member?.user?.entityUrn as string,
          name: member?.user?.name as string,
          color: getNthColor(colorOptions, i)
        })) ?? []
      );
    }, [activeTeamMembers]);

    const [value, setValue] = React.useState<string[]>(props.value ?? []);

    const [searchTerm, setSearchTerm] = React.useState('');

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleChange = (newVal: string[]) => {
      setValue(newVal);
      props.onValueChange?.(newVal);
    };

    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options
      .filter((option) =>
        option?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())
      );

    const setAdditionalValue = (option: WatchlistGridTeamMember) => {
      if (value.includes(option.urn)) {
        handleChange(value.filter((v) => v !== option.urn));
      } else {
        handleChange([...value, option.urn]);
      }
      setSearchTerm('');
    };

    const handeClick = (option: SelectListCustomFieldValueOption) => {
      setAdditionalValue(option);
    };

    if (!activeTeamMembers || activeTeamMembers.length === 0) {
      return <LoadingCellRenderer />;
    }

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <CustomFieldSelect
            options={options}
            api={props.api}
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value}
            onRemove={(val) => {
              handleChange(value.filter((v) => v !== val));
            }}
            size={props.size}
          >
            {filteredOptions.map((option) => {
              return (
                <CustomFieldSelectListItem
                  key={option.urn}
                  option={option}
                  selected={value.includes(option.urn)}
                  onClick={handeClick}
                />
              );
            })}
          </CustomFieldSelect>
        </div>
      </div>
    );
  }
);

export const CustomColumnMultiSelectCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      SelectListCustomFieldMetadataFragment,
    ref
  ) => {
    const { onValueChange } = props;
    const [value, setValue] = React.useState<string[]>(props.value ?? []);
    const [options, setOptions] = React.useState(props.options);
    const [searchTerm, setSearchTerm] = React.useState('');
    const gridContext = props.context as CompanyWatchlistGridContext;

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleChange = useCallback(
      (newVal: string[]) => {
        setValue(newVal);
        onValueChange?.(newVal);
      },
      [onValueChange]
    );

    const inputRef = useRef<InputRef>(null);

    const width = props.api.getColumn(props.colDef)?.getActualWidth();
    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const setAdditionalValue = useCallback(
      (option: SelectListCustomFieldValueOption) => {
        if (value.includes(option.urn)) {
          handleChange(value.filter((v) => v !== option.urn));
        } else {
          handleChange([...value, option.urn]);
        }
        setSearchTerm('');
      },
      [handleChange, value]
    );

    const handleUpdateOptions = useCallback(
      async (newOption?: string) => {
        const newOptions = formatSelectListCustomFieldMetadata({
          newOption,
          options
        });

        if (props.colDef.headerName && gridContext.watchlistUrn) {
          const result = await gridContext.updateCustomField(
            {
              watchlistUrn: gridContext.watchlistUrn as string,
              customFieldUrn: props.colDef.field as string,
              name: props.colDef.headerName,
              fieldType: ListCustomFieldType.SINGLE_SELECT,
              metadata: newOptions as MetadataValue
            },
            {
              updateEditColumnsConfig: false
            }
          );

          const options = (
            result?.data?.updateCompanyWatchlistCustomField
              .metadata as SelectListCustomFieldMetadataFragment
          ).options;
          setOptions(options);
          setAdditionalValue(options[options.length - 1]);
          setSearchTerm('');
          inputRef?.current?.focus();
        }
      },
      [gridContext, options, props.colDef, setAdditionalValue]
    );

    const handleRemove = useCallback(
      (val: string) => {
        handleChange(value.filter((v) => v !== val));
      },
      [handleChange, value]
    );

    const handeClick = useCallback(
      (option: SelectListCustomFieldValueOption) => {
        setAdditionalValue(option);
      },
      [setAdditionalValue]
    );

    return (
      <div style={{ width }}>
        <div className="flex flex-col gap-g40 bg-surface-default">
          <CustomFieldSelect
            options={options}
            api={props.api}
            filterTerm={searchTerm}
            onFilterTermChange={setSearchTerm}
            selected={value}
            onAddNewOption={handleUpdateOptions}
            onRemove={handleRemove}
            inputRef={inputRef}
            dataTestId="CustomColumnMultiSelectCellEditor-Select"
            size={props.size}
          >
            {filteredOptions.map((option) => {
              return (
                <CustomFieldSelectListItem
                  key={option.urn}
                  option={option}
                  onClick={handeClick}
                  selected={value.includes(option.urn)}
                />
              );
            })}
          </CustomFieldSelect>
        </div>
      </div>
    );
  }
);

export const CustomColumnTextCellEditor = React.forwardRef(
  (props: ICellEditorReactComp & ICellEditorParams, ref) => {
    const [value, setValue] = React.useState(props.value || '');
    const refInput = React.useRef<HTMLTextAreaElement>(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();

    React.useEffect(() => {
      if (refInput.current) {
        refInput.current.focus();
        refInput.current.setSelectionRange(
          refInput.current.value.length,
          refInput.current.value.length
        );
      }
    }, [refInput]);

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      props.onValueChange?.(e.target.value);
    };

    return (
      <TextareaAutosize
        onBlur={() => {
          refInput?.current?.focus();
        }}
        onFocus={(event) => {
          if (event.target) {
            event.target.scrollTop = event.target.scrollHeight;
          }
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            props.api.stopEditing();
          }
        }}
        autoFocus
        className={classNames(
          'bg-surface-default rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default',
          props.size === 'small' ? 'min-h-[28px] p-p20' : 'min-h-[77px] p-p50'
        )}
        {...(props.size === 'small' && { minRows: 1 })}
        onChange={handleChange}
        ref={refInput}
        value={value}
        style={{ width }}
      />
    );
  }
);

export const CustomColumnNumberCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      NumberListCustomFieldMetadataFragment,
    ref
  ) => {
    const num: number | undefined | null = props.value;
    const [value, setValue] = React.useState<string | undefined>(
      isNil(num) || isNumberAndNaN(num) ? undefined : num?.toString()
    );
    const refInput = React.useRef<HTMLTextAreaElement>(null);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();

    React.useEffect(() => {
      if (refInput.current) {
        refInput.current.focus();
        refInput.current.setSelectionRange(
          refInput.current.value.length,
          refInput.current.value.length
        );
      }
    }, [refInput]);

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(parseNumberListCustomFieldValue(e.target.value));
      props.onValueChange?.(e.target.value);
    };

    const formattedNumber = formatNumberListCustomFieldValue(
      value,
      props.numberFormat,
      false
    );

    return (
      <TextareaAutosize
        onBlur={() => {
          refInput?.current?.focus();
        }}
        autoFocus
        inputMode="decimal"
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            props.api.stopEditing();
          }
        }}
        className={classNames(
          'resize-none bg-surface-default rounded-br30 border-2 border-solid border-int-outline-secondary-selected-enabled text-content-default typography-paragraph-default-default',
          props.size === 'small' ? 'min-h-[28px] p-p20' : 'min-h-[77px] p-p50'
        )}
        {...(props.size === 'small' && { minRows: 1 })}
        onChange={handleOnChange}
        ref={refInput}
        value={formattedNumber}
        style={{ width }}
      />
    );
  }
);

export const CustomColumnDateCellEditor = React.forwardRef(
  (
    props: ICellEditorReactComp &
      ICellEditorParams &
      DateListCustomFieldMetadataFragment,
    ref
  ) => {
    const [value, setValue] = React.useState(props.value);
    const width = props.api.getColumn(props.colDef)?.getActualWidth();

    React.useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        }
      };
    });

    const dateFormat = props.dateFormat as unknown as InputDateFormat;
    const handleChange = (date: string | null) => {
      setValue(date);
      setTimeout(() => {
        props.api.stopEditing();
      }, 10);
      props.onValueChange?.(date);
    };

    return (
      <div className="inline-block h-[77px]" style={{ width }}>
        <SingleDatePicker
          minHeight={77}
          selectedDate={value}
          onChange={handleChange}
          native={false}
          dateFormat={dateFormat}
          alwaysOpen
          fullWidth
          autoFocus
        />
      </div>
    );
  }
);
