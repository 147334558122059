import { HarmonicIcon } from './type';
const ChevronDownV2: HarmonicIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Leading Icon">
        <path
          vectorEffect="non-scaling-stroke"
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.80298 6.1363C5.09588 5.8434 5.57075 5.8434 5.86364 6.1363L7.99998 8.27263L10.1363 6.1363C10.4292 5.8434 10.9041 5.8434 11.197 6.1363C11.4899 6.42919 11.4899 6.90406 11.197 7.19696L8.76601 9.62792C8.34294 10.051 7.65702 10.051 7.23395 9.62792L4.80298 7.19696C4.51009 6.90406 4.51009 6.42919 4.80298 6.1363Z"
          fill="#484E5B"
        />
      </g>
    </svg>
  );
};
export default ChevronDownV2;
