/* eslint-disable testing-library/render-result-naming-convention */
import { DRAWER_WIDTH } from 'components/Dashboard/DashboardLayout/styles';
import { ITableHeader } from 'interfaces/Grid';
import { SortContext, SortGroup } from 'utils/sort';
import {
  CommonCellRenderer,
  CommonCellRendererProps
} from '../CellRenderers/CommonCellRenderer';
import { formatHeadersForCellRenderers } from './utils';

const CommonPeopleHeaders: Array<ITableHeader> = [
  {
    field: 'person.id',
    headerName: 'ID',
    hide: true,
    sortable: true,
    sortContext: {
      sortGroup: SortGroup.PEOPLE_ID
    },
    searchModelIdentifier: 'person_id',
    filterModelIdentifier: 'person_id',
    initialWidth: 100
  },
  {
    field: 'person.fullName',
    headerName: 'Full Name',
    sortable: true,
    searchModelIdentifier: 'person_sortable_full_name',
    filterModelIdentifier: 'person_sortable_full_name',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_FULL_NAME
    },
    initialPinned: true,
    headerCheckboxSelection: false,
    headerComponent: 'BulkSelectCheckboxRenderer',
    cellRenderer: 'PersonNameWithCheckboxCellRenderer',
    minWidth: 235,
    suppressSizeToFit: true,
    valueGetter: (params) => params.data
  },
  {
    field: 'person.firstName',
    headerName: 'First Name',
    editable: false,
    sortable: true,
    searchModelIdentifier: 'person_sortable_first_name',
    filterModelIdentifier: 'person_sortable_first_name',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_FIRST_NAME
    },
    hide: true,
    initialWidth: 150
  },
  {
    field: 'person.lastName',
    headerName: 'Last Name',
    editable: false,
    sortable: true,
    searchModelIdentifier: 'person_sortable_last_name',
    filterModelIdentifier: 'person_sortable_last_name',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_NAME
    },
    hide: true,
    initialWidth: 150
  }
];

const _PeopleHeaders: Array<ITableHeader> = [
  ...CommonPeopleHeaders,
  {
    field: 'person.watchlists',
    headerName: 'Lists',
    editable: false,
    cellRenderer: 'PeopleListsCellRenderer',
    initialWidth: 250,
    sortable: false
  },
  {
    field: 'person.entryCreatedAt',
    headerName: 'Date added to list',
    hide: true,
    initialWidth: 250,
    cellRenderer: 'DateCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_entry_created_at',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_DATE_ADDED_TO_LIST
    }
  },
  {
    field: 'person.addedBy',
    headerName: 'Added to list by',
    hide: true,
    initialWidth: 250,
    cellRenderer: 'UserCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_entry_added_by',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_ADDED_BY
    }
  },
  {
    field: 'person.experience.currentCompany',
    headerName: 'Current Company',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'name',
      timeFrame: 'current'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_position_company_name',
    filterModelIdentifier: 'person_position_company_id',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CURRENT_COMPANY
    }
  },
  {
    field: 'person.experience.currentTitle',
    headerName: 'Current Title',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'title',
      timeFrame: 'current'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_position_title',
    filterModelIdentifier: 'person_position_title',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CURRENT_TITLE
    }
  },
  {
    field: 'person.experience.currentDepartment',
    headerName: 'Current Department',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'department',
      timeFrame: 'current'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_position_department',
    filterModelIdentifier: 'person_position_department',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CURRENT_DEPARTMENT
    }
  },
  {
    field: 'person.location.location',
    headerName: 'Current Location',
    editable: false,
    initialWidth: 200,
    sortable: true,
    searchModelIdentifier: 'person_sortable_location',
    filterModelIdentifier: 'person_sortable_location',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LOCATION
    }
  },
  {
    field: 'person.linkedinHeadline',
    headerName: 'LinkedIn Headline',
    editable: false,
    initialWidth: 200,
    sortable: true,
    searchModelIdentifier: 'person_sortable_linkedin_headline',
    filterModelIdentifier: 'person_sortable_linkedin_headline',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LINKEDIN_HEADLINE
    }
  },
  {
    field: 'person.experience.currentCompanyTags',
    headerName: 'Current Company Tags',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'tags',
      timeFrame: 'current'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_position_company_tags',
    filterModelIdentifier: 'person_position_company_tags',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CURRENT_COMPANY_TAGS
    }
  },
  {
    field: 'person.experience.previousCompany',
    headerName: 'Previous Company',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'name',
      timeFrame: 'previous'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_last_company_name',
    filterModelIdentifier: 'person_last_company_id',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_COMPANY
    }
  },
  {
    field: 'person.experience.previousCompanyTags',
    headerName: 'Previous Company Tags',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'tags',
      timeFrame: 'previous'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_last_company_tags',
    filterModelIdentifier: 'person_last_company_tags',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_COMPANY_TAGS
    }
  },
  {
    field: 'person.experience.previousCompanies',
    headerName: 'Previous Companies',
    editable: false,
    initialWidth: 500,
    minWidth: 300,
    cellRenderer: 'PersonExperienceDataCellRenderer',
    cellRendererParams: {
      dataToFetch: 'name',
      timeFrame: 'all_previous'
    },
    valueGetter: (params) => {
      return params.data?.person?.experience;
    },
    sortable: true,
    searchModelIdentifier: 'person_experience_company_name',
    filterModelIdentifier: 'person_experience_company_name_text',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_PREVIOUS_COMPANIES
    }
  },
  {
    field: 'person.highlights',
    headerName: 'Person Highlights',
    editable: false,
    initialWidth: 500,
    minWidth: 400,
    wrapText: true,
    cellStyle: { whiteSpace: 'normal' },
    searchModelIdentifier: 'person_highlights_category_list',
    filterModelIdentifier: 'person_highlights_category_list',
    cellRenderer: 'PeopleHighlightsCellRenderer',
    sortable: true,
    sortContext: {
      sortGroup: SortGroup.PEOPLE_HIGHLIGHTS
    }
  },
  {
    field: 'person.socials.linkedin.url',
    headerName: 'LinkedIn',
    editable: false,
    initialWidth: 300,
    cellRenderer: 'LinkCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_sortable_linkedin_url',
    filterModelIdentifier: 'person_sortable_linkedin_url',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LINKEDIN_URL
    }
  },
  {
    field: 'person.education',
    headerName: 'Education',
    initialWidth: 500,
    minWidth: 400,
    cellRenderer: 'EducationCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_education_experience_school',
    filterModelIdentifier: 'person_education_experience_school',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_EDUCATION
    }
  },
  {
    field: 'person.contact.emails',
    headerName: 'Email',
    editable: false,
    initialWidth: 400,
    cellRenderer: 'EmailForPeopleSearchCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_sortable_emails',
    filterModelIdentifier: 'person_sortable_emails',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_EMAILS
    }
  },
  {
    field: 'person.correspondenceSummary.lastMeetingAt',
    headerName: 'Last meeting date',
    editable: false,
    initialWidth: 235,
    cellRenderer: 'DateCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_customers_connections_last_meeting_at',
    filterModelIdentifier: 'person_customers_connections_last_meeting_at',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_MEETING_DATE
    }
  },
  {
    field: 'person.correspondenceSummary.lastMeetingContactPersonEmail',
    headerName: 'Last meeting with',
    editable: false,
    initialWidth: 235,
    cellRenderer: 'CorrespondencePersonCellRenderer',
    sortable: true,
    searchModelIdentifier:
      'person_customers_connections_last_meeting_person_email',
    filterModelIdentifier:
      'person_customers_connections_last_meeting_person_email',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_MEETING_WITH
    }
  },
  {
    field: 'person.correspondenceSummary.lastEmailAt',
    headerName: 'Last email date',
    editable: false,
    initialWidth: 235,
    cellRenderer: 'DateCellRenderer',
    sortable: true,
    searchModelIdentifier: 'person_customers_connections_last_email_at',
    filterModelIdentifier: 'person_customers_connections_last_email_at',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_CONTACTED_DATE
    }
  },
  {
    field: 'person.correspondenceSummary.lastEmailContactPersonEmail',
    headerName: 'Last email with',
    editable: false,
    initialWidth: 235,
    cellRenderer: 'CorrespondencePersonCellRenderer',
    sortable: true,
    searchModelIdentifier:
      'person_customers_connections_last_email_person_email',
    filterModelIdentifier:
      'person_customers_connections_last_email_person_email',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_LAST_CONTACTED_BY
    }
  },
  {
    field: 'person.userConnections',
    headerName: 'Team network',
    editable: false,
    initialWidth: 235,
    cellRenderer: 'UserConnectionsCellRenderer',
    customTooltip: 'See who on your team is in network with this person',
    sortable: true,
    searchModelIdentifier: 'person_customers_connections_connections_count',
    filterModelIdentifier: 'person_customers_connections_connections_count',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_TEAM_NETWORK
    }
  },
  {
    // Extra empty column to account for expanded sidebar overflow
    headerName: '',
    width: DRAWER_WIDTH, // width of sidebar
    sortable: false,
    hide: false
  }
];

/*
  CommonPeopleHeadersByField is a map of header fields that are returned by the query without extended data.
*/
const CommonPeopleHeadersByField = formatHeadersForCellRenderers({
  headers: CommonPeopleHeaders
});

const PeopleHeaders = _PeopleHeaders.map((header) => {
  return {
    ...header,
    cellRenderer: (params: CommonCellRendererProps) => (
      <CommonCellRenderer
        header={header}
        headers={CommonPeopleHeadersByField}
        {...params}
      />
    )
  } as ITableHeader;
});

export const PeopleSortableHeaderFields = [
  'person_id',
  ...PeopleHeaders.filter(
    (header) => header.sortable && header.searchModelIdentifier
  ).map((header) => header.searchModelIdentifier as string)
];

export const SortContextByPersonField = [
  ...PeopleHeaders,

  // Sortable but not a visible column
  {
    sortable: true,
    searchModelIdentifier: 'person_last_update_event_date',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_UPDATE_EVENT_DATE
    }
  },

  // Custom fields
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_text',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_TEXT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_number',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_NUMBER
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_date',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_DATE
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_single_select',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_SINGLE_SELECT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_multi_select',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_MULTI_SELECT
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_person',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_PERSON
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_status',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_STATUS
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_checkbox',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_CHECKBOX
    }
  },
  {
    sortable: true,
    searchModelIdentifier: 'person_custom_field_url',
    sortContext: {
      sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_URL
    }
  }
]
  .filter((header) => header.sortable && header.searchModelIdentifier)
  .reduce((acc, header) => {
    acc[header.searchModelIdentifier as string] =
      header.sortContext as unknown as SortContext;
    return acc;
  }, {} as Record<string, SortContext>);

export default PeopleHeaders;
