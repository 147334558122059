import { ApolloError, WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import { GetCompanyWatchlistQuery } from '__generated__/graphql';
import { GET_WATCHLIST } from 'queries/getWatchlist';
import { useMemo } from 'react';
import { getIdFromUrn } from 'utils/urn';
import useDashboardLocation, {
  DashboardLocation
} from './useDashboardLocation';

interface UseFetchSingleWatchlistResults {
  companyWatchlist?:
    | GetCompanyWatchlistQuery['getCompanyWatchlistByIdOrUrn']
    | null;
  companyWatchlistLoading: boolean;
  companyWatchlistError?: ApolloError;
}

const useFetchSingleWatchlist = (
  {
    fetchPolicy
  }: {
    fetchPolicy?: WatchQueryFetchPolicy;
  } = {
    fetchPolicy: 'cache-first'
  }
): UseFetchSingleWatchlistResults => {
  const routeDetails = useDashboardLocation();
  const urn = routeDetails.urn;
  const isCompanyWatchlist =
    routeDetails.location === DashboardLocation.COMPANY_LIST;

  const id = getIdFromUrn(urn ?? null) || '';
  const {
    data: companyData,
    loading: companyWatchlistLoading,
    error: companyWatchlistError
  } = useQuery<GetCompanyWatchlistQuery>(GET_WATCHLIST, {
    variables: {
      idOrUrn: id
    },
    skip: !isCompanyWatchlist,
    fetchPolicy,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true
  });

  const companyWatchlistData = useMemo(() => {
    if (companyData && isCompanyWatchlist) {
      return companyData?.getCompanyWatchlistByIdOrUrn;
    }
  }, [companyData, isCompanyWatchlist]);

  return {
    companyWatchlist: companyWatchlistData,
    companyWatchlistLoading: !companyData && companyWatchlistLoading,
    companyWatchlistError
  };
};

export default useFetchSingleWatchlist;
