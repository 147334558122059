import { HarmonicIcon } from './type';
const ChevronUpV2: HarmonicIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Trailing icon">
        <path
          id="Icon"
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99998 7.72735L10.1363 9.86369C10.4292 10.1566 10.9041 10.1566 11.197 9.86369C11.4899 9.5708 11.4899 9.09592 11.197 8.80303L8.76601 6.37207C8.34294 5.949 7.65702 5.949 7.23395 6.37206L4.80298 8.80303C4.51009 9.09592 4.51009 9.5708 4.80298 9.86369C5.09588 10.1566 5.57075 10.1566 5.86364 9.86369L7.99998 7.72735Z"
          fill="#484E5B"
        />
      </g>
    </svg>
  );
};
export default ChevronUpV2;
