import { Popover } from '@material-ui/core';
import { ReactComponent as BoxAwardIcon } from 'assets/bxs-award.svg';
import defaultPersonLogo from 'assets/default-person-logo.svg';
import classNames from 'classnames';
import {
  getHighlightIcon,
  SingleHighlight
} from 'components/common/Highlights/HighlightsChip';
import InternalLink from 'components/common/InternalLink';
import Avatar, { AvatarSizes } from 'harmonic-components/Avatar/Avatar';
import React, { useState } from 'react';
import { isSafeHostedAsset } from 'utils/utilities';

interface AvatarWithHighlightBadgeProps {
  highlights: string[];
  personId: number;
  href: string;
  src: string;
  size?: AvatarSizes;
}

const AvatarWithHighlightBadge: React.FC<AvatarWithHighlightBadgeProps> = ({
  highlights,
  personId,
  href,
  src,
  size
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const imageSrc = isSafeHostedAsset(src) ? src : defaultPersonLogo;

  const open = Boolean(anchorEl);
  return (
    <div className={classNames('relative')}>
      <div className="relative">
        <InternalLink
          to={{ pathname: href }}
          dataTestId="AvatarWithHighlightBadge-InternalLink"
        >
          <Avatar size={size} src={imageSrc} alt="Person image" kind="face" />
        </InternalLink>
        {highlights && highlights.length > 0 && (
          <div
            data-testid="HighlightBadge"
            className={classNames(
              'flex justify-center absolute -bottom-2 -right-1'
            )}
          >
            <span
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <BoxAwardIcon />
            </span>
          </div>
        )}
      </div>

      {highlights && highlights.length > 0 && (
        <div data-testid="HighlightBadge-Popover">
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            disableScrollLock
            disableRestoreFocus
            disableAutoFocus
            classes={{
              paper: 'rounded-none border-none bg-transparent shadow-none p-p20'
            }}
            style={{ pointerEvents: 'none', marginTop: 4 }}
          >
            <div
              data-testid="Highlights-Dropdown"
              className={classNames(
                'bg-white grid p-p50 gap-g30 border border-solid border-border shadow-int-elevation-floating rounded-br30',
                highlights.length > 1 ? 'grid-cols-2' : 'grid-cols-1'
              )}
            >
              {highlights.map((highlight) => {
                const HighlightIcon = getHighlightIcon(highlight);
                return (
                  <SingleHighlight
                    key={`highlight-${highlight}-${personId}`}
                    highlight={highlight}
                    icon={HighlightIcon}
                  />
                );
              })}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default AvatarWithHighlightBadge;
