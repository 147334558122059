import classNames from 'classnames';
import { ButtonEmphasis, ButtonType } from './Button';

//primary type does not need emphasis
export const generatePrimaryTypeClasses = (
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (isDisabled)
    return 'bg-int-fill-primary-disabled text-int-fill-primary-onDisabled';

  if (isSelected)
    return 'bg-int-fill-primary-selected-enabled text-int-fill-primary-onSelected hover:bg-int-fill-primary-selected-hover active:bg-int-fill-primary-selected-pressed';

  return 'bg-int-fill-primary-enabled text-int-fill-primary-onEnabled hover:bg-int-fill-primary-hover active:bg-int-fill-primary-pressed';
};

//negative type does not need emphasis
export const generateNegativeTypeClasses = (
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (isDisabled)
    return 'bg-int-fill-negative-disabled text-int-fill-negative-onDisabled';

  if (isSelected)
    return 'bg-int-fill-negative-selected-enabled text-int-fill-negative-onSelected hover:bg-int-fill-negative-selected-hover active:bg-int-fill-negative-selected-pressed';

  return 'bg-int-fill-negative-enabled text-int-fill-negative-onEnabled hover:bg-int-fill-negative-hover active:bg-int-fill-negative-pressed';
};

export const generateSecondaryTypeClasses = (
  emphasis: ButtonEmphasis,
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (emphasis === 'high') {
    if (isDisabled)
      return 'bg-int-fill-secondary-disabled text-int-fill-secondary-onDisabled';
    if (isSelected)
      return classNames(
        'bg-int-fill-secondary-selected-enabled text-int-fill-secondary-onSelected',
        'hover:bg-int-fill-secondary-selected-hover active:bg-int-fill-secondary-selected-pressed'
      );

    return 'bg-int-fill-secondary-enabled text-int-fill-secondary-onEnabled hover:bg-int-fill-secondary-hover active:bg-int-fill-secondary-pressed';
  }

  if (emphasis === 'medium') {
    if (isDisabled)
      return 'bg-int-overlay-secondary-enabled border border-solid border-int-outline-secondary-disabled text-int-outline-secondary-onDisabled';

    if (isSelected)
      return classNames(
        'bg-int-overlay-secondary-enabled border border-solid border-int-outline-secondary-selected-enabled',
        'hover:border-int-outline-secondary-selected-hover active:border-int-outline-secondary-selected-pressed'
      );

    return classNames(
      'bg-int-overlay-secondary-enabled border border-solid border-int-outline-secondary-enabled',
      'hover:border-int-outline-secondary-hover active:border-int-outline-secondary-pressed'
    );
  }
  //Low emphasis
  if (isDisabled)
    return 'bg-int-overlay-secondary-disabled text-int-overlay-secondary-onDisabled';

  if (isSelected)
    return classNames(
      'bg-int-overlay-secondary-selected-enabled text-int-overlay-secondary-onSelected',
      'hover:bg-int-overlay-secondary-selected-hover active:bg-int-overlay-secondary-selected-pressed'
    );

  return classNames(
    'bg-int-overlay-secondary-enabled text-int-overlay-secondary-onEnabled',
    'hover:bg-int-overlay-secondary-hover active:bg-int-overlay-secondary-pressed'
  );
};
export const generateInvertedTypeClasses = (
  emphasis: ButtonEmphasis,
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (emphasis === 'high') {
    if (isDisabled)
      return 'bg-int-fill-inverted-disabled text-int-fill-inverted-onDisabled';
    if (isSelected)
      return classNames(
        'bg-int-fill-inverted-selected-enabled text-int-fill-inverted-onSelected',
        'hover:bg-int-fill-inverted-selected-hover active:bg-int-fill-inverted-selected-pressed'
      );

    return 'bg-int-fill-inverted-enabled text-int-fill-inverted-onEnabled hover:bg-int-fill-inverted-hover active:bg-int-fill-inverted-pressed';
  }

  if (emphasis === 'medium') {
    if (isDisabled)
      return 'bg-int-overlay-inverted-enabled border border-solid border-int-outline-inverted-disabled text-int-outline-inverted-onDisabled';

    if (isSelected)
      return classNames(
        'bg-int-overlay-inverted-enabled border border-solid border-int-outline-inverted-selected-enabled',
        'hover:border-int-outline-inverted-selected-hover active:border-int-outline-inverted-selected-pressed'
      );

    return classNames(
      'bg-int-overlay-inverted-enabled border border-solid border-int-outline-inverted-enabled',
      'hover:border-int-outline-inverted-hover active:border-int-outline-inverted-pressed'
    );
  }
  //Low emphasis
  if (isDisabled)
    return 'bg-int-overlay-inverted-disabled text-int-overlay-inverted-onDisabled';

  if (isSelected)
    return classNames(
      'bg-int-overlay-inverted-selected-enabled text-int-overlay-inverted-onSelected',
      'hover:bg-int-overlay-inverted-selected-hover active:bg-int-overlay-inverted-selected-pressed'
    );

  return classNames(
    'bg-int-overlay-inverted-enabled text-int-overlay-inverted-onEnabled',
    'hover:bg-int-overlay-inverted-hover active:bg-int-overlay-inverted-pressed'
  );
};

export const generateNetNewTypeClasses = (
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (isDisabled)
    return 'bg-int-fill-net-new-disabled text-int-fill-net-new-onDisabled';

  if (isSelected)
    return classNames(
      'bg-int-fill-netNew-selected-enabled text-int-fill-net-new-onSelected',
      'hover:bg-int-fill-net-new-selected-hover active:bg-int-fill-net-new-selected-pressed'
    );

  return classNames(
    'bg-int-fill-net-new-enabled text-int-fill-net-new-onEnabled',
    'hover:bg-int-fill-net-new-hover active:bg-int-fill-net-new-pressed'
  );
};

export const generateDraftTypeClasses = (
  isSelected: boolean,
  isDisabled: boolean
) => {
  if (isDisabled)
    return 'bg-int-fill-draft-disabled text-int-fill-draft-onDisabled';

  if (isSelected)
    return classNames(
      'bg-int-fill-draft-selected-enabled text-int-fill-draft-onSelected',
      'hover:bg-int-fill-draft-selected-hover active:bg-int-fill-draft-selected-pressed'
    );

  return classNames(
    'bg-int-fill-draft-enabled text-int-fill-draft-onEnabled',
    'hover:bg-int-fill-draft-hover active:bg-int-fill-draft-pressed'
  );
};

export const generateFocusClasses = (type: ButtonType) => {
  if (type === 'inverted') {
    return 'focus:shadow-int-focus-inverted-outside';
  }
  return 'focus:shadow-int-focus-outside';
};
