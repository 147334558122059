import { ConnectionIntegrationType } from '__generated__/graphql';
import EmailCalendarConsentScreen from 'assets/email-calendar-consent-screen.webp';
import { Badge } from 'common/components';
import Button from 'harmonic-components/Button/Button';
import ModalFooter from 'harmonic-components/ModalFooter/ModalFooter';
import ModalTitle from 'harmonic-components/ModalTitle/ModalTitle';
import React from 'react';

interface EmailCalendarTermsModalProps {
  handleClose: () => void;
  onConfirmClick: () => void;
  integrationType: ConnectionIntegrationType;
}

const EmailCalendarTermsModal: React.FC<EmailCalendarTermsModalProps> = ({
  handleClose,
  onConfirmClick,
  integrationType
}) => {
  const title =
    integrationType === ConnectionIntegrationType.GOOGLE
      ? 'Google'
      : 'Microsoft';
  return (
    <div className="w-px-550">
      <ModalTitle
        title={`Connect ${title} account for network mapping`}
        titleBadge={<Badge label="Beta" />}
      />
      <div className="p-p50 flex flex-col gap-g80">
        {integrationType === ConnectionIntegrationType.GOOGLE && (
          <>
            <p className="typography-paragraph text-content-default">
              Check{' '}
              <span className="bg-surface-sentiment-neutral text-content-strong">
                both permission boxes
              </span>{' '}
              on the next screen to set up the Google email and calendar
              integration. Without these permissions, the integration will not
              be able to sync necessary data.
            </p>
            <div className="flex justify-center">
              <img
                src={EmailCalendarConsentScreen}
                alt="Email and Calendar Consent Screen"
                className="w-[300px] drop-shadow-md"
              />
            </div>
          </>
        )}
        <p className="typography-paragraph text-content-default">
          We take your privacy very seriously. Read our Privacy Policy{' '}
          <a
            href="https://www.harmonic.ai/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="typography-paragraph-default-link text-content-strong"
          >
            here
          </a>
          .
        </p>
        <p className="typography-paragraph text-content-default">
          {' '}
          By connecting your {title} account, you agree to the{' '}
          <a
            href="https://www.harmonic.ai/legal/harmonic-email-and-calendar-integrations-terms"
            target="_blank"
            rel="noreferrer"
            className="typography-paragraph-default-link text-content-strong"
          >
            Harmonic Email and Calendar Integrations Terms
          </a>
          .
        </p>
      </div>
      <ModalFooter>
        <Button
          label={'Cancel'}
          emphasis="medium"
          type="secondary"
          onClick={handleClose}
        />
        <Button
          label={'I accept these terms'}
          emphasis="high"
          type="primary"
          onClick={onConfirmClick}
        />
      </ModalFooter>
    </div>
  );
};

export default EmailCalendarTermsModal;
