import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const HarmonicFieldIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <g opacity="0.6">
        <path
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#7C8598"
          d="M12.9062 12.2759L9.2502 14.3866C8.47669 14.8332 7.5237 14.8332 6.7502 14.3866L3.09424 12.2759C2.32074 11.8293 1.84424 11.004 1.84424 10.1108V5.88925C1.84424 4.99609 2.32074 4.17077 3.09424 3.72419L6.7502 1.61342C7.5237 1.16684 8.47669 1.16684 9.2502 1.61342L12.9062 3.72419C13.6797 4.17077 14.1562 4.99609 14.1562 5.88925V10.1108C14.1562 11.004 13.6797 11.8293 12.9062 12.2759ZM7.25019 12.9432L3.84424 10.9768C3.53484 10.7982 3.34424 10.4681 3.34424 10.1108V5.92612L7.25019 8.41173V12.9432ZM4.30307 4.75832L7.5002 2.91246C7.8096 2.73383 8.19079 2.73383 8.50019 2.91246L12.1562 5.02323C12.1745 5.03383 12.1925 5.04497 12.21 5.05661L8.0446 7.1393L4.30307 4.75832ZM12.6562 6.51057V10.1108C12.6562 10.4681 12.4656 10.7982 12.1562 10.9768L8.75019 12.9432V8.46355L12.6562 6.51057Z"
        />
      </g>
    </svg>
  );
};

export default HarmonicFieldIcon;
