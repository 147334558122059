import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TrendingIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="m12.255 3.464 1.204 3.41a.75.75 0 1 0 1.415-.5l-1.3-3.678c-.471-1.336-2.39-1.23-2.714.147l.715.169-.715-.169-2.277 9.673-1.992-6.372c-.41-1.314-2.26-1.331-2.696-.026l-.773 2.318-.597-1.418a.75.75 0 0 0-1.383.582l.684 1.623m10.429-5.759-2.282 9.693c-.33 1.403-2.3 1.476-2.73.098L5.236 6.838l-.761 2.283c-.414 1.24-2.141 1.308-2.65.102"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default TrendingIcon;
