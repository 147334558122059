import { IRowNode } from 'ag-grid-community';
import { Badge } from 'common/components';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { getCompanyHighlights } from '../../../../../utils/cellRendererUtils';
import { LightTooltip } from '../../../../common';
import { getHighlightIcon } from '../../../../common/Highlights/HighlightsChip';

const HighlightBadge: React.FC<{
  highlight: {
    category: string;
    count: number;
    tooltip: JSX.Element;
  };
}> = React.memo(({ highlight }) => {
  const badgeContent = (
    <Badge
      leadingIcon={getHighlightIcon(highlight.category)}
      label={highlight.category}
      color="expressive-32"
      size="large"
      suffix={highlight.count > 1 ? `${highlight.count}` : ''}
    />
  );

  if (highlight.tooltip) {
    return (
      <LightTooltip
        enterDelay={1000}
        placement="bottom-end"
        interactive
        title={highlight.tooltip}
      >
        <div>{badgeContent}</div>
      </LightTooltip>
    );
  }

  return badgeContent;
});

export const CompanyHighlights: React.FC<{
  value: {
    text: string;
    category: string;
  }[];
  node?: IRowNode<{
    company?: ICompany;
  }>;
}> = (props) => {
  const company = get(props, 'node.data.company');
  const highlights = get(props, 'value');
  const allHighlights = useMemo(
    () => getCompanyHighlights(highlights, company),
    [highlights, company]
  );

  if (allHighlights.length === 0) return null;

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {allHighlights.map((highlight, index: number) => (
          <HighlightBadge
            key={`${highlight.category}-${index}`}
            highlight={highlight}
          />
        ))}
      </TruncatedList>
    </div>
  );
};
