import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SidebarLikedIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.99765 2.5C8.13224 2.5 9.55529 4.16674 10 4.86984C10.4447 4.16674 11.8678 2.5 14.0024 2.5C16.6706 2.5 18.6718 4.92745 18.4154 7.6532C18.0486 11.5526 13.5795 15.5103 10.8828 17.2761C10.3436 17.6292 9.6564 17.6292 9.11719 17.2761C6.42045 15.5103 1.95145 11.5526 1.58464 7.6532C1.32823 4.92745 3.32941 2.5 5.99765 2.5Z"
      />
    </svg>
  );
};

export default SidebarLikedIcon;
