import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';
const MicrosoftIcon: React.FC<IconProps> = ({
  applyCurrentColor = false,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 23 23"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill={applyCurrentColor ? 'currentColor' : '#f3f3f3'}
        d="M0 0h23v23H0z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill={applyCurrentColor ? 'currentColor' : '#f35325'}
        d="M1 1h10v10H1z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill={applyCurrentColor ? 'currentColor' : '#81bc06'}
        d="M12 1h10v10H12z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill={applyCurrentColor ? 'currentColor' : '#05a6f0'}
        d="M1 12h10v10H1z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fill={applyCurrentColor ? 'currentColor' : '#ffba08'}
        d="M12 12h10v10H12z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default MicrosoftIcon;
