import { ISavedSearchNotificationSubscription } from 'interfaces/SearchModel/Integrations';
import {
  ICompanyWatchlist,
  ISavedSearch,
  ISearchFieldSpec
} from 'interfaces/SearchModel/Search';
import { ICompany } from './Company';
import { ICustomer } from './Customer';
import { IEvent } from './Event';
import { IPerson } from './Person';
import { IUser } from './User';

import { ApolloError } from '@apollo/client';
import {
  GetCompaniesWithTypeaheadFragment,
  GetInvestorWithTypeaheadFragment,
  GetPeopleWithTypeaheadFragment,
  SearchPeopleQuery
} from '__generated__/graphql';

/**
 * @deprecated This type should not be used
 * use EntityListType instead.
 */
export enum ApiResourceType {
  Company = 'COMPANY',
  School = 'SCHOOL',
  Person = 'PERSON',
  PeopleList = 'PEOPLE_LIST',
  CompaniesList = 'COMPANIES_LIST'
}

export enum GraphQLResourceType {
  Company = 'Company',
  Person = 'Person'
}

interface IListResponse<T> {
  count: number;
  rows: T[];
  status: string;
  data?: SearchPeopleQuery;
  error?: ApolloError;
  errors?: ApolloError[];
}

export interface IGlobalSearchResponse {
  companies: GetCompaniesWithTypeaheadFragment[];
  people: GetPeopleWithTypeaheadFragment[];
  investors: GetInvestorWithTypeaheadFragment[];
}

interface ISavedSearchListResponse {
  saved_searches: ISavedSearch[];
}

interface ISavedSearchResponse {
  saved_search: ISavedSearch;
}

interface ICompanyWatchlistResponse {
  collection: ICompanyWatchlist;
}

interface ICompanyWatchlistListResponse {
  watchlists: ICompanyWatchlist[];
}

interface ICompanyUrnResponse {
  result: Record<string, string>;
}
interface IUserResponse {
  user: IUser;
  customer?: ICustomer;
  saved_searches?: ISavedSearch[];
}

interface ICustomerApiKeyResponse {
  key: string;
  permissions: string[];
}

export type IApiResource =
  | ICompany
  | IPerson
  | IListResponse<IEvent>
  | IListResponse<IPerson>
  | IListResponse<ICompany>
  | ICompany[]
  | ISearchFieldSpec[]
  | ISavedSearchListResponse
  | ISavedSearchResponse
  | ICompanyWatchlistListResponse
  | ICompanyWatchlistResponse
  | IUserResponse
  | ICustomerApiKeyResponse
  | ICompanyUrnResponse;

export type ISavedSearchNotificationSubscriptionResponse =
  ISavedSearchNotificationSubscription;

export interface ICompanyViewRecord {
  created_at: Date;
  updated_at: Date;
}

export interface ICapTableEntry {
  round: string;
  roundNumber: number | null;
  shares: number | null;
  value: number | null;
  issuePrice: number | null;
  liquidation: number | null;
  pref_multiple: number | null;
  conversion: number | null;
  owned: number | null;
}
