import classNames from 'classnames';
import { Tooltip } from 'common/components';
import {
  CompanySearchGridContext,
  CompanyWatchlistGridContext
} from 'components/common/ResultsWrapper/GridResultsView';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import useDashboardLocation, { EntityType } from 'hooks/useDashboardLocation';
import { SearchType } from 'interfaces/SearchModel/Search';
import analytics, { CustomTrackEvent } from 'utils/analytics';

interface IUnviewedNetDot {
  netNewId?: number;
  isUnviewed?: boolean;
  context?: CompanyWatchlistGridContext | CompanySearchGridContext;
  entityId?: number;
}

const UnviewedNewDot = ({
  netNewId,
  isUnviewed,
  context,
  entityId
}: IUnviewedNetDot) => {
  const { onClearNetNewByIds } = useNetNewCounts();
  const { entityType, urn } = useDashboardLocation();

  const searchType =
    entityType === EntityType.COMPANY
      ? SearchType.COMPANIES_LIST
      : SearchType.PERSONS;

  const viewHandler = () => {
    if (!context || !entityId) return;
    isUnviewed
      ? context?.setViewed([entityId])
      : context?.setUnviewed([entityId]);
  };

  const newHandler = () => {
    if (!netNewId || !urn) return;
    onClearNetNewByIds({
      ids: [netNewId],
      savedSearchUrn: urn,
      type: searchType
    });

    analytics.trackCustomEvent({
      event: CustomTrackEvent.DISMISS_NET_NEW_ENTITY_CLICKED,
      properties: {
        savedSearchUrn: urn,
        type: searchType
      }
    });
  };

  const clickHandler = () => {
    viewHandler();
    newHandler();
  };

  const getTooltipText = () => {
    if (netNewId) {
      return 'Mark new result as viewed';
    }

    if (context && entityId)
      return isUnviewed ? 'Mark viewed' : 'Mark unviewed';
  };

  if (!context && !entityId && !netNewId) return null;

  return (
    <Tooltip
      body={getTooltipText()}
      enterDelayMs={1500}
      alignment="top"
      arrow={false}
    >
      <div
        onClick={clickHandler}
        data-testid="unviewed-new-dot"
        className={classNames(
          'w-2 h-2 mr-2 rounded-full flex-shrink-0 hover:scale-[2] transition-all duration-100 cursor-pointer group relative',
          {
            'bg-surface-sentiment-highlight-informative':
              isUnviewed && !netNewId
          },
          {
            'bg-int-fill-net-new-selected-enabled hover:bg-int-fill-net-new-selected-pressed':
              netNewId
          }
        )}
      >
        <span
          className={classNames(
            'icon icon-rounded icon-filled text-white hidden group-hover:flex absolute text-[7px] top-[1px]'
          )}
        >
          check
        </span>
      </div>
    </Tooltip>
  );
};

export default UnviewedNewDot;
