import { useApolloClient } from '@apollo/client';
import { getIdFromUrn } from 'common/utils/urn';
import useDashboardLocation, {
  DashboardLocation
} from 'hooks/useDashboardLocation';
import useFetchSingleSavedSearch from 'hooks/useFetchSingleSavedSearch';
import {
  EntityListType,
  INITIAL_SEARCH_MODEL,
  SearchFilterGroupQuery
} from 'interfaces/SearchModel/Search';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import useStore from 'stores/zustandStore';
import { ISavedSearchInput, updateSavedSearch } from 'utils/midtierApi';
import { SortHookResponse } from 'utils/sort';
import { displayToast } from 'utils/toasts';

export const usePeopleSearchSort = (): SortHookResponse => {
  const client = useApolloClient();
  const { urn, location: dashboardLocation } = useDashboardLocation();
  const userUrn = useStore((state) => state.userUrn);

  const { getCurrentPersistedQuery, currentPageSavedSearch } =
    useFetchSingleSavedSearch();
  const isSearchOwner = currentPageSavedSearch?.creator?.entityUrn === userUrn;
  const entityId = urn && getIdFromUrn(urn);
  const isSavedSearch =
    dashboardLocation === DashboardLocation.PEOPLE_SEARCH && entityId;

  const { editFilterStore, peopleSavedSearchPageSort, peopleExplorePageSort } =
    useShallowFilterStore([
      'editFilterStore',
      'peopleSavedSearchPageSort',
      'peopleExplorePageSort'
    ]);

  const sort = useMemo(() => {
    if (!urn && dashboardLocation === DashboardLocation.PEOPLE_SEARCH) {
      return peopleExplorePageSort;
    }
    return peopleSavedSearchPageSort;
  }, [
    dashboardLocation,
    peopleExplorePageSort,
    peopleSavedSearchPageSort,
    urn
  ]);

  const zustandStoreSortKey = useMemo(() => {
    if (!urn && dashboardLocation === DashboardLocation.PEOPLE_SEARCH) {
      return 'peopleExplorePageSort';
    }
    if (dashboardLocation === DashboardLocation.PERSON_LIST) {
      return 'peopleWatchlistPageSort';
    }
    return 'peopleSavedSearchPageSort';
  }, [dashboardLocation, urn]);

  const currentPersistedQuery =
    isSavedSearch &&
    entityId &&
    getCurrentPersistedQuery(
      EntityListType.PEOPLE_SAVED_SEARCH,
      parseInt(entityId)
    );

  const updateSortDataOnBackend = async (
    sortField: string,
    isDescending: boolean
  ) => {
    if (!isSavedSearch) return;
    const clonedSearchQuery = cloneDeep(
      currentPersistedQuery
    ) as SearchFilterGroupQuery;

    const queryInfo = {
      filter_group: clonedSearchQuery.filter_group,
      controlled_filter_group: clonedSearchQuery.controlled_filter_group,
      sort: [
        {
          sort_field: sortField,
          descending: isDescending
        }
      ]
    };
    const mainPayload: Partial<ISavedSearchInput> = {
      query: {
        ...INITIAL_SEARCH_MODEL,
        ...queryInfo
      }
    };
    if (!isSearchOwner) return;
    try {
      await updateSavedSearch(client, entityId ?? '', mainPayload);
      displayToast({
        primaryText: 'Successfully updated saved search',
        mode: 'success'
      });
    } catch {
      displayToast({
        primaryText: 'There was a problem updating the saved search',
        mode: 'error'
      });
    }
  };

  const changeSort = ({
    field,
    descending
  }: {
    field: string;
    descending: boolean;
  }) => {
    editFilterStore(zustandStoreSortKey, {
      sortField: field,
      descending: descending
    });
    updateSortDataOnBackend(field, descending);
  };

  return {
    changeSort,
    sort
  };
};
