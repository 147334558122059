import classNames from 'classnames';

const Shimmer = ({ className }: { className?: string }) => {
  return (
    <div
      data-testid="shimmer"
      className={classNames(
        'animate-shimmer bg-gradient-to-r from-surface-default via-surface-raised to-surface-default bg-[length:200%_100%]',
        className
      )}
    />
  );
};

export default Shimmer;
