import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const BuildingIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#111318"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        vectorEffect="non-scaling-stroke"
        d="M6.33374 5.75C6.33374 4.7835 7.11724 4 8.08374 4H16.5837C17.5502 4 18.3337 4.7835 18.3337 5.75V8H20.5837C21.5502 8 22.3337 8.7835 22.3337 9.75V18.5H22.5837C22.998 18.5 23.3337 18.8358 23.3337 19.25C23.3337 19.6642 22.998 20 22.5837 20H2.08374C1.66953 20 1.33374 19.6642 1.33374 19.25C1.33374 18.8358 1.66953 18.5 2.08374 18.5H2.33374V9.75C2.33374 8.7835 3.11724 8 4.08374 8H6.33374V5.75ZM6.33374 9.5H4.08374C3.94567 9.5 3.83374 9.61193 3.83374 9.75V18.5H6.33374V9.5ZM18.3337 18.5H20.8337V9.75C20.8337 9.61193 20.7218 9.5 20.5837 9.5H18.3337V18.5ZM14.3337 18.5V15.25C14.3337 15.1119 14.2218 15 14.0837 15H10.5837C10.4457 15 10.3337 15.1119 10.3337 15.25V18.5H14.3337Z"
      />
    </svg>
  );
};

export default BuildingIcon;
