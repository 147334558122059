import classNames from 'classnames';
import { HarmonicIcon } from './type';

const AffinityIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M8.764 13.087a2.934 2.934 0 0 0 4.15-4.15l-.433-.433c.096-.305.07-.641-.078-.93l.51-.511a2.934 2.934 0 1 0-4.15-4.15l-.566.567a1.23 1.23 0 0 0-.74 0l-.567-.567a2.934 2.934 0 0 0-4.15 4.15l.567.567c-.076.24-.076.5 0 .74l-.567.567a2.934 2.934 0 1 0 4.15 4.15l.51-.51c.29.147.625.173.931.077zm2.694-6.174.652-.653a1.798 1.798 0 0 0-2.543-2.543L9 4.283c.076.24.076.5 0 .741l1.937 1.936c.168-.053.346-.069.52-.047m-1.325.85L8.197 5.827c-.24.076-.5.076-.74 0L5.653 7.63c.076.24.076.5 0 .74l1.936 1.937c.169-.053.347-.07.521-.048l1.975-1.975a1.2 1.2 0 0 1 .047-.52m.614 1.467-1.69 1.69c.148.29.173.625.077.93l.433.434A1.798 1.798 0 0 0 12.11 9.74l-.432-.433c-.306.097-.642.07-.931-.077m-4.008 2.4a1.2 1.2 0 0 1 .048-.52L4.851 9.174c-.241.075-.5.075-.741 0l-.567.566a1.798 1.798 0 0 0 2.544 2.544zM4.851 6.827l1.802-1.802c-.076-.24-.076-.5 0-.741l-.566-.566A1.798 1.798 0 0 0 3.543 6.26l.567.566c.24-.075.5-.075.74 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default AffinityIcon;
