import { KeyboardEvent, PropsWithChildren } from 'react';

export const Form: React.FC<
  PropsWithChildren & {
    children: React.ReactNode | React.ReactNode[];
    onSubmit: () => void;
    className?: string;
    enableSubmitOnCmdEnter?: boolean;
  }
> = ({ children, onSubmit, className, enableSubmitOnCmdEnter = true }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
    if (
      (e.metaKey || e.ctrlKey) &&
      e.key === 'Enter' &&
      enableSubmitOnCmdEnter
    ) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <form
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      className={className}
    >
      {children}
    </form>
  );
};
