import classNames from 'classnames';
import { IconProps } from './type';

const BellFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      className={classNames(applyCurrentColor && 'fill-current', className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 1.33334C5.44927 1.33334 3.27559 3.18452 2.86944 5.70269L1.96435 11.3142C1.84995 12.0235 2.39767 12.6667 3.11613 12.6667H5.00523C5.57135 13.8334 6.67971 14.6667 7.99999 14.6667C9.32027 14.6667 10.4286 13.8334 10.9947 12.6667H12.8838C13.6023 12.6667 14.15 12.0235 14.0356 11.3142L13.1305 5.70269C12.7244 3.18452 10.5507 1.33334 7.99999 1.33334ZM9.83566 12.6667H6.16432C6.61604 13.2916 7.28409 13.6667 7.99999 13.6667C8.71589 13.6667 9.38393 13.2916 9.83566 12.6667Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77223 1.4877C3.9624 1.68793 3.95425 2.00441 3.75402 2.19457C2.96562 2.94337 2.37553 3.89773 2.06931 4.97079C1.99354 5.23633 1.71684 5.39017 1.4513 5.31439C1.18576 5.23861 1.03192 4.96192 1.1077 4.69638C1.46412 3.44741 2.15036 2.33853 3.06536 1.46949C3.26559 1.27932 3.58207 1.28748 3.77223 1.4877ZM12.2277 1.4877C12.4179 1.28748 12.7344 1.27932 12.9346 1.46949C13.8496 2.33853 14.5359 3.44741 14.8923 4.69638C14.9681 4.96192 14.8142 5.23861 14.5487 5.31439C14.2831 5.39017 14.0064 5.23633 13.9307 4.97079C13.6244 3.89773 13.0344 2.94337 12.246 2.19457C12.0457 2.00441 12.0376 1.68793 12.2277 1.4877Z"
        fill="currentColor"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BellFilledIcon;
