const Spacing = require('./primitives');
module.exports = {
  g00: Spacing.sp00,
  g10: Spacing.sp10,
  g20: Spacing.sp20,
  g30: Spacing.sp30,
  g40: Spacing.sp40,
  g50: Spacing.sp60,
  g60: Spacing.sp70,
  g70: Spacing.sp80,
  g80: Spacing.sp90,
  g90: Spacing.sp110,
  g100: Spacing.sp130,
  g110: Spacing.sp120
};