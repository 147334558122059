import classNames from 'classnames';
import { IconProps } from './type';

const Exclude: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <rect width="16" height="16" rx="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00005 7.1486C3.00005 7.05655 3.07466 6.98193 3.16671 6.98193H8.17257C8.26462 6.98193 8.33924 7.05655 8.33924 7.1486V8.89858C8.33924 8.99063 8.26462 9.06525 8.17257 9.06525H3.16671C3.07466 9.06525 3.00005 8.99063 3.00005 8.89858V7.1486ZM6.28439 3.00888C6.19234 3.00888 6.11772 3.0835 6.11772 3.17555V12.8716C6.11772 12.9637 6.19234 13.0383 6.28439 13.0383H8.03437C8.12642 13.0383 8.20104 12.9637 8.20104 12.8716V10.3802C8.20104 10.2318 8.38056 10.1574 8.48555 10.2624L10.6925 12.4694C10.7576 12.5345 10.8632 12.5345 10.9282 12.4694L12.1657 11.232C12.2308 11.1669 12.2308 11.0613 12.1657 10.9963L10.5192 9.34977C10.4142 9.24478 10.4886 9.06525 10.637 9.06525L12.8334 9.06525C12.9254 9.06525 13 8.99063 13 8.89859V7.1486C13 7.05655 12.9254 6.98193 12.8334 6.98193H10.637C10.4886 6.98193 10.4142 6.80241 10.5192 6.69742L12.1657 5.05093C12.2308 4.98584 12.2308 4.88031 12.1657 4.81523L10.9282 3.5778C10.8632 3.51271 10.7576 3.51271 10.6925 3.5778L8.48555 5.78479C8.38056 5.88979 8.20104 5.81542 8.20104 5.66694V3.17555C8.20104 3.0835 8.12642 3.00888 8.03437 3.00888H6.28439Z"
        fill="white"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
export default Exclude;
