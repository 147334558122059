import { IRowNode } from 'ag-grid-community';
import { Badge } from 'common/components';
import { isNil, toNumber } from 'lodash';
import { ICompany } from '../../../../../interfaces/DataModel/Company';

export const RelevanceScore: React.FC<{
  value?: string | null;
  node?: IRowNode<{
    company?: ICompany;
  }>;
}> = (props) => {
  if (isNil(props.value)) return null;
  const value = toNumber(props.value);
  const isBelowThreshold = value < 70;
  return (
    <div className="inline-block">
      <Badge
        size="large"
        color={isBelowThreshold ? 'neutral' : 'positive'}
        intensity="subtle"
        label={`${value}%`}
      />
    </div>
  );
};
