import { DocumentNode, useApolloClient, useLazyQuery } from '@apollo/client';
import {
  GetCompaniesByIdsQuery,
  GetCompaniesByIdsQueryVariables,
  GetPersonsByIdsQuery,
  GetPersonsByIdsQueryVariables,
  GetWatchlistWithCompaniesQuery,
  GetWatchlistWithCompaniesQueryVariables,
  GetWatchlistWithPeopleQuery,
  GetWatchlistWithPeopleQueryVariables
} from '../__generated__/graphql';
import { useShallowTableStore } from '../stores/tableStore';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';

interface WatchlistEntry {
  edges: Array<{
    node: {
      [key: string]: {
        id: number;
      };
    };
  }> | null;
  pageInfo: {
    hasNextPage?: boolean;
    endCursor?: string | null;
  };
}

export type RefetchWatchlistResultsProps<
  TData extends GetWatchlistWithCompaniesQuery | GetWatchlistWithPeopleQuery,
  TVariables extends
    | GetWatchlistWithCompaniesQueryVariables
    | GetWatchlistWithPeopleQueryVariables,
  TExtendedData extends GetCompaniesByIdsQuery | GetPersonsByIdsQuery
> = {
  query: DocumentNode;
  extendedQuery: DocumentNode;
  variables: TVariables;
  getDataPath: (data: TData | undefined) => WatchlistEntry | undefined;
  getIdsFromData: (data: TData) => number[];
  getUrnsFromExtendedData: (data: TExtendedData) => string[];
};

export const useRefetchWatchlistResults = <
  TData extends GetWatchlistWithCompaniesQuery | GetWatchlistWithPeopleQuery,
  TVariables extends
    | GetWatchlistWithCompaniesQueryVariables
    | GetWatchlistWithPeopleQueryVariables,
  TExtendedData extends GetCompaniesByIdsQuery | GetPersonsByIdsQuery,
  TExtendedVariables extends
    | GetCompaniesByIdsQueryVariables
    | GetPersonsByIdsQueryVariables
>({
  query,
  extendedQuery,
  variables,
  getDataPath,
  getIdsFromData,
  getUrnsFromExtendedData
}: RefetchWatchlistResultsProps<TData, TVariables, TExtendedData>) => {
  const client = useApolloClient();
  const { addToLoadedExtendedUrns } = useShallowTableStore([
    'addToLoadedExtendedUrns'
  ]);

  const [fetchCachedData] = useLazyQuery<TData, TVariables>(query, {
    fetchPolicy: 'cache-only'
  });

  const [fetch] = useLazyQuery<TData, TVariables>(query, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only'
  });

  const handleCompleted = (data: TData) => {
    const entries = getDataPath(data);
    const ids = getIdsFromData(data);

    client
      .query<TExtendedData, TExtendedVariables>({
        query: extendedQuery,
        variables: {
          ids,
          extended: true,
          skipExtended: false
        } as unknown as TExtendedVariables,
        fetchPolicy: 'network-only'
      })
      .then(({ data }) => {
        const urns = getUrnsFromExtendedData(data);
        addToLoadedExtendedUrns(urns);
      });

    return entries;
  };

  const refetchResults = async (idOrUrn: string) => {
    let cursor: string | null = null;

    const { data: cachedData } = await fetchCachedData({
      variables: {
        ...variables,
        idOrUrn
      } as TVariables
    });

    const numCachedEdges = getDataPath(cachedData)?.edges?.length || 0;

    let numPages = Math.floor(numCachedEdges / GRID_INITIAL_PAGE_SIZE) + 1;

    while (numPages > 0) {
      const { data, error } = await fetch({
        variables: {
          ...variables,
          idOrUrn,
          first: GRID_INITIAL_PAGE_SIZE,
          after: cursor,
          skipExtended: true
        } as TVariables
      });

      if (!data || error) break;

      const entries = handleCompleted(data);
      const pageInfo = entries?.pageInfo;

      if (!pageInfo?.hasNextPage) break;

      cursor = pageInfo.endCursor ?? null;
      numPages--;
    }
  };

  return {
    refetchResults
  };
};
