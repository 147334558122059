import { UserStatusType } from 'interfaces/Auth';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  ACCEPT_PILOT_AGREEMENT_ROUTE,
  DEFAULT_DASHBOARD_ROUTE
} from 'utils/constants';
import { useAuthState } from '../../hooks/useAppState';
interface RequireAuthProps {
  disableAccess?: boolean;
  children: JSX.Element;
}
export const RequireAuth: FC<RequireAuthProps> = ({
  children,
  disableAccess = false
}) => {
  const auth = useAuthState();
  const location = useLocation();

  if (
    auth.isAuthenticated &&
    auth.status === UserStatusType.PENDING_PILOT_AGREEMENT_ACCEPTANCE
  ) {
    return <Navigate to={ACCEPT_PILOT_AGREEMENT_ROUTE} />;
  }

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (disableAccess) {
    return <Navigate to={DEFAULT_DASHBOARD_ROUTE} />;
  }
  return children;
};
