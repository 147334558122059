import classNames from 'classnames';
import InternalLink from 'components/common/InternalLink';
import Avatar from 'harmonic-components/Avatar/Avatar';

interface CompanyAttributeProps {
  src: string;
  href: string;
  name: string;
  disabled?: boolean;
  internalLink?: boolean;
  className?: string;
  singleLine?: boolean;
}

const CompanyAttribute: React.FC<CompanyAttributeProps> = ({
  src,
  href,
  name,
  disabled = false,
  internalLink = false,
  className,
  singleLine = false
}) => {
  const wrapperClass = classNames(
    'flex items-start gap-g30 cursor-pointer px-p20 py-p10 hover:bg-int-overlay-secondary-hover',
    'aria-disabled:bg-int-overlay-secondary-disabled aria-disabled:cursor-default rounded-br30'
  );

  const mainContent = (
    <>
      <Avatar
        size="medium"
        kind="logo"
        src={src}
        alt="company-profile-picture"
      />
      <p
        className={classNames(
          'hidden lg:inline text-content-strong typography-label-default-default overflow-hidden',
          singleLine && 'line-clamp-1 truncate'
        )}
      >
        {name}
      </p>
    </>
  );

  if (!internalLink)
    return (
      <a
        href={!disabled ? href : undefined}
        aria-disabled={disabled}
        className={classNames(wrapperClass, className)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {mainContent}
      </a>
    );

  return (
    <InternalLink to={{ pathname: href }}>
      <div className={wrapperClass}>{mainContent}</div>
    </InternalLink>
  );
};

export default CompanyAttribute;
