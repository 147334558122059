const semanticsColors = require("./colors/semantics");
const expressiveColors = require("./colors/expressive");
const componentColors = require("./colors/components");
const semanticsTypography = require("./typography/semantics");
const semanticsBorderRadius = require("./borderRadius/semantics");
const semanticsGap = require("./spacing/semanticsGap");
const semanticsMarginPadding = require("./spacing/semanticsMarginPadding");
const semanticsBoxShadow = require("./boxShadow/semantics");
module.exports = {
  semanticsColors,
  expressiveColors,
  componentColors,
  semanticsTypography,
  semanticsBorderRadius,
  semanticsGap,
  semanticsMarginPadding,
  semanticsBoxShadow
};