import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import ReportDataIssue from 'components/common/ReportDataIssue';
import useFlags from 'hooks/useFlags';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import React from 'react';
import { SPLITS } from 'utils/constants';
import {
  CompanyNavbarOptions,
  nonStealthNavbarOptions,
  stealthNavbarOptions
} from '../CompanyHeader/CompanyHeader';

type CompanyActionsProps = {
  companyId: number;
  selectedTab: string;
  isStealthCompany?: boolean;
};

export const CompanyActions: React.FC<CompanyActionsProps> = ({
  companyId,
  isStealthCompany,
  selectedTab
}) => {
  const { enabled: enablePushToAffinity } = useFlags(
    SPLITS.enablePushToAffinity
  );

  const navbarOptions = isStealthCompany
    ? stealthNavbarOptions
    : nonStealthNavbarOptions;

  const currentOption = navbarOptions.find(
    (option) => option.value === selectedTab
  )?.value as CompanyNavbarOptions;

  return (
    <div className="flex flex-row items-center gap-g20 lg:gap-g40">
      <AddCompanyToWatchlistButton companyId={companyId} />
      {enablePushToAffinity && (
        <AddCompanyToAffinityButton
          companyId={companyId}
          isDisabled={isStealthCompany ?? false}
          tooltipText={
            isStealthCompany
              ? 'Only companies with a website URL can be added'
              : undefined
          }
        />
      )}
      <LikeCompanyButton companyId={companyId} />

      <CompanyNotesButton companyId={companyId} />
      <ReportDataIssue
        showCircularBorder
        reportParams={{
          companyUrn: `urn:company:harmonic:${companyId}`,
          reportSourceView: ReportSourceView.COMPANY,
          reportSourceSection: ReportSourceSection[currentOption]
        }}
      />
    </div>
  );
};
