import { useQuery } from '@apollo/client';
import {
  ConnectionIntegrationType,
  GetCurrentUserQuery
} from '__generated__/graphql';
import ConnectionsGraphic from 'assets/connections-graphic';
import IntegrateGmailButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateGmailButton';
import IntegrateLinkedinButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateLinkedinButton';
import IntegrateOutlookButton from 'components/Dashboard/CompanyV2/CompanyConnections/IntegrateOutlookButton';
import Banner from 'harmonic-components/Banner/Banner';
import Button from 'harmonic-components/Button/Button';
import useFlags from 'hooks/useFlags';
import { GET_CURRENT_USER } from 'queries/getCurrentUser';
import { useState } from 'react';
import {
  getConnectionsBannerDismissLocalStorageState,
  setConnectionsBannerDismissLocalStorageState
} from 'utils/connections';
import { SPLITS } from 'utils/constants';

export const ConnectionsBanner: React.FC<{ hideDismissButton?: boolean }> = ({
  hideDismissButton
}) => {
  const [dismissed, setDismissed] = useState<boolean>(
    getConnectionsBannerDismissLocalStorageState()
  );

  const { data: userData } = useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: 'cache-first'
  });

  const integrationStatus =
    userData?.getCurrentUser?.connectionsIntegrationStatus;

  const isLinkedinIntegrated =
    integrationStatus?.find(
      (integ) => integ?.type === ConnectionIntegrationType.LINKEDIN
    )?.isEnabled ?? false;
  const isGmailIntegrated =
    integrationStatus?.find(
      (integ) => integ?.type === ConnectionIntegrationType.GOOGLE
    )?.isEnabled ?? false;
  const isMicrosoftIntegrated =
    integrationStatus?.find(
      (integ) => integ?.type === ConnectionIntegrationType.MICROSOFT
    )?.isEnabled ?? false;

  const { enabled: enableOutlookOauth } = useFlags(SPLITS.enableOutlookOauth);
  const onDismiss = () => {
    setDismissed(true);
    setConnectionsBannerDismissLocalStorageState(true);
  };
  if (dismissed) {
    return null;
  }
  return (
    <div className="hidden lg:block">
      <Banner
        leftGraphic={<ConnectionsGraphic />}
        data-testid="ConnectionsBanner"
      >
        <h3 className="typography-label-large-strong text-content-strong">
          Get the most from your network
        </h3>
        <p className="mt-p20 typography-paragraph-default-default text-content-default">
          {`Use your teammates' network to discover connections into companies
        and people. Sync your email, calendar, and LinkedIn to take advantage of
        connections.`}
        </p>
        <div className="flex justify-between mt-p40 max-h-8 w-full">
          <div className="flex gap-g20 w-full">
            {!isMicrosoftIntegrated && (
              <IntegrateGmailButton
                type="primary"
                isIntegrated={isGmailIntegrated}
              />
            )}
            {!isGmailIntegrated && enableOutlookOauth && (
              <IntegrateOutlookButton
                type="primary"
                isIntegrated={isMicrosoftIntegrated}
              />
            )}
            <IntegrateLinkedinButton
              type="primary"
              isIntegrated={isLinkedinIntegrated}
            />
          </div>
          {!hideDismissButton && (
            <Button label="Dismiss" emphasis="low" onClick={onDismiss} />
          )}
        </div>
        <div>&nbsp;</div>
      </Banner>
    </div>
  );
};
