import { useState } from 'react';

import SquarePlaceholder from 'assets/harmonic-icons/square-placeholder';
import Button from 'harmonic-components/Button/Button';
import ListItem, { ListVariant } from 'harmonic-components/ListItem/ListItem';
import SearchInput from 'harmonic-components/SearchInput/SearchInput';
import Select from 'harmonic-components/Select/Select';
import { capitalize, startCase, toLower } from 'lodash';
import { getColorsForDepartment } from 'utils/chart';
import { HeadcountMetricType } from './CompanyTeamGrowth';

interface DepartmentFilterProps {
  departmentOptions: string[];
  shownDepartments: string[];
  setShownDepartments: (departments: string[]) => void;
}

const DepartmentFilter = (props: DepartmentFilterProps) => {
  const { departmentOptions, shownDepartments, setShownDepartments } = props;
  const [departmentInput, setDepartmentInput] = useState('');
  const getSelectedText = () => {
    switch (shownDepartments.length) {
      case departmentOptions.length:
        return 'All';
      case 1:
        return `${startCase(toLower(shownDepartments[0]))}`;
      default:
        return `${shownDepartments.length} departments`;
    }
  };
  return (
    <div
      data-testid="CompanyDepartmentFilter"
      className="flex flex-row items-center"
    >
      <div className="text-content-weak relative whitespace-nowrap pr-p50">
        By department
      </div>
      <Select
        multiple={false}
        selected={getSelectedText()}
        keepOpenOnSelect={true}
        dropdownWidth={250}
      >
        <div className="">
          <SearchInput
            value={departmentInput}
            onChange={setDepartmentInput}
            fullWidth={true}
            placeholder="Filter departments"
          />

          <div className="flex flex-row justify-between w-full py-p20 border-b border-solid border-border">
            <Button
              label="Select all"
              onClick={() => setShownDepartments(departmentOptions)}
              size="compact"
              emphasis="low"
              type="secondary"
            />
            <Button
              label="Clear"
              onClick={() => setShownDepartments([])}
              size="compact"
              emphasis="low"
              type="secondary"
            />
          </div>
        </div>
        <div className="max-h-72 overflow-y-auto w-full">
          {departmentOptions.slice(1).map(
            (
              department //slice to remove ALL option
            ) => {
              if (
                departmentInput.length > 0 &&
                !department
                  .toLowerCase()
                  .includes(departmentInput.toLowerCase())
              ) {
                return null;
              }
              return (
                <ListItem
                  variant={ListVariant.default}
                  label={capitalize(startCase(toLower(department)))}
                  value={department}
                  onClick={() => {
                    if (shownDepartments.includes(department)) {
                      setShownDepartments(
                        shownDepartments.filter((d) => d !== department)
                      );
                    } else {
                      setShownDepartments([...shownDepartments, department]);
                    }
                  }}
                  selected={shownDepartments.includes(department)}
                  key={department}
                  primaryIcon={() => (
                    <SquarePlaceholder
                      style={{
                        color: getColorsForDepartment(
                          department as HeadcountMetricType
                        )?.background
                      }}
                    />
                  )}
                />
              );
            }
          )}
        </div>
      </Select>
    </div>
  );
};

export default DepartmentFilter;
