import classNames from 'classnames';
import { IconProps } from './type';

const SlackIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M5.66166 12.7033C5.66166 13.67 4.87582 14.4592 3.90916 14.4592C2.94416 14.4592 2.15582 13.67 2.15582 12.7033C2.15582 11.7367 2.94416 10.9483 3.90916 10.9483H5.66166V12.7033ZM6.54582 12.7033C6.54582 11.7367 7.33249 10.9483 8.29916 10.9483C9.26249 10.9483 10.0517 11.7367 10.0517 12.7033V17.0983C10.0517 18.065 9.26332 18.8533 8.29916 18.8533C7.33249 18.8533 6.54582 18.065 6.54582 17.0983V12.7033ZM8.29916 5.6575C7.33249 5.6575 6.54582 4.8675 6.54582 3.90167C6.54582 2.93583 7.33249 2.14667 8.29916 2.14667C9.26249 2.14667 10.0517 2.935 10.0517 3.90167V5.6575H8.29916ZM8.29916 6.54083C9.26249 6.54083 10.0517 7.33 10.0517 8.29667C10.0517 9.26333 9.26332 10.0517 8.29916 10.0517H3.90999C2.94499 10.0517 2.15666 9.26333 2.15666 8.29667C2.15666 7.33 2.94499 6.54083 3.90999 6.54083H8.29916ZM15.3383 8.29667C15.3383 7.33 16.1242 6.54083 17.0908 6.54083C18.0558 6.54083 18.8442 7.33 18.8442 8.29667C18.8442 9.26333 18.0558 10.0517 17.0908 10.0517H15.3383V8.29667ZM14.4542 8.29667C14.4542 9.26333 13.6675 10.0517 12.7008 10.0517C11.7375 10.0517 10.9483 9.26333 10.9483 8.29667V3.90167C10.9483 2.935 11.7367 2.14667 12.7008 2.14667C13.6675 2.14667 14.4542 2.935 14.4542 3.90167V8.29667ZM12.7008 15.3425C13.6675 15.3425 14.4542 16.1325 14.4542 17.0983C14.4542 18.0642 13.6675 18.8533 12.7008 18.8533C11.7375 18.8533 10.9483 18.065 10.9483 17.0983V15.3425H12.7008ZM12.7008 14.4592C11.7375 14.4592 10.9483 13.67 10.9483 12.7033C10.9483 11.7367 11.7367 10.9483 12.7008 10.9483H17.0908C18.0558 10.9483 18.8442 11.7367 18.8442 12.7033C18.8442 13.67 18.0558 14.4592 17.0908 14.4592H12.7008Z"
        fill="#484E5B"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SlackIcon;
