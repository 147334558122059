import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import { ReactComponent as LinkedInIcon } from 'assets/linkedin-blue.svg';
import Button from 'harmonic-components/Button/Button';
import { HARMONIC_LINKEDIN_SYNC_DOC } from 'utils/constants';

interface IntegrateButtonProps {
  isIntegrated?: boolean;
  type: 'primary' | 'secondary';
}
const IntegrateLinkedinButton: React.FC<IntegrateButtonProps> = ({
  isIntegrated,
  type
}) => {
  if (type === 'primary')
    return (
      <Button
        leadingIcon={LinkedInIcon}
        trailingIcon={
          isIntegrated
            ? (props) => (
                <CircleCheckFilledIcon
                  {...props}
                  className="text-content-sentiment-positive"
                  applyCurrentColor={true}
                />
              )
            : undefined
        }
        onClick={() => window.open(HARMONIC_LINKEDIN_SYNC_DOC, '_blank')}
        type={isIntegrated ? 'secondary' : 'primary'}
        size="compact"
        emphasis="high"
        label={isIntegrated ? 'Connected' : 'Connect LinkedIn account'}
        isDisabled={isIntegrated}
      />
    );

  return (
    <Button
      leadingIcon={(props) => <LinkedInIcon {...props} />}
      trailingIcon={
        isIntegrated
          ? (props) => (
              <CircleCheckFilledIcon {...props} applyCurrentColor={false} />
            )
          : undefined
      }
      onClick={() => window.open(HARMONIC_LINKEDIN_SYNC_DOC, '_blank')}
      type="secondary"
      size="compact"
      emphasis="high"
      label={isIntegrated ? 'Connected' : 'Connect LinkedIn account'}
      isDisabled={isIntegrated}
    />
  );
};

export default IntegrateLinkedinButton;
