import { useQuery } from '@apollo/client';
import {
  GetCompanyMetricsQuery,
  GetCompanyMetricsQueryVariables,
  GetCompanyMetrics_GetCompanyByIdFragment
} from '__generated__/graphql';
import classNames from 'classnames';
import StackedLineChart from 'components/Charts/StackedLineChart';
import { ILineChartDataPoint } from 'interfaces/Charts';
import { getCompanyMetrics } from 'queries/getCompanyMetrics';
import { useMemo, useState } from 'react';
import { formatMetricsForChart, getColorsForDepartment } from 'utils/chart';

import LineChartV2 from 'components/Charts/LineChartV2';
import HeadcountChange from '../Cards/HeadcountChange';
import DepartmentFilter from './DepartmentFilter';

const TeamGrowthContentLoader: React.FC = () => {
  return (
    <>
      <div
        data-testid="CompanyTeamGrowthLoading"
        className="flex flex-row justify-between gap-10 grid-cols-2 mt-5 mb-4"
      >
        <div className="flex w-full h-20 py-px animated-box-pulse" />
        <div className="flex w-full" />
      </div>
      <div className="mt-5 h-80 py-14 bg-slate-200 rounded-md animate-pulse"></div>
    </>
  );
};

export enum HeadcountMetricType {
  ALL_TEAM = 'ALL_TEAM',
  ADVISOR = 'ADVISOR',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  DATA = 'DATA',
  DESIGN = 'DESIGN',
  ENGINEERING = 'ENGINEERING',
  FINANCE = 'FINANCE',
  LEGAL = 'LEGAL',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  OTHER = 'OTHER',
  PEOPLE = 'PEOPLE',
  PRODUCT = 'PRODUCT',
  SALES = 'SALES',
  SUPPORT = 'SUPPORT'
}

const HeadcountMetricLabelMap = {
  [HeadcountMetricType.ALL_TEAM]: 'Entire Team',
  [HeadcountMetricType.ADVISOR]: 'Advisor',
  [HeadcountMetricType.CUSTOMER_SUCCESS]: 'Customer Success',
  [HeadcountMetricType.DATA]: 'Data',
  [HeadcountMetricType.DESIGN]: 'Design',
  [HeadcountMetricType.ENGINEERING]: 'Engineering',
  [HeadcountMetricType.FINANCE]: 'Finance',
  [HeadcountMetricType.LEGAL]: 'Legal',
  [HeadcountMetricType.MARKETING]: 'Marketing',
  [HeadcountMetricType.OPERATIONS]: 'Operations',
  [HeadcountMetricType.OTHER]: 'Other',
  [HeadcountMetricType.PEOPLE]: 'People',
  [HeadcountMetricType.PRODUCT]: 'Product',
  [HeadcountMetricType.SALES]: 'Sales',
  [HeadcountMetricType.SUPPORT]: 'Support'
};

export const formatDataForChart = (
  tractionMetrics?: GetCompanyMetrics_GetCompanyByIdFragment['tractionMetrics']
) => {
  let chartData: Partial<Record<HeadcountMetricType, ILineChartDataPoint[]>> =
    {};
  chartData = {
    [HeadcountMetricType.ALL_TEAM]: formatMetricsForChart(
      tractionMetrics?.headcount?.metrics ?? []
    ),
    [HeadcountMetricType.ADVISOR]: formatMetricsForChart(
      tractionMetrics?.headcountAdvisor?.metrics ?? []
    ),
    [HeadcountMetricType.CUSTOMER_SUCCESS]: formatMetricsForChart(
      tractionMetrics?.headcountCustomerSuccess?.metrics ?? []
    ),
    [HeadcountMetricType.DATA]: formatMetricsForChart(
      tractionMetrics?.headcountData?.metrics ?? []
    ),
    [HeadcountMetricType.DESIGN]: formatMetricsForChart(
      tractionMetrics?.headcountDesign?.metrics ?? []
    ),
    [HeadcountMetricType.ENGINEERING]: formatMetricsForChart(
      tractionMetrics?.headcountEngineering?.metrics ?? []
    ),
    [HeadcountMetricType.FINANCE]: formatMetricsForChart(
      tractionMetrics?.headcountFinance?.metrics ?? []
    ),
    [HeadcountMetricType.LEGAL]: formatMetricsForChart(
      tractionMetrics?.headcountLegal?.metrics ?? []
    ),
    [HeadcountMetricType.MARKETING]: formatMetricsForChart(
      tractionMetrics?.headcountMarketing?.metrics ?? []
    ),
    [HeadcountMetricType.OPERATIONS]: formatMetricsForChart(
      tractionMetrics?.headcountOperations?.metrics ?? []
    ),
    [HeadcountMetricType.OTHER]: formatMetricsForChart(
      tractionMetrics?.headcountOther?.metrics ?? []
    ),
    [HeadcountMetricType.PEOPLE]: formatMetricsForChart(
      tractionMetrics?.headcountPeople?.metrics ?? []
    ),
    [HeadcountMetricType.PRODUCT]: formatMetricsForChart(
      tractionMetrics?.headcountProduct?.metrics ?? []
    ),
    [HeadcountMetricType.SALES]: formatMetricsForChart(
      tractionMetrics?.headcountSales?.metrics ?? []
    ),
    [HeadcountMetricType.SUPPORT]: formatMetricsForChart(
      tractionMetrics?.headcountSupport?.metrics ?? []
    )
  };
  Object.keys(chartData).forEach((key) => {
    if (chartData[key as HeadcountMetricType]?.length === 0) {
      delete chartData[key as HeadcountMetricType];
    }
  });
  return chartData;
};

interface CompanyTeamGrowthProps {
  companyId: number;
}

const CompanyTeamGrowth: React.FC<CompanyTeamGrowthProps> = ({ companyId }) => {
  const { data } = useQuery<
    GetCompanyMetricsQuery,
    GetCompanyMetricsQueryVariables
  >(getCompanyMetrics, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only',
    onCompleted: () => {
      setShownDepartments(departmentOptions);
    }
  });

  const companyData = data?.getCompanyById;
  const tractionMetricsData = companyData?.tractionMetrics;
  const chartData = useMemo(
    () =>
      formatDataForChart(
        tractionMetricsData as GetCompanyMetrics_GetCompanyByIdFragment['tractionMetrics']
      ),
    [tractionMetricsData]
  );
  const departmentOptions = Object.keys(chartData);
  const [shownDepartments, setShownDepartments] = useState<string[]>([]);
  const departmentExist = departmentOptions.length > 1;

  return (
    <div>
      <p className="typography-title-medium py-p60 border-b border-solid border-border">
        Team growth
      </p>
      {!data ? (
        <TeamGrowthContentLoader />
      ) : (
        <>
          <div className="sm:w-2/5 mt-3 mb-5">
            <HeadcountChange companyId={companyId} />
          </div>

          <div className="border border-solid border-border bg-surface-default rounded-br40">
            <div className="p-p60 border-b border-solid border-border flex justify-between items-center">
              <p className={classNames('typography-label text-content-strong')}>
                Headcount
              </p>
              {departmentOptions.length > 2 && (
                <DepartmentFilter
                  departmentOptions={departmentOptions}
                  shownDepartments={shownDepartments}
                  setShownDepartments={setShownDepartments}
                />
              )}
            </div>

            <div className="px-p60 pt-p60 pb-p80 h-80">
              {!departmentExist && chartData.ALL_TEAM && (
                <div
                  data-testid="CompanyAllHeadcountChart"
                  className="h-80 pb-p90"
                >
                  <LineChartV2 yAxisPrefix="" data={chartData.ALL_TEAM} />
                </div>
              )}
              {departmentExist && (
                <div
                  data-testid="CompanyDepartmentHeadcountChart"
                  className="h-80 pb-p90"
                >
                  <StackedLineChart
                    yAxisPrefix=""
                    datasets={Object.entries(chartData)
                      .filter((d) => d[0] !== 'ALL_TEAM')
                      .filter(([key]) => shownDepartments.includes(key))
                      .map(([key, value]) => ({
                        label:
                          HeadcountMetricLabelMap[key as HeadcountMetricType],
                        data: value,
                        backgroundColor: getColorsForDepartment(
                          key as HeadcountMetricType
                        ).background,
                        borderColor: getColorsForDepartment(
                          key as HeadcountMetricType
                        ).border
                      }))}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyTeamGrowth;
