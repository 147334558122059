import { InstagramIcon } from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import { ReactComponent as AngelListIcon } from 'assets/socials/ic-angellist.svg';
import { ReactComponent as CrunchbaseIcon } from 'assets/socials/ic-crunchbase.svg';
import { ReactComponent as FacebookIcon } from 'assets/socials/ic-facebook.svg';
import { ReactComponent as IndeedIcon } from 'assets/socials/ic-indeed.svg';
import { ReactComponent as LinkedinIcon } from 'assets/socials/ic-linkedin.svg';
import { ReactComponent as MonsterIcon } from 'assets/socials/ic-monster.svg';
import { ReactComponent as PitchbookLoggedInIcon } from 'assets/socials/ic-pitchbook-logged.svg';
import { ReactComponent as PitchbookIcon } from 'assets/socials/ic-pitchbook.svg';
import { ReactComponent as RetoolIconInverted } from 'assets/socials/ic-retool-inverted.svg';
import { ReactComponent as RetoolIcon } from 'assets/socials/ic-retool.svg';
import { ReactComponent as StackoverflowIcon } from 'assets/socials/ic-stackoverflow.svg';
import { ReactComponent as TwitterIcon } from 'assets/socials/ic-twitter.svg';
import { ReactComponent as WebsiteIcon } from 'assets/socials/ic-website.svg';
import { ReactComponent as YoutubeIcon } from 'assets/socials/ic-youtube.svg';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { isNil } from 'lodash';
import analytics, { CustomTrackEvent } from 'utils/analytics';

interface SocialsProps {
  id: number;
  type: 'company' | 'person' | 'investor';
  websiteUrl?: string | null;
  linkedinUrl?: string | null;
  crunchbaseUrl?: string | null;
  pitchbookUrl?: string | null;
  angellistUrl?: string | null;
  twitterUrl?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  indeedUrl?: string | null;
  youtubeUrl?: string | null;
  monsterUrl?: string | null;
  stackoverflowUrl?: string | null;
  fundingInspectorUrl?: string;
  companyCorrectorUrl?: string;
}

//convert public pitchbook URL to private pitchbook URL
const getTransformedPrivatePitchbookUrl = (
  urlString: string | null | undefined
) => {
  if (isNil(urlString)) return null;
  try {
    const url = new URL(urlString);
    const pathSegments = url.pathname.split('/').filter(Boolean); // Remove empty segments

    const companyIndex = pathSegments.findIndex(
      (segment) => segment === 'company'
    );
    if (companyIndex !== -1 && pathSegments[companyIndex + 1]) {
      const id = pathSegments[companyIndex + 1];
      // Construct the new URL
      return `https://my.pitchbook.com/profile/${id}/company/profile`;
    } else {
      return null; // ID not found
    }
  } catch (error) {
    return null; // Invalid URL
  }
};

const Socials = (props: SocialsProps) => {
  const {
    id,
    type,
    websiteUrl,
    linkedinUrl,
    crunchbaseUrl,
    pitchbookUrl,
    angellistUrl,
    twitterUrl,
    facebookUrl,
    instagramUrl,
    indeedUrl,
    youtubeUrl,
    monsterUrl,
    stackoverflowUrl,
    fundingInspectorUrl,
    companyCorrectorUrl
  } = props;

  const privatePitchbookUrl = getTransformedPrivatePitchbookUrl(pitchbookUrl);
  const openSocialLink = (url: string, linkType: string) => {
    if (type === 'company') {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_PROFILE_OUTLINK_CLICK,
        properties: {
          companyId: id,
          link: url,
          linkType
        }
      });
    }
    if (type === 'person') {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.PERSON_PROFILE_OUTLINK_CLICK,
        properties: {
          personId: id,
          link: url,
          linkType
        }
      });
    }

    window.open(url);
  };

  const emphasis = type == 'investor' ? 'high' : 'low';
  const size = type == 'investor' ? 'default' : 'compact';

  return (
    <div
      className={classNames('flex items-center', {
        'gap-x-1': type !== 'investor',
        'gap-g30': type == 'investor'
      })}
    >
      {websiteUrl && (
        <a href={websiteUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(websiteUrl, 'website')}
            icon={WebsiteIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {linkedinUrl && (
        <a href={linkedinUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(linkedinUrl, 'linkedin')}
            icon={LinkedinIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {crunchbaseUrl && (
        <a href={crunchbaseUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(crunchbaseUrl, 'crunchbase')}
            icon={CrunchbaseIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {pitchbookUrl && (
        <a href={pitchbookUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(pitchbookUrl, 'pitchbook')}
            icon={PitchbookIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {privatePitchbookUrl && (
        <a href={privatePitchbookUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(privatePitchbookUrl, 'pitchbook')}
            icon={PitchbookLoggedInIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {angellistUrl && (
        <a href={angellistUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(angellistUrl, 'angellist')}
            icon={AngelListIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {twitterUrl && (
        <a href={twitterUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(twitterUrl, 'twitter')}
            icon={TwitterIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {facebookUrl && (
        <a href={facebookUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(facebookUrl, 'facebook')}
            icon={FacebookIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {instagramUrl && (
        <a href={instagramUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(instagramUrl, 'instagram')}
            icon={(props: IconProps) => (
              <InstagramIcon {...props} applyCurrentColor={false} />
            )}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {indeedUrl && (
        <a href={indeedUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(indeedUrl, 'indeed')}
            icon={IndeedIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {youtubeUrl && (
        <a href={youtubeUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(youtubeUrl, 'youtube')}
            icon={YoutubeIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {monsterUrl && (
        <a href={monsterUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(monsterUrl, 'monster')}
            icon={MonsterIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {stackoverflowUrl && (
        <a href={stackoverflowUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() => openSocialLink(stackoverflowUrl, 'stackoverflow')}
            icon={StackoverflowIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {fundingInspectorUrl && (
        <a href={fundingInspectorUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() =>
              openSocialLink(fundingInspectorUrl, 'fundingInspector')
            }
            icon={RetoolIcon}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}

      {companyCorrectorUrl && (
        <a href={companyCorrectorUrl} onClick={(e) => e.preventDefault()}>
          <IconButton
            onClick={() =>
              openSocialLink(companyCorrectorUrl, 'companyCorrectorUrl')
            }
            icon={RetoolIconInverted}
            size={size}
            type="secondary"
            emphasis={emphasis}
            dataTestId="Social"
          />
        </a>
      )}
    </div>
  );
};

export default Socials;
