import { ColDef, IRowNode } from 'ag-grid-community';
import { get, uniqBy } from 'lodash';
import { CompanyUserConnectionV2Fragment } from '../../../../../__generated__/graphql';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { useAppState } from '../../../../../hooks/useAppState';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { IPerson } from '../../../../../interfaces/DataModel/Person';
import { DASHBOARD_ROUTE } from '../../../../../utils/constants';
import { CompanyNavbarOptions } from '../../../CompanyV2/CompanyHeader/CompanyHeader';
import { NoDataSubRenderer } from '../CellRenderers';

export const UserConnections: React.FC<{
  value: any;
  colDef?: ColDef;
  node?: IRowNode<any>;
}> = (props) => {
  const company: ICompany = get(props, 'node.data.company');
  const person: IPerson = get(props, 'node.data.person');
  const field = props.colDef?.field ?? '';

  const currentUserEmail = useAppState<string>(
    (state) => state.auth.user?.email ?? ''
  );

  const connections: CompanyUserConnectionV2Fragment[] = (
    (props.value as CompanyUserConnectionV2Fragment[]) ?? []
  ).map(
    (connection) =>
      ({
        ...connection,
        user: {
          ...connection.user,
          name:
            // Show name as "You" if the connection is the currently logged in user
            connection.user?.email === currentUserEmail
              ? 'You'
              : connection.user?.name
        }
      } as CompanyUserConnectionV2Fragment)
  );
  const items = uniqBy(connections, (connection) => connection.user?.email).map(
    (connection: CompanyUserConnectionV2Fragment) => ({
      text: connection.user?.name ?? connection.user?.email ?? '',
      href: field.includes('company.')
        ? `${DASHBOARD_ROUTE}/company/${company?.id}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
        : `${DASHBOARD_ROUTE}/person/${person?.id}`
    })
  );

  if (items.length === 0) {
    return <NoDataSubRenderer type="connections" />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {items.map((item, index) => (
          <div key={index}>
            <PersonAttribute src="" name={item.text} href={item.href} />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};
