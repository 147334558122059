import { Popover } from '@headlessui/react';
import { Options } from '@popperjs/core';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import Dropdown from 'harmonic-components/Dropdown/Dropdown';
import { ListVariant } from 'harmonic-components/ListItem/ListItem';
import { isObject, isString } from 'lodash';
import React, {
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import { usePopper } from 'react-popper';
import { ColorShorthand } from 'utils/design';
import { InputRef, MultiSelect } from './MultiSelect';
import SelectAddOption from './SelectAddOption';
import SelectContext from './SelectContext';
import { SelectListItemProps } from './SelectListItem';
import { SingleSelect } from './SingleSelect';

export type SelectBaseProps = {
  multiple?: boolean;
  onRemove?: (key: string) => void;
  getLabelFromValue?: (value?: string) => string;
  filterable?: boolean;
  filterTerm?: string;
  onFilterTermChange?: (value: string) => void;
  initialFocus?: boolean;
  fullWidth?: boolean;
  dropdownWidth?: number;
  dropdownMaxHeight?: string;
  dataTestId?: string;
  dropdownDataTestId?: string;
  placeholder?: string;
  hideChevronDown?: boolean;
  hideDropdown?: boolean;
  scrollDropdownIntoView?: boolean;
  disabled?: boolean;
  minHeight?: number;
  alwaysOpen?: boolean;
  onClose?: () => void;
  onAddNewOption?: (value?: string) => Promise<void>;
  borderOverrideClasses?: string;
  clampValues?: boolean;
  inputRef?: RefObject<InputRef>;
  size?: 'small';
};

type SingleSelectProps = {
  selected?: string;
  icon?: HarmonicIcon;
  keepOpenOnSelect?: boolean;
};

export type MultiSelectProps = {
  selected?: string[];
  labelPrefix?: string;
  freeSolo?: boolean;
  onAdd?: (value: string) => void;
  getTagIconFromValue?: (value?: string) => HarmonicIcon | undefined;
  getTagColorFromValue?: (value?: string) => ColorShorthand | undefined;
};

type SelectPropsByType =
  | ({ multiple: false } & SingleSelectProps)
  | ({ multiple: true } & MultiSelectProps);

type SelectProps = SelectBaseProps & SelectPropsByType;

// Extracts all the list items from props.children
export const getListItemsFromChildren = (children: React.ReactNode) => {
  let values: SelectListItemProps[] = [];
  React.Children.forEach(children, (child) => {
    if (!isObject(child)) return;
    const childElement = child as React.ReactElement;
    if (
      !isString(childElement.type) &&
      //eslint-disable-next-line
      //@ts-ignore
      childElement.type.displayName === 'SelectListItem'
    ) {
      values.push(childElement.props);
    }
    if (childElement.props && childElement.props.children) {
      values = [
        ...values,
        ...getListItemsFromChildren(childElement.props.children)
      ];
    }
  });
  return values;
};

const Select: React.FC<PropsWithChildren<SelectProps>> = (props) => {
  const [keyboardFocusIndex, setKeyboardFocusIndex] = useState(-1);
  const [listItems, setListItems] = useState<SelectListItemProps[]>([]);
  const initialized = useRef(false);
  const {
    onRemove,
    multiple,
    selected,
    filterable,
    filterTerm,
    onFilterTermChange,
    getLabelFromValue,
    placeholder,
    children,
    initialFocus,
    fullWidth = false,
    minHeight,
    dropdownWidth,
    dataTestId,
    dropdownDataTestId,
    disabled,
    alwaysOpen,
    scrollDropdownIntoView,
    borderOverrideClasses,
    clampValues,
    inputRef
  } = props;

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const buttonRef = useRef<HTMLButtonElement | null>();

  const { styles, attributes } = usePopper(buttonRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2]
        }
      },
      {
        name: 'recalculate when height changes',
        options: buttonRef?.current?.clientHeight as Partial<Options>
      }
    ]
  });

  useEffect(() => {
    if (initialFocus && buttonRef && !initialized.current) {
      buttonRef?.current?.click();
      initialized.current = true;
    }
  }, [buttonRef, initialFocus]);

  useEffect(() => {
    const listItems = getListItemsFromChildren(children);
    setListItems(listItems);
  }, [children]);

  useEffect(() => {
    if (filterTerm) {
      setKeyboardFocusIndex(0);
    } else {
      setKeyboardFocusIndex(-1);
    }
  }, [filterTerm]);

  const onArrowDownPress = () => {
    if (keyboardFocusIndex < listItems.length - 1) {
      setKeyboardFocusIndex(keyboardFocusIndex + 1);
    }
  };

  const onArrowUpPress = () => {
    if (keyboardFocusIndex > 0) {
      setKeyboardFocusIndex(keyboardFocusIndex - 1);
    }
  };

  const onEnterPress = () => {
    if (listItems.length > 0) {
      const item = listItems?.[keyboardFocusIndex];
      if (!item) return;

      // TODO: Update the ListItem type to have just "onClick" for uniformity
      if (item.variant === ListVariant.checkboxes) {
        //eslint-disable-next-line
        //@ts-ignore
        item?.onChange();
      } else {
        item?.onClick?.();
      }
    }
    if (multiple && props.freeSolo && filterTerm) {
      props.onAdd?.(filterTerm);
    }
  };

  const dropdownProps = {
    maxHeight: props.dropdownMaxHeight,
    dataTestId: dropdownDataTestId,
    scrollIntoView: scrollDropdownIntoView
  };

  if (multiple) {
    return (
      <Popover
        className={classNames('relative', {
          'w-full': fullWidth
        })}
      >
        {({ open, close }) => {
          return (
            <SelectContext.Provider
              value={{ open, close, keyboardFocusIndex, listItems }}
            >
              <MultiSelect
                minHeight={minHeight}
                selected={selected}
                open={alwaysOpen || open}
                close={() => {
                  close();
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
                onRemove={onRemove}
                filterable={filterable}
                filterTerm={filterTerm}
                setFilterTerm={onFilterTermChange}
                getLabelFromValue={getLabelFromValue}
                getTagColorFromValue={props.getTagColorFromValue}
                getTagIconFromValue={props.getTagIconFromValue}
                onArrowDownPress={onArrowDownPress}
                onArrowUpPress={onArrowUpPress}
                onEnterPress={onEnterPress}
                labelPrefix={props.labelPrefix}
                freeSolo={props.freeSolo}
                onAdd={props.onAdd}
                placeholder={placeholder}
                dataTestId={dataTestId}
                ref={buttonRef as React.MutableRefObject<HTMLButtonElement>}
                hideChevronDown={props.hideChevronDown}
                disabled={disabled}
                borderOverrideClasses={borderOverrideClasses}
                clampValues={clampValues}
                externalInputRef={inputRef}
                size={props.size}
              />
              {!props.hideDropdown && (
                <Popover.Panel
                  static={alwaysOpen}
                  className={classNames(
                    'absolute z-10',
                    !dropdownWidth && 'w-full'
                  )}
                  ref={setPopperElement}
                  style={{
                    ...styles.popper,
                    width: dropdownWidth ? `${dropdownWidth}px` : undefined
                  }}
                  {...attributes.popper}
                >
                  <Dropdown {...dropdownProps}>
                    {children}
                    {props.onAddNewOption && filterTerm && (
                      <SelectAddOption
                        filterTerm={filterTerm}
                        onAddNewOption={props.onAddNewOption}
                        borderTop={children != ''}
                      />
                    )}
                  </Dropdown>
                </Popover.Panel>
              )}
            </SelectContext.Provider>
          );
        }}
      </Popover>
    );
  }

  return (
    <Popover
      className={classNames('relative', {
        'w-full': fullWidth
      })}
    >
      {({ open, close }) => {
        return (
          <SelectContext.Provider
            value={{ open, close, keyboardFocusIndex, listItems }}
          >
            <SingleSelect
              minHeight={minHeight}
              selected={selected}
              open={alwaysOpen || open}
              filterable={filterable}
              filterTerm={filterTerm}
              setFilterTerm={onFilterTermChange}
              getLabelFromValue={getLabelFromValue}
              onArrowDownPress={onArrowDownPress}
              onArrowUpPress={onArrowUpPress}
              onEnterPress={onEnterPress}
              placeholder={placeholder}
              dataTestId={dataTestId}
              icon={props.icon}
              disabled={disabled}
              ref={buttonRef as React.MutableRefObject<HTMLButtonElement>}
              borderOverrideClasses={borderOverrideClasses}
              size={props.size}
            />
            {!props.hideDropdown && (
              <Popover.Panel
                static={alwaysOpen}
                className={classNames(
                  'absolute z-10',
                  !dropdownWidth && 'w-full'
                )}
                ref={setPopperElement}
                style={{
                  ...styles.popper,
                  width: dropdownWidth ? `${dropdownWidth}px` : undefined
                }}
                {...attributes.popper}
              >
                <Dropdown {...dropdownProps}>
                  {React.Children.map(children, (child) => {
                    return (
                      <div
                        onClick={() => {
                          if (props.keepOpenOnSelect) return;
                          close();
                        }}
                        className="w-full"
                      >
                        {child}
                      </div>
                    );
                  })}
                </Dropdown>
              </Popover.Panel>
            )}
          </SelectContext.Provider>
        );
      }}
    </Popover>
  );
};
export default Select;
