import { WarningIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { SVGProps, useEffect, useRef, useState } from 'react';

interface TextInputProps {
  errorMessage?: string;
  helperText?: string;
  label?: string;
  prefix?: string;
  placeholder?: string;
  leadingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  trailingIcon?: React.FC<SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  suffix?: string;
  dataTestId?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  type?: string;
  fullWidth?: boolean;
  maxLength?: number;
  borderOverrideClasses?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const TextInput: React.FC<TextInputProps> = ({
  errorMessage,
  leadingIcon,
  label,
  prefix,
  placeholder,
  trailingIcon,
  dataTestId,
  suffix,
  helperText,
  isDisabled,
  value,
  autoFocus,
  onChange,
  onBlur,
  onKeyDown,
  type,
  inputRef,
  maxLength,
  borderOverrideClasses
}) => {
  //Prefix and suffix width depends on length of text. It's dynamically calculated
  const [prefixWidth, setPrefixWidth] = useState(0);
  const prefixRef = useRef<HTMLDivElement>(null);
  const [suffixWidth, setSuffixWidth] = useState(0);
  const suffixRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prefixRef.current) {
      const prefixWidth = prefixRef.current.offsetWidth;
      setPrefixWidth(prefixWidth);
    } else {
      setPrefixWidth(0);
    }
  }, [prefix, prefixRef?.current?.offsetWidth, leadingIcon]);

  useEffect(() => {
    if (suffixRef.current) {
      const suffixWidth = suffixRef.current.offsetWidth;
      setSuffixWidth(suffixWidth);
    }
  }, [suffix, suffixRef?.current?.offsetWidth, trailingIcon, errorMessage]);

  const LeadingIcon = leadingIcon;
  const TrailingIcon = trailingIcon;

  return (
    <div
      data-testid={dataTestId}
      className="flex flex-col gap-g20 w-full bg-surface-default"
    >
      {label && <p className="text-content-weak typography-label">{label}</p>}
      <div className="relative">
        <input
          style={{
            paddingLeft:
              prefixWidth > 0
                ? `calc(${prefixWidth}px + var(--gap-g10))`
                : 'var(--spacing-p50)',
            paddingRight:
              suffixWidth > 0
                ? `calc(${suffixWidth}px + var(--gap-g10))`
                : 'var(--spacing-p50)'
          }}
          ref={inputRef}
          disabled={isDisabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          type={type}
          className={classNames(
            'py-p40 border border-solid typography-label w-full',
            'bg-int-overlay-secondary-enabled placeholder-content-muted',
            'text-content-default rounded-br20 custom-text-input',

            //disabled state
            isDisabled &&
              'bg-int-overlay-secondary-disabled border-int-outline-secondary-disabled placeholder-content-muted',
            //error state
            errorMessage &&
              !isDisabled &&
              !borderOverrideClasses &&
              'border-int-fill-negative-enabled border-[1.5px]',
            //default state
            !errorMessage &&
              !isDisabled &&
              classNames(
                // Hover state
                'hover:bg-int-overlay-secondary-hover hover:text-input-value-hover',
                // Active state
                'active:bg-int-overlay-secondary-pressed active:text-input-value-pressed',
                // Focus state
                'focus-visible:bg-int-overlay-secondary-enabled',
                'data-[open=true]:bg-int-overlay-secondary-enabled'
              ),
            !errorMessage &&
              !isDisabled &&
              !borderOverrideClasses &&
              classNames(
                // Default state
                'border-int-outline-secondary-enabled',
                // Hover state
                'hover:border-int-outline-secondary-hover',
                // Active state
                'active:border-int-outline-secondary-pressed',
                // Focus state
                'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled',
                'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled'
              ),
            borderOverrideClasses
          )}
          maxLength={maxLength}
          autoFocus={autoFocus}
        />
        {/* Prefix components */}
        {(LeadingIcon || prefix) && (
          <div
            ref={prefixRef}
            className="absolute top-0 bottom-0 left-0 pl-p50 flex items-center gap-g40 pointer-events-none"
          >
            {LeadingIcon && (
              <span>
                <LeadingIcon className="text-content-weak w-4 h-4" />
              </span>
            )}

            {prefix && (
              <span className="text-content-weak typography-label">
                {prefix}
              </span>
            )}
          </div>
        )}

        {/* Suffix components */}
        {(TrailingIcon || suffix || errorMessage) && (
          <div
            ref={suffixRef}
            className="absolute top-0 bottom-0 right-0 pr-p50 flex items-center gap-g40 pointer-events-none"
          >
            {suffix && (
              <span className="text-content-weak typography-label">
                {suffix}
              </span>
            )}
            {TrailingIcon && (
              <TrailingIcon className="fill-current text-content-weak w-4 h-4" />
            )}
            {errorMessage && (
              <WarningIcon className=" text-content-sentiment-negative w-4 h-4" />
            )}
          </div>
        )}
      </div>
      {errorMessage && (
        <p className="typography-label text-int-fill-negative-enabled">
          {errorMessage}
        </p>
      )}

      {helperText && (
        <p className="typography-label text-content-weak">{helperText}</p>
      )}
    </div>
  );
};

export default TextInput;
