//give me a basic react component

import { useMutation } from '@apollo/client';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import {
  ConnectionIntegrationType,
  CustomerIntegrationPrivacySetting,
  UpdateCustomerIntegrationMutation,
  UpdateCustomerIntegrationMutationVariables
} from '__generated__/graphql';
import CircleCheckFilledIcon from 'assets/harmonic-icons/circle-check-filled';
import GoogleIcon from 'assets/harmonic-icons/google';
import { Tooltip } from 'common';
import Button from 'harmonic-components/Button/Button';
import useFlags from 'hooks/useFlags';
import { useModal } from 'hooks/useModal';
import useUser from 'hooks/useUser';
import { UPDATE_CUSTOMER_INTEGRATION_MUTATION } from 'queries/updateCustomerIntegration';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SPLITS } from 'utils/constants';
import { authenticateGoogleIntegration } from 'utils/midtierApi';
import { displayToast } from 'utils/toasts';
import EmailCalendarTermsModal from './EmailCalendarTermsModal';

interface AuthResponse {
  code: string;
  scope: string;
  prompt: string;
  authuser: string;
  query?: string;
}

enum AuthStatus {
  None,
  GettingStarted,
  ReadyToSign,
  Pending,
  MissingScopes,
  Valid,
  Scheduled,
  Error
}

const endpoint = 'https://api.harmonic.ai/google_auth/authenticate';

interface IntegrateGmailButtonProps {
  isIntegrated?: boolean;
  type: 'primary' | 'secondary';
  onSuccess?: () => void;
  labelOverride?: string;
}
const IntegrateGmailButton: React.FC<IntegrateGmailButtonProps> = ({
  isIntegrated = false,
  type,
  onSuccess,
  labelOverride
}) => {
  const { show, close } = useModal();

  const { enabled: restrictNetworkMappingMetadataOnly } = useFlags(
    SPLITS.restrictNetworkMappingMetadataOnly
  );
  const [updateCustomerIntegration] = useMutation<
    UpdateCustomerIntegrationMutation,
    UpdateCustomerIntegrationMutationVariables
  >(UPDATE_CUSTOMER_INTEGRATION_MUTATION);

  const { enabled: blockEmailIntegration } = useFlags(
    SPLITS.blockEmailIntegration
  );

  const { setIntegrationStatus } = useUser();
  const didPressSignIn = useGoogleLogin({
    onSuccess: (codeResponse) =>
      receivedAuthResponse(codeResponse as AuthResponse),
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly openid',
    redirect_uri: endpoint
  });

  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(
    AuthStatus.ReadyToSign
  );

  const receivedAuthResponse = (authResponse: AuthResponse) => {
    setAuthStatus(AuthStatus.Pending);
    if (
      !authResponse.scope.includes('gmail.readonly') ||
      !authResponse.scope.includes('calendar.readonly')
    ) {
      setAuthStatus(AuthStatus.MissingScopes);
      toast.error(
        'To set up this integration, you need to grant permissions for both email and calendar. Please try again.',
        { autoClose: false }
      );
      return;
    }
    authenticateGoogleIntegration(authResponse.code)
      .then(async (response) => {
        const statusText = response.data.status;
        if (statusText === 'missing_scopes') {
          setAuthStatus(AuthStatus.MissingScopes);
          displayToast({
            primaryText:
              'Some permission scopes are missing. Please try again later',
            mode: 'error'
          });
        } else if (statusText === 'success') {
          // TODO: Update authenticate endpoint to pass this in as part of initial request
          if (restrictNetworkMappingMetadataOnly) {
            await updateCustomerIntegration({
              variables: {
                input: {
                  integrationType: ConnectionIntegrationType.GOOGLE,
                  privacySetting: CustomerIntegrationPrivacySetting.LIMITED
                }
              }
            });
          }
          setAuthStatus(AuthStatus.Valid);
          setIntegrationStatus(ConnectionIntegrationType.GOOGLE, true);
          toast.success(
            <p>
              Google account connected successfully. Data may take up to 24
              hours to appear in app. You may check sync status in your{' '}
              <Link className="underline" to="/settings?t=emailCalendar">
                Settings
              </Link>
              .
            </p>,
            { autoClose: false }
          );
          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(() => {
        setAuthStatus(AuthStatus.Error);
        displayToast({
          primaryText: 'Some problem occured. Please try again later',
          mode: 'error'
        });
      });
  };

  const onClick = () => {
    show(
      <EmailCalendarTermsModal
        handleClose={close}
        onConfirmClick={() => {
          didPressSignIn();
          close();
        }}
        integrationType={ConnectionIntegrationType.GOOGLE}
      />
    );
  };

  let ButtonComponent = null;

  const isLoading = authStatus === AuthStatus.Pending;
  if (type === 'primary') {
    ButtonComponent = () => (
      <Button
        dataTestId="integrate-gmail-button"
        leadingIcon={() => <GoogleIcon />}
        trailingIcon={
          isIntegrated
            ? () => (
                <CircleCheckFilledIcon
                  className="text-content-sentiment-positive"
                  applyCurrentColor={true}
                />
              )
            : undefined
        }
        isDisabled={isLoading || isIntegrated || blockEmailIntegration}
        loading={isLoading}
        type={isIntegrated ? 'secondary' : 'primary'}
        size="compact"
        emphasis="high"
        label={
          labelOverride ||
          (isIntegrated ? 'Connected' : 'Connect Google account')
        }
        onClick={onClick}
      />
    );
  } else {
    ButtonComponent = () => (
      <Button
        dataTestId="integrate-gmail-button"
        leadingIcon={GoogleIcon}
        trailingIcon={
          isIntegrated
            ? () => <CircleCheckFilledIcon applyCurrentColor={false} />
            : undefined
        }
        isDisabled={isLoading || isIntegrated || blockEmailIntegration}
        loading={isLoading}
        type="secondary"
        size="compact"
        emphasis="high"
        label={
          labelOverride ||
          (isIntegrated ? 'Connected' : 'Connect Google account')
        }
        onClick={onClick}
      />
    );
  }

  return (
    <Tooltip
      title={
        blockEmailIntegration
          ? 'Email integration disabled by your administrator.'
          : ''
      }
    >
      <ButtonComponent />
    </Tooltip>
  );
};

const AuthProviderWrapper: React.FC<IntegrateGmailButtonProps> = (props) => {
  return (
    <GoogleOAuthProvider clientId="738524585869-nu2uafl27s7nfusqmr8val0opbh5tuv5.apps.googleusercontent.com">
      <IntegrateGmailButton {...props} />
    </GoogleOAuthProvider>
  );
};

export default AuthProviderWrapper;
