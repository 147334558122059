import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import {
  CompanyViewRecordTuple,
  CompanyWatchlistEntryEdge,
  GetCompaniesViewRecordsQuery,
  GetCompaniesViewRecordsQueryVariables,
  HideCompaniesFromSearchesMutation,
  HideCompaniesFromSearchesMutationVariables,
  LikeCompaniesMutation,
  LikeCompaniesMutationVariables,
  SearchResultNode,
  SearchType,
  UnhideCompaniesFromSearchesMutation,
  UnhideCompaniesFromSearchesMutationVariables,
  UnlikeCompaniesMutation,
  UnlikeCompaniesMutationVariables
} from '__generated__/graphql';
import { getUrnFragment } from 'common/utils/urn';
import useNetNewCounts from 'components/Dashboard/Collections/Searches/NetNew/useNetNewCounts';
import { SearchStateFilter } from 'interfaces/SearchV2';
import { every } from 'lodash';
import { DELETE_USER_VIEW_RECORD_FOR_COMPANY } from 'queries/deleteUserViewRecord';
import { getCompaniesViewRecords } from 'queries/getCompanyViewRecords';
import {
  HIDE_COMPANIES_FROM_SEARCHES,
  UNHIDE_COMPANIES_FROM_SEARCHES
} from 'queries/hideUnhideCompanies';
import { LIKE_COMPANIES, UNLIKE_COMPANIES } from 'queries/likeUnlikeCompanies';
import { UPSERT_USER_VIEW_RECORD_FOR_COMPANY } from 'queries/upsertUserViewRecordForCompany';
import { useCallback } from 'react';
import useSavedSearchStore from 'stores/savedSearchStore';
import { useShallowTableStore } from 'stores/tableStore';
import analytics, {
  CompanyActionEventType,
  CustomTrackEvent
} from 'utils/analytics';
import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import useDashboardLocation from './useDashboardLocation';
import useFetchWatchlists from './useFetchWatchlists';

export type SetViewed = (
  companyIds: number[],
  fromHotkey?: boolean,
  disableToast?: boolean
) => void;
export type SetUnviewed = (
  companyIds: number[],
  fromHotkey?: boolean,
  disableToast?: boolean
) => void;

interface UseCompanyEntityActionsResponse {
  likeCompanies: (
    companyIds: number[],
    fromHotkey?: boolean,
    disableToast?: boolean
  ) => void;
  unlikeCompanies: (
    companyIds: number[],
    fromHotkey?: boolean,
    disableToast?: boolean
  ) => void;
  hideCompanies: (
    companyIds: number[],
    savedSearchId?: string | null,
    fromHotkey?: boolean,
    disableToast?: boolean
  ) => void;
  unhideCompanies: (
    companyIds: number[],
    savedSearchId?: string | null,
    fromHotkey?: boolean,
    disableToast?: boolean
  ) => void;
  setViewed: SetViewed;
  setUnviewed: SetUnviewed;
  areViewed: (companyIds: number[]) => boolean;
}

interface UseCompanyEntityActionsOptions {
  disableToast?: boolean;
}

const defaultOptions: UseCompanyEntityActionsOptions = {
  disableToast: false
};

export const useCompanyEntityActions = ({
  disableToast
}: UseCompanyEntityActionsOptions = defaultOptions): UseCompanyEntityActionsResponse => {
  const { likedCompaniesWatchlist, hiddenCompaniesWatchlist } =
    useFetchWatchlists();

  const { editTableStoreData, selectedNetNewIds } = useShallowTableStore([
    'editTableStoreData',
    'selectedNetNewIds'
  ]);

  const client = useApolloClient();
  const { urn, location } = useDashboardLocation();
  const currentPageEntityId = getUrnFragment(urn, 3);
  const isSavedSearch = urn?.includes('urn:harmonic:saved_search');
  const incrementCount = useSavedSearchStore((state) => state.incrementCount);
  const decrementCount = useSavedSearchStore((state) => state.decrementCount);

  const [upsertUserViewed] = useMutation(UPSERT_USER_VIEW_RECORD_FOR_COMPANY);
  const [deleteUserViewed] = useMutation(DELETE_USER_VIEW_RECORD_FOR_COMPANY);

  const { onClearNetNewByIds } = useNetNewCounts();

  const [likeCompaniesMutation] = useMutation<
    LikeCompaniesMutation,
    LikeCompaniesMutationVariables
  >(LIKE_COMPANIES);
  const [unlikeCompaniesMutation] = useMutation<
    UnlikeCompaniesMutation,
    UnlikeCompaniesMutationVariables
  >(UNLIKE_COMPANIES);
  const [hideCompaniesFromSearches] = useMutation<
    HideCompaniesFromSearchesMutation,
    HideCompaniesFromSearchesMutationVariables
  >(HIDE_COMPANIES_FROM_SEARCHES);
  const [unhideCompaniesFromSearches] = useMutation<
    UnhideCompaniesFromSearchesMutation,
    UnhideCompaniesFromSearchesMutationVariables
  >(UNHIDE_COMPANIES_FROM_SEARCHES);

  const likeCompanies = useCallback(
    async (companyIds: number[], fromHotkey = false, isUndo = false) => {
      if (!likedCompaniesWatchlist) return;
      const areCompaniesViewed = areViewed(companyIds);

      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_ACTION_CLICK,
        properties: {
          action: CompanyActionEventType.LIKE,
          companyIds: companyIds.join(','),
          openedFromLocation: location,
          isBulkAction: companyIds?.length > 1,
          fromHotkey
        }
      });

      if (!disableToast) {
        displayToast({
          primaryText: isUndo
            ? 'Action undone.'
            : companyIds.length > 1
            ? `Liked ${companyIds.length} companies.`
            : `Company liked.`,
          cta: !isUndo
            ? {
                text: 'Undo',
                onClick: () => {
                  unlikeCompanies(companyIds, false, true);
                }
              }
            : undefined
        });
      }

      likeCompaniesMutation({
        variables: {
          companies: companyIds.map((id) => 'urn:harmonic:company:' + id)
        },
        optimisticResponse: {
          likeCompanies: companyIds.map((id) => ({
            __typename: 'Company',
            id: id,
            isLikedByCurrentUser: true
          }))
        },
        update: (existingCache) => {
          companyIds.forEach((companyId) => {
            // Mark as viewed on like
            existingCache.modify({
              id: existingCache.identify({
                __typename: 'Company',
                id: companyId
              }),
              fields: {
                viewRecord: () => {
                  return {
                    __typename: 'CompanyViewRecord',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                  };
                }
              },
              optimistic: true,
              broadcast: false
            });
          });
        },
        onError: (error) => {
          if (error) {
            logger.error('Like company error', { error });
            if (!disableToast) {
              displayToast({
                mode: 'error',
                primaryText:
                  'A problem occured while liking the company. Please try again later'
              });
            }
          }
        }
      });

      if (!areCompaniesViewed) {
        setViewed(companyIds);
      }

      // Deselect rows when on the liked page
      if (currentPageEntityId == likedCompaniesWatchlist?.id) {
        _deselectRows();
      }
    },
    [likedCompaniesWatchlist]
  );

  const hideCompanies = async (
    companyIds: number[],
    savedSearchId?: string | null,
    fromHotkey = false,
    isUndo = false
  ) => {
    const areCompaniesViewed = areViewed(companyIds);
    analytics.trackCustomEvent({
      event: CustomTrackEvent.COMPANY_ACTION_CLICK,
      properties: {
        action: CompanyActionEventType.HIDE,
        companyIds: companyIds.join(','),
        openedFromLocation: location,
        isBulkAction: companyIds?.length > 1,
        fromHotkey
      }
    });

    hideCompaniesFromSearches({
      variables: {
        companies: companyIds.map((id) => 'urn:harmonic:company:' + id)
      },
      optimisticResponse: {
        hideCompaniesFromSearches: companyIds.map((id) => ({
          __typename: 'Company',
          id: id,
          isHiddenFromSearchesByCurrentUser: true
        }))
      },
      onError: (error) => {
        if (error) {
          logger.error('Hide company error', {
            error
          });
          if (!disableToast) {
            displayToast({
              mode: 'error',
              primaryText:
                'A problem occured while hiding the company. Please try again later'
            });
          }
        }
      },
      onCompleted: () => {
        if (!disableToast) {
          displayToast({
            primaryText: isUndo
              ? 'Action undone.'
              : companyIds.length > 1
              ? `${companyIds?.length} companies moved to Hidden companies.`
              : `Company moved to Hidden companies.`,
            cta: !isUndo
              ? {
                  text: 'Undo',
                  onClick: () => {
                    unhideCompanies(companyIds, savedSearchId, false, true);
                  }
                }
              : undefined
          });
        }
        if (!areCompaniesViewed) {
          setViewed(companyIds);
        }
      }
    });

    _clearCompaniesFromSearchCompaniesCache({ companyIds, savedSearchId });
    _deselectRows();
  };

  const unhideCompanies = async (
    companyIds: number[],
    savedSearchId?: string | null,
    fromHotkey = false,
    isUndo = false
  ) => {
    if (!hiddenCompaniesWatchlist) return;
    const areCompaniesViewed = areViewed(companyIds);
    analytics.trackCustomEvent({
      event: CustomTrackEvent.COMPANY_ACTION_CLICK,
      properties: {
        action: CompanyActionEventType.UNHIDE,
        companyIds: companyIds.join(','),
        openedFromLocation: location,
        isBulkAction: companyIds?.length > 1,
        fromHotkey
      }
    });

    unhideCompaniesFromSearches({
      variables: {
        companies: companyIds.map((id) => 'urn:harmonic:company:' + id)
      },
      optimisticResponse: {
        unhideCompaniesFromSearches: companyIds.map((id) => ({
          __typename: 'Company',
          id: id,
          isHiddenFromSearchesByCurrentUser: false
        }))
      },
      onError: (error) => {
        if (error) {
          logger.error('Unhide company error', { error });
          if (!disableToast) {
            displayToast({
              mode: 'error',
              primaryText:
                'A problem occured while unhiding the company. Please try again later'
            });
          }
        }
      },
      update: () => {
        clearEntriesFromWatchlistCompaniesCache(
          client,
          filterCompanyWatchlistEntriesByCompanyIds(companyIds),
          hiddenCompaniesWatchlist.id
        );
      }
    });
    if (!disableToast) {
      displayToast({
        primaryText: isUndo
          ? 'Action undone.'
          : companyIds?.length > 1
          ? `${companyIds?.length} companies removed from Hidden companies.`
          : `Company removed from Hidden companies.`,
        cta: !isUndo
          ? {
              text: 'Undo',
              onClick: () =>
                hideCompanies(companyIds, savedSearchId, false, true)
            }
          : undefined
      });
    }

    if (!areCompaniesViewed) {
      setViewed(companyIds);
    }

    _deselectRows();
  };

  const unlikeCompanies = useCallback(
    async (companyIds: number[], fromHotkey = false, isUndo = false) => {
      if (!likedCompaniesWatchlist) return;
      const areCompaniesViewed = areViewed(companyIds);

      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_ACTION_CLICK,
        properties: {
          action: CompanyActionEventType.UNLIKE,
          companyIds: companyIds.join(','),
          openedFromLocation: location,
          isBulkAction: companyIds?.length > 1,
          fromHotkey
        }
      });

      if (!disableToast) {
        displayToast({
          primaryText: isUndo
            ? 'Action Undone.'
            : companyIds.length > 1
            ? `Unliked ${companyIds.length} companies.`
            : `Company unliked.`,
          cta: !isUndo
            ? {
                text: 'Undo',
                onClick: () => {
                  likeCompanies(companyIds, false, true);
                }
              }
            : undefined
        });
      }

      unlikeCompaniesMutation({
        variables: {
          companies: companyIds.map((id) => 'urn:harmonic:company:' + id)
        },
        optimisticResponse: {
          unlikeCompanies: companyIds.map((id) => ({
            __typename: 'Company',
            id: id,
            isLikedByCurrentUser: false
          }))
        },
        onError: (error) => {
          if (error) {
            logger.error('Unlike company error', { error });
            if (!disableToast) {
              displayToast({
                mode: 'error',
                primaryText:
                  'A problem occured while unliking the company. Please try again later'
              });
            }
          }
        },
        update: () => {
          clearEntriesFromWatchlistCompaniesCache(
            client,
            filterCompanyWatchlistEntriesByCompanyIds(companyIds),
            likedCompaniesWatchlist.id
          );
        }
      });

      if (!areCompaniesViewed) {
        setViewed(companyIds);
      }

      // Deselect rows when on the liked page
      if (currentPageEntityId == likedCompaniesWatchlist?.id) {
        _deselectRows();
      }
    },
    [likedCompaniesWatchlist]
  );

  const setUnviewed = useCallback(
    (companyIds: number[], fromHotkey = false) => {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_ACTION_CLICK,
        properties: {
          action: CompanyActionEventType.UNVIEW,
          companyIds: companyIds.join(','),
          openedFromLocation: location,
          isBulkAction: companyIds?.length > 1,
          fromHotkey
        }
      });
      deleteUserViewed({
        variables: {
          urns: companyIds.map((id) => `urn:harmonic:company:${id}`)
        },
        optimisticResponse: {
          deleteUserViewRecordsForCompanyUrns: true
        },
        update: (existingCache) => {
          companyIds.forEach((companyId) => {
            // Direct cache modify, trade off is if you have a custom merge function, modify skips it, so broadcast wont happen correctly
            existingCache.modify({
              id: existingCache.identify({
                __typename: 'Company',
                id: companyId
              }), // The id of the company to be removed.
              fields: {
                // all cache entries that are within ROOT_QUERY named searchCompanies <- GQL query powering company search/saved search
                viewRecord: () => {
                  return null;
                }
              },
              optimistic: true,
              broadcast: true
            });
          });
        }
      });
      _clearCompaniesFromSearchCompaniesCache({
        companyIds,
        searchStateFilterTypeToRemoveEntityFrom: SearchStateFilter.VIEWED
      });
      companyIds.forEach(() => {
        if (isSavedSearch && currentPageEntityId) {
          incrementCount(parseInt(currentPageEntityId ?? 0));
        }
      });
    },
    []
  );

  const setViewed = useCallback(
    (companyIds: number[], fromHotkey = false) => {
      analytics.trackCustomEvent({
        event: CustomTrackEvent.COMPANY_ACTION_CLICK,
        properties: {
          action: CompanyActionEventType.VIEW,
          companyIds: companyIds.join(','),
          openedFromLocation: location,
          isBulkAction: companyIds?.length > 1,
          fromHotkey
        }
      });
      const createOptimisticResponse = (companyIds: number[]) => {
        const viewRecords = companyIds.map((id) => ({
          __typename: 'CompanyViewRecordTuple',
          entityUrn: `urn:harmonic:company:${id}`,
          viewRecord: {
            __typename: 'CompanyViewRecord',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          }
        }));

        return {
          upsertUserViewRecordsForCompanyUrns: {
            viewRecords: viewRecords,
            __typename: 'CompaniesViewRecordsResponse'
          }
        };
      };
      try {
        upsertUserViewed({
          variables: {
            urns: companyIds.map((id) => `urn:harmonic:company:${id}`)
          },
          optimisticResponse: createOptimisticResponse(companyIds),
          update: (existingCache, incomingResult) => {
            companyIds.forEach((companyId) => {
              // Direct cache modify
              existingCache.modify({
                id: existingCache.identify({
                  __typename: 'Company',
                  id: companyId
                }), // The id of the company to be removed.
                fields: {
                  // all cache entries that are within ROOT_QUERY named searchCompanies <- GQL query powering company search/saved search
                  viewRecord: () => {
                    const newViewRecord =
                      incomingResult.data.upsertUserViewRecordsForCompanyUrns.viewRecords.find(
                        (item: CompanyViewRecordTuple) =>
                          item.entityUrn === `urn:harmonic:company:${companyId}`
                      );
                    return newViewRecord.viewRecord;
                  }
                },
                optimistic: true,
                broadcast: false
              });
            });
          }
        });
        clearCompanyNetNew();
        // if we have a cached search that has a state of unviewed we should remove this company from it if it exists.
        _clearCompaniesFromSearchCompaniesCache({
          companyIds,
          searchStateFilterTypeToRemoveEntityFrom: SearchStateFilter.UNVIEWED
        });
        companyIds.forEach(() => {
          if (isSavedSearch && currentPageEntityId) {
            decrementCount(parseInt(currentPageEntityId ?? 0));
          }
        });
      } catch (err) {
        logger.error('An error occured when setting company as viewed', {
          err,
          code_area: 'grid'
        });
      }
    },
    [selectedNetNewIds]
  );

  const _deselectRows = () => {
    editTableStoreData('selectedRowIds', []);
  };

  const clearCompanyNetNew = () => {
    if (urn && selectedNetNewIds.length > 0) {
      onClearNetNewByIds({
        ids: selectedNetNewIds,
        savedSearchUrn: urn,
        type: SearchType.COMPANIES_LIST
      });
    }
  };

  // This function removes companies to full SavedSearchCompanies search element
  const _clearCompaniesFromSearchCompaniesCache = ({
    companyIds,
    savedSearchId,
    searchStateFilterTypeToRemoveEntityFrom
  }: {
    companyIds: number[];
    savedSearchId?: string | null;
    searchStateFilterTypeToRemoveEntityFrom?: SearchStateFilter;
  }) => {
    // Remove from saved search
    if (savedSearchId) {
      client.cache.modify({
        id: client.cache.identify({
          __typename: 'SavedSearch',
          id: savedSearchId
        }),
        fields: {
          results: (existingCompaniesRef, { readField }) => {
            const edges =
              readField<SearchResultNode[]>('edges', existingCompaniesRef) ||
              [];
            const totalCount =
              readField<number>('totalCount', existingCompaniesRef) || 0;
            const newEdges = edges.filter((edge) => {
              const companyId = readField<number>(
                'id',
                readField('entity', readField('node', edge))
              );
              if (companyId === undefined) return false;
              return !companyIds.includes(companyId);
            });

            const netEdgesRemoved = edges.length - newEdges.length;
            const updatedTotalCount = totalCount - netEdgesRemoved;

            const updatedSearchCompanies = {
              ...existingCompaniesRef,
              edges: newEdges,
              totalCount: updatedTotalCount
            };

            return updatedSearchCompanies;
          }
        },
        broadcast: true
      });
    }

    // Direct cache modify
    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Query',
        id: 'ROOT_QUERY'
      }),
      fields: {
        // all cache entries that are within ROOT_QUERY named searchCompanies <- GQL query powering company search/saved search
        searchCompanies(existingCompaniesRef, { readField, storeFieldName }) {
          const edges =
            readField<SearchResultNode[]>('edges', existingCompaniesRef) || [];
          const totalCount =
            readField<number>('totalCount', existingCompaniesRef) || 0;
          const newEdges = edges.filter((edge) => {
            const companyId = readField<number>('id', readField('node', edge));
            if (companyId === undefined) return false;
            return !companyIds.includes(companyId);
          });

          const netEdgesRemoved = edges.length - newEdges.length;
          const updatedTotalCount = totalCount - netEdgesRemoved;

          const updatedSearchCompanies = {
            ...existingCompaniesRef,
            edges: newEdges,
            totalCount: updatedTotalCount
          };

          // if the param is present only remove this company from the searches that have
          // this SearchStateFilter in their cache key
          // When marking a company as viewed on an unviewed search result, we should remove it from the search cache for this unviewed search result.
          // EX: A search this will NOT be removed from:
          // "searchCompanies:{"controlledFilterGroup":{"joinOperator":"and","filters":[],"filterGroups":[],"filterGroupGenerators":[]},"filterGroup":{"filters":[],"joinOperator":"and","filterGroups":[]},"sort":[]}"
          // EX: A search this WILL be removed from:
          // "searchCompanies:{"controlledFilterGroup":{"joinOperator":"and","filters":[],"filterGroups":[],"filterGroupGenerators":[]},"filterGroup":{"filters":[],"joinOperator":"and","filterGroups":[]},"sort":[],"state":"UNVIEWED"}"
          if (searchStateFilterTypeToRemoveEntityFrom) {
            if (
              storeFieldName.includes(searchStateFilterTypeToRemoveEntityFrom)
            ) {
              return updatedSearchCompanies;
            } else {
              return existingCompaniesRef;
            }
          }

          return updatedSearchCompanies;
        }
      },
      broadcast: true
    });
  };

  // Return true if and only if all companies in list are viewed
  const areViewed = (companyIds: number[]): boolean => {
    // Read from apollo cache. Assumption is we have fetched this already
    const companyResult = client.readQuery<
      GetCompaniesViewRecordsQuery,
      GetCompaniesViewRecordsQueryVariables
    >({
      query: getCompaniesViewRecords,
      variables: { ids: companyIds, extended: false }
    });
    if (!companyResult?.getCompaniesByIds) {
      return false;
    }
    if (
      !every(
        companyResult?.getCompaniesByIds?.map(
          (company) =>
            company?.viewRecord !== null && company?.viewRecord !== undefined
        )
      )
    ) {
      return false;
    }

    return true;
  };

  return {
    likeCompanies,
    unlikeCompanies,
    hideCompanies,
    unhideCompanies,
    setViewed,
    setUnviewed,
    areViewed
  };
};

export const clearEntriesFromWatchlistCompaniesCache = (
  client: ApolloClient<object>,
  filterCompaniesFromEdges: (
    edges: Readonly<CompanyWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ) => CompanyWatchlistEntryEdge[],
  watchlistId: string
) => {
  client.cache.modify({
    id: `CompanyWatchlist:${watchlistId}`,
    fields: {
      companyEntries: (cachedCompanyEntries, { readField }) => {
        const edges =
          readField<CompanyWatchlistEntryEdge[]>(
            'edges',
            cachedCompanyEntries
          ) || [];
        const totalCount =
          readField<number>('totalCount', cachedCompanyEntries) || 0;
        const newEdges = filterCompaniesFromEdges(edges, readField);
        const netEdgesRemoved = edges.length - newEdges.length;
        const updatedTotalCount = totalCount - netEdgesRemoved;
        return {
          ...cachedCompanyEntries,
          totalCount: updatedTotalCount,
          edges: newEdges
        };
      }
    },
    broadcast: true,
    optimistic: true
  });
};

const filterCompanyWatchlistEntriesByCompanyIds =
  (companyIds: number[]) =>
  (
    edges: Readonly<CompanyWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ): CompanyWatchlistEntryEdge[] => {
    return edges.filter((edge) => {
      const companyId = readField<number>(
        'id',
        readField('company', readField('node', edge))
      );
      if (companyId === undefined) return false;
      return !companyIds.includes(companyId);
    });
  };

export const filterCompanyWatchlistEntriesByEntryUrn =
  (entryUrns: string[]) =>
  (
    edges: Readonly<CompanyWatchlistEntryEdge[]>,
    readField: ReadFieldFunction
  ): CompanyWatchlistEntryEdge[] => {
    const newEdges = edges.filter((edge) => {
      const entryUrn = readField<string>('entryUrn', readField('node', edge));
      if (entryUrn === undefined) return false;
      return !entryUrns.includes(entryUrn);
    });

    return newEdges;
  };
