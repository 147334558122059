const BorderRadius = require('./primitives');
module.exports = {
  br00: BorderRadius.br00,
  br10: BorderRadius.br10,
  br20: BorderRadius.br20,
  br30: BorderRadius.br30,
  br40: BorderRadius.br40,
  br50: BorderRadius.br50,
  br60: BorderRadius.br60,
  br70: BorderRadius.br70,
  br80: BorderRadius.br80,
  br90: BorderRadius.br100
};