import classNames from 'classnames';
import { HarmonicIcon } from './type';

const NumberIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M5.2 1.8a.6.6 0 0 1 .6.6v2.2h4.4V2.4a.6.6 0 1 1 1.2 0v2.2h2.2a.6.6 0 1 1 0 1.2h-2.2v4.4h2.2a.6.6 0 1 1 0 1.2h-2.2v2.2a.6.6 0 1 1-1.2 0v-2.2H5.8v2.2a.6.6 0 1 1-1.2 0v-2.2H2.4a.6.6 0 1 1 0-1.2h2.2V5.8H2.4a.6.6 0 0 1 0-1.2h2.2V2.4a.6.6 0 0 1 .6-.6m.6 4v4.4h4.4V5.8z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default NumberIcon;
