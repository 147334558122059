import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CalendarAddIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7C8598"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M5.66667 1.83334C5.66667 1.5572 5.44281 1.33334 5.16667 1.33334C4.89052 1.33334 4.66667 1.5572 4.66667 1.83334V2.66668H3.16667C2.52233 2.66668 2 3.18901 2 3.83334V12.8333C2 13.4777 2.52233 14 3.16667 14H7.5C7.77614 14 8 13.7762 8 13.5C8 13.2239 7.77614 13 7.5 13H3.16667C3.07462 13 3 12.9254 3 12.8333V6.66668H13V7.50001C13 7.77615 13.2239 8.00001 13.5 8.00001C13.7761 8.00001 14 7.77615 14 7.50001V3.83334C14 3.18901 13.4777 2.66668 12.8333 2.66668H11.3333V1.83334C11.3333 1.5572 11.1095 1.33334 10.8333 1.33334C10.5572 1.33334 10.3333 1.5572 10.3333 1.83334V2.66668H5.66667V1.83334Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M12.3333 9.83334C12.3333 9.5572 12.1095 9.33334 11.8333 9.33334C11.5572 9.33334 11.3333 9.5572 11.3333 9.83334V11.5H9.66667C9.39052 11.5 9.16667 11.7239 9.16667 12C9.16667 12.2762 9.39052 12.5 9.66667 12.5H11.3333V14.1667C11.3333 14.4428 11.5572 14.6667 11.8333 14.6667C12.1095 14.6667 12.3333 14.4428 12.3333 14.1667V12.5H14C14.2761 12.5 14.5 12.2762 14.5 12C14.5 11.7239 14.2761 11.5 14 11.5H12.3333V9.83334Z"
      />
    </svg>
  );
};

export default CalendarAddIcon;
