import classNames from 'classnames';
import { HarmonicIcon } from './type';

const UrlIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M3.133 5.8H6a.6.6 0 0 0 0-1.2H3.067c-.626 0-1.134.507-1.134 1.133v4.534c0 .626.508 1.133 1.134 1.133H6a.6.6 0 1 0 0-1.2H3.133zM9.4 5.2a.6.6 0 0 1 .6-.6h2.933c.626 0 1.134.507 1.134 1.133v4.534c0 .626-.508 1.133-1.134 1.133H10a.6.6 0 1 1 0-1.2h2.867V5.8H10a.6.6 0 0 1-.6-.6M4.6 8a.6.6 0 0 1 .6-.6h5.6a.6.6 0 1 1 0 1.2H5.2a.6.6 0 0 1-.6-.6"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default UrlIcon;
