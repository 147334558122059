import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const SidebarCompanyIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="#7C8598"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50564 2.68614C3.34323 2.95437 2.76202 3.08849 2.32852 3.40456C1.94611 3.68337 1.64619 4.06038 1.4605 4.49568C1.25 4.98915 1.25 5.58563 1.25 6.77859V16.3501C1.25 17.1901 1.25 17.6102 1.41349 17.931C1.5573 18.2133 1.78677 18.4428 2.06901 18.5866C2.38988 18.7501 2.80992 18.7501 3.65 18.7501H11.0375C11.8776 18.7501 12.2976 18.7501 12.6185 18.5866C12.9007 18.4428 13.1302 18.2133 13.274 17.931C13.4375 17.6102 13.4375 17.1901 13.4375 16.3501V5.65319C13.4375 3.93555 13.4375 3.07672 13.0827 2.48386C12.7717 1.96409 12.2825 1.57493 11.7061 1.38871C11.0486 1.1763 10.2118 1.36941 8.53811 1.75562L4.50564 2.68614ZM4.0625 7.67495C4.0625 7.39493 4.0625 7.25491 4.117 7.14796C4.16493 7.05388 4.24142 6.97739 4.3355 6.92945C4.44246 6.87495 4.58247 6.87495 4.8625 6.87495H9.825C10.105 6.87495 10.245 6.87495 10.352 6.92945C10.4461 6.97739 10.5226 7.05388 10.5705 7.14796C10.625 7.25491 10.625 7.39493 10.625 7.67495V7.69995C10.625 7.97998 10.625 8.11999 10.5705 8.22695C10.5226 8.32103 10.4461 8.39752 10.352 8.44546C10.245 8.49995 10.105 8.49995 9.825 8.49995H4.8625C4.58247 8.49995 4.44246 8.49995 4.3355 8.44546C4.24142 8.39752 4.16493 8.32103 4.117 8.22695C4.0625 8.11999 4.0625 7.97998 4.0625 7.69995V7.67495ZM4.117 10.898C4.0625 11.0049 4.0625 11.1449 4.0625 11.425V11.45C4.0625 11.73 4.0625 11.87 4.117 11.9769C4.16493 12.071 4.24142 12.1475 4.3355 12.1955C4.44246 12.25 4.58247 12.25 4.8625 12.25H9.825C10.105 12.25 10.245 12.25 10.352 12.1955C10.4461 12.1475 10.5226 12.071 10.5705 11.9769C10.625 11.87 10.625 11.73 10.625 11.45V11.425C10.625 11.1449 10.625 11.0049 10.5705 10.898C10.5226 10.8039 10.4461 10.7274 10.352 10.6794C10.245 10.625 10.105 10.625 9.825 10.625H4.8625C4.58247 10.625 4.44246 10.625 4.3355 10.6794C4.24142 10.7274 4.16493 10.8039 4.117 10.898Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M15.3125 9.76083C15.3125 9.0983 15.3125 8.76704 15.4283 8.54781C15.5878 8.24599 15.8902 8.04637 16.2304 8.01836C16.4776 7.99801 16.7822 8.12822 17.3914 8.38863V8.38863C17.8231 8.5732 18.039 8.66548 18.2062 8.80375C18.4361 8.99395 18.604 9.2484 18.6886 9.53456C18.75 9.74258 18.75 9.97737 18.75 10.4469V17.15C18.75 17.7101 18.75 17.9901 18.641 18.204C18.5451 18.3922 18.3922 18.5451 18.204 18.641C17.9901 18.75 17.7101 18.75 17.15 18.75H16.9125C16.3524 18.75 16.0724 18.75 15.8585 18.641C15.6703 18.5451 15.5174 18.3922 15.4215 18.204C15.3125 17.9901 15.3125 17.7101 15.3125 17.15V9.76083Z"
      />
    </svg>
  );
};

export default SidebarCompanyIcon;
