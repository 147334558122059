import classNames from 'classnames';
import { IconProps } from './type';

const FilterTimelineIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#677183"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.00107 3.16663V12.8333M4.00107 12.8333L2 10.8333M4.00107 12.8333L6 10.8333M7.83333 4.49996H13.5M10.5 11.5H13.5M9.16667 7.99996H13.5"
      />
    </svg>
  );
};

export default FilterTimelineIcon;
