import classNames from 'classnames';
import { HarmonicIcon } from './type';

const SelectIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M13.12 8A5.12 5.12 0 1 1 2.88 8a5.12 5.12 0 0 1 10.24 0m1.28 0A6.4 6.4 0 1 1 1.6 8a6.4 6.4 0 0 1 12.8 0m-5.957 2.432a.512.512 0 0 1-.886 0L5.364 6.635a.512.512 0 0 1 .444-.768h4.384c.394 0 .64.426.444.768z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default SelectIcon;
