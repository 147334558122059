const baseUnit = 2;
module.exports = {
  sp00: `${baseUnit * 0}px`,
  sp10: `${baseUnit}px`,
  sp20: `${baseUnit * 2}px`,
  sp30: `${baseUnit * 3}px`,
  sp40: `${baseUnit * 4}px`,
  sp50: `${baseUnit * 5}px`,
  sp60: `${baseUnit * 6}px`,
  sp70: `${baseUnit * 8}px`,
  sp80: `${baseUnit * 10}px`,
  sp90: `${baseUnit * 12}px`,
  sp100: `${baseUnit * 16}px`,
  sp110: `${baseUnit * 20}px`,
  sp120: `${baseUnit * 24}px`,
  sp130: `${baseUnit * 32}px`
};