import { DocumentNode, useLazyQuery, useQuery } from '@apollo/client';
import {
  GetWatchlistWithCompaniesQuery,
  GetWatchlistWithCompaniesQueryVariables,
  GetWatchlistWithPeopleQuery,
  GetWatchlistWithPeopleQueryVariables
} from '../__generated__/graphql';
import { useShallowTableStore } from '../stores/tableStore';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';

interface WatchlistEntry {
  edges: Array<{
    node: {
      [key: string]: {
        id: number;
      };
    };
  }> | null;
  pageInfo?: {
    hasNextPage: boolean;
    endCursor: string | null;
  };
}

export const useRefetchWatchlistResults = <
  TData extends GetWatchlistWithCompaniesQuery | GetWatchlistWithPeopleQuery,
  TVariables extends
    | GetWatchlistWithCompaniesQueryVariables
    | GetWatchlistWithPeopleQueryVariables
>({
  query,
  variables,
  getDataPath,
  getUrnPrefix
}: {
  query: DocumentNode;
  variables: TVariables;
  getDataPath: (data: TData | undefined) => WatchlistEntry | undefined;
  getUrnPrefix: string;
}) => {
  const { addToLoadedExtendedUrns } = useShallowTableStore([
    'addToLoadedExtendedUrns'
  ]);

  const { data: cachedData } = useQuery<TData, TVariables>(query, {
    variables,
    fetchPolicy: 'cache-only'
  });

  const numCachedEdges = getDataPath(cachedData)?.edges?.length || 0;

  const [fetch] = useLazyQuery<TData, TVariables>(query, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only'
  });

  const handleCompleted = (data: TData) => {
    const entries = getDataPath(data);
    const urns =
      entries?.edges?.map(
        (edge) => `${getUrnPrefix}${edge.node[Object.keys(edge.node)[0]].id}`
      ) ?? [];

    addToLoadedExtendedUrns(urns);

    return entries;
  };

  const refetchResults = async () => {
    let hasNextPage = true;
    let cursor: string | null = null;
    let fetchedCount = 0;

    // Fetch only first page if no results are cached
    if (numCachedEdges === 0) {
      await fetch({
        variables: {
          ...variables,
          first: GRID_INITIAL_PAGE_SIZE,
          after: cursor,
          skipExtended: false
        } as TVariables,
        onCompleted: handleCompleted
      });
    } else {
      let shouldRefetch = fetchedCount < numCachedEdges;
      while (hasNextPage && shouldRefetch) {
        await fetch({
          variables: {
            ...variables,
            first: GRID_INITIAL_PAGE_SIZE,
            after: cursor,
            skipExtended: false
          } as TVariables,
          onError: () => {
            shouldRefetch = false;
          },
          onCompleted: (data) => {
            const entries = handleCompleted(data);
            const pageInfo = entries?.pageInfo;
            hasNextPage = pageInfo?.hasNextPage ?? false;
            cursor = pageInfo?.endCursor ?? null;
            fetchedCount += entries?.edges?.length ?? 0;
            shouldRefetch = fetchedCount < numCachedEdges;
          }
        });
      }
    }
  };

  return {
    refetchResults
  };
};
