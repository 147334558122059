import classNames from 'classnames';
import { HarmonicIcon } from './type';

const ArrowTopRightCircleIcon: HarmonicIcon = ({
  applyCurrentColor,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M12.714 12.714a6.667 6.667 0 1 1-9.428-9.428 6.667 6.667 0 0 1 9.428 9.428M5.48 10.52a.5.5 0 0 1 0-.707l3.48-3.48H6.5a.5.5 0 1 1 0-1h3.667a.5.5 0 0 1 .5.5V9.5a.5.5 0 0 1-1 0V7.04l-3.48 3.48a.5.5 0 0 1-.707 0"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default ArrowTopRightCircleIcon;
