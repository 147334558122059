import { InfoCircleIcon } from 'assets/harmonic-icons';
import { Tooltip } from 'common/components';
import { NETWORK_MAPPING_SUPPORT_DOC } from 'utils/constants';

const UserConnectionsTooltip = () => {
  const TooltipMessage = (
    <div>
      See who on your team has the best path in to this company. Share{' '}
      <a
        className="underline"
        href={NETWORK_MAPPING_SUPPORT_DOC}
        target="_blank"
        rel="noopener noreferrer"
      >
        this guide
      </a>{' '}
      to help your teammates sync their email, calendar, and LinkedIn to
      Harmonic.
    </div>
  );
  return (
    <div className="hidden lg:block">
      <Tooltip interactive body={TooltipMessage} alignment="right">
        <InfoCircleIcon className="w-4 h-4 text-content-weak cursor-pointer" />
      </Tooltip>
    </div>
  );
};

export default UserConnectionsTooltip;
