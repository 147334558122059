import dayjs from 'dayjs';
import { Parser } from 'json2csv';
import { cloneDeep, difference, flattenDeep, get, omit } from 'lodash';

import { GridExportFileType, IRow } from 'interfaces/Grid';

import { ApolloClient } from '@apollo/client';
import {
  EducationMetadata,
  PeopleSearchExperienceFragment,
  PersonHighlight,
  Tag,
  UserConnectionFragment
} from '__generated__/graphql';
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community';
import { ApiResourceType } from 'interfaces/DataModel/ApiResource';
import { ICompany, IInvestor } from 'interfaces/DataModel/Company';
import { EventTypeToDescriptionMapping } from 'interfaces/DataModel/Event';
import { IHighlight } from 'interfaces/DataModel/Highlight';
import { IPersonRelationship } from 'interfaces/DataModel/Relationship';
import {
  TagTypeToLabelMapping,
  TypedTagV2
} from 'interfaces/DataModel/TypedTag';
import { ColumnHeaderOrder } from 'interfaces/Grid';
import {
  ISearchFieldSpec,
  SearchValueToDisplayNameMap
} from 'interfaces/SearchModel/Search';
import * as API from 'utils/api';
import { dayjsExt } from '../config/dayjs';
import { getCompanyExecEmails } from './company';
import { COMPANY_FIELD_HEADER_SPEC } from './constants';
import { setDataStoreUserValue } from './midtierApi';
import { displayToast } from './toasts';

export interface IExportHeaderField {
  label: string;
  value: string;
}

const getPreviousExperiences = (row: IRow) =>
  row?.experience
    ?.filter((experienceObj: PeopleSearchExperienceFragment) => experienceObj)
    ?.filter(
      (experienceObj: PeopleSearchExperienceFragment) =>
        !experienceObj?.isCurrentPosition
    )
    ?.sort(
      (
        left: PeopleSearchExperienceFragment,
        right: PeopleSearchExperienceFragment
      ) => {
        dayjsExt(left?.startDate).diff(dayjsExt(right?.startDate));
      }
    ) ?? [];

export const transformRowForExport = (
  fields: {
    label: string;
    value: string;
  }[],
  row: IRow
): IRow => {
  const STRING_LIST_DELIMITER = '; ';
  const reduceListToString = (list: string[]) => {
    return list?.join(STRING_LIST_DELIMITER);
  };
  const reduceTimestampToDateString = (timestamp: string) => {
    if (!timestamp) {
      return '';
    }
    return dayjs.utc(timestamp).format('MM/DD/YYYY');
  };

  // Existing row values, filtered by only the fields we want to include
  const existingRowValues: IRow = {};
  fields.forEach((field) => {
    const fieldKey = field.value;
    const fieldValue = get(row, fieldKey);
    existingRowValues[fieldKey] = fieldValue;
  });

  const fundingAttributeNullStatus = get(
    SearchValueToDisplayNameMap,
    row?.company?.funding_attribute_null_status
  );

  // Row values that we want to transform/overwrite
  const overwriteRowValues: IRow = {
    'company.logo_url': null,

    observed_at: reduceTimestampToDateString(row?.observed_at),

    event_type: get(EventTypeToDescriptionMapping, row?.event_type),

    'company.highlights': [
      ...(row?.company?.highlights?.map(
        (highlight: IHighlight) => highlight.text
      ) || []),
      ...(row?.company?.person_relationships?.flatMap(
        (relationship: IPersonRelationship) =>
          relationship.person?.highlights?.map((highlight) => highlight.text)
      ) || []),
      ...(row?.company?.employee_highlights?.flatMap(
        (highlight: IHighlight) => highlight.text
      ) || [])
    ]
      ?.filter((highlight) => highlight && highlight != '')
      .join(STRING_LIST_DELIMITER),

    'company.person_relationships': row?.company?.person_relationships
      ?.filter(
        (relationship: IPersonRelationship) =>
          (relationship?.role_type == 'FOUNDER' ||
            relationship?.role_type == 'EXECUTIVE') &&
          relationship.person?.full_name
      )
      ?.map(
        (relationship: IPersonRelationship) =>
          `${relationship.person.full_name} (${relationship.person.linkedin_url})`
      )
      ?.join(STRING_LIST_DELIMITER),

    'company.person_linkedin_profiles': row?.company?.person_relationships
      ?.filter(
        (relationship: IPersonRelationship) =>
          (relationship?.role_type == 'FOUNDER' ||
            relationship?.role_type == 'EXECUTIVE') &&
          relationship.person?.full_name
      )
      ?.map(
        (relationship: IPersonRelationship) => relationship.person.linkedin_url
      )
      ?.join(STRING_LIST_DELIMITER),

    'company.founding_date': reduceTimestampToDateString(
      row?.company?.founding_date
    ),

    'company.typed_tags': [
      ...(row?.company?.typed_tags
        ?.filter(
          (tag: TypedTagV2) =>
            tag.type === 'MARKET_VERTICAL' || tag.type === 'TECHNOLOGY_TYPE'
        )
        .map(
          (tag: TypedTagV2) =>
            `${tag?.display_value} (Tag Type: ${get(
              TagTypeToLabelMapping,
              tag.type
            )}, Date Added: ${
              tag?.date_added && dayjs(tag?.date_added).format('MM/DD/YYYY')
            })`
        ) || [])
    ]
      ?.filter((tag) => tag && tag != '')
      .join(STRING_LIST_DELIMITER),

    'company.num_funding_rounds':
      row?.company?.num_funding_rounds || fundingAttributeNullStatus,

    'company.funding_stage':
      row?.company?.funding_stage || fundingAttributeNullStatus,

    'company.last_funding_type':
      row?.company?.last_funding_type || fundingAttributeNullStatus,

    'company.last_funding_at': row?.company?.last_funding_at
      ? reduceTimestampToDateString(row?.company?.last_funding_at)
      : fundingAttributeNullStatus,

    'company.last_funding_total':
      row?.company?.last_funding_total || fundingAttributeNullStatus,

    'company.funding_total':
      row?.company?.funding_total || fundingAttributeNullStatus,

    'company.industry_tags': reduceListToString(row?.company?.industry_tags),

    'company.technology_tags': reduceListToString(
      row?.company?.technology_tags
    ),

    'company.user_custom_fields': row?.company?.user_custom_fields?.custom_text,

    'company.team_custom_fields': row?.company?.team_custom_fields?.custom_text,
    'company.traction_metrics.web_traffic.latest_metric_value':
      row?.company?.web_traffic,
    'company.investors':
      row?.company?.investors?.length > 0
        ? reduceListToString(
            row.company.investors
              ?.map((investor: IInvestor) => investor.name)
              .filter((item: string) => item)
          )
        : fundingAttributeNullStatus,

    'company.company_emails': row?.company?.company_emails?.join(
      STRING_LIST_DELIMITER
    ),

    'company.team_emails': getCompanyExecEmails(row?.company as ICompany)?.join(
      STRING_LIST_DELIMITER
    ),

    'company.team_linkedin_profiles': row?.company?.person_relationships
      ?.map((relationship: IPersonRelationship) =>
        get(relationship, 'person.linkedin_url')
      )
      .join(STRING_LIST_DELIMITER),

    'experience.currentCompany': row?.experience
      ?.filter(
        (experience: PeopleSearchExperienceFragment) =>
          experience?.isCurrentPosition
      )
      ?.map(
        (experience: PeopleSearchExperienceFragment) =>
          experience?.company?.name
      )
      .join(STRING_LIST_DELIMITER),

    'experience.currentTitle': row?.experience
      ?.filter(
        (experience: PeopleSearchExperienceFragment) =>
          experience?.isCurrentPosition
      )
      ?.map((experience: PeopleSearchExperienceFragment) => experience?.title)
      .join(STRING_LIST_DELIMITER),

    'experience.currentDepartment': row?.experience
      ?.filter(
        (experience: PeopleSearchExperienceFragment) =>
          experience?.isCurrentPosition
      )
      ?.map(
        (experience: PeopleSearchExperienceFragment) => experience?.department
      )
      .join(STRING_LIST_DELIMITER),

    'experience.currentCompanyTags': reduceListToString(
      row?.experience
        ?.filter(
          (experience: PeopleSearchExperienceFragment) =>
            experience?.isCurrentPosition
        )
        ?.map(
          (experience: PeopleSearchExperienceFragment) =>
            experience?.company?.tags
        )[0]
        ?.map((tag: Tag) => tag?.displayValue)
    ),

    'experience.previousCompany': getPreviousExperiences(row)[0]?.company?.name,
    'socials.linkedin': row?.socials?.linkedin?.url,
    education: row?.education
      ?.map((education: EducationMetadata) => education?.school?.name)
      .join(STRING_LIST_DELIMITER),

    'experience.previousCompanyTags': reduceListToString(
      row?.experience
        ?.filter(
          (experience: PeopleSearchExperienceFragment) =>
            !experience?.isCurrentPosition
        )
        ?.reduce(
          (
            acc: PeopleSearchExperienceFragment['company']['tags'][],
            experience: PeopleSearchExperienceFragment
          ) => {
            if (!experience || !experience.company) {
              return acc;
            }
            if (
              !experience.company.tags ||
              experience.company.tags?.length === 0
            ) {
              return acc;
            }

            const companyTags = experience.company.tags
              .map((tag) => {
                if (tag) return tag.displayValue;
              })
              .filter((tag) => tag);

            return [
              ...acc,
              `${experience.company.name}: ${companyTags.join(`, `)}`
            ];
          },
          []
        )
    ),

    highlights: row?.highlights
      ?.map((highlight: Omit<PersonHighlight, 'text'>) => highlight?.category)
      ?.join(STRING_LIST_DELIMITER),

    'experience.previousCompanies': reduceListToString(
      (
        flattenDeep(
          getPreviousExperiences(row)?.map(
            (experience: PeopleSearchExperienceFragment) => experience?.company
          )
        ) as PeopleSearchExperienceFragment['company'][]
      )?.map(
        (company: PeopleSearchExperienceFragment['company']) =>
          company?.name ?? ''
      )
    ),
    userConnections: row?.userConnections
      ?.map(
        (connection: UserConnectionFragment) =>
          connection?.user?.name ?? connection?.user?.email
      )
      .join('; ')
  };

  const returnRowValues = existingRowValues;
  Object.entries(overwriteRowValues).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    if (key in returnRowValues) {
      returnRowValues[key] = value;
    }
  });
  return returnRowValues;
};

export const downloadFile = (
  fileContents: string,
  fileType: GridExportFileType,
  fileName?: string
) => {
  let mimeType = '',
    fileExtension = '';
  switch (fileType) {
    case GridExportFileType.JSON:
      (mimeType = 'application/json'), (fileExtension = 'json');
      break;
    case GridExportFileType.JSONL:
      (mimeType = 'application/jsonl'), (fileExtension = 'jsonl');
      break;
    case GridExportFileType.CSV:
    default:
      (mimeType = 'text/csv'), (fileExtension = 'csv');
      break;
  }
  const element = document.createElement('a');
  const file = new Blob([fileContents], { type: mimeType });
  element.href = URL.createObjectURL(file);

  const date = dayjs().format('MMDDYYYY');
  const finalFileName = fileName || `harmonic_export_${date}`;

  element.download = `${finalFileName}.${fileExtension}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const formatFieldsForCsvExport = (
  fields: IExportHeaderField[],
  resourceType: ApiResourceType
) => {
  if (resourceType === ApiResourceType.PeopleList) {
    return fields;
  }

  // Filter out the "Company Name" field and prepend it to the fields array
  const filteredFields = fields.filter(
    (field) => field.value !== 'urn:harmonic:company_field:company_name'
  );

  // Company name is always the first column and visible even if it was turned off or the position was changed
  // Right now, edit columns does not show company name field and assumes it is always checked and visible.
  // For full proof, we are always adding it manually here
  const updatedFields = [COMPANY_FIELD_HEADER_SPEC, ...filteredFields];
  return updatedFields;
};

export const exportGridToCsv = (
  fields: IExportHeaderField[],
  exportData: IRow[],
  fileName?: string
): void => {
  const csvParser = new Parser({ fields: fields });
  const csv = csvParser.parse(exportData);

  downloadFile(csv, GridExportFileType.CSV, fileName);
};

// Editing cells: currently only exposed to admins, except for custom text fields
export const onCellValueChanged = async (
  client: ApolloClient<any>,
  event: CellValueChangedEvent
): Promise<void> => {
  const fieldPath = event.colDef.field?.split('.') || [];
  const headerName = event.colDef.headerName;

  const oldValue = event.oldValue;
  const oldValueText =
    oldValue === null || oldValue === undefined ? 'Empty' : oldValue;
  const newValue = event.newValue;

  const entity = fieldPath[0];

  if (!newValue || newValue === oldValue) return;

  if (entity === 'company') {
    const companyId = event.node.data?.company?.id;
    const companyName = event.node.data?.company?.name;

    if (fieldPath[1] === 'view_record') return;

    const displayFailureMessage = () => {
      displayToast({
        primaryText: `Error updating ${headerName} for ${companyName}: ${oldValueText} -> ${newValue}`,
        mode: 'error'
      });
    };

    if (fieldPath[1].includes('custom_fields')) {
      const attributeName = fieldPath[1];
      const scope = attributeName === 'user_custom_fields' ? 'USER' : 'TEAM';

      const payload = {
        custom_text: newValue,
        scope
      };
      API.updateCompanyCustomText(client, companyId, payload).catch(() =>
        displayFailureMessage()
      );
    }
  } else if (entity === 'person') {
    displayToast({
      primaryText: `Deprecated! No longer possible to update person from console`,
      mode: 'error'
    });
  }
};

export const defaultGridOptions: GridOptions = {
  suppressColumnVirtualisation: false,
  rowBuffer: 25,
  suppressCellFocus: true,
  suppressPropertyNamesCheck: true,
  suppressRowHoverHighlight: true,
  rowSelection: {
    headerCheckbox: false,
    checkboxes: false,
    mode: 'multiRow',
    enableClickSelection: false
  },
  enableCellTextSelection: true,
  enableAdvancedFilter: false,
  rowHeight: 65,
  // Editing Options
  singleClickEdit: true,
  enterNavigatesVertically: true,
  enterNavigatesVerticallyAfterEdit: true,
  stopEditingWhenCellsLoseFocus: true
};

export const defaultColDef: ColDef = {
  cellStyle: {
    fontFamily: 'var(--font-description)',
    fontSize: '10pt',
    fontWeight: 300,
    lineHeight: 'unset',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    display: 'flex',
    alignItems: 'center'
  },
  headerClass:
    'column-headers text-content-default font-normal typography-label',
  filter: false,
  floatingFilter: false,
  sortable: false,
  resizable: true,
  headerComponent: 'CommonHeader',
  cellRenderer: 'LongTextCellRenderer',
  enableCellChangeFlash: false
};

// Compares the column name of default columns with custom columns.
// If any name are different, it updates them
export const updateCustomColumnsName = (
  savedColumns: ColumnHeaderOrder[],
  defaultColumns: ColumnHeaderOrder[]
): { diffCount: number; updatedColumns: ColumnHeaderOrder[] } => {
  const defaultColumnsIdToNameMap: Record<string, string> = {};
  defaultColumns.forEach((column) => {
    defaultColumnsIdToNameMap[column.id] = column.name;
  });
  let diffCount = 0;
  const savedColumnsClone = cloneDeep(savedColumns);
  savedColumns.forEach((column, index) => {
    // For custom columns, defaultName will be empty string since it does not exist on config.
    // They should not be reset
    const defaultName = defaultColumnsIdToNameMap[column.id];

    if (defaultName && column.name !== defaultName) {
      diffCount = diffCount + 1;
      savedColumnsClone[index].name = defaultName;
    }
  });

  return {
    diffCount,
    updatedColumns: savedColumnsClone
  };
};

/**
 *  Determine if number of total columns/display names in saved columns
 *  are different from default ordering. If so, update display names,
 *  append new columns, and save back to datastore.
 */
export const updateSavedColumnsWithDefaultColumns = (
  savedColumns: ColumnHeaderOrder[],
  defaultColumns: ColumnHeaderOrder[],
  customColumnsKey: string
) => {
  const defaultColumnIds = defaultColumns.map((column) => column.id);
  const savedColumnIds = savedColumns.map((column) => column.id);
  const newlyAddedColumnIds = difference(defaultColumnIds, savedColumnIds);
  const removedColumnIds = difference(savedColumnIds, defaultColumnIds);
  if (newlyAddedColumnIds.length === 0 && removedColumnIds.length === 0)
    return savedColumns;

  let newColumns: ColumnHeaderOrder[] = savedColumns;
  // Add new columns
  for (const newColumnId of newlyAddedColumnIds) {
    const newColumn = defaultColumns.find((column) => column.id == newColumnId);
    const newColumnOrder = newColumn?.order;
    if (isFinite(newColumnOrder as number)) {
      //Add new column to appropriate order if mentioned
      newColumns = [
        ...newColumns.slice(0, newColumnOrder),
        omit(newColumn, 'order') as ColumnHeaderOrder,
        ...newColumns.slice(newColumnOrder)
      ];
    } else {
      // Add new column to end of list
      newColumns = [...newColumns, newColumn as ColumnHeaderOrder];
    }
  }

  // Remove deleted columns
  newColumns = newColumns.filter(
    (column) => !removedColumnIds.includes(column.id)
  );

  setDataStoreUserValue(customColumnsKey, { columns: newColumns });
  return newColumns;
};

export const fieldSpecPromise = async (
  resourceType: ApiResourceType
): Promise<ISearchFieldSpec[]> =>
  resourceType === ApiResourceType.PeopleList
    ? await API.getPeopleListSearchFieldSpec()
    : API.getCompaniesListSearchFieldSpec();
