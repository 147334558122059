import { useQuery } from '@apollo/client';
import {
  GetCompanyEmployees_EmployeeEdges_PersonFragment,
  GetPersonContactInformationQuery,
  GetPersonContactInformationQueryVariables
} from '__generated__/graphql';
import {
  BagIcon,
  GraduationCapIcon,
  LinkedInIcon
} from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import { TeamMemberConnections } from 'components/Dashboard/CompanyV2/TeamMemberConnections';
import AddPeopleToWatchlist from 'components/common/AddPeopleToWatchlist';
import GetPersonEmailButton from 'components/common/ContactData/GetEmailButton';
import AvatarWithHighlightBadge from 'components/common/Highlights/AvatarWithHighlightBadge';
import InternalLink from 'components/common/InternalLink';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { compact } from 'lodash';
import uniq from 'lodash/uniq';
import getPersonContactInformation from 'queries/getPersonContactInformation';

export interface EmployeeProps {
  employee: GetCompanyEmployees_EmployeeEdges_PersonFragment;
  currentCompanyId: number;
}

const Employee: React.FC<EmployeeProps> = ({ employee, currentCompanyId }) => {
  const id = employee.id;

  const currentExperience = employee.experience?.find((exp) => {
    return exp?.isCurrentPosition && exp.company.id === currentCompanyId;
  });
  const currentTitle = currentExperience?.title;
  const pastExperience = employee.experience?.find((exp) => {
    return exp?.isCurrentPosition === false;
  });
  const pastTitle = pastExperience?.title;
  const pastCompany = pastExperience?.company.name;
  const pastCompanyId = pastExperience?.company.id;
  const schoolName = employee.education?.[0]?.school?.name;

  const highlights =
    uniq(
      compact(employee.highlights?.map((highlight) => highlight?.category))
    ) ?? [];

  // Query to get person contact information to display email from cache
  useQuery<
    GetPersonContactInformationQuery,
    GetPersonContactInformationQueryVariables
  >(getPersonContactInformation, {
    variables: {
      id
    }
  });

  return (
    <div
      key={`employee-${employee.id}`}
      data-testid="EmployeesList-Employee"
      className={classNames(
        'flex flex-col',
        'p-p10 rounded-br40 bg-surface-background'
      )}
    >
      <TeamMemberConnections employee={employee} />
      <div
        className={classNames(
          'flex flex-col gap-2',
          'p-p50',
          'rounded-br40 bg-surface-default border border-border border-solid',
          'h-full'
        )}
      >
        <div className="flex flex-row items-center gap-x-g60">
          <div className="flex-shrink-0">
            <AvatarWithHighlightBadge
              size="x-large"
              highlights={highlights}
              src={employee.profilePictureUrl ?? ''}
              personId={id}
              href={`/dashboard/person/${id}`}
            />
          </div>
          <div className="gap-y-1 w-full">
            <div
              className={classNames(
                'flex flex-row gap-g20',
                currentTitle && 'items-center'
              )}
            >
              <InternalLink to={{ pathname: `/dashboard/person/${id}` }}>
                <p className="typography-label-default-strong text-content-strong line-clamp-1">
                  {employee.fullName}
                </p>
              </InternalLink>
              {employee.socials?.linkedin?.url && (
                <IconButton
                  icon={(props: IconProps) => (
                    <LinkedInIcon {...props} applyCurrentColor={false} />
                  )}
                  type="secondary"
                  emphasis="low"
                  size="tiny"
                  dataTestId="LinkedinOutlink"
                  onClick={() =>
                    window.open(employee.socials?.linkedin?.url as string)
                  }
                />
              )}
            </div>
            <p className="line-clamp-1 typography-label-xs text-content-weak">
              {currentTitle}
            </p>
          </div>
          <div className="flex flex-shrink-0 flex-row justify-start gap-2">
            <AddPeopleToWatchlist personIds={[id]} />
            <GetPersonEmailButton personId={id} size="compact" />
          </div>
        </div>
        <div
          className={classNames(
            'w-full',
            'pt-p40',
            'flex flex-row flex-wrap items-start content-start gap-g50',
            'border-t border-solid border-border border-b-0 border-l-0 border-r-0',
            'typography-label-default-default text-content-weak'
          )}
        >
          <span
            data-testid="EmployeesList-Employee-PriorExperience"
            className={classNames(
              !schoolName && 'w-full',
              'flex gap-g30 items-center',
              'typography-label-default-default text-content-weak'
            )}
          >
            <BagIcon className="flex-shrink-0 w-4 h-4" />{' '}
            <p className="line-clamp-1">
              Prior:{' '}
              {pastTitle && pastCompany ? (
                <>
                  <>{pastTitle} at </>
                  <span className="text-content-strong">
                    {pastCompanyId && pastCompanyId > 0 ? (
                      <InternalLink
                        to={{ pathname: `/dashboard/company/${pastCompanyId}` }}
                      >
                        {pastCompany}
                      </InternalLink>
                    ) : (
                      pastCompany
                    )}
                  </span>
                </>
              ) : (
                'N/A'
              )}
            </p>
          </span>
          <span className={classNames('flex gap-g30 items-center')}>
            <GraduationCapIcon className="flex-shrink-0 w-4 h-4" />{' '}
            <p className="line-clamp-1 text-content-strong">
              {schoolName ? schoolName : 'N/A'}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Employee;
