import classNames from 'classnames';
import { HarmonicIcon } from './type';

const EducationIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <g fill="#7C8598">
        <path
          d="m3.333 8.795 3.983 2.04c.43.22.938.22 1.368 0l3.983-2.04v2.142c0 .428-.234.82-.61 1.025l-3.5 1.904a1.17 1.17 0 0 1-1.115 0l-3.5-1.904a1.17 1.17 0 0 1-.609-1.025z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M7.772 9.945a.5.5 0 0 0 .456 0l6.105-3.127v3.349a.5.5 0 0 0 1 0V6a.5.5 0 0 0-.272-.445l-6.833-3.5a.5.5 0 0 0-.456 0L.94 5.555a.5.5 0 0 0 0 .89z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
};

export default EducationIcon;
