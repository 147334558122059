import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CalendarIcon: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#7C8598"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M5.167 1.233a.6.6 0 0 1 .6.6v.734h4.466v-.734a.6.6 0 0 1 1.2 0v.734h1.4c.7 0 1.267.567 1.267 1.266v9c0 .7-.567 1.267-1.267 1.267H3.167c-.7 0-1.267-.567-1.267-1.267v-9c0-.7.567-1.266 1.267-1.266h1.4v-.734a.6.6 0 0 1 .6-.6M12.9 7.1H3.1v5.733c0 .037.03.067.067.067h9.666c.037 0 .067-.03.067-.067z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default CalendarIcon;
