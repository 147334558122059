import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const LayoutRowIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08398 4.16669C1.08398 3.20019 1.86749 2.41669 2.83398 2.41669H13.1673C14.1338 2.41669 14.9173 3.20019 14.9173 4.16669V8.00002V11.8334C14.9173 12.7998 14.1338 13.5834 13.1673 13.5834H2.83398C1.86748 13.5834 1.08398 12.7999 1.08398 11.8334V8.00002V4.16669ZM2.83398 3.91669C2.69591 3.91669 2.58398 4.02862 2.58398 4.16669V7.25002H13.4173V4.16669C13.4173 4.02862 13.3054 3.91669 13.1673 3.91669H2.83398ZM13.4173 8.75002H2.58398V11.8334C2.58398 11.9714 2.69591 12.0834 2.83398 12.0834H13.1673C13.3054 12.0834 13.4173 11.9714 13.4173 11.8334V8.75002Z"
        fill="#677183"
      />
    </svg>
  );
};

export default LayoutRowIcon;
