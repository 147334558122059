module.exports = {
  functional: {
    white: '#ffffff',
    black: '#000000',
    transparent: 'hsla(0, 0%, 100%, 0)',
    grey: {
      10: 'hsla(220, 16%, 98%, 1)',
      20: 'hsla(220, 16%, 96%, 1)',
      30: 'hsla(220, 10%, 92%, 1)',
      40: 'hsla(220, 16%, 84%, 1)',
      50: 'hsla(220, 16%, 76%, 1)',
      60: 'hsla(220, 12%, 64%, 1)',
      70: 'hsla(220, 12%, 54%, 1)',
      80: 'hsla(220, 12%, 46%, 1)',
      90: 'hsla(220, 12%, 32%, 1)',
      100: 'hsla(220, 16%, 24%, 1)',
      110: 'hsla(220, 16%, 12%, 1)',
      120: 'hsla(220, 16%, 8%, 1)'
    },
    blue: {
      10: 'hsla(220, 60%, 96%, 1)',
      20: 'hsla(220, 60%, 93%, 1)',
      30: 'hsla(220, 60%, 88%, 1)',
      40: 'hsla(220, 60%, 80%, 1)',
      50: 'hsla(220, 64%, 64%, 1)',
      60: 'hsla(220, 68%, 56%, 1)',
      70: 'hsla(220, 72%, 48%, 1)',
      80: 'hsla(220, 72%, 40%, 1)',
      90: 'hsla(220, 72%, 32%, 1)',
      100: 'hsla(220, 60%, 24%, 1)',
      110: 'hsla(220, 60%, 16%, 1)',
      120: 'hsla(220, 60%, 8%, 1)'
    },
    green: {
      10: 'hsla(132, 40%, 96%, 1)',
      20: 'hsla(132, 40%, 92%, 1)',
      30: 'hsla(132, 40%, 80%, 1)',
      40: 'hsla(132, 44%, 72%, 1)',
      50: 'hsla(132, 48%, 64%, 1)',
      60: 'hsla(132, 52%, 56%, 1)',
      70: 'hsla(132, 60%, 48%, 1)',
      80: 'hsla(132, 64%, 40%, 1)',
      90: 'hsla(132, 68%, 32%, 1)',
      100: 'hsla(132, 60%, 24%, 1)',
      110: 'hsla(132, 60%, 16%, 1)',
      120: 'hsla(132, 60%, 8%, 1)'
    },
    yellow: {
      10: 'hsla(44, 80%, 96%, 1)',
      20: 'hsla(44, 80%, 88%, 1)',
      30: 'hsla(44, 80%, 80%, 1)',
      40: 'hsla(44, 80%, 72%, 1)',
      50: 'hsla(44, 80%, 64%, 1)',
      60: 'hsla(44, 84%, 56%, 1)',
      70: 'hsla(44, 100%, 48%, 1)',
      80: 'hsla(44, 100%, 40%, 1)',
      90: 'hsla(44, 100%, 32%, 1)',
      100: 'hsla(44, 100%, 24%, 1)',
      110: 'hsla(44, 100%, 16%, 1)',
      120: 'hsla(44, 95%, 8%, 1)'
    },
    orange: {
      10: 'hsla(28, 80%, 96%, 1)',
      20: 'hsla(28, 80%, 88%, 1)',
      30: 'hsla(28, 80%, 80%, 1)',
      40: 'hsla(28, 80%, 72%, 1)',
      50: 'hsla(28, 80%, 64%, 1)',
      60: 'hsla(28, 84%, 56%, 1)',
      70: 'hsla(28, 84%, 48%, 1)',
      80: 'hsla(28, 92%, 40%, 1)',
      90: 'hsla(28, 92%, 32%, 1)',
      100: 'hsla(28, 92%, 24%, 1)',
      110: 'hsla(28, 92%, 16%, 1)',
      120: 'hsla(28, 92%, 8%, 1)'
    },
    red: {
      10: 'hsla(4, 80%, 96%, 1)',
      20: 'hsla(4, 80%, 88%, 1)',
      30: 'hsla(4, 80%, 80%, 1)',
      40: 'hsla(4, 80%, 72%, 1)',
      50: 'hsla(4, 80%, 64%, 1)',
      60: 'hsla(4, 80%, 56%, 1)',
      70: 'hsla(4, 80%, 48%, 1)',
      80: 'hsla(4, 80%, 40%, 1)',
      90: 'hsla(4, 80%, 32%, 1)',
      100: 'hsla(4, 80%, 24%, 1)',
      110: 'hsla(4, 80%, 16%, 1)',
      120: 'hsla(4, 80%, 8%, 1)'
    },
    purple: {
      10: 'hsla(260, 80%, 96%, 1)',
      20: 'hsla(260, 80%, 88%, 1)',
      30: 'hsla(260, 90%, 80%, 1)',
      40: 'hsla(260, 76%, 72%, 1)',
      50: 'hsla(260, 72%, 64%, 1)',
      60: 'hsla(260, 60%, 56%, 1)',
      70: 'hsla(260, 56%, 48%, 1)',
      80: 'hsla(260, 72%, 40%, 1)',
      90: 'hsla(260, 80%, 32%, 1)',
      100: 'hsla(260, 80%, 24%, 1)',
      110: 'hsla(260, 80%, 16%, 1)',
      120: 'hsla(260, 80%, 8%, 1)'
    },
    lilac: {
      10: 'hsla(288, 80%, 96%, 1)',
      20: 'hsla(288, 80%, 88%, 1)',
      30: 'hsla(288, 90%, 80%, 1)',
      40: 'hsla(288, 76%, 72%, 1)',
      50: 'hsla(288, 72%, 64%, 1)',
      60: 'hsla(288, 60%, 60%, 1)',
      70: 'hsla(288, 56%, 48%, 1)',
      80: 'hsla(288, 72%, 40%, 1)',
      90: 'hsla(288, 80%, 32%, 1)',
      100: 'hsla(288, 80%, 24%, 1)',
      110: 'hsla(288, 80%, 16%, 1)',
      120: 'hsla(288, 80%, 8%, 1)'
    }
  },
  transparent: {
    lighten: {
      10: 'hsla(220, 14%, 96%, 0.04)',
      20: 'hsla(220, 14%, 96%, 0.08)',
      30: 'hsla(220, 14%, 96%, 0.12)',
      40: 'hsla(220, 14%, 96%, 0.16)',
      50: 'hsla(220, 14%, 96%, 0.32)',
      60: 'hsla(220, 14%, 96%, 0.48)',
      70: 'hsla(220, 14%, 96%, 0.64)',
      80: 'hsla(220, 14%, 96%, 0.80)'
    },
    darken: {
      10: 'hsla(220, 56%, 20%, 0.02)',
      20: 'hsla(220, 56%, 20%, 0.06)',
      30: 'hsla(220, 56%, 20%, 0.08)',
      40: 'hsla(220, 56%, 20%, 0.16)',
      50: 'hsla(220, 56%, 20%, 0.32)',
      60: 'hsla(220, 56%, 20%, 0.48)',
      70: 'hsla(220, 56%, 20%, 0.64)',
      80: 'hsla(220, 56%, 20%, 0.80)'
    },
    blue: {
      10: 'hsla(220, 60%, 56%, 0.04)',
      20: 'hsla(220, 60%, 56%, 0.08)',
      30: 'hsla(220, 60%, 56%, 0.12)',
      40: 'hsla(220, 60%, 56%, 0.16)',
      50: 'hsla(220, 60%, 56%, 0.32)',
      60: 'hsla(220, 60%, 56%, 0.48)',
      70: 'hsla(220, 60%, 56%, 0.64)',
      80: 'hsla(220, 60%, 56%, 0.80)'
    },
    green: {
      10: 'hsla(0, 56%, 48%, 0.04)',
      20: 'hsla(0, 56%, 48%, 0.08)',
      30: 'hsla(0, 56%, 48%, 0.12)',
      40: 'hsla(0, 56%, 48%, 0.16)',
      50: 'hsla(0, 56%, 48%, 0.32)',
      60: 'hsla(0, 56%, 48%, 0.48)',
      70: 'hsla(0, 56%, 48%, 0.64)',
      80: 'hsla(0, 56%, 48%, 0.8)'
    },
    orange: {
      10: 'hsla(0, 84%, 56%, 0.04)',
      20: 'hsla(0, 84%, 56%, 0.08)',
      30: 'hsla(0, 84%, 56%, 0.12)',
      40: 'hsla(0, 84%, 56%, 0.16)',
      50: 'hsla(0, 84%, 56%, 0.32)',
      60: 'hsla(0, 84%, 56%, 0.48)',
      70: 'hsla(0, 84%, 56%, 0.64)',
      80: 'hsla(0, 84%, 56%, 0.8)'
    },
    red: {
      10: 'hsla(0, 60%, 56%, 0.04)',
      20: 'hsla(0, 60%, 56%, 0.08)',
      30: 'hsla(0, 60%, 56%, 0.12)',
      40: 'hsla(0, 60%, 56%, 0.16)',
      50: 'hsla(0, 60%, 56%, 0.32)',
      60: 'hsla(0, 60%, 56%, 0.48)',
      70: 'hsla(0, 60%, 56%, 0.64)',
      80: 'hsla(0, 60%, 56%, 0.8)'
    }
  }
};