module.exports = {
  expressive: {
    0: {
      light: 'hsla(0, 48%, 92%, 1)',
      mid: 'hsla(0, 48%, 44%, 1)',
      dark: 'hsla(0, 48%, 12%, 1)',
      overlay: 'hsla(0, 46%, 92%, 0.4)'
    },
    8: {
      light: 'hsla(8, 48%, 92%, 1)',
      mid: 'hsla(8, 48%, 44%, 1)',
      dark: 'hsla(8, 48%, 12%, 1)',
      overlay: 'hsla(8, 46%, 92%, 0.4)'
    },
    16: {
      light: 'hsla(16, 48%, 92%, 1)',
      mid: 'hsla(16, 48%, 44%, 1)',
      dark: 'hsla(16, 48%, 12%, 1)',
      overlay: 'hsla(16, 46%, 92%, 0.4)'
    },
    24: {
      light: 'hsla(24, 48%, 92%, 1)',
      mid: 'hsla(24, 48%, 44%, 1)',
      dark: 'hsla(24, 48%, 12%, 1)',
      overlay: 'hsla(24, 46%, 92%, 0.4)'
    },
    32: {
      light: 'hsla(32, 48%, 92%, 1)',
      mid: 'hsla(32, 48%, 44%, 1)',
      dark: 'hsla(32, 48%, 12%, 1)',
      overlay: 'hsla(32, 46%, 92%, 0.4)'
    },
    40: {
      light: 'hsla(40, 48%, 92%, 1)',
      mid: 'hsla(40, 48%, 44%, 1)',
      dark: 'hsla(40, 48%, 12%, 1)',
      overlay: 'hsla(40, 46%, 92%, 0.4)'
    },
    48: {
      light: 'hsla(48, 48%, 92%, 1)',
      mid: 'hsla(48, 48%, 44%, 1)',
      dark: 'hsla(48, 48%, 12%, 1)',
      overlay: 'hsla(48, 46%, 92%, 0.4)'
    },
    56: {
      light: 'hsla(56, 48%, 92%, 1)',
      mid: 'hsla(56, 48%, 44%, 1)',
      dark: 'hsla(56, 48%, 12%, 1)',
      overlay: 'hsla(56, 46%, 92%, 0.4)'
    },
    64: {
      light: 'hsla(64, 48%, 92%, 1)',
      mid: 'hsla(64, 48%, 44%, 1)',
      dark: 'hsla(64, 48%, 12%, 1)',
      overlay: 'hsla(64, 46%, 92%, 0.4)'
    },
    72: {
      light: 'hsla(72, 48%, 92%, 1)',
      mid: 'hsla(72, 48%, 44%, 1)',
      dark: 'hsla(72, 48%, 12%, 1)',
      overlay: 'hsla(72, 46%, 92%, 0.4)'
    },
    80: {
      light: 'hsla(80, 48%, 92%, 1)',
      mid: 'hsla(80, 48%, 44%, 1)',
      dark: 'hsla(80, 48%, 12%, 1)',
      overlay: 'hsla(80, 46%, 92%, 0.4)'
    },
    88: {
      light: 'hsla(88, 48%, 92%, 1)',
      mid: 'hsla(88, 48%, 44%, 1)',
      dark: 'hsla(88, 48%, 12%, 1)',
      overlay: 'hsla(88, 46%, 92%, 0.4)'
    },
    96: {
      light: 'hsla(96, 48%, 92%, 1)',
      mid: 'hsla(96, 48%, 44%, 1)',
      dark: 'hsla(96, 48%, 12%, 1)',
      overlay: 'hsla(96, 46%, 92%, 0.4)'
    },
    104: {
      light: 'hsla(104, 48%, 92%, 1)',
      mid: 'hsla(104, 48%, 44%, 1)',
      dark: 'hsla(104, 48%, 12%, 1)',
      overlay: 'hsla(104, 46%, 92%, 0.4)'
    },
    112: {
      light: 'hsla(112, 48%, 92%, 1)',
      mid: 'hsla(112, 48%, 44%, 1)',
      dark: 'hsla(112, 48%, 12%, 1)',
      overlay: 'hsla(112, 46%, 92%, 0.4)'
    },
    120: {
      light: 'hsla(120, 48%, 92%, 1)',
      mid: 'hsla(120, 48%, 44%, 1)',
      dark: 'hsla(120, 48%, 12%, 1)',
      overlay: 'hsla(120, 46%, 92%, 0.4)'
    },
    128: {
      light: 'hsla(128, 48%, 92%, 1)',
      mid: 'hsla(128, 48%, 44%, 1)',
      dark: 'hsla(128, 48%, 12%, 1)',
      overlay: 'hsla(128, 46%, 92%, 0.4)'
    },
    136: {
      light: 'hsla(136, 48%, 92%, 1)',
      mid: 'hsla(136, 48%, 44%, 1)',
      dark: 'hsla(136, 48%, 12%, 1)',
      overlay: 'hsla(136, 46%, 92%, 0.4)'
    },
    144: {
      light: 'hsla(144, 48%, 92%, 1)',
      mid: 'hsla(144, 48%, 44%, 1)',
      dark: 'hsla(144, 48%, 12%, 1)',
      overlay: 'hsla(144, 46%, 92%, 0.4)'
    },
    152: {
      light: 'hsla(152, 48%, 92%, 1)',
      mid: 'hsla(152, 48%, 44%, 1)',
      dark: 'hsla(152, 48%, 12%, 1)',
      overlay: 'hsla(152, 46%, 92%, 0.4)'
    },
    160: {
      light: 'hsla(160, 48%, 92%, 1)',
      mid: 'hsla(160, 48%, 44%, 1)',
      dark: 'hsla(160, 48%, 12%, 1)',
      overlay: 'hsla(160, 46%, 92%, 0.4)'
    },
    168: {
      light: 'hsla(168, 48%, 92%, 1)',
      mid: 'hsla(168, 48%, 44%, 1)',
      dark: 'hsla(168, 48%, 12%, 1)',
      overlay: 'hsla(168, 46%, 92%, 0.4)'
    },
    176: {
      light: 'hsla(176, 48%, 92%, 1)',
      mid: 'hsla(176, 48%, 44%, 1)',
      dark: 'hsla(176, 48%, 12%, 1)',
      overlay: 'hsla(176, 46%, 92%, 0.4)'
    },
    184: {
      light: 'hsla(184, 48%, 92%, 1)',
      mid: 'hsla(184, 48%, 44%, 1)',
      dark: 'hsla(184, 48%, 12%, 1)',
      overlay: 'hsla(184, 46%, 92%, 0.4)'
    },
    192: {
      light: 'hsla(192, 48%, 92%, 1)',
      mid: 'hsla(192, 48%, 44%, 1)',
      dark: 'hsla(192, 48%, 12%, 1)',
      overlay: 'hsla(192, 46%, 92%, 0.4)'
    },
    200: {
      light: 'hsla(200, 48%, 92%, 1)',
      mid: 'hsla(200, 48%, 44%, 1)',
      dark: 'hsla(200, 48%, 12%, 1)',
      overlay: 'hsla(200, 46%, 92%, 0.4)'
    },
    208: {
      light: 'hsla(208, 48%, 92%, 1)',
      mid: 'hsla(208, 48%, 44%, 1)',
      dark: 'hsla(208, 48%, 12%, 1)',
      overlay: 'hsla(208, 46%, 92%, 0.4)'
    },
    216: {
      light: 'hsla(216, 48%, 92%, 1)',
      mid: 'hsla(216, 48%, 44%, 1)',
      dark: 'hsla(216, 48%, 12%, 1)',
      overlay: 'hsla(216, 46%, 92%, 0.4)'
    },
    224: {
      light: 'hsla(224, 48%, 92%, 1)',
      mid: 'hsla(224, 48%, 44%, 1)',
      dark: 'hsla(224, 48%, 12%, 1)',
      overlay: 'hsla(224, 46%, 92%, 0.4)'
    },
    232: {
      light: 'hsla(232, 48%, 92%, 1)',
      mid: 'hsla(232, 48%, 44%, 1)',
      dark: 'hsla(232, 48%, 12%, 1)',
      overlay: 'hsla(232, 46%, 92%, 0.4)'
    },
    240: {
      light: 'hsla(240, 48%, 92%, 1)',
      mid: 'hsla(240, 48%, 44%, 1)',
      dark: 'hsla(240, 48%, 12%, 1)',
      overlay: 'hsla(240, 46%, 92%, 0.4)'
    },
    248: {
      light: 'hsla(248, 48%, 92%, 1)',
      mid: 'hsla(248, 48%, 44%, 1)',
      dark: 'hsla(248, 48%, 12%, 1)',
      overlay: 'hsla(248, 46%, 92%, 0.4)'
    },
    256: {
      light: 'hsla(256, 48%, 92%, 1)',
      mid: 'hsla(256, 48%, 44%, 1)',
      dark: 'hsla(256, 48%, 12%, 1)',
      overlay: 'hsla(256, 46%, 92%, 0.4)'
    },
    264: {
      light: 'hsla(264, 48%, 92%, 1)',
      mid: 'hsla(264, 48%, 44%, 1)',
      dark: 'hsla(264, 48%, 12%, 1)',
      overlay: 'hsla(264, 46%, 92%, 0.4)'
    },
    272: {
      light: 'hsla(272, 48%, 92%, 1)',
      mid: 'hsla(272, 48%, 44%, 1)',
      dark: 'hsla(272, 48%, 12%, 1)',
      overlay: 'hsla(272, 46%, 92%, 0.4)'
    },
    280: {
      light: 'hsla(280, 48%, 92%, 1)',
      mid: 'hsla(280, 48%, 44%, 1)',
      dark: 'hsla(280, 48%, 12%, 1)',
      overlay: 'hsla(280, 46%, 92%, 0.4)'
    },
    288: {
      light: 'hsla(288, 48%, 92%, 1)',
      mid: 'hsla(288, 48%, 44%, 1)',
      dark: 'hsla(288, 48%, 12%, 1)',
      overlay: 'hsla(288, 46%, 92%, 0.4)'
    },
    296: {
      light: 'hsla(296, 48%, 92%, 1)',
      mid: 'hsla(296, 48%, 44%, 1)',
      dark: 'hsla(296, 48%, 12%, 1)',
      overlay: 'hsla(296, 46%, 92%, 0.4)'
    },
    304: {
      light: 'hsla(304, 48%, 92%, 1)',
      mid: 'hsla(304, 48%, 44%, 1)',
      dark: 'hsla(304, 48%, 12%, 1)',
      overlay: 'hsla(304, 46%, 92%, 0.4)'
    },
    312: {
      light: 'hsla(312, 48%, 92%, 1)',
      mid: 'hsla(312, 48%, 44%, 1)',
      dark: 'hsla(312, 48%, 12%, 1)',
      overlay: 'hsla(312, 46%, 92%, 0.4)'
    },
    320: {
      light: 'hsla(320, 48%, 92%, 1)',
      mid: 'hsla(320, 48%, 44%, 1)',
      dark: 'hsla(320, 48%, 12%, 1)',
      overlay: 'hsla(320, 46%, 92%, 0.4)'
    },
    328: {
      light: 'hsla(328, 48%, 92%, 1)',
      mid: 'hsla(328, 48%, 44%, 1)',
      dark: 'hsla(328, 48%, 12%, 1)',
      overlay: 'hsla(328, 46%, 92%, 0.4)'
    },
    336: {
      light: 'hsla(336, 48%, 92%, 1)',
      mid: 'hsla(336, 48%, 44%, 1)',
      dark: 'hsla(336, 48%, 12%, 1)',
      overlay: 'hsla(336, 46%, 92%, 0.4)'
    },
    344: {
      light: 'hsla(344, 48%, 92%, 1)',
      mid: 'hsla(344, 48%, 44%, 1)',
      dark: 'hsla(344, 48%, 12%, 1)',
      overlay: 'hsla(344, 46%, 92%, 0.4)'
    },
    352: {
      light: 'hsla(352, 48%, 92%, 1)',
      mid: 'hsla(352, 48%, 44%, 1)',
      dark: 'hsla(352, 48%, 12%, 1)',
      overlay: 'hsla(352, 46%, 92%, 0.4)'
    }
  }
};