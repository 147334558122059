import { GetCompanyUserConnectionsV2_UserFragment } from '__generated__/graphql';
import { UsersFilledIcon } from 'assets/harmonic-icons';
import classNames from 'classnames';
import { useGroupedUserConnections } from 'hooks/useGroupedUserConnections';
import { PersonUserConnnectionsTooltip } from './PersonUserConnnectionsTooltip';

interface UserConnectionsProps {
  target?: { name: string };
  userConnections: GetCompanyUserConnectionsV2_UserFragment[];
  includeViaPerson?: boolean;
  emphasizedStyle?: boolean;
  gap?: 'g20' | 'g30';
  iconFill?: 'weak' | 'default' | 'muted';
  showIcon?: boolean;
}

interface ConnectionsRenderable {
  userName: string;
}

const PersonUserConnections = ({
  target,
  userConnections,
  emphasizedStyle = true,
  gap = 'g20',
  iconFill = 'default',
  showIcon = true
}: UserConnectionsProps) => {
  const groupedUserConnections = useGroupedUserConnections({
    userConnections
  });

  if (!groupedUserConnections) {
    return null;
  }

  const {
    userConnectionsToRender,
    groupedUserConnectionsToRender,
    connectionsToShowBehindHover,
    connectionsToShowWithoutHover
  } = groupedUserConnections;

  return (
    <div data-testid="PersonUserConnections" className="flex max-w-[200px]">
      <div
        className={classNames(
          'flex items-center text-content-weak typography-label-default-default whitespace-pre',
          gap === 'g20' && 'gap-g20',
          gap === 'g30' && 'gap-g30'
        )}
      >
        {showIcon && (
          <UsersFilledIcon
            className={classNames(
              iconFill === 'default'
                ? 'text-content-default'
                : iconFill === 'weak'
                ? 'text-content-weak'
                : 'text-content-muted'
            )}
          />
        )}

        <div className="lg:hidden">
          {`${groupedUserConnectionsToRender[0][0].userName} ${
            groupedUserConnectionsToRender.length > 1
              ? `and ${groupedUserConnectionsToRender.length - 1} others`
              : groupedUserConnectionsToRender[0][0].userName === 'You'
              ? 'are'
              : 'is'
          } in network`}
        </div>

        <div
          className="hidden lg:flex"
          data-testid="PersonUserConnections-text"
        >
          {connectionsToShowWithoutHover.map(
            (group: ConnectionsRenderable[], index) => {
              const connection = group[0];
              return (
                <span key={`userConnection_${index}`} className="flex ">
                  <span
                    className={classNames(
                      emphasizedStyle
                        ? 'text-content-strong'
                        : 'text-content-weak'
                    )}
                  >
                    {connection.userName}
                  </span>
                  {index < groupedUserConnectionsToRender?.length - 2
                    ? ', '
                    : index == groupedUserConnectionsToRender?.length - 2
                    ? ' and '
                    : ''}
                  {index == connectionsToShowWithoutHover.length - 1 &&
                    connectionsToShowBehindHover.length > 0 && (
                      <PersonUserConnnectionsTooltip
                        userConnections={connectionsToShowBehindHover}
                        emphasizedStyle={emphasizedStyle}
                      />
                    )}
                </span>
              );
            }
          )}

          <span className="text-content-weak flex">
            {userConnectionsToRender.length > 1
              ? ' on your team are'
              : userConnectionsToRender[0].userName === 'You'
              ? ' are'
              : ' on your team is'}{' '}
            in network{' '}
            {target ? (
              <>
                with{' '}
                <p
                  className={classNames(
                    emphasizedStyle
                      ? 'text-content-strong'
                      : 'text-content-weak'
                  )}
                >
                  {target.name}
                </p>
              </>
            ) : (
              'here'
            )}
            .
          </span>
        </div>
      </div>
    </div>
  );
};

export default PersonUserConnections;
