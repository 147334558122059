import { SortContextValues } from '__generated__/graphql';
import useFetchSinglePeopleWatchlist from 'hooks/useFetchSinglePeopleWatchlist';
import useUpsertPeopleWatchlistNamedViews from 'hooks/useUpsertPeopleWatchlistNamedViews';
import { INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { useMemo } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import useStore from 'stores/zustandStore';
import { getSelectedPersonNamedViewIndex } from 'utils/namedViews';
import {
  SortHookResponse,
  getModifiedPeopleSortValueAfterValidationCheck
} from 'utils/sort';

export const usePeopleWatchlistSort = (): SortHookResponse => {
  const { peopleWatchlist } = useFetchSinglePeopleWatchlist({
    fetchPolicy: 'cache-only'
  });
  const personListSelectedNamedView = useStore(
    (state) => state.personListSelectedNamedView
  );
  const selectedNamedViewIndex = useMemo(() => {
    return getSelectedPersonNamedViewIndex(
      peopleWatchlist,
      personListSelectedNamedView
    );
  }, [personListSelectedNamedView, peopleWatchlist]);
  const currentNamedViews =
    peopleWatchlist?.namedViews?.[selectedNamedViewIndex];

  const { upsertCurrentSelectedNamedView } =
    useUpsertPeopleWatchlistNamedViews();

  const { editFilterStore } = useShallowFilterStore(['editFilterStore']);

  const zustandStoreSortKey = 'peopleWatchlistPageSort';

  const updateSortDataOnBackend = async (
    sortField: string,
    isDescending: boolean,
    sortContextValues?: SortContextValues
  ) => {
    const updatedSearchQuery = {
      ...INITIAL_SEARCH_MODEL,
      ...(currentNamedViews?.searchQuery ?? {}),
      sort: [
        {
          sortField,
          descending: isDescending,
          sortContextValues
        }
      ]
    };
    upsertCurrentSelectedNamedView({
      variables: {
        searchQuery: updatedSearchQuery
      }
    });
  };

  const changeSort = ({
    field,
    descending,
    sortContextValues
  }: {
    field: string;
    descending: boolean;
    sortContextValues?: SortContextValues;
  }) => {
    editFilterStore(zustandStoreSortKey, {
      sortField: field,
      descending: descending,
      sortContextValues
    });
    updateSortDataOnBackend(field, descending, sortContextValues);
  };

  const sort = getModifiedPeopleSortValueAfterValidationCheck(
    currentNamedViews?.searchQuery?.sort?.[0]?.sortField,
    currentNamedViews?.searchQuery?.sort?.[0]?.descending,
    currentNamedViews?.searchQuery?.sort?.[0]?.sortContextValues,
    peopleWatchlist?.customFields
  );

  return {
    changeSort,
    sort
  };
};
