import { IRowNode } from 'ag-grid-community';
import { get, toString } from 'lodash';
import React from 'react';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { IPerson } from '../../../../../interfaces/DataModel/Person';
import { useShallowTableStore } from '../../../../../stores/tableStore';
import { isElementOverflow } from '../../../../../utils/utilities';
import { LightTooltip } from '../../../../common';
import {
  LoadingCellRenderer,
  NoDataSubRenderer,
  useStyles
} from '../CellRenderers';

export const LongText: React.FC<{
  value: string | number;
  hidePlaceholder?: boolean;
  node?: IRowNode<
    | {
        company?: ICompany;
      }
    | {
        person?: IPerson;
      }
  >;
}> = (props) => {
  const classes = useStyles();
  const textRef = React.useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = React.useState(false);
  const { gridRowHeight } = useShallowTableStore(['gridRowHeight']);

  React.useEffect(() => {
    if (textRef.current) {
      setIsEllipsisActive(isElementOverflow(textRef.current));
    }
  }, [textRef.current]);

  const text = get(props, 'value');

  if (text === 'loading') return <LoadingCellRenderer />;
  if (!text && props.hidePlaceholder) return null;
  if (!text) {
    return <NoDataSubRenderer />;
  }
  let formattedText = '';

  if (typeof text === 'number') {
    formattedText = toString(text);
  } else if (typeof text === 'string') {
    formattedText = text;
  }

  // Remove characters that may mess with formatting (currently just emojis)
  formattedText = formattedText.replace(
    /[\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]/g,
    ''
  );

  if (text && text != '') {
    return (
      <LightTooltip
        enterDelay={1500}
        title={isEllipsisActive ? formattedText : ''}
        interactive
      >
        <div
          ref={textRef}
          className={`${classes.ellipsis} ${
            gridRowHeight > 100
              ? classes.rowHeightTall
              : gridRowHeight < 60
              ? classes.rowHeightSmall
              : classes.rowHeightMedium
          }
          ${classes.breakWord}
          typography-paragraph text-content-default
          `}
        >
          {formattedText}
        </div>
      </LightTooltip>
    );
  }

  return (
    <p className="typography-paragraph text-content-default">
      {props.node?.data && ' '}
    </p>
  );
};
