import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { generateSecondaryTypeClasses } from '../../../harmonic-components/Button/utils';
import { useShallowSidebarStore } from '../../../stores/sidebarStore';

const LeftPanelClosed = ({ className }: { className?: string }) => (
  <span
    className={classNames('icon icon-rounded icon-filled icon-20', className)}
  >
    left_panel_close
  </span>
);

const LeftPanelOpen = ({ className }: { className?: string }) => (
  <span
    className={classNames('icon icon-rounded icon-filled icon-20', className)}
  >
    left_panel_open
  </span>
);

const Menu = ({ className }: { className?: string }) => (
  <span
    className={classNames('icon icon-rounded icon-filled icon-20', className)}
  >
    menu
  </span>
);

export const DashboardSidebarMenuButton = () => {
  const { isAnimating, isFloating, isCollapsed, editSidebar } =
    useShallowSidebarStore([
      'isAnimating',
      'isFloating',
      'isCollapsed',
      'editSidebar'
    ]);

  const handleClick = useCallback(() => {
    if (isFloating) {
      editSidebar('isCollapsed', false);
    } else {
      editSidebar('isCollapsed', !isCollapsed);
    }
    editSidebar('isFloating', !isFloating);
    editSidebar('isAnimating', true);
  }, [editSidebar, isFloating, isCollapsed]);

  const handleMouseEnter = useCallback(() => {
    if (isFloating && !isAnimating && isCollapsed) {
      editSidebar('isCollapsed', false);
    }
  }, [editSidebar, isFloating, isAnimating, isCollapsed]);

  if (!isFloating && !isCollapsed) return null;

  return (
    <DashboardIconButton
      icon={!isCollapsed ? <LeftPanelOpen /> : <Menu />}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    />
  );
};

export const DashboardSidebarInvisibleButton = () => {
  const [mouseEnterTimer, setMouseEnterTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const { isAnimating, isFloating, isCollapsed, editSidebar } =
    useShallowSidebarStore([
      'isAnimating',
      'isFloating',
      'isCollapsed',
      'editSidebar'
    ]);

  const toggleSidebarCollapsed = useCallback(
    (isCollapsed: boolean) => {
      if (isFloating && !isAnimating) {
        editSidebar('isCollapsed', isCollapsed);
      }
    },
    [editSidebar, isFloating, isAnimating]
  );

  const handleWindowMouseLeave = useCallback(
    (event: MouseEvent) => {
      if (event.clientX <= 0) {
        toggleSidebarCollapsed(false);
      }
    },
    [toggleSidebarCollapsed]
  );

  const handleMouseEnter = useCallback(() => {
    const timer = setTimeout(() => {
      toggleSidebarCollapsed(false);
    }, 100);

    setMouseEnterTimer(timer);
  }, [toggleSidebarCollapsed]);

  const handleMouseLeave = useCallback(() => {
    if (mouseEnterTimer) {
      clearTimeout(mouseEnterTimer);
      setMouseEnterTimer(null);
    }
  }, [mouseEnterTimer]);

  useEffect(() => {
    const handleMouseOutEvent = (event: MouseEvent) => {
      // Only trigger if actually leaving the window, not just moving between elements
      if (event.relatedTarget === null) {
        handleWindowMouseLeave(event);
      }
    };

    window.addEventListener('mouseout', handleMouseOutEvent);

    return () => {
      window.removeEventListener('mouseout', handleMouseOutEvent);
    };
  }, [handleWindowMouseLeave]);

  if (!isCollapsed) return null;

  return (
    <div
      data-testid="sidebar-invisible-button"
      className="fixed top-0 left-0 w-3 h-full bg-transparent z-10"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

const DashboardIconButton = ({
  onMouseEnter,
  onClick,
  icon
}: {
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon: React.ReactNode;
}) => {
  return (
    <button
      className={classNames(
        'flex items-center text-content-weak justify-center',
        'focus:shadow-none leading-none p-1',
        'rounded-br20',
        generateSecondaryTypeClasses('low', false, false)
      )}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {icon}
    </button>
  );
};

export const DashboardSidebarButton = () => {
  const { isFloating, isCollapsed, editSidebar } = useShallowSidebarStore([
    'isAnimating',
    'isFloating',
    'isCollapsed',
    'editSidebar'
  ]);

  if (isFloating && !isCollapsed) return null;

  return (
    <DashboardIconButton
      icon={<LeftPanelClosed />}
      onClick={() => {
        if (isFloating) {
          editSidebar('isCollapsed', false);
        } else {
          editSidebar('isCollapsed', !isCollapsed);
        }
        editSidebar('isFloating', !isFloating);
        editSidebar('isAnimating', true);
      }}
    />
  );
};
