import classNames from 'classnames';
import { IconProps } from './type';

const PencilFilledIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="#484E5B"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M10.5071 3.78458C10.9627 3.32897 11.7014 3.32897 12.157 3.78458L14.2142 5.84177C14.6698 6.29738 14.6698 7.03608 14.2142 7.49169L12.5391 9.16673L8.83203 5.45962L10.5071 3.78458Z"
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M8.12492 6.16673L1.67374 12.6179C1.45495 12.8367 1.33203 13.1335 1.33203 13.4429V16.1667C1.33203 16.4429 1.55589 16.6667 1.83203 16.6667H4.55589C4.86531 16.6667 5.16205 16.5438 5.38085 16.325L11.832 9.87383L8.12492 6.16673Z"
      />
    </svg>
  );
};

export default PencilFilledIcon;
