import CircleQuestionmarkIcon from 'assets/harmonic-icons/circle-questionmark';
import classNames from 'classnames';
import { Tooltip } from 'common/components';
import Button from 'harmonic-components/Button/Button';
import Checkbox from 'harmonic-components/Checkbox/Checkbox';
import { clone } from 'lodash';
import React, { useState } from 'react';

export interface CheckboxItem {
  label: string;
  value: string;
  tooltipLabel?: string;
}

interface CheckboxesProps {
  onValueChange: (
    newSelectedValues?: string[],
    newExcludedValues?: string[]
  ) => void;
  options: CheckboxItem[];
  selectedValues?: string[];
  excludedValues?: string[];
  onCheckChange?: (checked: boolean, fieldName: string) => void;
  enableExcludeMode?: boolean;
  //If the options exceed this limit, Show More/less button is shown
  maxOptionsLimit?: number;
  showAllIfSelected?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  singleColumn?: boolean;
}

const Checkboxes: React.FC<CheckboxesProps> = ({
  options,
  onValueChange,
  selectedValues = [],
  excludedValues = [],
  maxOptionsLimit = 6,
  enableExcludeMode = false,
  showAllIfSelected: showAllIfSelected = false,
  disabled,
  onCheckChange,
  dataTestId,
  singleColumn = false
}) => {
  const [showAllOptions, setShowAllOptions] = useState(
    !showAllIfSelected ? false : selectedValues.length > 0
  );

  const onChange = (checked: boolean, fieldName: string) => {
    let newSelectedValues = clone(selectedValues);
    let newExcludedValues = clone(excludedValues);
    if (checked) {
      newSelectedValues.push(fieldName);
    } else {
      newSelectedValues = newSelectedValues.filter(
        (value) => value !== fieldName
      );
    }
    newExcludedValues = newExcludedValues.filter(
      (value) => value !== fieldName
    );

    onValueChange(
      newSelectedValues.length > 0 ? newSelectedValues : undefined,
      newExcludedValues.length > 0 ? newExcludedValues : undefined
    );
    onCheckChange && onCheckChange(checked, fieldName);
  };

  const onExclude = (fieldName: string) => {
    let newSelectedValues = clone(selectedValues);
    let newExcludedValues = clone(excludedValues);
    const alreadyExcluded = excludedValues.includes(fieldName);
    newSelectedValues = newSelectedValues.filter(
      (value) => value !== fieldName
    );
    if (!alreadyExcluded) {
      newExcludedValues.push(fieldName);
    } else {
      newExcludedValues = newExcludedValues.filter(
        (value) => value !== fieldName
      );
    }

    onValueChange(
      newSelectedValues.length > 0 ? newSelectedValues : undefined,
      newExcludedValues.length > 0 ? newExcludedValues : undefined
    );
  };

  React.useEffect(() => {
    if (showAllIfSelected && selectedValues.length > 0) {
      setShowAllOptions(selectedValues.length > 0);
    }
  }, [selectedValues, showAllIfSelected]);

  const showToggleButton = options.length > maxOptionsLimit;

  return (
    <div data-testid={dataTestId} className="w-full flex flex-col gap-g50">
      <div
        className={classNames(
          'grid',
          singleColumn ? 'grid-cols-1' : 'grid-cols-2',
          'gap-y-2 gap-x-10'
        )}
      >
        {options
          ?.slice(0, showAllOptions ? options.length : maxOptionsLimit)
          .map((option) => (
            <div key={`${option.value}`} className="flex flex-row gap-g20">
              <Checkbox
                checked={
                  selectedValues.includes(option.value) ||
                  excludedValues.includes(option.value)
                }
                variant={
                  selectedValues.includes(option.value)
                    ? 'default'
                    : excludedValues.includes(option.value)
                    ? 'exclude'
                    : 'default'
                }
                onChange={(e) => {
                  const checked = e.target.checked;
                  onChange(checked, option.value);
                }}
                disabled={disabled}
                showExcludeButton={enableExcludeMode}
                onExclude={() => onExclude(option.value)}
                label={option.label}
                fullWidth
                id={option.label}
                dataTestId={`${dataTestId}-${option.value}`}
              />
              {option.tooltipLabel && (
                <Tooltip body={option.tooltipLabel}>
                  <CircleQuestionmarkIcon />
                </Tooltip>
              )}
            </div>
          ))}
      </div>
      {showToggleButton && (
        <div className="flex-shrink">
          <Button
            emphasis="high"
            label={!showAllOptions ? 'Show more' : 'Show less'}
            onClick={() => setShowAllOptions(!showAllOptions)}
          />
        </div>
      )}
    </div>
  );
};

export default Checkboxes;
