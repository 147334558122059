import { ListNamedViewDisplayType } from '__generated__/graphql';
import { ICellRendererParams } from 'ag-grid-community';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import { isNil } from 'lodash';
import { EntityType } from '../../../../../hooks/useDashboardLocation';
import { useShallowTableStore } from '../../../../../stores/tableStore';
import { LOADING_ROW_ID } from '../../../../../utils/constants';

export const useIsExtendedLoading = (props: ICellRendererParams) => {
  const { loadedExtendedUrns } = useShallowTableStore(['loadedExtendedUrns']);

  const { companyWatchlist } = useFetchSingleWatchlist({
    fetchPolicy: 'cache-only'
  });

  const view = companyWatchlist?.namedViews?.[0]?.displayType;

  const entityType = props?.data?.person?.id
    ? EntityType.PERSON
    : props?.data?.company?.id
    ? EntityType.COMPANY
    : undefined;

  const id: number =
    entityType === EntityType.PERSON
      ? props?.data?.person?.id
      : entityType === EntityType.COMPANY
      ? props?.data?.company?.id
      : undefined;

  const urn = entityType ? `urn:harmonic:${entityType}:${id}` : undefined;
  const shouldShowLoading = urn ? !loadedExtendedUrns[urn] : false;

  if (
    (shouldShowLoading || props.data?.id === LOADING_ROW_ID) &&
    (isNil(companyWatchlist) ||
      (!isNil(view) && view !== ListNamedViewDisplayType.BOARD))
  ) {
    return true;
  }

  return false;
};
