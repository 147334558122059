export const setConnectionsBannerDismissLocalStorageState = (
  dismissed: boolean
) => {
  localStorage.setItem(
    'simpleSearchConnectionsBannerDismissed',
    JSON.stringify(dismissed)
  );
};

export const getConnectionsBannerDismissLocalStorageState = (): boolean => {
  const connectionsBannerDismissed = localStorage.getItem(
    'simpleSearchConnectionsBannerDismissed'
  );
  return connectionsBannerDismissed
    ? JSON.parse(connectionsBannerDismissed)
    : false;
};
