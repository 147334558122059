import { gql } from '@apollo/client';
import { GetPersonsByIdsQuery } from '__generated__/graphql';
import { FullPeopleSearch } from './peopleSearchFragment';

const getPersonsByIds = gql`
  query GetPersonsByIds(
    $ids: [Int!]!
    $extended: Boolean!
    $skipExtended: Boolean!
  ) {
    getPersonsByIds(ids: $ids, extended: $extended) {
      ...PeopleSearch
    }
  }
  ${FullPeopleSearch}
`;

export default getPersonsByIds;

export const getPersonsByIdsMockData: GetPersonsByIdsQuery = {
  getPersonsByIds: [
    {
      personUrn: 'urn:harmonic:person:2',
      id: 2,
      fullName: 'María Del Pino Ravelo Rivero',
      firstName: 'María',
      lastName: 'Del Pino Ravelo Rivero',
      about: null,
      watchlists: [],
      profilePictureUrl:
        'https://static-exp1.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
      linkedinHeadline: null,
      location: {
        location: 'Greater Las Palmas Metropolitan Area',
        state: null,
        city: null,
        country: null,
        __typename: 'LocationMetadata'
      },
      highlights: [
        {
          category: 'Top University',
          text: 'Top University, Anirudh Reddy: Bachelor’s Degree @ Stanford',
          __typename: 'PersonHighlight'
        },
        {
          category: 'Prior VC Backed Founder',
          text: 'Prior VC Backed Founder, Anirudh Reddy: Founder-CEO @ Einsite (Acquired by Monarch Tractor), Backed By: StartX (Stanford-StartX Fund), Alchemist Accelerator, Quiet Capital, Locus Ventures, KPCB Edge, Resolute Ventures, Firebolt Ventures',
          __typename: 'PersonHighlight'
        },
        {
          category: 'Prior Exit',
          text: 'Prior Exit, Anirudh Reddy: Founder-CEO @ Einsite (Acquired by Monarch Tractor), 1/2019-4/2022',
          __typename: 'PersonHighlight'
        },
        {
          category: 'Prior VC Backed Executive',
          text: 'Prior VC Backed Executive, Anirudh Reddy: VP, Digital @ Gayatri, ',
          __typename: 'PersonHighlight'
        },
        {
          category: 'Seasoned Executive',
          text: 'Seasoned Executive, Anirudh Reddy: VP, Digital @ Gayatri (1181 Employees) 6/2015-12/2018',
          __typename: 'PersonHighlight'
        }
      ],
      socials: {
        linkedin: {
          followerCount: null,
          url: 'https://linkedin.com/in/mar%c3%ada-del-pino-ravelo-rivero-b6727584',
          status: null,
          __typename: 'SocialMetadata'
        },
        twitter: null,
        __typename: 'Socials'
      },
      experience: [
        {
          company: {
            id: 9004442,
            entityUrn: 'urn:harmonic:company:9004442',
            logoUrl:
              'https://assets.harmonic.ai/company_a57a29fafe283acb2f5150ec25ea54b2779bfc0a2db3c5bc2b619023f0a5ccb8.png',
            name: 'CGI, S.A.',
            tags: [],
            location: {
              city: 'Barcelona',
              state: 'Catalunya',
              zip: '08005',
              country: 'Spain',
              __typename: 'LocationMetadata'
            },
            __typename: 'Company'
          },
          isCurrentPosition: true,
          startDate: null,
          endDate: null,
          location: null,
          title: 'CTO',
          department: null,
          __typename: 'ExperienceMetadata'
        }
      ],
      education: [
        {
          school: {
            name: 'Stanford University',
            websiteUrl: null,
            linkedinUrl: 'https://linkedin.com/school/stanford-university',
            logoUrl:
              'https://assets.harmonic.ai/school_7a709d91c95e4fed47f0bc2d0628c4028cd3cc7e8f5661d51ef9032334003622.png',
            entityUrn:
              'urn:harmonic:school:354afe5b-9cd6-40be-b4c3-e4d877e63854',
            __typename: 'School'
          },
          degree: 'Bachelor’s Degree',
          __typename: 'EducationMetadata'
        }
      ],
      contact: null,
      updates: [],
      emailEnrichmentStatus: {
        enrichedAt: null,
        __typename: 'EmailEnrichmentStatus'
      },
      userConnections: [
        {
          targetPerson: {
            id: 355,
            fullName: 'Anirudh Reddy',
            __typename: 'Person'
          },
          user: {
            email: 'matthiaslink@harmonic.ai',
            name: 'Matthias122',
            __typename: 'User'
          },
          __typename: 'UserConnection'
        }
      ],
      correspondenceSummary: {
        lastEmailAt: '2021-08-25T00:00:00Z',
        lastEmailContactPersonEmail: 'erlich@harmonic.ai',
        lastEmailContactPerson: null,
        lastMeetingAt: '2021-08-25T00:00:00Z',
        lastMeetingContactPersonEmail: 'erlich@harmonic.ai',
        lastMeetingContactPerson: null
      },
      __typename: 'Person'
    },
    {
      personUrn: 'urn:harmonic:person:3',
      id: 3,
      fullName: 'Franziska Schröttle',
      firstName: 'Franziska',
      lastName: 'Schröttle',
      about: null,
      watchlists: [],
      profilePictureUrl:
        'https://media-exp1.licdn.com/dms/image/C5103AQEVCfrcEpib2g/profile-displayphoto-shrink_200_200/0/1517234047172?e=1657756800&v=beta&t=LqrE6qBde1FRvOc-_A5b3WDNXrgDzr_vBeywijes9SE',
      linkedinHeadline: null,
      location: {
        location: 'Vaduz, Vaduz, Liechtenstein',
        state: null,
        city: null,
        country: null,
        __typename: 'LocationMetadata'
      },
      highlights: [],
      socials: {
        linkedin: {
          followerCount: null,
          url: 'https://linkedin.com/in/franziska-schr%c3%b6ttle-8a324759',
          status: null,
          __typename: 'SocialMetadata'
        },
        twitter: null,
        __typename: 'Socials'
      },
      experience: [
        {
          company: {
            id: 8424053,
            entityUrn: 'urn:harmonic:company:8424053',
            logoUrl:
              'https://assets.harmonic.ai/company_edf11b729413721952749ee288c31ef706220a5b3e1b49f9b3c4eca82e28ff50.png',
            name: 'swisspartners Group AG',
            tags: [],
            location: {
              city: 'Zürich',
              state: 'Zürich',
              zip: '8002',
              country: 'Switzerland',
              __typename: 'LocationMetadata'
            },
            __typename: 'Company'
          },
          isCurrentPosition: true,
          startDate: null,
          endDate: null,
          location: null,
          title: 'CEO',
          department: null,
          __typename: 'ExperienceMetadata'
        }
      ],
      education: [
        {
          school: {
            name: 'University of Michigan',
            websiteUrl: null,
            linkedinUrl: 'https://linkedin.com/school/university-of-michigan',
            logoUrl:
              'https://assets.harmonic.ai/school_9c4d44cbe5df091efd2ff9c0ab8ac8f3c1928ad4837670e1825b96d752729ba2.png',
            entityUrn:
              'urn:harmonic:school:ff814a40-5568-4f05-94b1-367cd41f1613',
            __typename: 'School'
          },
          degree: 'Computer Engineering',
          __typename: 'EducationMetadata'
        }
      ],
      contact: null,
      updates: [],
      emailEnrichmentStatus: null,
      userConnections: [],
      correspondenceSummary: {
        lastEmailAt: '2021-08-25T00:00:00Z',
        lastEmailContactPersonEmail: 'erlich@harmonic.ai',
        lastEmailContactPerson: null,
        lastMeetingAt: '2021-08-25T00:00:00Z',
        lastMeetingContactPersonEmail: 'erlich@harmonic.ai',
        lastMeetingContactPerson: null
      },
      __typename: 'Person'
    }
  ]
};
