import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const MinusIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M4.5 8H11.5"
        stroke="#1A1D23"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MinusIcon;
