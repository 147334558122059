import {
  ArrowRightIcon,
  CrossIcon,
  ListIcon,
  SearchIcon
} from 'assets/harmonic-icons';
import { IconProps } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_DASHBOARD_ROUTE } from 'utils/constants';
import { DashboardSidebarMenuButton } from '../../components/Dashboard/DashboardLayout/DashboardSidebarButton';

interface TopNavigationBarProps {
  /**
   * The icon to show in the button of the navbar.
   */
  iconButton?: 'cross' | 'back-arrow';
  /**
   * The icon to show before the title.
   */
  icon?: 'list' | 'magifying-glass';
  /**
   * The title segments of the page. The segments are the subpages with a '/' separator.
   */
  segments?: string[];
  /**
   * Legacy support: Simple name string (will be converted to a segment)
   */
  name?: string;
  /**
   * The route to navigate to when the user clicks the back button and there is no history.
   */
  defaultNavigationOnEmtpyHistory?: string;
}

const TopNavigationBar: React.FC<TopNavigationBarProps> = ({
  iconButton = 'cross',
  icon,
  segments,
  name,
  defaultNavigationOnEmtpyHistory = DEFAULT_DASHBOARD_ROUTE
}) => {
  const navigationLocation = useLocation();
  const navigate = useNavigate();
  const onGoBackRoute = () => {
    const historyState: Record<string, unknown> =
      navigationLocation.state as Record<string, unknown>;
    if (historyState && historyState.from && historyState.params) {
      const lastURL = `${historyState.from}?${historyState.params}`;
      navigate(lastURL);
      return;
    }
    if (history.length > 1) {
      navigate(-1);
    } else {
      navigate(defaultNavigationOnEmtpyHistory);
    }
  };

  function pickIconButton(iconButton: TopNavigationBarProps['iconButton']) {
    switch (iconButton) {
      case 'back-arrow':
        return (props: IconProps) => (
          <ArrowRightIcon
            {...props}
            applyCurrentColor={false}
            className="stroke-int-overlay-secondary-onEnabled rotate-180"
          />
        );
      case 'cross':
      default:
        return CrossIcon;
    }
  }

  function pickIcon(icon: TopNavigationBarProps['icon']) {
    switch (icon) {
      case 'magifying-glass':
        return (props: IconProps) => (
          <SearchIcon {...props} className="w-3 h-3" applyCurrentColor />
        );
      case 'list':
      default:
        return (props: IconProps) => (
          <ListIcon {...props} className="w-3 h-3" applyCurrentColor />
        );
    }
  }

  const PrefixIcon = icon ? pickIcon(icon) : null;
  const finalSegments = segments || (name ? [name] : undefined);

  return (
    <div
      className={classNames(
        'fixed left-0 right-0 px-p70 py-p50 h-[52px] bg-white z-10 flex items-center',
        'border-b border-solid border-border justify-between'
      )}
    >
      <div className="flex items-center gap-g60">
        <DashboardSidebarMenuButton />
        <IconButton
          data-testid="TopNavigationBar-Logo"
          icon={pickIconButton(iconButton)}
          onClick={onGoBackRoute}
          size="compact"
        />
        {finalSegments && (
          <div
            data-testid="TopNavigationbar-Title"
            className={classNames(
              'typography-label-small-default text-content-weak flex flex-row items-center gap-g30'
            )}
          >
            {PrefixIcon && <PrefixIcon />}
            {finalSegments.map((segment, index) => (
              <div key={index} className="flex flex-row gap-g30">
                <p>{segment}</p>
                {index < finalSegments.length - 1 && <p>/</p>}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigationBar;
