import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const ArrowTriangleTopIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="none"
    >
      <path
        vectorEffect="non-scaling-stroke"
        d="M3.4583 11.6435C3.07526 12.4081 3.61758 13.3332 4.48991 13.3332H12.8435C13.7159 13.3332 14.2582 12.4081 13.8751 11.6435L9.69833 3.30631C9.27099 2.45332 8.06246 2.45332 7.63512 3.30631L3.4583 11.6435Z"
        fill="#25A73F"
      />
    </svg>
  );
};

export default ArrowTriangleTopIcon;
