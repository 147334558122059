import {
  GetCompaniesByIdsQuery,
  GetCompaniesByIdsQueryVariables,
  GetWatchlistWithCompaniesQuery,
  GetWatchlistWithCompaniesQueryVariables
} from '__generated__/graphql';
import { compact } from 'lodash';
import { WATCHLIST_WITH_COMPANIES } from 'queries/getWatchlistWithCompanies';
import { getCompaniesByIds } from '../queries/getCompaniesByIds';
import { useRefetchWatchlistResults } from './useRefetchWatchlistResults';
import { useWatchlistCompanySearchVariables } from './useWatchlistCompanySearchVariables';

export const useRefetchCompanyWatchlistResults = () => {
  const variables = useWatchlistCompanySearchVariables();

  return useRefetchWatchlistResults<
    GetWatchlistWithCompaniesQuery,
    GetWatchlistWithCompaniesQueryVariables,
    GetCompaniesByIdsQuery,
    GetCompaniesByIdsQueryVariables
  >({
    query: WATCHLIST_WITH_COMPANIES,
    extendedQuery: getCompaniesByIds,
    variables,
    getDataPath: (data) => ({
      edges:
        data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.edges?.map(
          (edge) => ({
            node: { company: { id: edge.node.company.id } }
          })
        ) || [],
      pageInfo: {
        hasNextPage:
          data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.pageInfo
            ?.hasNextPage,
        endCursor:
          data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.pageInfo
            ?.endCursor
      }
    }),
    getIdsFromData: (data) =>
      compact(
        data.getCompanyWatchlistByIdOrUrn?.companyEntries.edges.map(
          (edge) => edge.node.company.id
        )
      ),
    getUrnsFromExtendedData: (data) =>
      data?.getCompaniesByIds?.map(
        (company) => `urn:harmonic:company:${company?.id}`
      ) ?? []
  });
};
