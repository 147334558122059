import { getFirebaseToken } from 'actions/fetchActions';
import axios from 'axios';
import {
  CustomerToInvestorAssociation,
  CustomerToInvestorAssociationHasura
} from 'interfaces/Investor';
import { UserReportedDataIssueInput } from 'interfaces/UserReportedDataIssue';
import { first } from 'lodash';
import { HASURA_API_REST_PATH, HASURA_API_URL } from './constants';
import { getIdFromUrn, urnFrom } from './urn';

const hasuraAxios = axios.create({
  baseURL: HASURA_API_URL
});

hasuraAxios.interceptors.request.use(async (config) => {
  const firebaseToken = await getFirebaseToken();
  config.headers.set('Authorization', 'Bearer ' + firebaseToken);
  return config;

  return config;
});

export const submitDataIssues = async (
  dataIssueInputs: UserReportedDataIssueInput[]
) => {
  const response = await hasuraAxios.post(
    HASURA_API_REST_PATH + '/userReportedDataIssue/batch',
    {
      objects: dataIssueInputs.map((input) => ({
        company_id: input.companyUrn
          ? getIdFromUrn(input.companyUrn)
          : undefined,
        person_id: input.personUrn ? getIdFromUrn(input.personUrn) : undefined,
        report_source: `CONSOLE_${input.reportSourceView}_${input.reportSourceSection}`,
        report_text: input.reportText,
        report_details: input.reportDetails
      }))
    }
  );
  return response.data;
};

export const submitDataIssue = async (input: UserReportedDataIssueInput) => {
  const response = await hasuraAxios.post(
    HASURA_API_REST_PATH + '/userReportedDataIssue',
    {
      companyId: input.companyUrn ? getIdFromUrn(input.companyUrn) : undefined,
      personId: input.personUrn ? getIdFromUrn(input.personUrn) : undefined,
      reportSource: `CONSOLE_${input.reportSourceView}_${input.reportSourceSection}`,
      reportText: input.reportText,
      reportDetails: input.reportDetails
    }
  );
  return response.data;
};

export const getRelevanceModelRefresh = async () => {
  const response = await hasuraAxios.get(
    HASURA_API_REST_PATH + '/userRelevanceScoreCluster/updatedAt'
  );
  return response.data;
};

export const getUserCompanyCapWaitlistEntry = async (companyId: number) => {
  const response = await hasuraAxios.get(
    HASURA_API_REST_PATH + '/getUserCompanyCapWaitlistEntry',
    {
      params: { companyId: companyId }
    }
  );
  return response.data;
};

export const addUserToCompanyCapWaitlist = async (companyId: number) => {
  const response = await hasuraAxios.post(
    HASURA_API_REST_PATH + '/addUserToCompanyCapWaitlist',
    {
      companyId: companyId
    }
  );
  return response.data;
};

export const getCompanyLineageRelationshipsByParent = async (
  companyId: number
) => {
  const response = await hasuraAxios.get(
    HASURA_API_REST_PATH +
      '/companyRelationshipsByParent?parentCompanyId=' +
      companyId
  );

  return response?.data?.company_relationships;
};

export const getCompanyLineageRelationshipsByChild = async (
  companyId: number
) => {
  const response = await hasuraAxios.get(
    HASURA_API_REST_PATH +
      '/companyRelationshipsByChild?childCompanyId=' +
      companyId
  );

  return response?.data?.company_relationships;
};

export const getCustomerInvestorAssociation = async (): Promise<
  CustomerToInvestorAssociation | undefined
> => {
  const response = await hasuraAxios.get(
    HASURA_API_REST_PATH + '/customerInvestorAssociation'
  );

  const data: CustomerToInvestorAssociationHasura | undefined = first(
    response.data?.customer_to_investor_associations
  );
  if (!data) return;

  return {
    customerUrn: urnFrom('customer', data.customer_id),
    investorUrn: urnFrom('investor', data.investor_id),
    lastValidatedProfileInfoDate: data.last_validated_profile_info_date
      ? new Date(data.last_validated_profile_info_date)
      : null
  };
};

export const updateCustomerInvestorAssociationDate = async (
  lastValidatedProfileInfoDate: Date
) => {
  const customerInvestorAssociation = await getCustomerInvestorAssociation();
  if (!customerInvestorAssociation) return;
  const customerUrn = customerInvestorAssociation.customerUrn;

  const response = await hasuraAxios.put(
    HASURA_API_REST_PATH + '/customerInvestorAssociation',
    {
      customer_id: getIdFromUrn(customerUrn),
      last_validated_profile_info_date:
        lastValidatedProfileInfoDate.toISOString()
    }
  );
  return response.data;
};
