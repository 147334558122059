const Typography = require('./primitives');
module.exports = {
  title: {
    extraLarge: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['540'],
      fontSize: Typography.fontSize['40'],
      lineHeight: Typography.lineHeight.huge,
      textCase: Typography.textCase.none,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '-0.4px'
    },
    large: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['540'],
      lineHeight: Typography.lineHeight.extraLarge,
      fontSize: Typography.fontSize['32'],
      textCase: Typography.textCase.none,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '-0.8px'
    },
    medium: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['540'],
      lineHeight: Typography.lineHeight.large,
      fontSize: Typography.fontSize['24'],
      textCase: Typography.textCase.none,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '-0.4px'
    },
    small: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['540'],
      lineHeight: Typography.lineHeight.medium,
      fontSize: Typography.fontSize['20'],
      textCase: Typography.textCase.none,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '-0.2px'
    },
    extraSmall: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['540'],
      lineHeight: Typography.lineHeight.small,
      fontSize: Typography.fontSize['16'],
      textCase: Typography.textCase.none,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '-0.16px'
    },
    kicker: {
      fontFamily: Typography.fontFamily,
      fontWeight: Typography.fontWeight['480'],
      lineHeight: Typography.lineHeight.small,
      fontSize: Typography.fontSize['10'],
      textCase: Typography.textCase.uppercase,
      textDecoration: Typography.textDecoration.none,
      letterSpacing: '0.1px'
    }
  },
  paragraph: {
    large: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['460'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.08px'
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.08px'
      },
      link: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.08px',
        textDecoration: Typography.textDecoration.underline
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.08px',
        textDecoration: Typography.textDecoration.lineThrough
      }
    },
    default: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.compact,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.1px'
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.compact,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.1px'
      },
      link: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.compact,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.1px',
        textDecoration: Typography.textDecoration.underline
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.compact,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.1px',
        textDecoration: Typography.textDecoration.lineThrough
      }
    },
    small: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.06px'
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.06px'
      },
      link: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.06px',
        textDecoration: Typography.textDecoration.underline
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.06px',
        textDecoration: Typography.textDecoration.lineThrough
      }
    },
    extraSmall: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['10'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.05px'
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['10'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.05px'
      },
      link: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['10'],
        textCase: Typography.textCase.none,
        textDecoration: Typography.textDecoration.underline,
        letterSpacing: '-0.05px'
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.default,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: '-0.05px',
        textDecoration: Typography.textDecoration.lineThrough
      }
    }
  },
  label: {
    large: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['460'],
        lineHeight: Typography.lineHeight.medium,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.medium,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['460'],
        lineHeight: Typography.lineHeight.medium,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        textDecoration: Typography.textDecoration.lineThrough,
        letterSpacing: Typography.letterSpacing['0']
      },
      number: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['460'],
        lineHeight: Typography.lineHeight.medium,
        fontSize: Typography.fontSize['16'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      }
    },
    default: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.regular,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.regular,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.regular,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        textDecoration: Typography.textDecoration.lineThrough,
        letterSpacing: Typography.letterSpacing['0']
      },
      number: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.regular,
        fontSize: Typography.fontSize['14'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      }
    },
    small: {
      default: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.small,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strong: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['520'],
        lineHeight: Typography.lineHeight.small,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      },
      strikethrough: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.small,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        textDecoration: Typography.textDecoration.lineThrough,
        letterSpacing: Typography.letterSpacing['0']
      },
      number: {
        fontFamily: Typography.fontFamily,
        fontWeight: Typography.fontWeight['480'],
        lineHeight: Typography.lineHeight.small,
        fontSize: Typography.fontSize['12'],
        textCase: Typography.textCase.none,
        letterSpacing: Typography.letterSpacing['0']
      }
    }
  }
};