import classNames from 'classnames';
import { HarmonicIcon } from './type';

const MultiSelectIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M3.833 3a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667m0 6.333a1.833 1.833 0 1 0 0 3.667 1.833 1.833 0 0 0 0-3.667M7.4 11.2a.6.6 0 0 1 .6-.6h5.6a.6.6 0 1 1 0 1.2H8a.6.6 0 0 1-.6-.6m.6-7a.6.6 0 0 0 0 1.2h5.6a.6.6 0 1 0 0-1.2z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default MultiSelectIcon;
