import { Popover } from '@headlessui/react';
import { ChevronDown } from 'assets/harmonic-icons';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

interface SingleSelectProps {
  selected?: string;
  open?: boolean;
  filterable?: boolean;
  filterTerm?: string;
  setFilterTerm?: (term: string) => void;
  getLabelFromValue?: (value?: string) => string;
  onArrowDownPress: () => void;
  onArrowUpPress: () => void;
  onEnterPress: () => void;
  dataTestId?: string;
  icon?: HarmonicIcon;
  placeholder?: string;
  disabled?: boolean;
  minHeight?: number;
  ref?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  borderOverrideClasses?: string;
  size?: 'small';
}

export const SingleSelect = React.forwardRef<
  HTMLButtonElement,
  SingleSelectProps
>(
  (
    {
      selected,
      open,
      filterable,
      filterTerm,
      setFilterTerm,
      getLabelFromValue,
      onArrowDownPress,
      onArrowUpPress,
      onEnterPress,
      dataTestId,
      icon,
      placeholder,
      disabled,
      minHeight,
      borderOverrideClasses,
      size
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const Icon = icon;

    useEffect(() => {
      if (filterTerm && !open) {
        inputRef.current?.click();
        setTimeout(() => {
          inputRef.current?.setSelectionRange(
            filterTerm.length,
            filterTerm.length
          );
        }, 0);
      }
    }, [filterTerm]);
    return (
      <Popover.Button
        onClick={(e) => {
          const input = inputRef.current;
          if (input && filterable) {
            input.focus();
            setTimeout(() => {
              input.select();
            }, 0);
          }
        }}
        data-open={open}
        as={'button'}
        data-testid={dataTestId}
        disabled={disabled}
        ref={ref}
        className={classNames(
          // Base classes
          'w-full flex border-[1.5px] rounded-br30 bg-surface-default',
          'px-p50 py-p40 typography-label-default-default text-input-value-default hover:text-input-value-hover cursor-pointer justify-between',
          !borderOverrideClasses &&
            classNames(
              'border-int-outline-secondary-enabled',
              // Hover state
              'hover:border-int-outline-secondary-hover hover:bg-int-overlay-secondary-hover',
              // Active state
              'active:border-int-outline-secondary-pressed',
              // Focus state
              'focus-visible:outline-none focus-visible:border-int-outline-secondary-selected-enabled',
              'data-[open=true]:outline-none data-[open=true]:border-int-outline-secondary-selected-enabled',
              // Disabled state
              'disabled:border-int-outline-secondary-disabled'
            ),
          // Hover state
          'hover:text-input-value-hover',
          // Active state
          'active:border-int-outline-secondary-pressed active:bg-int-overlay-secondary-pressed active:text-input-value-pressed',
          // Focus state
          'focus-visible:bg-int-overlay-secondary-enabled',
          'data-[open=true]:bg-int-overlay-secondary-enabled',
          // Disabled state
          'disabled:bg-int-overlay-secondary-disabled disabled:text-input-value-disabled',
          { 'items-center': !minHeight, 'py-50': minHeight },
          borderOverrideClasses,
          size === 'small' && 'p-p20'
        )}
        style={{ minHeight }}
      >
        <div className="flex items-center justify-start">
          {Icon && <Icon className="w-4 h-4 text-content-weak mr-p40" />}
          {filterable && (
            <input
              className={classNames(
                'bg-inherit text-input-value-default typography-label-default-default cursor-pointer',
                size !== 'small' && 'w-full'
              )}
              ref={inputRef}
              value={filterTerm ?? getLabelFromValue?.(selected)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onKeyDownCapture={(e) => {
                if (e.key === 'ArrowDown') {
                  onArrowDownPress();
                }

                if (e.key === 'ArrowUp') {
                  onArrowUpPress();
                }
                if (e.key === 'Enter') {
                  onEnterPress();
                  return;
                }
              }}
              onChange={(e) => {
                setFilterTerm?.(e.target.value);
              }}
              placeholder={placeholder}
            ></input>
          )}
          {!filterable && (
            <p className="flex flex-1">
              {getLabelFromValue?.(selected) ?? selected}
            </p>
          )}
        </div>
        <ChevronDown width={16} />
      </Popover.Button>
    );
  }
);
