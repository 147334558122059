import classNames from 'classnames';
import { HarmonicIcon } from './type';

const EmailIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <g fill="#7C8598">
        <path
          d="M14.667 4.935V3.833c0-.644-.523-1.166-1.167-1.166h-11c-.644 0-1.167.522-1.167 1.166v1.102A9.47 9.47 0 0 0 8 7.667a9.47 9.47 0 0 0 6.667-2.732"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M14.667 6.28A10.46 10.46 0 0 1 8 8.666a10.46 10.46 0 0 1-6.667-2.388v5.888c0 .644.523 1.166 1.167 1.166h11c.644 0 1.167-.522 1.167-1.166z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
};

export default EmailIcon;
