import { useQuery } from '@apollo/client';
import defaultCompanyLogo from 'assets/company-profile/default-logo.svg';
import stealthLogo from 'assets/company-profile/stealth-logo.svg';
import classNames from 'classnames';
import AddCompanyToWatchlistButton from 'components/common/CompanyActions/AddCompanyToWatchlistButton';
import CompanyNotesButton from 'components/common/CompanyActions/CompanyNotesButton';
import LikeCompanyButton from 'components/common/CompanyActions/LikeCompanyButton';
import FoundingDate from 'components/common/FoundingDate';
import Location from 'components/common/Location';
import PersonUserConnections from 'components/common/PersonUserConnections';
import RelevanceScore from 'components/common/RelevanceScore';
import ReportDataIssue from 'components/common/ReportDataIssue';
import Socials from 'components/common/Social/Socials';
import {
  ReportSourceSection,
  ReportSourceView
} from 'interfaces/UserReportedDataIssue';
import { getCompanyProfileHeader } from 'queries/getCompanyProfileHeader';
import { SPLITS } from 'utils/constants';
import { isSafeHostedAsset } from 'utils/utilities';
import { MAX_WIDTH_CLASS, OnTabChangeType } from '../CompanyV2';

import UserConnectionsTooltip from 'components/common/UserConnectionsTooltip';
import TopNavigationBar from 'harmonic-components/TopNavigationBar/TopNavigationBar';
import useFlags from 'hooks/useFlags';
import { SearchValueToDisplayNameMap } from 'interfaces/SearchModel/Search';
import { compact, get } from 'lodash';
import getCompanyUserConnections from 'queries/getCompanyUserConnections';

import {
  GetCompanyProfileHeaderQuery,
  GetCompanyProfileHeaderQueryVariables,
  GetCompanyUserConnectionsV2_UserFragment,
  GetCompanyUserConnectionsV2Query,
  GetCompanyUserConnectionsV2QueryVariables
} from '__generated__/graphql';
import { ReactComponent as ChevronLeftIcon } from 'assets/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import { Badge } from 'common/components';
import AddCompanyToAffinityButton from 'components/common/CompanyActions/AddCompanyToAffinityButton';
import InvestorViewToggle from 'components/common/InvestorViewToggle';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { EntityType } from 'hooks/useDashboardLocation';
import { useEffect, useRef, useState } from 'react';
import useStore from 'stores/zustandStore';
import { getFundingStageBadgeColor } from 'utils/funding';
import { isHarmonicUser, urnFrom } from 'utils/urn';
import CompanyAbout from '../CompanyOverview/CompanyAbout';

export enum CompanyNavbarOptions {
  OVERVIEW = 'OVERVIEW',
  TEAM = 'TEAM',
  FINANCING = 'FINANCING',
  SIMILAR_COMPANIES = 'SIMILAR_COMPANIES',
  TRACTION_METRICS = 'TRACTION_METRICS',
  CONTACT = 'CONTACT',
  CONNECTIONS = 'CONNECTIONS',
  LINEAGE = 'LINEAGE'
}

const nonStealthNavbarOptions = [
  {
    value: CompanyNavbarOptions.OVERVIEW,
    label: 'Overview'
  },
  {
    value: CompanyNavbarOptions.TEAM,
    label: 'Team '
  },

  {
    value: CompanyNavbarOptions.FINANCING,
    label: 'Financing'
  },
  {
    value: CompanyNavbarOptions.TRACTION_METRICS,
    label: 'Traction Metrics'
  },
  {
    value: CompanyNavbarOptions.CONTACT,
    label: 'Contact'
  },
  {
    value: CompanyNavbarOptions.LINEAGE,
    label: 'Lineage'
  },
  {
    value: CompanyNavbarOptions.SIMILAR_COMPANIES,
    label: 'Similar Companies',
    inBeta: true
  },
  {
    value: CompanyNavbarOptions.CONNECTIONS,
    label: 'Network',
    inBeta: true
  }
];

export const stealthNavbarOptions = [
  {
    value: CompanyNavbarOptions.OVERVIEW,
    label: 'Overview'
  },
  {
    value: CompanyNavbarOptions.CONTACT,
    label: 'Contact'
  },
  {
    value: CompanyNavbarOptions.CONNECTIONS,
    label: 'Network',
    inBeta: true
  }
];

interface CompanyHeaderProps {
  companyId: number;
  selectedTab: string;
  isStealthCompany?: boolean;
  onTabChange: OnTabChangeType;
  isSticky?: boolean;
}
const CompanyHeader: React.FC<CompanyHeaderProps> = ({
  companyId,
  isSticky,
  isStealthCompany,
  selectedTab,
  onTabChange
}) => {
  // Scroll buttons for the mobile navbar
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      checkScrollButtons();
      scrollContainer.addEventListener('scroll', checkScrollButtons);
      window.addEventListener('resize', checkScrollButtons);

      return () => {
        scrollContainer.removeEventListener('scroll', checkScrollButtons);
        window.removeEventListener('resize', checkScrollButtons);
      };
    }
  }, []);

  const { enabled: enableCompanyEngagementTab } = useFlags(
    SPLITS.enableCompanyEngagementTab
  );
  const { enabled: enableCompanyLineageTab } = useFlags(
    SPLITS.showLineageOnCompanyPage
  );
  const { enabled: enablePushToAffinity } = useFlags(
    SPLITS.enablePushToAffinity
  );
  const previousPageTitle = useStore((state) => state.previousPageTitle);

  const { data } = useQuery<
    GetCompanyProfileHeaderQuery,
    GetCompanyProfileHeaderQueryVariables
  >(getCompanyProfileHeader, {
    variables: {
      id: companyId
    },
    fetchPolicy: 'cache-only'
  });

  const customerUrn = useStore((state) => state.customerUrn);

  const { data: userConnectionsData } = useQuery<
    GetCompanyUserConnectionsV2Query,
    GetCompanyUserConnectionsV2QueryVariables
  >(getCompanyUserConnections, {
    variables: { id: companyId },
    fetchPolicy: 'cache-only'
  });
  const userConnections: GetCompanyUserConnectionsV2_UserFragment[] =
    compact(
      compact(userConnectionsData?.getCompanyById?.userConnections).map(
        (connection) => connection.user
      )
    ) ?? [];

  if (!data?.getCompanyById) return null;
  const logo = data.getCompanyById.logoUrl;
  const companyName = data.getCompanyById.name;
  const location = data.getCompanyById.location;
  const foundingDate = data.getCompanyById.foundingDate?.date;
  const companyStage = data.getCompanyById.stage;
  const convertedFundingStage =
    (companyStage && get(SearchValueToDisplayNameMap, companyStage)) ?? '';
  const website = data.getCompanyById.website;
  const linkedin = data.getCompanyById.socials?.linkedin;
  const crunchbase = data.getCompanyById.socials?.crunchbase;
  const pitchbook = data.getCompanyById.socials?.pitchbook;
  const angellist = data.getCompanyById.socials?.angellist;
  const twitter = data.getCompanyById.socials?.twitter;
  const facebook = data.getCompanyById.socials?.facebook;
  const relevanceScore = data.getCompanyById.relevanceScore;
  const instagram = data.getCompanyById.socials?.instagram;
  const investorUrn = data.getCompanyById.investorUrn;

  const fundingInspectorUrl = `https://harmonic.retool.com/app/fundinginspector#companyId=${companyId}`;

  const navbarOptions = isStealthCompany
    ? stealthNavbarOptions
    : nonStealthNavbarOptions;

  const currentOption = navbarOptions.find(
    (option) => option.value === selectedTab
  )?.value as CompanyNavbarOptions;

  const companyLogoUrl = isStealthCompany
    ? stealthLogo
    : isSafeHostedAsset(logo ?? '')
    ? logo
    : defaultCompanyLogo;

  let filteredNavbarOptions = navbarOptions;
  if (!enableCompanyEngagementTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.CONNECTIONS
    );
  }
  if (enableCompanyEngagementTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.CONTACT
    );
  }
  if (!enableCompanyLineageTab) {
    filteredNavbarOptions = filteredNavbarOptions.filter(
      (option) => option.value !== CompanyNavbarOptions.LINEAGE
    );
  }

  const navigationTitle = previousPageTitle
    ? `${previousPageTitle} / ${companyName}  /`
    : companyName;
  const titleComponent = (
    <div className="flex gap-g40 items-center">
      <Avatar src={companyLogoUrl ?? ''} alt="Company Logo" />
      <p className={classNames('typography-title-medium text-content-title')}>
        {companyName}
      </p>
      {investorUrn && (
        <InvestorViewToggle
          baseEntityUrn={urnFrom(EntityType.COMPANY, companyId)}
          investorUrn={investorUrn}
        />
      )}
    </div>
  );
  const mainCompanyActions = (
    <div className="flex flex-row items-center gap-g20 lg:gap-g40">
      <AddCompanyToWatchlistButton companyId={companyId} />
      {enablePushToAffinity && (
        <AddCompanyToAffinityButton
          companyId={companyId}
          isDisabled={isStealthCompany ?? false}
          tooltipText={
            isStealthCompany
              ? 'Only companies with a website URL can be added'
              : undefined
          }
        />
      )}
      <LikeCompanyButton companyId={companyId} />

      <CompanyNotesButton companyId={companyId} />
      <ReportDataIssue
        showCircularBorder
        reportParams={{
          companyUrn: `urn:company:harmonic:${companyId}`,
          reportSourceView: ReportSourceView.COMPANY,
          reportSourceSection: ReportSourceSection[currentOption]
        }}
      />
    </div>
  );

  const relevanceScoreComponent = (
    <div className="flex flex-row items-center gap-x-g20 lg:gap-x-g40">
      {relevanceScore && <RelevanceScore score={relevanceScore} />}
      {convertedFundingStage && (
        <Badge
          label={convertedFundingStage}
          color={getFundingStageBadgeColor(convertedFundingStage)}
          size="large"
          intensity="subtle"
          dataTestId="CompanyHeader-FundingStage"
        />
      )}
    </div>
  );

  const socialMediaComponent = (
    <Socials
      id={companyId}
      type="company"
      websiteUrl={website?.url}
      linkedinUrl={linkedin?.url}
      crunchbaseUrl={crunchbase?.url}
      pitchbookUrl={pitchbook?.url}
      angellistUrl={angellist?.url}
      twitterUrl={twitter?.url}
      facebookUrl={facebook?.url}
      instagramUrl={instagram?.url}
      //only show funding inspector url if the user is a harmonic user
      {...(isHarmonicUser(customerUrn) && {
        fundingInspectorUrl: fundingInspectorUrl
      })}
    />
  );

  const locationAndFoundingDate = (
    <div className="flex items-center">
      <Location
        city={location?.city}
        state={location?.state}
        country={location?.country}
        fontSize="small"
      />
      {foundingDate && (
        <div className="ml-p50">
          <FoundingDate date={foundingDate} />
        </div>
      )}
    </div>
  );

  const linkedInNetwork = userConnectionsData && userConnections.length > 0 && (
    <div className="flex items-center justify-between gap-g20">
      <button onClick={() => onTabChange(CompanyNavbarOptions.CONNECTIONS)}>
        <PersonUserConnections userConnections={userConnections} />
      </button>
      <div>
        <UserConnectionsTooltip />
      </div>
    </div>
  );

  const companyAboutComponent = <CompanyAbout companyId={companyId} />;

  const checkScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const desktopNavBar = (
    <Tabs
      tabs={filteredNavbarOptions.map((navbar) => ({
        ...navbar,
        variation: 'label'
      }))}
      selectedTabValue={selectedTab}
      onTabChange={(newTabValue) =>
        onTabChange(newTabValue as CompanyNavbarOptions)
      }
    />
  );

  const stickyNavBar = (
    <div className="flex flex-col gap-g50 w-full h-fit bg-transparent shadow-search px-p70 py-p50">
      <div className="flex justify-between items-center">
        <div className="flex gap-g50 items-center">
          <Avatar src={companyLogoUrl ?? ''} alt="Company Logo" size="large" />
          <p className={classNames('typography-label text-content-title')}>
            {companyName}
          </p>
        </div>
        {mainCompanyActions}
      </div>
      {desktopNavBar}
    </div>
  );

  const mobileNavBar = (
    <div className="relative">
      {showLeftScroll && (
        <button
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-r from-white to-transparent pl-p20 pr-p40 py-p20"
        >
          <ChevronLeftIcon className="w-4 h-4 text-content-primary" />
        </button>
      )}
      {showRightScroll && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-gradient-to-l from-white to-transparent pr-p20 pl-p40 py-p20"
        >
          <ChevronRightIcon className="w-4 h-4 text-content-primary" />
        </button>
      )}
      <div
        ref={scrollContainerRef}
        className="overflow-x-auto scrollbar-hide relative"
      >
        <div className="flex w-full min-w-max">
          <Tabs
            tabs={filteredNavbarOptions.map((navbar) => ({
              ...navbar,
              variation: 'label'
            }))}
            selectedTabValue={selectedTab}
            onTabChange={(newTabValue) =>
              onTabChange(newTabValue as CompanyNavbarOptions)
            }
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <TopNavigationBar name={navigationTitle as string} />
      <div
        data-testid="CompanyHeader"
        className={`pt-[53px] ${MAX_WIDTH_CLASS} relative`}
      >
        <div className="flex flex-col pb-p60 gap-g50 pt-p80">
          <div className="flex flex-col gap-g50 lg:flex-row lg:items-start lg:justify-between">
            {titleComponent}
            {mainCompanyActions}
          </div>
          <div>{relevanceScoreComponent}</div>

          <div className="flex gap-x-g50 gap-y-g50 flex-col">
            <div className="flex flex-col gap-g50 lg:flex-row">
              {socialMediaComponent}
              {locationAndFoundingDate}
            </div>
            {linkedInNetwork}
          </div>
          {companyAboutComponent}
        </div>
        <div className="lg:hidden">{mobileNavBar}</div>
        <div
          className={classNames('hidden', {
            'lg:flex w-full': !isSticky
          })}
        >
          {desktopNavBar}
        </div>
        {isSticky && (
          <div className="hidden lg:block sticky z-10 top-[53px]">
            {/* 53px because that's the height of the top nav bar */}
            {stickyNavBar}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyHeader;
