import {
  ApolloCache,
  ApolloClient,
  useApolloClient,
  useMutation,
  useQuery
} from '@apollo/client';
import {
  ClearCompanyNetNewMutation,
  ClearCompanyNetNewMutationVariables,
  ClearNetNewBySavedSearchIdOrUrnMutation,
  ClearNetNewBySavedSearchIdOrUrnMutationVariables,
  ClearPersonNetNewMutation,
  ClearPersonNetNewMutationVariables,
  GetNetNewCompaniesQueryVariables,
  GetNetNewCountsQuery,
  GetNetNewSavedSearchCountQuery,
  SavedSearchResultsConnection,
  SearchType
} from '__generated__/graphql';
import { getIdFromUrn } from 'common/utils/urn';
import { GET_NET_NEW_SAVED_SEARCH_COUNT } from 'queries/getSavedSearch';
import {
  CLEAR_COMPANY_NET_NEW,
  CLEAR_NET_NEW_FOR_SEARCH,
  CLEAR_PERSON_NET_NEW,
  GET_NET_NEW_COUNTS
} from 'queries/netNew';
import { useMemo } from 'react';

export interface SearchMap {
  count: number;
  name: string;
  type: SearchType;
}

const updateCacheAfterClear = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingCache: ApolloCache<any>,
  savedSearchUrn: string,
  ids: number[]
) => {
  // Update the results in the SavedSearch
  existingCache.modify({
    id: client.cache.identify({
      __typename: 'SavedSearch',
      id: getIdFromUrn(savedSearchUrn)
    }),
    fields: {
      results(current) {
        const currentEdges: SavedSearchResultsConnection['edges'] =
          current.edges;

        const updatedEdges = currentEdges.map((edge) =>
          ids.includes(Number(edge.node.netNewId))
            ? {
                ...edge,
                node: {
                  ...edge.node,
                  netNewId: null
                }
              }
            : edge
        );

        return { ...current, edges: updatedEdges };
      }
    }
  });

  // Update the NetNewCount
  existingCache.modify({
    id: client.cache.identify({
      __typename: 'NetNewCount',
      savedSearch: {
        entityUrn: savedSearchUrn
      }
    }),
    fields: {
      count(current) {
        return current - ids.length;
      }
    },
    broadcast: false
  });

  const currentCachedQuery = client.readQuery({
    query: GET_NET_NEW_SAVED_SEARCH_COUNT,
    variables: {
      idOrUrn: getIdFromUrn(savedSearchUrn)
    }
  }) as GetNetNewSavedSearchCountQuery;

  const currentCount = currentCachedQuery?.getSavedSearch?.results?.totalCount;

  currentCount &&
    client.writeQuery({
      query: GET_NET_NEW_SAVED_SEARCH_COUNT,
      data: {
        getSavedSearch: {
          __typename: 'SavedSearch',
          id: getIdFromUrn(savedSearchUrn),
          results: {
            __typename: 'SavedSearchResultsConnection',
            totalCount: currentCount - ids.length
          }
        }
      },
      variables: {
        idOrUrn: getIdFromUrn(savedSearchUrn)
      }
    });
};

const useNetNewCounts = () => {
  const client = useApolloClient();
  const { data, loading: searchMapLoading } = useQuery<
    GetNetNewCountsQuery,
    GetNetNewCompaniesQueryVariables
  >(GET_NET_NEW_COUNTS);

  const [clearNetNewForSearch, { loading: clearSearchLoading }] = useMutation<
    ClearNetNewBySavedSearchIdOrUrnMutation,
    ClearNetNewBySavedSearchIdOrUrnMutationVariables
  >(CLEAR_NET_NEW_FOR_SEARCH);

  const [clearCompanyNetNew, { loading: companyLoading }] = useMutation<
    ClearCompanyNetNewMutation,
    ClearCompanyNetNewMutationVariables
  >(CLEAR_COMPANY_NET_NEW, {
    optimisticResponse: {
      clearCompanyNetNew: null
    }
  });

  const [clearPersonNetNew, { loading: personLoading }] = useMutation<
    ClearPersonNetNewMutation,
    ClearPersonNetNewMutationVariables
  >(CLEAR_PERSON_NET_NEW, {
    optimisticResponse: {
      clearPersonNetNew: null
    }
  });

  const onClearNetNewForSearch = (searchUrn: string) => {
    clearNetNewForSearch({
      variables: { idOrUrn: searchUrn },
      update: (existingCache) => {
        existingCache.modify({
          id: client.cache.identify({
            __typename: 'NetNewCount',
            savedSearch: {
              entityUrn: searchUrn
            }
          }),
          fields: {
            count() {
              return 0;
            }
          },
          broadcast: false
        });
      }
    });
  };

  const onClearNetNewByIds = ({
    ids,
    savedSearchUrn,
    type
  }: {
    ids: number[];
    savedSearchUrn: string;
    type: SearchType;
  }) => {
    if (!savedSearchUrn) {
      return;
    }

    const clearNetNew =
      type === SearchType.COMPANIES_LIST
        ? clearCompanyNetNew
        : clearPersonNetNew;

    clearNetNew({
      variables: { netNewIds: ids },
      update: (existingCache) =>
        updateCacheAfterClear(client, existingCache, savedSearchUrn, ids)
    });
  };

  const searchMap = useMemo(() => {
    return new Map(
      data?.getNetNewCounts
        .filter((data) => data.count > 0)
        .map(({ count, savedSearch }) => [
          savedSearch.entityUrn,
          { count: count, ...savedSearch }
        ])
    );
  }, [data]);

  return {
    getNetNewCountForSearch: (searchUrn: string) =>
      searchMap.get(searchUrn)?.count || 0,
    hasNetNew: (searchUrn?: string) => {
      if (!searchUrn || searchMap.size == 0) {
        return false;
      }

      const count = searchMap.get(searchUrn)?.count || 0;
      return count > 0;
    },
    onClearNetNewForSearch,
    onClearNetNewByIds,
    loading: clearSearchLoading || companyLoading || personLoading,
    searchMapLoading,
    searchMap
  };
};

export default useNetNewCounts;
