import harmonicLogo from 'assets/harmonic-icons/harmonic-gradient-logo.svg';
import Button from 'harmonic-components/Button/Button';
import * as React from 'react';
import {
  NotFoundMode,
  SITE_STATUS_URL,
  SUPPORT_EMAIL_ADDRESS
} from 'utils/constants';
import { useAuthState } from '../../hooks/useAppState';
import NoConnectionsPage from './NoConnectionsPage';

type INotFoundProps = {
  mode?: NotFoundMode;
  showBack?: boolean;
};

const SupportEmail = () => (
  <a className="underline" href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
    {SUPPORT_EMAIL_ADDRESS}
  </a>
);

const StatusUrl = () => (
  <a href={SITE_STATUS_URL} className="underline">
    {SITE_STATUS_URL}
  </a>
);

const NotFound: React.FC<INotFoundProps> = ({
  mode = NotFoundMode.DEFAULT
}) => {
  const authState = useAuthState();

  let message, title;

  if (mode === NotFoundMode.SAVED_SEARCH_FORBIDDEN) {
    title = 'Oops... looks like you don’t have permission!';
    // prettier-ignore
    message = (
      <>
        This saved search is not accessible to {
        authState.user?.email || 'your user'
        }. Please reach out to <SupportEmail /> to request access.
      </>
    );
  }

  if (mode === NotFoundMode.NO_RESULTS_SAVED_SEARCH) {
    title = 'No results for your query';
    message = <>Please refine your filters and try again.</>;
  }
  if (mode === NotFoundMode.NO_RESULTS_WATCHLIST) {
    title = 'No companies are in this list';
    message = <>Add companies from search results or a company page.</>;
  }
  if (mode === NotFoundMode.NO_RESULTS_PEOPLE_WATCHLIST) {
    title = 'No people are in this list';
    message = <>Add people from search results or a person page.</>;
  }
  if (mode === NotFoundMode.NO_RESULTS_CONNECTIONS) {
    return <NoConnectionsPage />;
  }
  if (mode === NotFoundMode.NO_CUSTOMER_INVESTOR_FOUND) {
    title = 'No linked investor found';
    message = <>Reach out to support to access investor data.</>;
  }

  if (mode === NotFoundMode.WATCHLIST_FORBIDDEN) {
    title = 'Oops... looks like you don’t have permission!';
    message = (
      <>
        This watchlist is not accessible to <br />
        {authState.user?.email || 'your user'}. Please reach out to <br />
        <SupportEmail /> to request access.
      </>
    );
  }

  if (mode === NotFoundMode.SAVED_SEARCH_ERROR) {
    title = 'Oops! An unexpected error occurred.';
    message = (
      <>
        Please try again in a few minutes. If this problem persists, <br />{' '}
        reach out to support@harmonic.ai
      </>
    );
  }

  if (mode === NotFoundMode.WATCHLIST_NOT_EXIST) {
    title = 'The page you are viewing is no longer available';
  }

  if (mode === NotFoundMode.DEFAULT) {
    // prettier-ignore
    message = (
      <>
        Please check if there is a typo in the URL, or reach out to <SupportEmail /> if you expected something to be here.
      </>
    );
    // prettier-ignore
    title = (
      <>
        We can&apos;t find what you&apos;re looking for! Please check out <StatusUrl /> for potential known outage
      </>
    );
  }

  const showContactSupport =
    mode === NotFoundMode.SAVED_SEARCH_ERROR ||
    mode === NotFoundMode.WATCHLIST_FORBIDDEN;

  const showHomeButton = mode === NotFoundMode.WATCHLIST_NOT_EXIST;

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex flex-col items-center gap-g60">
        <img src={harmonicLogo} style={{ width: 60, height: 60 }} />
        <div className="flex flex-col items-center gap-g40 self-stretch">
          <p className="text-content-strong text-center typography-title-extraSmall">
            {title}
          </p>
          <p className="text-content-weak typography-paragraph-default-default">
            {message}
          </p>
        </div>
        <div className="flex gap-g30">
          {showContactSupport && (
            <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
              <Button
                type="secondary"
                emphasis="high"
                label="Contact support"
                dataTestId="ContactSupportBtn"
                onClick={() => null}
              />
            </a>
          )}
          {showHomeButton && (
            <Button
              type="secondary"
              emphasis="medium"
              label="Home"
              dataTestId="HomeBtn"
              onClick={() => (window.location.href = '/')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
