import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const CustomFieldIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
      fill="none"
    >
      <g opacity="0.6">
        <path
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1665 5.99996C4.52299 5.99996 5.99984 4.52311 5.99984 1.16663C5.99984 4.52311 7.47669 5.99996 10.8332 5.99996C7.47669 5.99996 5.99984 7.47681 5.99984 10.8333C5.99984 7.47681 4.52299 5.99996 1.1665 5.99996Z"
          fill="#913AA6"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49984 11.6666C10.6989 11.6666 11.6665 10.699 11.6665 8.49996C11.6665 10.699 12.6341 11.6666 14.8332 11.6666C12.6341 11.6666 11.6665 12.6342 11.6665 14.8333C11.6665 12.6342 10.6989 11.6666 8.49984 11.6666Z"
          fill="#913AA6"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1665 5.99996C4.52299 5.99996 5.99984 4.52311 5.99984 1.16663C5.99984 4.52311 7.47669 5.99996 10.8332 5.99996C7.47669 5.99996 5.99984 7.47681 5.99984 10.8333C5.99984 7.47681 4.52299 5.99996 1.1665 5.99996Z"
          stroke="#913AA6"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49984 11.6666C10.6989 11.6666 11.6665 10.699 11.6665 8.49996C11.6665 10.699 12.6341 11.6666 14.8332 11.6666C12.6341 11.6666 11.6665 12.6342 11.6665 14.8333C11.6665 12.6342 10.6989 11.6666 8.49984 11.6666Z"
          stroke="#913AA6"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CustomFieldIcon;
