import { gql } from '@apollo/client';
import {
  CreatePeopleWatchlistMutation,
  PeopleWatchlistType
} from '__generated__/graphql';
import { GET_PEOPLE_WATCHLIST_FRAGMENT } from './getPeopleWatchlists';

export const CREATE_PEOPLE_WATCHLIST = gql`
  mutation CreatePeopleWatchlist($watchlistInput: PeopleWatchlistInput!) {
    createPeopleWatchlist(watchlistInput: $watchlistInput) {
      ...PeopleWatchlist
      people {
        id
      }
    }
  }
  ${GET_PEOPLE_WATCHLIST_FRAGMENT}
`;

export const CREATE_PEOPLE_WATCHLIST_MOCKED_RESPONSE: CreatePeopleWatchlistMutation =
  {
    createPeopleWatchlist: {
      id: '44ac2b7d-0fdd-42e5-b4da-bb5af30427a3',
      people: [],
      entityUrn:
        'urn:harmonic:people_watchlist:44ac2b7d-0fdd-42e5-b4da-bb5af30427a3',
      name: 'New list',
      sharedWithTeam: false,
      owner: {
        entityUrn: 'urn:harmonic:user:357',
        email: 'test@test.com',
        __typename: 'User'
      },
      customFields: [],
      namedViews: [],
      userWatchlistType: PeopleWatchlistType.USER_MANAGED_WATCHLIST,
      __typename: 'PeopleWatchlist'
    }
  };
