import { GetCompanyUserConnectionsV2_UserFragment } from '__generated__/graphql';
import classNames from 'classnames';
import { useAppState } from '../../../../hooks/useAppState';

const UserConnectionsDegree = (props: {
  userConnections: GetCompanyUserConnectionsV2_UserFragment[];
  onClick?: () => void;
}) => {
  const { userConnections, onClick } = props;
  const currentUserEmail = useAppState<string>(
    (state) => state.auth.user?.email ?? ''
  );
  const degreeConnection = userConnections.find(
    (userConnection) => userConnection.email === currentUserEmail
  )
    ? '1st'
    : '2nd';

  return (
    <div
      data-testid="UserConnectionsDegree"
      className={classNames(
        'select-none text-content-headerGray typography-label-small',
        onClick ? 'cursor-pointer' : 'cursor-default'
      )}
      onClick={onClick}
    >
      {degreeConnection}
    </div>
  );
};

export default UserConnectionsDegree;
