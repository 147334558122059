import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TextIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M2.224 3.2a.624.624 0 1 0 0 1.248h11.2a.624.624 0 0 0 0-1.248zm0 2.848a.624.624 0 1 0 0 1.248h11.2a.624.624 0 0 0 0-1.248zM1.6 9.52c0-.345.28-.624.624-.624h11.2a.624.624 0 0 1 0 1.248h-11.2A.624.624 0 0 1 1.6 9.52m.697 2.224c-.385 0-.697.28-.697.624 0 .345.312.624.697.624H8.55c.385 0 .697-.28.697-.624 0-.345-.312-.624-.697-.624z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default TextIcon;
