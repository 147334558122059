const baseUnit = 2;
module.exports = {
  br00: `${baseUnit * 0}px`,
  br10: `${baseUnit}px`,
  br20: `${baseUnit * 2}px`,
  br30: `${baseUnit * 3}px`,
  br40: `${baseUnit * 4}px`,
  br50: `${baseUnit * 6}px`,
  br60: `${baseUnit * 8}px`,
  br70: `${baseUnit * 10}px`,
  br80: `${baseUnit * 12}px`,
  br90: `${baseUnit * 16}px`,
  br100: `999999px`
};