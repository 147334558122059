import classNames from 'classnames';
import { HarmonicIcon } from './type';

const TagIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M1.333 2.5c0-.644.523-1.167 1.167-1.167h5.22c.309 0 .606.123.824.342l5.838 5.838a1.167 1.167 0 0 1 0 1.65l-5.22 5.22a1.167 1.167 0 0 1-1.65 0l-5.836-5.84a1.17 1.17 0 0 1-.342-.825zM5 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default TagIcon;
