import { SelectListCustomFieldValueOption } from '../../../../__generated__/graphql';
import { getNthColor } from '../../../../utils/colors';
import { colorOptions } from '../EditField/SelectOptions/EditOptionItem';

export const formatSelectListCustomFieldMetadata = ({
  newOption,
  options
}: {
  newOption?: string;
  options: SelectListCustomFieldValueOption[];
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const newOptions = options.map(({ __typename, ...rest }) => rest);
  const color = getNthColor(colorOptions, newOptions.length);
  if (newOption) {
    newOptions.push({ name: newOption, color: color, urn: undefined });
  }
  return newOptions;
};

export function isElementInViewport(el: Element) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
