import { datadogLogs } from '@datadog/browser-logs';
import {
  addDatadogEventProperties,
  addDatadogRUMGlobalContext,
  identifyDatadogUser,
  initializeDatadog,
  sendDatadogTiming
} from './datadog';

const MAX_ERROR_MESSAGE_LENGTH = 225280;

export enum LoggerEvent {
  // Auth logging events
  TOKEN_LOADING_STARTED = 'token_loading_started',
  TOKEN_LOADING_COMPLETED = 'token_loading_completed',
  USER_LOADING_STARTED = 'user_loading_started',
  USER_LOADING_COMPLETED = 'user_loading_completed',
  // New generic logging events using view name to differentiate
  GRID_READY = 'grid_ready',
  GRID_FIRST_DATA_RENDERED = 'grid_first_data_rendered',
  GRID_FIRST_EXTENDED_DATA_RENDERED = 'grid_first_extended_data_rendered',
  GRID_DATA_LOADING_STARTED = 'grid_data_loading_started',
  GRID_DATA_LOADING_COMPLETED = 'grid_data_loading_completed',
  GRID_DATA_EXTENDED_LOADING_STARTED = 'grid_data_extended_loading_started',
  GRID_DATA_EXTENDED_LOADING_COMPLETED = 'grid_data_extended_loading_completed',
  GRID_NEXT_DATA_LOADING_STARTED = 'grid_data_next_data_loading_started',
  GRID_NEXT_DATA_LOADING_COMPLETED = 'grid_data_next_data_loading_completed',
  GRID_NEXT_EXTENDED_DATA_LOADING_STARTED = 'grid_data_next_extended_data_loading_started',
  GRID_NEXT_EXTENDED_DATA_LOADING_COMPLETED = 'grid_data_next_extended_data_loading_completed',
  // Logging to debug grid rendering logging with user tab change
  USER_CHANGED_TAB = 'user_changed_tab',
  COMPANY_PROFILE_RENDER = 'company_profile_render',
  PERSON_PROFILE_RENDER = 'person_profile_render',
  // Performance logging for sidebar
  SIDEBAR_SEARCH_LOADING_STARTED = 'sidebar_search_loading_started',
  SIDEBAR_SEARCH_LOADED = 'sidebar_search_loaded',
  SIDEBAR_LISTS_LOADING_STARTED = 'sidebar_lists_loading_started',
  SIDEBAR_LISTS_LOADED = 'sidebar_lists_loaded',
  // Performance logging for board
  BOARD_FIRST_COLUMN_RENDERED = 'board_first_column_rendered',
  BOARD_FIRST_CARD_RENDERED = 'board_first_card_rendered'
}

const initialize = async (): Promise<void> => {
  initializeDatadog();
};

const identifyUser = async ({
  name,
  email,
  entityUrn
}: {
  name: string;
  email: string;
  entityUrn: string;
}): Promise<void> => {
  identifyDatadogUser({ name, email, entityUrn });
};

export const logger = {
  initialize,
  identifyUser,
  addGlobalContext: addDatadogRUMGlobalContext,
  addEventProperties: addDatadogEventProperties,
  error: (message: string, context?: Record<string, unknown>): void => {
    datadogLogs.logger.error(
      message.slice(0, MAX_ERROR_MESSAGE_LENGTH),
      context
    );
    // eslint-disable-next-line no-console
    console.error(message, context);
  },
  warning: (message: string, context?: Record<string, unknown>): void => {
    datadogLogs.logger.warn(message, context);
  },
  info: (message: string, context?: Record<string, unknown>): void => {
    datadogLogs.logger.log(message, context);
  },
  sendTiming: sendDatadogTiming
};
