const Colors = require('./primitives');
module.exports = {
  filterPill: {
    enabled: Colors.functional.blue[10],
    hover: Colors.functional.blue[20],
    pressed: Colors.functional.blue[30],
    disabled: Colors.functional.grey[10],
    selected: {
      enabled: Colors.functional.blue[70],
      hover: Colors.functional.blue[80],
      pressed: Colors.functional.blue[90]
    }
  },
  controls: {
    selected: {
      enabled: Colors.functional.blue[70],
      hover: Colors.functional.blue[80],
      pressed: Colors.functional.blue[90],
      disabled: Colors.functional.grey[60]
    }
  },
  segmentedControl: {
    enabled: Colors.functional.transparent,
    hover: Colors.transparent.darken[20],
    pressed: Colors.transparent.darken[30],
    disabled: Colors.functional.transparent,
    selected: Colors.functional.white
  },
  input: {
    utils: {
      cursor: Colors.functional.blue[70],
      selected: Colors.functional.blue[20]
    },
    placeholder: {
      default: Colors.functional.grey[50],
      hover: Colors.functional.grey[60],
      pressed: Colors.functional.grey[70],
      active: Colors.functional.grey[50],
      disabled: Colors.functional.grey[40]
    },
    value: {
      default: Colors.functional.grey[110],
      hover: Colors.functional.grey[110],
      pressed: Colors.functional.grey[110],
      active: Colors.functional.grey[110],
      disabled: Colors.functional.grey[80]
    }
  },
  slider: {
    border: {
      enabled: Colors.functional.grey[40],
      hover: Colors.functional.grey[50],
      active: Colors.functional.blue[70],
      disabled: Colors.functional.grey[30],
      selected: {
        enabled: Colors.functional.grey[60],
        hover: Colors.functional.grey[70],
        active: Colors.functional.blue[70],
        disabled: Colors.functional.grey[30]
      }
    },
    fill: {
      background: Colors.functional.grey[20],
      selection: Colors.functional.grey[100],
      selectionDisabled: Colors.functional.grey[50]
    }
  },
  toggle: {
    enabled: Colors.functional.grey[30],
    hover: Colors.functional.grey[40],
    active: Colors.functional.grey[50],
    disabled: Colors.functional.grey[20],
    handle: Colors.functional.white,
    handleDisabled: Colors.functional.grey[40],
    selected: {
      enabled: Colors.functional.blue[70],
      hover: Colors.functional.blue[80],
      active: Colors.functional.blue[90],
      disabled: Colors.functional.grey[60]
    }
  },
  grid: {
    enabled: Colors.functional.transparent,
    hover: Colors.transparent.darken[10],
    pressed: Colors.transparent.darken[20],
    selected: Colors.transparent.blue[10],
    selectedBorder: Colors.functional.blue[40],
    onScrollBorder: Colors.transparent.darken[40]
  }
};