import { useMemo } from 'react';
import { camelize } from 'utils/utilities';

import {
  EntitySearchQuery,
  GetWatchlistWithCompaniesQueryVariables,
  SearchCompaniesQueryVariables
} from '__generated__/graphql';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_SEARCH_MODEL,
  ISearchModel
} from 'interfaces/SearchModel/Search';
import { get } from 'lodash';
import { transformSearchModelForApiv2 } from 'utils/search';
import { isValidSortField } from 'utils/sort';
import { getIdFromUrn } from 'utils/urn';
import { GRID_INITIAL_PAGE_SIZE } from '../utils/constants';
import { useCompanySkipVariables } from './useCompanySkipVariables';
import useDashboardLocation from './useDashboardLocation';
import useSearchQuery from './useSearchQuery';
import { useSearchSort } from './useSearchSort';
import useTableUrlParams from './useTableUrlParams';

type SearchVariablesHookResult = {
  watchlistSearchVariables: GetWatchlistWithCompaniesQueryVariables;
  dashboardOrSavedSearchVariables: SearchCompaniesQueryVariables;
};

const useSearchVariables = (
  watchlistSearchQuery?: ISearchModel
): SearchVariablesHookResult => {
  const { searchStateFilter, highlightNewState } = useTableUrlParams();
  const { urn } = useDashboardLocation();

  const skipVariables = useCompanySkipVariables();

  const sort = useSearchSort();
  const { searchModel } = useSearchQuery();

  const companySearchQuery = useMemo(() => {
    // TODO: Potentially use a reactive variable for search model
    // https://www.apollographql.com/docs/react/local-state/reactive-variables
    const query = camelize(transformSearchModelForApiv2(searchModel));

    return query;
  }, [searchModel]);

  const formattedSort = useMemo(() => {
    if (!sort) {
      return undefined;
    }
    const validSortField = isValidSortField(sort.sortField ?? '');

    if (!validSortField) {
      return [
        {
          sortField: INITIAL_COMPANY_SORT.sortField,
          descending: INITIAL_COMPANY_SORT.sortDescending
        }
      ];
    }
    return [sort];
  }, [sort]);

  const dashboardOrSavedSearchVariables = useMemo(() => {
    return {
      query: {
        ...(companySearchQuery as EntitySearchQuery),
        pagination: { pageSize: GRID_INITIAL_PAGE_SIZE, start: 0 },
        sort: formattedSort
      },
      first: null,
      after: null,
      state: searchStateFilter,
      highlightNew: highlightNewState,
      ...skipVariables
    } as SearchCompaniesQueryVariables;
  }, [
    companySearchQuery,
    formattedSort,
    searchStateFilter,
    skipVariables,
    highlightNewState
  ]);

  const watchlistSearchVariables = useMemo(() => {
    const sortDescending = get(
      formattedSort,
      '[0].descending',
      INITIAL_COMPANY_SORT.sortDescending
    );
    return {
      idOrUrn: urn?.includes('company_watchlist') ? getIdFromUrn(urn) : 'NA',
      sortField: formattedSort?.[0].sortField || INITIAL_COMPANY_SORT.sortField,
      sortDescending,
      searchQuery: watchlistSearchQuery
        ? camelize(watchlistSearchQuery)
        : camelize(INITIAL_SEARCH_MODEL),
      ...skipVariables
    } as GetWatchlistWithCompaniesQueryVariables;
  }, [urn, formattedSort, skipVariables]);

  return {
    watchlistSearchVariables,
    dashboardOrSavedSearchVariables
  };
};

export default useSearchVariables;
