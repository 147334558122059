export const getUrnValues = urn => {
  const urnValues = urn.split(':');
  if (urnValues.length === 0 || urnValues[0] !== 'urn') {
    return null;
  }
  return urnValues;
};
export const getIdFromUrn = urn => {
  if (!urn) {
    return null;
  }
  const urnValues = urn.split(':');
  return urnValues[urnValues.length - 1];
};
export const getUrnFragment = (urn, fragment) => {
  if (!urn) return null;
  const urnValues = urn.split(':');
  const urnValue = urnValues[fragment];
  return urnValue ?? null;
};
export const getIdFromUrl = url => {
  const urlMatch = url.match(/\/urn:harmonic:[^:]+:([^/?]+)/);
  return urlMatch && urlMatch[1];
};
export const urnFrom = (entityType, id) => {
  return `urn:harmonic:${entityType}:${id}`;
};
export const isHarmonicUser = customerUrn => {
  return '1' === getIdFromUrn(customerUrn);
};