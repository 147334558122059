import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, useState } from 'react';

import { TruncatedListV2 } from './TruncatedListV2';

const LineclampButton: React.FC<LineclampButtonProps> = ({
  numClamped,
  handleExpand,
  className
}) => {
  return (
    <div className={className}>
      <button
        onClick={handleExpand}
        data-testid="TruncatedListButton"
        className={classNames(
          'w-min whitespace-nowrap rounded-br20 flex gap-g10 items-center justify-center',
          'py-p10 px-p30 typography-label-default-default',
          'cursor-pointer',
          'text-content-sentiment-neutral bg-surface-sentiment-neutral'
        )}
      >
        <p className="line-clamp-1">{`+${numClamped}`}</p>
      </button>
    </div>
  );
};

export interface LineclampButtonProps {
  numClamped: number;
  handleExpand: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

interface TruncatedListProps {
  height: number;
  divider?: JSX.Element;
  lineclampButton?: (props: LineclampButtonProps) => React.ReactElement;
  wrapperClassName?: string;
  contentClassName?: string;
}
const TruncatedList: FC<PropsWithChildren<TruncatedListProps>> = ({
  children,
  height,
  divider,
  lineclampButton = (props) => <LineclampButton {...props} />,
  wrapperClassName,
  contentClassName
}) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setExpanded(false);
    setAnchorEl(null);
  };

  return (
    <div
      className={classNames('flex flex-row justify-start', wrapperClassName)}
    >
      <TruncatedListV2
        className="flex items-start flex-wrap gap-g30"
        renderTruncator={({ hiddenItemsCount }) => {
          return lineclampButton({
            numClamped: hiddenItemsCount,
            handleExpand
          });
        }}
        style={{ maxHeight: height + 4 }}
      >
        {children}
      </TruncatedListV2>
      <Popover
        open={expanded}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className="ml-p30 overflow-hidden bg-none rounded-br40 visible-scrollbar"
        PaperProps={{
          style: {
            borderRadius: 6
          }
        }}
      >
        <div
          data-testid="TruncatedListPopover"
          className="p-p50 max-h-96 overflow-y-scroll flex flex-col gap-y-g40 whitespace-nowrap bg-surface-default shadow-static-elevation-floating border-[1.5px] border-solid border-border rounded-br30"
        >
          {children}
        </div>
      </Popover>
    </div>
  );
};

export default TruncatedList;
