import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const BoardIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        d="M18.0003 3.07342L18.0003 16.4259C18.0003 16.6833 18.0004 16.9198 17.9842 17.1175C17.9668 17.3306 17.9269 17.5638 17.8096 17.7941C17.6418 18.1234 17.3741 18.3911 17.0448 18.5589C16.8145 18.6762 16.5813 18.7161 16.3682 18.7335C16.1705 18.7497 15.934 18.7497 15.6766 18.7496L13.0003 18.7496L13.0003 0.749639L11.5003 0.749639L11.5003 18.7496L6.50033 18.7496L6.50033 0.749639L5.00033 0.749639L5.00033 18.7496L2.32408 18.7496C2.06659 18.7497 1.83012 18.7497 1.63245 18.7335C1.41931 18.7161 1.18614 18.6762 0.955834 18.5589C0.626549 18.3911 0.358833 18.1234 0.191055 17.7941C0.0737079 17.5638 0.0338452 17.3306 0.0164315 17.1175C0.000281095 16.9198 0.000296885 16.6834 0.000280089 16.4259L0.000280089 3.07341C0.000296883 2.81592 0.000281093 2.57945 0.0164315 2.38177C0.0338452 2.16863 0.0737079 1.93546 0.191055 1.70516C0.358833 1.37587 0.626549 1.10815 0.955834 0.940376C1.18614 0.823029 1.41931 0.783166 1.63245 0.765752C1.83012 0.749602 2.06663 0.749618 2.32409 0.749635L15.6765 0.749635C15.934 0.749618 16.1705 0.749602 16.3682 0.765752C16.5813 0.783166 16.8145 0.823029 17.0448 0.940376C17.3741 1.10815 17.6418 1.37587 17.8096 1.70515C17.9269 1.93546 17.9668 2.16863 17.9842 2.38177C18.0004 2.57944 18.0003 2.81595 18.0003 3.07342Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default BoardIcon;
