import { SVGProps } from 'react';

const RelevanceIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#7C8598"
        d="M2.697 8a5.303 5.303 0 1 1 10.606 0A5.303 5.303 0 0 1 2.697 8M8 1.6a6.4 6.4 0 1 0 0 12.8A6.4 6.4 0 0 0 8 1.6M5.623 8a2.377 2.377 0 1 1 4.754 0 2.377 2.377 0 0 1-4.754 0M8 4.526a3.474 3.474 0 1 0 0 6.948 3.474 3.474 0 0 0 0-6.948"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default RelevanceIcon;
