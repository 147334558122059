import { SVGProps } from 'react';

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#7C8598"
        d="M5.445 1.945a.833.833 0 0 0-1.305-.163L1.752 4.171a1.15 1.15 0 0 0-.293 1.138 13.66 13.66 0 0 0 3.452 5.78 13.7 13.7 0 0 0 5.78 3.453c.411.122.846-.001 1.138-.294l2.388-2.388a.833.833 0 0 0-.162-1.305l-2.344-1.398a.83.83 0 0 0-1.016.126l-.933.933a10.1 10.1 0 0 1-2.27-1.709c-.69-.69-1.26-1.456-1.708-2.27l.933-.932a.83.83 0 0 0 .126-1.016z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default PhoneIcon;
