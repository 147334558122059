import { CircularProgress } from '@material-ui/core';
import { HarmonicIcon } from 'assets/harmonic-icons/type';
import classNames from 'classnames';
import {
  ButtonSize,
  CommonButtonProps
} from 'harmonic-components/Button/Button';
import { HTMLAttributes, SVGProps } from 'react';
import {
  generateFocusClasses,
  generateInvertedTypeClasses,
  generateNegativeTypeClasses,
  generatePrimaryTypeClasses,
  generateSecondaryTypeClasses
} from '../Button/utils';

type IconButtonSize = ButtonSize | 'tiny';

export interface IconButtonProps extends CommonButtonProps {
  icon: React.FC<SVGProps<SVGSVGElement>> | HarmonicIcon;
  size?: IconButtonSize;
  'data-testid'?: string;
  className?: HTMLAttributes<HTMLButtonElement>['className'];
  stopPropagation?: boolean;
}

const generateSizeClasses = (size: IconButtonSize) => {
  if (size === 'default') return 'p-p40';
  if (size === 'tiny') return 'p-p10';
  return 'p-p20';
};

/**
 * IconButton
 * @param stopPropagation - if true, stops the event from bubbling up the DOM tree. Allows you to nest buttons without triggering the parent button's onClick event.
 */

const IconButton: React.FC<IconButtonProps> = ({
  type = 'secondary',
  emphasis = 'high',
  size = 'default',
  isSelected = false,
  isDisabled = false,
  icon,
  onClick,
  onMouseDown,
  dataTestId,
  className,
  stopPropagation,
  loading
}) => {
  let mainClasses = '';
  if (type === 'primary')
    mainClasses = generatePrimaryTypeClasses(isSelected, isDisabled);
  else if (type === 'negative')
    mainClasses = generateNegativeTypeClasses(isSelected, isDisabled);
  else if (type === 'secondary')
    mainClasses = generateSecondaryTypeClasses(
      emphasis,
      isSelected,
      isDisabled
    );
  else if (type === 'inverted')
    mainClasses = generateInvertedTypeClasses(emphasis, isSelected, isDisabled);

  const sizeClasses = generateSizeClasses(size);
  const focusClasses = generateFocusClasses(type);
  const MainIcon = icon;
  return (
    <button
      data-testid={dataTestId}
      onClickCapture={
        stopPropagation
          ? (e) => {
              onClick?.(e);
              e.stopPropagation();
            }
          : undefined
      }
      className={classNames(
        mainClasses,
        sizeClasses,
        focusClasses,
        //border radius and font sizes are same in all variations. Change it if needed
        'rounded-br20 relative',
        'typography-label',
        'flex items-center',
        'outline-none focus:outline-none active:outline-none',
        className
      )}
      disabled={isDisabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center">
          <CircularProgress
            classes={{
              root: 'text-inherit'
            }}
            size={16}
          />
        </div>
      )}
      <span
        className={classNames('flex items-center', {
          'p-p10': size !== 'tiny',
          'text-transparent': loading
        })}
      >
        {<MainIcon className="w-4 h-4" />}
      </span>
    </button>
  );
};

export default IconButton;
