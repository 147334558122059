import classNames from 'classnames';
import React from 'react';
import { IconProps } from './type';

const GridIcon: React.FC<IconProps> = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54919 2C3.555 2 3.56083 2 3.56667 2H6.83333C7.10948 2 7.33334 2.22386 7.33334 2.5V6.83333C7.33334 7.10948 7.10948 7.33334 6.83333 7.33334H2.5C2.22386 7.33334 2 7.10948 2 6.83333V3.56667C2 3.56083 2 3.555 2 3.54919C1.99999 3.37753 1.99998 3.21988 2.01074 3.08809C2.02235 2.946 2.04893 2.79055 2.12716 2.63701C2.23901 2.41749 2.41749 2.23901 2.63701 2.12716C2.79055 2.04893 2.946 2.02235 3.08809 2.01074C3.21988 1.99998 3.37753 1.99999 3.54919 2ZM12.9119 2.01074C13.054 2.02235 13.2095 2.04893 13.363 2.12716C13.5825 2.23901 13.761 2.41749 13.8728 2.63701C13.9511 2.79055 13.9776 2.946 13.9893 3.08809C14 3.21988 14 3.37753 14 3.54918V6.83333C14 7.10948 13.7761 7.33334 13.5 7.33334H9.16667C8.89053 7.33334 8.66667 7.10948 8.66667 6.83333V2.5C8.66667 2.22386 8.89053 2 9.16667 2L12.4508 2C12.6225 1.99999 12.7801 1.99998 12.9119 2.01074ZM2 9.16667C2 8.89053 2.22386 8.66667 2.5 8.66667H6.83333C7.10948 8.66667 7.33334 8.89053 7.33334 9.16667V13.5C7.33334 13.7761 7.10948 14 6.83333 14H3.54918C3.37753 14 3.21988 14 3.08809 13.9893C2.946 13.9776 2.79055 13.9511 2.63701 13.8728C2.41749 13.761 2.23901 13.5825 2.12716 13.363C2.04893 13.2095 2.02235 13.054 2.01074 12.9119C1.99998 12.7801 1.99999 12.6225 2 12.4508L2 9.16667ZM8.66667 9.16667C8.66667 8.89053 8.89053 8.66667 9.16667 8.66667H13.5C13.7761 8.66667 14 8.89053 14 9.16667V12.4508C14 12.6225 14 12.7801 13.9893 12.9119C13.9776 13.054 13.9511 13.2095 13.8728 13.363C13.761 13.5825 13.5825 13.761 13.363 13.8728C13.2095 13.9511 13.054 13.9776 12.9119 13.9893C12.7801 14 12.6225 14 12.4508 14H9.16667C8.89053 14 8.66667 13.7761 8.66667 13.5V9.16667Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default GridIcon;
