import { gql } from '@apollo/client';

export const UPSERT_COMPANY_WATCHLIST_ENTRIES = gql`
  mutation UpsertCompanyWatchlistEntries(
    $upsertEntriesInput: [CompanyListUpsertEntriesInput!]!
    $watchlistUrn: CompanyWatchlistUrn!
  ) {
    upsertCompanyWatchlistEntries(
      upsertEntriesInput: $upsertEntriesInput
      watchlistUrn: $watchlistUrn
    ) {
      companiesFoundCount
      totalCompaniesCount
      userCompaniesImportUrn
    }
  }
`;
