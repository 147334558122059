import { AppState, useAppStore } from './useAppStore';

export const useAppState = <T,>(selector: (state: AppState) => T): T => {
  return useAppStore(selector);
};

export const useAuthState = () => {
  const authState = useAppState<AppState['auth']>(
    (state: AppState) => state.auth
  );
  return {
    apiKey: authState.apiKey,
    error: authState.error,
    success: authState.success,
    status: authState.status,
    needVerification: authState.needVerification,
    user: authState.user,
    isAuthenticated: authState.isAuthenticated,
    userMetadata: authState.userMetadata,
    role: authState.role,
    loading: authState.loading
  };
};

export const useDashboardState = () => {
  const dashboardState = useAppState<AppState['dashboard']>(
    (state: AppState) => state.dashboard
  );
  return dashboardState;
};

export const useLocalSearchState = () => {
  const localSearchState = useAppState<AppState['localSearch']>(
    (state: AppState) => state.localSearch
  );
  return localSearchState;
};
