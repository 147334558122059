import { ReactComponent as ConnectLinkedInGraphic } from 'assets/banners/connect-linkedin.svg';
import { SquareArrowTopRightIcon } from 'assets/harmonic-icons';
import { FC } from 'react';
import { DOWNLOAD_EXTENSION_URL } from 'utils/constants';

const ConnectLinkedInCard: FC = () => {
  return (
    <div className="border border-solid border-border rounded-lg space-y-2 w-[584px]">
      <div className="flex items-center">
        <div>
          <ConnectLinkedInGraphic />
        </div>
        <div className="flex flex-col text-left p-p60">
          <div className="flex flex-col gap-g40 mb-p70">
            <div className="text-content-strong typography-label-default-strong">
              Sync your LinkedIn
            </div>
            <p className="text-content-default typography-paragraph-default-default">
              Bring your existing and future connections into Harmonic
            </p>
          </div>
          <div className="typography-paragraph-default-default text-content-default flex gap-g20">
            <p className="w-3">1.</p>
            <p className="flex gap-g20">
              <a
                href={DOWNLOAD_EXTENSION_URL}
                target="_blank"
                rel="noreferrer"
                className="text-surface-sentiment-highlight-informative flex gap-g10 items-center"
              >
                Install <SquareArrowTopRightIcon />
              </a>
              <p>and open our Chrome extension</p>
            </p>
          </div>
          <div className="typography-paragraph-default-default text-content-default flex gap-g20">
            <p className="w-3">2.</p>
            <div>
              Click on
              <span className="text-content-core px-p20">Connect</span>
              in the LinkedIn banner
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectLinkedInCard;
