import classNames from 'classnames';
import Avatar from 'harmonic-components/Avatar/Avatar';
import Tabs from 'harmonic-components/Tabs/Tabs';
import { CompanyActions } from './CompanyActions/CompanyActions';
import { CompanyNavbarOptions } from './CompanyHeader/CompanyHeader';
import { OnTabChangeType } from './CompanyV2';

type StickyNavBarProps = {
  companyId: number;
  companyLogoUrl: string | null | undefined;
  companyName: string | null | undefined;
  selectedTab: string;
  filteredNavbarOptions: (
    | {
        value: CompanyNavbarOptions;
        label: string;
        inBeta?: undefined;
      }
    | {
        value: CompanyNavbarOptions;
        label: string;
        inBeta: boolean;
      }
  )[];
  onTabChange: OnTabChangeType;
};

export const StickyNavBar: React.FC<StickyNavBarProps> = ({
  companyLogoUrl,
  companyId,
  companyName,
  selectedTab,
  filteredNavbarOptions,
  onTabChange
}) => {
  return (
    <div className="flex flex-col gap-g50 w-full h-fit bg-transparent shadow-search px-p70 py-p50">
      <div className="flex justify-between items-center">
        <div className="flex gap-g50 items-center">
          <Avatar src={companyLogoUrl ?? ''} alt="Company Logo" size="large" />
          <p className={classNames('typography-label text-content-title')}>
            {companyName}
          </p>
        </div>
        <CompanyActions companyId={companyId} selectedTab={selectedTab} />
      </div>
      <Tabs
        tabs={filteredNavbarOptions.map((navbar) => ({
          ...navbar,
          variation: 'label'
        }))}
        selectedTabValue={selectedTab}
        onTabChange={(newTabValue) =>
          onTabChange(newTabValue as CompanyNavbarOptions)
        }
      />
    </div>
  );
};
