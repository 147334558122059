const Spacing = require('./primitives');
module.exports = {
  p00: Spacing.sp00,
  p10: Spacing.sp10,
  p20: Spacing.sp20,
  p30: Spacing.sp30,
  p40: Spacing.sp40,
  p50: Spacing.sp60,
  p60: Spacing.sp70,
  p70: Spacing.sp80,
  p80: Spacing.sp90,
  p90: Spacing.sp100,
  p100: Spacing.sp110
};