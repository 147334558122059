import { CircularProgress } from '@material-ui/core';
import { ReactComponent as CheckedStateIcon } from 'assets/harmonic-icons/checkbox/checked.svg';
import { ReactComponent as ExcludedStateIcon } from 'assets/harmonic-icons/checkbox/excluded.svg';
import { ReactComponent as IntermediateStateIcon } from 'assets/harmonic-icons/checkbox/intermediate.svg';
import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { HarmonicIcon } from '../../assets/harmonic-icons/type';

interface CheckboxProps {
  variant?: 'default' | 'exclude' | 'indeterminate' | 'indeterminate-warning';
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onExclude?: () => void;
  label?: string;
  labelAlignment?: 'left' | 'right';
  showExcludeButton?: boolean;
  //This component is used in ListItem. ListItem wraps Checkbox with root "label" tag. Label inside checkbox stops working and gives weird behaviour.
  renderLabelAsDiv?: boolean;
  dataTestId?: string;
  id?: string;
  loading?: boolean;
  fullWidth?: boolean;
  inverted?: boolean;
  icon?: HarmonicIcon;
  stopPropagation?: boolean;
}
const Checkbox: React.FC<CheckboxProps> = ({
  variant = 'default',
  checked,
  onChange,
  labelAlignment = 'right',
  label,
  showExcludeButton = false,
  onExclude,
  disabled,
  dataTestId,
  id,
  renderLabelAsDiv,
  loading,
  fullWidth = false,
  inverted,
  icon,
  stopPropagation = false
}) => {
  let labelComponent = (
    <label
      className={classNames(
        'typography-label line-clamp-1',
        !inverted && 'text-content-default',
        inverted && 'text-content-inverted-strong',
        fullWidth && 'w-full',
        !disabled && 'cursor-pointer'
      )}
      htmlFor={id}
      onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
    >
      {label}
    </label>
  );

  if (renderLabelAsDiv) {
    labelComponent = (
      <div
        className={classNames(
          'typography-label',
          !inverted && 'text-content-default',
          inverted && 'text-content-inverted-strong',
          fullWidth && 'w-full',
          disabled && 'text-content-weak'
        )}
      >
        {label}
      </div>
    );
  }

  const Icon = icon;

  return (
    <div
      className={classNames(
        'flex gap-g40 items-center group',
        fullWidth && 'w-full'
      )}
    >
      {labelAlignment === 'left' && label && labelComponent}
      {loading ? (
        <CircularProgress
          data-testid="Checkbox-loading"
          color="inherit"
          className="w-4 h-4 text-int-overlay-secondary-onEnabled"
        />
      ) : (
        <input
          type="checkbox"
          id={id}
          data-testid={dataTestId}
          className={classNames(
            'appearance-none relative peer shrink-0 w-4 h-4 rounded-br20 cursor-pointer disabled:cursor-auto',
            'border border-solid border-int-outline-secondary-enabled hover:border-int-outline-secondary-hover',
            'active:border-int-outline-secondary-pressed focus-visible:shadow-int-focus-outside disabled:border-int-outline-secondary-disabled checked:border-none',
            {
              'checked:disabled:bg-int-fill-primary-disabled checked:bg-controls-selected-enabled checked:hover:bg-controls-selected-hover checked:active:bg-controls-selected-pressed checked:focus-visible:shadow-int-focus-outside':
                variant === 'default' || variant === 'indeterminate',
              'disabled:bg-int-fill-negative-disabled checked:bg-int-fill-negative-enabled checked:hover:bg-int-fill-negative-hover checked:active:bg-int-fill-negative-pressed checked:focus-visible:shadow-int-focus-outside':
                variant === 'exclude',
              'disabled:bg-surface-sentiment-highlight-caution checked:bg-surface-sentiment-highlight-caution checked:hover:bg-surface-sentiment-highlight-caution checked:active:bg-surface-sentiment-highlight-caution checked:focus-visible:shadow-int-focus-outside':
                variant === 'indeterminate-warning'
            }
          )}
          disabled={disabled}
          onChange={onChange}
          onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
          checked={checked}
        />
      )}

      {checked && !loading && variant === 'default' && (
        <CheckedStateIcon className="-ml-6 z-10 shrink-0 w-4 h-4 pointer-events-none fill-none" />
      )}
      {checked && variant === 'exclude' && (
        <ExcludedStateIcon className="-ml-6 z-10 shrink-0 w-4 h-4 pointer-events-none fill-none" />
      )}
      {checked &&
        (variant === 'indeterminate' ||
          variant === 'indeterminate-warning') && (
          <IntermediateStateIcon className="-ml-6 z-10 shrink-0 w-4 h-4 pointer-events-none fill-none" />
        )}
      {Icon && <Icon className="shrink-0 w-4 h-4 pointer-events-none" />}
      {labelAlignment === 'right' && label && labelComponent}
      {showExcludeButton && (
        <button
          aria-checked={checked}
          data-excluded={
            checked &&
            (variant === 'exclude' || variant === 'indeterminate-warning')
          }
          disabled={disabled}
          className={classNames(
            'hidden group-hover:block typography-label rounded-br20 px-p20 hover:bg-int-overlay-secondary-hover',
            'text-int-overlay-secondary-onEnabled active:bg-int-overlay-secondary-pressed',
            'data-[excluded=true]:text-content-sentiment-negative'
          )}
          onClick={onExclude}
        >
          {checked && variant === 'exclude' ? 'Excluded' : 'Exclude'}
        </button>
      )}
    </div>
  );
};

export default Checkbox;
