import {
  FilterArrayComparator,
  ISearchModel
} from '../../../interfaces/SearchModel/Search';
import { GRID_INITIAL_PAGE_SIZE } from '../../../utils/constants';
import { NOT_APPLICABLE_FIELD_OPTION } from './BoardView';

export const getFieldSearchQuery = ({
  watchlistId,
  fieldUrn,
  fieldValueUrn
}: {
  watchlistId?: string;
  fieldUrn?: string;
  fieldValueUrn: string;
}) => {
  if (!watchlistId) return;

  const fieldOptionSearchQuery: ISearchModel = {
    filter_group: {
      join_operator: 'and',
      filters: [
        {
          field: 'company_custom_field_single_select',
          field_urn: fieldUrn,
          comparator: FilterArrayComparator.ANY_OF_ARRAY,
          filter_value: [fieldValueUrn]
        }
      ],
      filter_groups: []
    },
    pagination: { start: 0, page_size: GRID_INITIAL_PAGE_SIZE }
  };
  const notApplicableFieldSearchQuery: ISearchModel = {
    filter_group: {
      join_operator: 'and',
      filters: [
        {
          field: 'company_collection_id',
          filter_value: [watchlistId],
          comparator: FilterArrayComparator.ANY_OF_ARRAY
        },
        {
          field: 'company_custom_field_single_select',
          field_urn: fieldUrn,
          comparator: FilterArrayComparator.IS_EMPTY,
          filter_value: null
        }
      ],
      filter_groups: []
    },
    pagination: { start: 0, page_size: GRID_INITIAL_PAGE_SIZE }
  };

  return fieldValueUrn === NOT_APPLICABLE_FIELD_OPTION.urn
    ? notApplicableFieldSearchQuery
    : fieldOptionSearchQuery;
};

export const getCustomFieldValue = (urn: string) => {
  if (urn === NOT_APPLICABLE_FIELD_OPTION.urn) {
    return null;
  }
  return urn;
};
