import { useApolloClient } from '@apollo/client';
import { EntityListTypeToDashboardPath } from 'interfaces/SearchModel/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from 'stores/zustandStore';
import { getCustomColumnsKey } from 'utils/utilities';
import DefaultCompanySearchColumns from '../components/Dashboard/Grids/ColumnDefaultOrderings/DefaultCompanySearchColumns';
import DefaultPeopleSearchColumns from '../components/Dashboard/Grids/ColumnDefaultOrderings/DefaultPeopleSearchColumns';
import { EntityListType, ISearchModel } from '../interfaces/SearchModel/Search';
import { useShallowTableStore } from '../stores/tableStore';
import { SearchVersion } from '../utils/analytics';
import {
  ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE,
  ADD_SAVED_SEARCH_ERROR_MESSAGE
} from '../utils/constants';
import { logger } from '../utils/logger';
import {
  ISavedSearchInput,
  createSavedSearch,
  setDataStoreUserValue
} from '../utils/midtierApi';
import { displayToast } from '../utils/toasts';

export const useCreateSavedSearchList = () => {
  const client = useApolloClient();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const editStoreData = useStore((state) => state.editStoreData);
  const { tableColumns } = useShallowTableStore(['tableColumns']);

  const createSavedSearchList = async (
    name: string,
    type:
      | EntityListType.COMPANY_SAVED_SEARCH
      | EntityListType.PEOPLE_SAVED_SEARCH,
    query: ISearchModel,
    disableNavigation = false
  ) => {
    const getColumns = () => {
      if (type === EntityListType.PEOPLE_SAVED_SEARCH) {
        if (pathname.includes('/dashboard/people')) return tableColumns;
        return DefaultPeopleSearchColumns;
      }
      if (type === EntityListType.COMPANY_SAVED_SEARCH) {
        if (pathname.includes('/dashboard/companies')) return tableColumns;
        return DefaultCompanySearchColumns;
      }
      return [];
    };

    const payload: ISavedSearchInput = {
      name,
      is_private: true,
      type,
      keywords: '',
      query
    };
    try {
      const response = await createSavedSearch(
        client,
        payload,
        SearchVersion.V2
      );
      const dataStoreKey = getCustomColumnsKey(response?.entityUrn);
      await setDataStoreUserValue(dataStoreKey, {
        columns: getColumns()
      });

      editStoreData('pageTitle', name);
      displayToast({
        primaryText: 'Successfully created new saved search',
        mode: 'success'
      });

      if (!disableNavigation) {
        const toNavigateRoute = `/dashboard/${EntityListTypeToDashboardPath[type]}/${response?.entityUrn}`;
        navigate(toNavigateRoute);
      }
    } catch (error) {
      if (error == 409) {
        displayToast({
          primaryText: ADD_SAVED_SEARCH_CONFLICT_ERROR_MESSAGE,
          mode: 'error'
        });
      } else {
        displayToast({
          primaryText: ADD_SAVED_SEARCH_ERROR_MESSAGE,
          mode: 'error'
        });
      }
      logger.error('Create people or company saved search error', { error });
    }
  };

  return { createSavedSearchList };
};
