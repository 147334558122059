import {
  GetWatchlistWithCompaniesQuery,
  GetWatchlistWithCompaniesQueryVariables
} from '__generated__/graphql';
import { WATCHLIST_WITH_COMPANIES } from 'queries/getWatchlistWithCompanies';
import { useRefetchWatchlistResults } from './useRefetchWatchlistResults';
import { useWatchlistCompanySearchVariables } from './useWatchlistCompanySearchVariables';

export const useRefetchCompanyWatchlistResults = () => {
  const variables = useWatchlistCompanySearchVariables();

  return useRefetchWatchlistResults<
    GetWatchlistWithCompaniesQuery,
    GetWatchlistWithCompaniesQueryVariables
  >({
    query: WATCHLIST_WITH_COMPANIES,
    variables,
    getDataPath: (data) => ({
      edges:
        data?.getCompanyWatchlistByIdOrUrn?.companyEntries?.edges?.map(
          (edge) => ({
            node: { person: { id: edge.node.company.id } }
          })
        ) || []
    }),
    getUrnPrefix: 'urn:harmonic:company:'
  });
};
