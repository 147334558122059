import { ColDef, IRowNode } from 'ag-grid-community';
import { get, uniqBy } from 'lodash';
import React from 'react';
import { CompanyUserConnectionV2Fragment } from '../../../../../__generated__/graphql';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { useAppState } from '../../../../../hooks/useAppState';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { IPerson } from '../../../../../interfaces/DataModel/Person';
import { DASHBOARD_ROUTE } from '../../../../../utils/constants';
import { CompanyNavbarOptions } from '../../../CompanyV2/CompanyHeader/CompanyHeader';
import { NoDataSubRenderer } from '../CellRenderers';

export const UserConnections: React.FC<{
  value?: CompanyUserConnectionV2Fragment[];
  colDef?: ColDef;
  node?: IRowNode<
    | {
        company?: ICompany;
      }
    | {
        person?: IPerson;
      }
  >;
}> = (props) => {
  const userConnections = props.value;
  const company = get(props, 'node.data.company');
  const person = get(props, 'node.data.person');
  const field = props.colDef?.field ?? '';

  const currentUserEmail = useAppState<string>(
    (state) => state.auth.user?.email ?? ''
  );

  const items = React.useMemo(() => {
    const connections = (userConnections ?? []).map((connection) => ({
      ...connection,
      user: {
        ...connection.user,
        name:
          connection.user?.email === currentUserEmail
            ? 'You'
            : connection.user?.name
      }
    }));

    return uniqBy(connections, (connection) => connection.user?.email).map(
      (connection) => ({
        text: connection.user?.name ?? connection.user?.email ?? '',
        href: field.includes('company.')
          ? `${DASHBOARD_ROUTE}/company/${company?.id}?selectedTab=${CompanyNavbarOptions.CONNECTIONS}`
          : `${DASHBOARD_ROUTE}/person/${person?.id}`
      })
    );
  }, [userConnections, currentUserEmail, field, company?.id, person?.id]);

  if (items.length === 0) {
    return <NoDataSubRenderer type="connections" />;
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {items.map((item, index) => (
          <div key={index}>
            <PersonAttribute src="" name={item.text} href={item.href} />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};
