import { HarmonicIcon } from '../assets/harmonic-icons/type';

export interface ColumnConfig {
  urn: string;
  label: string;
  value: string;
  fieldType?: ColumnFieldType;
  splitFlagToEnable?: string;
  icon?: HarmonicIcon;
  searchModelIdentifier?: string;
}

export enum ColumnFieldType {
  //Enriched by harmonic
  HARMONIC = 'HARMONIC',
  //Enriched from integration
  SMART = 'SMART',
  CUSTOM = 'CUSTOM'
}

export interface ColumnsConfig {
  [key: string]: ColumnConfig;
}
