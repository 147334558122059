import classNames from 'classnames';
import { HarmonicIcon } from './type';

const StatusIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M8 1.233a.6.6 0 0 1 .6.6v2.334a.6.6 0 0 1-1.2 0V1.833a.6.6 0 0 1 .6-.6M3.215 3.215a.6.6 0 0 1 .849 0l1.65 1.65a.6.6 0 0 1-.849.849l-1.65-1.65a.6.6 0 0 1 0-.849m9.57.001a.6.6 0 0 1 0 .848l-1.65 1.65a.6.6 0 0 1-.848-.849l1.65-1.65a.6.6 0 0 1 .848 0M1.233 8a.6.6 0 0 1 .6-.6h2.334a.6.6 0 0 1 0 1.2H1.833a.6.6 0 0 1-.6-.6m10 0a.6.6 0 0 1 .6-.6h2.334a.6.6 0 0 1 0 1.2h-2.334a.6.6 0 0 1-.6-.6m-.946 2.286a.6.6 0 0 1 .848 0l1.65 1.65a.6.6 0 0 1-.849.849l-1.65-1.65a.6.6 0 0 1 0-.849m-4.572.001a.6.6 0 0 1 0 .848l-1.65 1.65a.6.6 0 0 1-.848-.848l1.65-1.65a.6.6 0 0 1 .848 0M8 11.233a.6.6 0 0 1 .6.6v2.334a.6.6 0 0 1-1.2 0v-2.334a.6.6 0 0 1 .6-.6"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default StatusIcon;
