import { IRowNode } from 'ag-grid-community';
import CalendarDaysIcon from 'assets/harmonic-icons/calendar-days';
import { formatFoundingDate } from 'components/common/FoundingDate/utils';
import { get } from 'lodash';
import { dayjsExt } from '../../../../../config/dayjs';
import { NoDataSubRenderer } from '../CellRenderers';

export const DateCell: React.FC<{
  value: any;
  node?: IRowNode<any>;
}> = (props) => {
  const date = get(props, 'value');
  if (
    date &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    props.colDef?.searchModelIdentifier === 'company_founding_date'
  ) {
    return (
      <div className="flex items-center gap-g20">
        <CalendarDaysIcon className="w-4 h-4 text-content-weak" />
        <span className="typography-label-default-number text-content-default">
          {formatFoundingDate(date)}
        </span>
      </div>
    );
  }

  return date ? (
    <div className="flex items-center gap-g20">
      <CalendarDaysIcon className="w-4 h-4 text-content-weak" />
      <span className="typography-label-default-number text-content-default">
        {dayjsExt.utc(date).format('MM/DD/YYYY')}
      </span>
    </div>
  ) : (
    <NoDataSubRenderer />
  );
};
