import { datadogRum } from '@datadog/browser-rum';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { useAuthState } from '../hooks/useAppState';
import { config } from './config';

const getSplitSdkConfig = (key: string): SplitIO.IBrowserSettings => ({
  core: {
    authorizationKey: config.SPLIT_API_KEY,
    key
  },
  sync: {
    enabled: false
  },
  impressionListener: {
    logImpression(impressionData) {
      datadogRum.addFeatureFlagEvaluation(
        impressionData.impression.feature,
        impressionData.impression.treatment
      );
    }
  }
});

export const SplitWrapper = ({ children }: { children: React.ReactNode }) => {
  const authState = useAuthState();

  return (
    <SplitFactoryProvider
      config={
        authState.user && authState.user.email
          ? getSplitSdkConfig(authState.user.email.toLocaleLowerCase())
          : undefined
      }
    >
      {children}
    </SplitFactoryProvider>
  );
};
