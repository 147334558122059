module.exports = {
  fontWeight: {
    440: '440',
    460: '460',
    480: '480',
    500: '500',
    520: '520',
    540: '540',
    560: '560'
  },
  fontSize: {
    8: '8px',
    10: '10px',
    12: '12px',
    14: '14px',
    16: '16px',
    18: '18px',
    20: '20px',
    24: '24px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    48: '48px',
    56: '56px'
  },
  textDecoration: {
    none: 'none',
    underline: 'underline',
    lineThrough: 'line-through'
  },
  textCase: {
    none: 'none',
    uppercase: 'uppercase'
  },
  fontFamily: 'InterVariable, Arial, sans-serif',
  lineHeight: {
    huge: '56px',
    extraLarge: '40px',
    large: '32px',
    medium: '24px',
    regular: '20px',
    small: '16px',
    default: '140%',
    compact: '136%'
  },
  letterSpacing: {
    0: '0px',
    default: '-0.5%',
    compact: '-1%',
    wide: '1%'
  }
};