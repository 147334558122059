import { CompanyEmployeeCompanyFragment } from '__generated__/graphql';
import { ICellRendererParams } from 'ag-grid-community';
import CompanyAttribute from 'harmonic-components/CompanyAttribute/CompanyAttribute';
import TruncatedList from 'harmonic-components/TruncatedList/TruncatedList';
import { shouldLinkCompanyId } from 'utils/company';

const CompanyEmployeesExperienceCellRenderer = (props: ICellRendererParams) => {
  const companies: CompanyEmployeeCompanyFragment[] = props.value;

  if (!companies || companies.length === 0) {
    return (
      <div className="inline-block">
        <span className="typography-paragraph text-content-muted">No data</span>
      </div>
    );
  }

  return (
    <div className="inline-block">
      <TruncatedList height={54}>
        {companies.map((company, index) => (
          <div key={index}>
            <CompanyAttribute
              src={company.logoUrl ?? ''}
              href={
                shouldLinkCompanyId(company.id)
                  ? `/dashboard/company/${company.id}`
                  : ''
              }
              name={company.name ?? ''}
            />
          </div>
        ))}
      </TruncatedList>
    </div>
  );
};

export default CompanyEmployeesExperienceCellRenderer;
