import { TeamRole } from '__generated__/graphql';

// User metadata from Firebase
export interface FirebaseUserToken {
  email: string;
  user_id: string;
  email_verified: boolean;
  createdAt?: number;
  displayName: string;
}

export enum UserStatusType {
  ACTIVE = 'ACTIVE',
  NOT_ASSIGNED_TO_TEAM = 'NOT_ASSIGNED_TO_TEAM',
  PAUSED = 'PAUSED',
  PENDING_PILOT_AGREEMENT_ACCEPTANCE = 'PENDING_PILOT_AGREEMENT_ACCEPTANCE',
  NO_CONSOLE_ACCESS = 'NO_CONSOLE_ACCESS'
}

// User metadata from backend DB
export interface HarmonicUser {
  user_id: string;
  user_urn: string;
  customer_urn: string;
  customer_name: string;
  settings: { has_completed_onboarding: boolean };
}

interface User {
  token?: FirebaseUserToken;
  apiKey?: string;
  user_metadata?: HarmonicUser;
}

export interface LoginResponse extends User {
  status: 'ok' | 'error';
  error?: string;
}

export interface UserOutput {
  email: string;
  name: string;
  entity_urn: string;
  company_watchlist: string[];
  saved_searches: string[];
  customer: string;
  customer_name: string;
  firebase_token: string;
  customer_apikey: string;
  apikey: string;
  role: string;
  status: string;
  settings: { has_completed_onboarding: boolean };
  max_user_seats?: number;
  max_lite_user_seats?: number;
}
export interface SigninData {
  email: string;
  password: string;
}

export interface ResetPasswordData {
  password: string;
}

export interface IAuthState {
  user: FirebaseUserToken | null;
  userMetadata?: HarmonicUser;
  isAuthenticated: boolean;
  apiKey: string;
  loading: boolean;
  error: string;
  needVerification: boolean;
  status?: UserStatusType;
  role?: TeamRole;
  success: string;
}

export enum AuthErrorCodes {
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  USER_NOT_FOUND = 'auth/user-not-found',
  INVALID_PASSWORD = 'auth/wrong-password'
}

export enum LogoutReason {
  UserLoggedOut,
  SessionExpired
}
