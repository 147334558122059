import {
  CustomFieldsFragment,
  ListCustomFieldType,
  PeopleCustomFieldsFragment,
  SortContextValues
} from '__generated__/graphql';
import { TrophyIcon } from 'assets/harmonic-icons';
import { EditFieldTypeOptionIcon } from 'components/Dashboard/Grids/EditField/utils';
import {
  INITIAL_COMPANY_SORT,
  INITIAL_PEOPLE_SORT
} from 'interfaces/SearchModel/Search';
import { FunctionComponent, SVGProps } from 'react';
import {
  ArrowTopRightCircleIcon,
  CalendarIcon,
  CompanyIcon,
  DollarIcon,
  EducationIcon,
  LocationIcon,
  NumberIcon,
  PersonCircleIcon,
  RelevanceIcon,
  SelectIcon,
  TagIcon,
  TextIcon,
  URLIcon
} from '../assets/harmonic-icons';

enum SortType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN'
}

export enum SortDepartment {
  ALL = '*all*',
  ADVISOR = 'advisor',
  CUSTOMER_SUCCESS = 'customer_success',
  DATA = 'data',
  DESIGN = 'design',
  ENGINEERING = 'engineering',
  FINANCE = 'finance',
  LEGAL = 'legal',
  MARKETING = 'marketing',
  OPERATIONS = 'operations',
  OTHER = 'other',
  PEOPLE = 'people',
  SALES = 'sales',
  SUPPORT = 'support'
}

export enum SortTractionMetricOption {
  TOTAL = '*total*',
  PERCENT = 'percent_change',
  DELTA = 'real_change'
}

export enum SortTractionMetricGranularity {
  FOURTEEN_DAYS = '14d_ago',
  THIRTY_DAYS = '30d_ago',
  NINETY_DAYS = '90d_ago',
  ONE_HUNDRED_EIGHTY_DAYS = '180d_ago',
  THREE_HUNDRED_SIXTY_FIVE_DAYS = '365d_ago'
}

export enum SortGroup {
  // Company
  FEATURED = 'featured',
  MOST_RELEVANT = 'relevance_score',
  MOST_RECENTLY_ADDED = 'company_created_at',
  HEADCOUNT = 'company_headcount',
  FUNDING_TOTAL = 'company_funding_total',
  FOUNDING_DATE = 'company_founding_date',
  COMPANY_NAME = 'company_sortable_name',
  CITY = 'company_city',
  STATE = 'company_state',
  COUNTRY = 'company_country',
  LAST_FUNDING_DATE = 'company_last_funding_date',
  LAST_FUNDING_TYPE = 'company_last_funding_type',
  LAST_FUNDING_TOTAL = 'company_last_funding_total',
  TWITTER_FOLLOWERS = 'company_twitter_follower_count',
  LINKEDIN_FOLLOWERS = 'company_linkedin_follower_count',
  FACEBOOK_LIKES = 'company_facebook_page_like_count',
  INSTAGRAM_FOLLOWERS = 'company_instagram_follower_count',
  UPDATED_AT = 'company_updated_at',
  WEB_TRAFFIC = 'web_traffic',
  LAST_MEETING_DATE = 'company_customers_connections_last_meeting_at',
  LAST_MEETING_WITH = 'company_customers_connections_last_meeting_person_email',
  LAST_CONTACTED_DATE = 'company_customers_connections_last_email_at',
  LAST_CONTACTED_BY = 'company_customers_connections_last_email_person_email',
  DATE_ADDED_TO_LIST = 'company_entry_created_at',
  COMPANY_CUSTOM_FIELD_TEXT = 'company_custom_field_text',
  COMPANY_CUSTOM_FIELD_NUMBER = 'company_custom_field_number',
  COMPANY_CUSTOM_FIELD_DATE = 'company_custom_field_date',
  COMPANY_CUSTOM_FIELD_SINGLE_SELECT = 'company_custom_field_single_select',
  COMPANY_CUSTOM_FIELD_MULTI_SELECT = 'company_custom_field_multi_select',
  COMPANY_CUSTOM_FIELD_PERSON = 'company_custom_field_person',
  COMPANY_CUSTOM_FIELD_STATUS = 'company_custom_field_status',
  COMPANY_CUSTOM_FIELD_CHECKBOX = 'company_custom_field_checkbox',
  COMPANY_CUSTOM_FIELD_URL = 'company_custom_field_url',

  // People
  PEOPLE_ID = 'person_id',
  PEOPLE_UPDATE_EVENT_DATE = 'person_last_update_event_date',
  PEOPLE_LINKEDIN_URL = 'person_sortable_linkedin_url',
  PEOPLE_EMAILS = 'person_sortable_emails',
  PEOPLE_FULL_NAME = 'person_sortable_full_name',
  PEOPLE_FIRST_NAME = 'person_sortable_first_name',
  PEOPLE_LAST_NAME = 'person_sortable_last_name',
  PEOPLE_HIGHLIGHTS = 'person_highlights_category_list',
  PEOPLE_EDUCATION = 'person_education_experience_school',
  PEOPLE_CURRENT_COMPANY = 'person_position_company_name',
  PEOPLE_CURRENT_TITLE = 'person_position_title',
  PEOPLE_CURRENT_DEPARTMENT = 'person_position_department',
  PEOPLE_LOCATION = 'person_sortable_location',
  PEOPLE_LINKEDIN_HEADLINE = 'person_sortable_linkedin_headline',
  PEOPLE_CURRENT_COMPANY_TAGS = 'person_position_company_tags',
  PEOPLE_LAST_COMPANY = 'person_last_company_name',
  PEOPLE_LAST_COMPANY_TAGS = 'person_last_company_tags',
  PEOPLE_PREVIOUS_COMPANIES = 'person_experience_company_name',
  PEOPLE_TEAM_NETWORK = 'person_customers_connections_connections_count',
  PEOPLE_LAST_MEETING_DATE = 'person_customers_connections_last_meeting_at',
  PEOPLE_LAST_MEETING_WITH = 'person_customers_connections_last_meeting_person_email',
  PEOPLE_LAST_CONTACTED_DATE = 'person_customers_connections_last_email_at',
  PEOPLE_LAST_CONTACTED_BY = 'person_customers_connections_last_email_person_email',
  PEOPLE_DATE_ADDED_TO_LIST = 'person_entry_created_at',
  PEOPLE_CUSTOM_FIELD_TEXT = 'person_custom_field_text',
  PEOPLE_CUSTOM_FIELD_NUMBER = 'person_custom_field_number',
  PEOPLE_CUSTOM_FIELD_DATE = 'person_custom_field_date',
  PEOPLE_CUSTOM_FIELD_SINGLE_SELECT = 'person_custom_field_single_select',
  PEOPLE_CUSTOM_FIELD_MULTI_SELECT = 'person_custom_field_multi_select',
  PEOPLE_CUSTOM_FIELD_PERSON = 'person_custom_field_person',
  PEOPLE_CUSTOM_FIELD_STATUS = 'person_custom_field_status',
  PEOPLE_CUSTOM_FIELD_CHECKBOX = 'person_custom_field_checkbox',
  PEOPLE_CUSTOM_FIELD_URL = 'person_custom_field_url'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

interface SortMetadata {
  title: string;
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  sortType?: SortType;
  departmentEnabled?: boolean;
  tractionMetricEnabled?: boolean;
  orderDisabled?: boolean;
  isPopularField?: boolean;
  disabledS?: boolean;
  tooltip?: string;
  isCustomField?: boolean;
}

interface DepartmentSortMetadata {
  title: string;
}

interface SortTractionMetricOptionMetadata {
  title: string;
}

interface SortTractionMetricGranularityMetadata {
  title: string;
}

export const SORT_DEPARTMENTS_METADATA: {
  [key in SortDepartment]: DepartmentSortMetadata;
} = {
  [SortDepartment.ALL]: {
    title: 'All'
  },
  [SortDepartment.ADVISOR]: {
    title: 'Advisor'
  },
  [SortDepartment.CUSTOMER_SUCCESS]: {
    title: 'Customer success'
  },
  [SortDepartment.DATA]: {
    title: 'Data'
  },
  [SortDepartment.DESIGN]: {
    title: 'Design'
  },
  [SortDepartment.ENGINEERING]: {
    title: 'Engineering'
  },
  [SortDepartment.FINANCE]: {
    title: 'Finance'
  },
  [SortDepartment.LEGAL]: {
    title: 'Legal'
  },
  [SortDepartment.MARKETING]: {
    title: 'Marketing'
  },
  [SortDepartment.OPERATIONS]: {
    title: 'Operations'
  },
  [SortDepartment.OTHER]: {
    title: 'Other'
  },
  [SortDepartment.PEOPLE]: {
    title: 'People'
  },
  [SortDepartment.SALES]: {
    title: 'Sales'
  },
  [SortDepartment.SUPPORT]: {
    title: 'Support'
  }
};

// This is where we configure the metadata for each sort group. The metadata is used to determine how the sort groups are displayed and which variations it has.
export const SORT_GROUPS_METADATA: {
  [key in SortGroup]: SortMetadata;
} = {
  // Company
  [SortGroup.FEATURED]: {
    title: 'Featured',
    isPopularField: true,
    orderDisabled: true,
    icon: TrophyIcon
  },
  [SortGroup.WEB_TRAFFIC]: {
    title: 'Web Traffic',
    sortType: SortType.NUMBER,
    isPopularField: true,
    tractionMetricEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.MOST_RELEVANT]: {
    title: 'Most relevant',
    isPopularField: true,
    orderDisabled: true,
    icon: RelevanceIcon
  },
  [SortGroup.MOST_RECENTLY_ADDED]: {
    title: 'Date added to Harmonic',
    isPopularField: true,
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.HEADCOUNT]: {
    title: 'Headcount',
    sortType: SortType.NUMBER,
    isPopularField: true,
    tractionMetricEnabled: true,
    departmentEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.FUNDING_TOTAL]: {
    title: 'Total funding',
    sortType: SortType.NUMBER,
    isPopularField: true,
    tractionMetricEnabled: true,
    icon: DollarIcon
  },
  [SortGroup.FOUNDING_DATE]: {
    title: 'Founding date',
    sortType: SortType.DATE,
    isPopularField: true,
    icon: CalendarIcon
  },
  [SortGroup.COMPANY_NAME]: {
    title: 'Company name',
    sortType: SortType.TEXT,
    icon: TextIcon
  },
  [SortGroup.CITY]: {
    title: 'City',
    sortType: SortType.TEXT,
    icon: LocationIcon
  },
  [SortGroup.STATE]: {
    title: 'State',
    sortType: SortType.TEXT,
    icon: LocationIcon
  },
  [SortGroup.COUNTRY]: {
    title: 'Country',
    sortType: SortType.TEXT,
    icon: LocationIcon
  },
  [SortGroup.LAST_FUNDING_DATE]: {
    title: 'Last funding date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.LAST_FUNDING_TYPE]: {
    title: 'Last funding type',
    sortType: SortType.TEXT,
    icon: ArrowTopRightCircleIcon
  },
  [SortGroup.LAST_FUNDING_TOTAL]: {
    title: 'Last funding total',
    sortType: SortType.NUMBER,
    icon: DollarIcon
  },
  [SortGroup.TWITTER_FOLLOWERS]: {
    title: 'Twitter followers',
    sortType: SortType.NUMBER,
    tractionMetricEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.LINKEDIN_FOLLOWERS]: {
    title: 'LinkedIn followers',
    sortType: SortType.NUMBER,
    tractionMetricEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.FACEBOOK_LIKES]: {
    title: 'Facebook likes',
    sortType: SortType.NUMBER,
    tractionMetricEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.INSTAGRAM_FOLLOWERS]: {
    title: 'Instagram followers',
    sortType: SortType.NUMBER,
    tractionMetricEnabled: true,
    icon: NumberIcon
  },
  [SortGroup.UPDATED_AT]: {
    title: 'Updated date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.LAST_MEETING_DATE]: {
    title: 'Last meeting date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.LAST_MEETING_WITH]: {
    title: 'Last meeting with',
    sortType: SortType.TEXT,
    icon: PersonCircleIcon
  },
  [SortGroup.LAST_CONTACTED_DATE]: {
    title: 'Last email date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.LAST_CONTACTED_BY]: {
    title: 'Last email with',
    sortType: SortType.TEXT,
    icon: PersonCircleIcon
  },
  [SortGroup.DATE_ADDED_TO_LIST]: {
    title: 'Date added to list',
    sortType: SortType.DATE,
    icon: CalendarIcon,
    isPopularField: true
  },

  // People
  [SortGroup.PEOPLE_ID]: {
    title: 'ID',
    sortType: SortType.NUMBER,
    icon: NumberIcon
  },
  [SortGroup.PEOPLE_LINKEDIN_URL]: {
    title: 'LinkedIn URL',
    sortType: SortType.TEXT,
    icon: URLIcon
  },
  [SortGroup.PEOPLE_EMAILS]: {
    title: 'Emails',
    sortType: SortType.TEXT,
    icon: URLIcon
  },
  [SortGroup.PEOPLE_FULL_NAME]: {
    title: 'Full name',
    sortType: SortType.TEXT,
    icon: TextIcon,
    isPopularField: true
  },
  [SortGroup.PEOPLE_FIRST_NAME]: {
    title: 'First name',
    sortType: SortType.TEXT,
    icon: TextIcon
  },
  [SortGroup.PEOPLE_LAST_NAME]: {
    title: 'Last name',
    sortType: SortType.TEXT,
    icon: TextIcon
  },
  [SortGroup.PEOPLE_HIGHLIGHTS]: {
    title: 'Person highlights',
    sortType: SortType.TEXT,
    icon: TagIcon
  },
  [SortGroup.PEOPLE_EDUCATION]: {
    title: 'Education',
    sortType: SortType.TEXT,
    icon: EducationIcon
  },
  [SortGroup.PEOPLE_CURRENT_COMPANY]: {
    title: 'Current company',
    sortType: SortType.TEXT,
    icon: CompanyIcon
  },
  [SortGroup.PEOPLE_CURRENT_TITLE]: {
    title: 'Current title',
    sortType: SortType.TEXT,
    icon: TextIcon
  },
  [SortGroup.PEOPLE_CURRENT_DEPARTMENT]: {
    title: 'Current department',
    sortType: SortType.TEXT,
    icon: SelectIcon
  },
  [SortGroup.PEOPLE_LOCATION]: {
    title: 'Current location',
    sortType: SortType.TEXT,
    icon: LocationIcon,
    isPopularField: true
  },
  [SortGroup.PEOPLE_LINKEDIN_HEADLINE]: {
    title: 'LinkedIn headline',
    sortType: SortType.TEXT,
    icon: TextIcon
  },
  [SortGroup.PEOPLE_CURRENT_COMPANY_TAGS]: {
    title: 'Current company tags',
    sortType: SortType.TEXT,
    icon: TagIcon
  },
  [SortGroup.PEOPLE_LAST_COMPANY]: {
    title: 'Previous company',
    sortType: SortType.TEXT,
    icon: CompanyIcon
  },
  [SortGroup.PEOPLE_LAST_COMPANY_TAGS]: {
    title: 'Previous company tags',
    sortType: SortType.TEXT,
    icon: TagIcon
  },
  [SortGroup.PEOPLE_PREVIOUS_COMPANIES]: {
    title: 'Previous companies',
    sortType: SortType.TEXT,
    icon: CompanyIcon
  },
  [SortGroup.PEOPLE_TEAM_NETWORK]: {
    title: 'Team network',
    sortType: SortType.NUMBER,
    icon: PersonCircleIcon,
    isPopularField: true
  },
  [SortGroup.PEOPLE_UPDATE_EVENT_DATE]: {
    title: 'Job updated date',
    sortType: SortType.DATE,
    orderDisabled: true,
    icon: CalendarIcon
  },
  [SortGroup.PEOPLE_LAST_MEETING_DATE]: {
    title: 'Last meeting date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.PEOPLE_LAST_MEETING_WITH]: {
    title: 'Last meeting with',
    sortType: SortType.TEXT,
    icon: PersonCircleIcon
  },
  [SortGroup.PEOPLE_LAST_CONTACTED_DATE]: {
    title: 'Last email date',
    sortType: SortType.DATE,
    icon: CalendarIcon
  },
  [SortGroup.PEOPLE_LAST_CONTACTED_BY]: {
    title: 'Last email with',
    sortType: SortType.TEXT,
    icon: PersonCircleIcon
  },
  [SortGroup.PEOPLE_DATE_ADDED_TO_LIST]: {
    title: 'Date added to list',
    sortType: SortType.DATE,
    icon: CalendarIcon,
    isPopularField: true
  },

  // Company custom fields
  [SortGroup.COMPANY_CUSTOM_FIELD_TEXT]: {
    title: 'Custom Text Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.TEXT]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_NUMBER]: {
    title: 'Custom Number Field',
    sortType: SortType.NUMBER,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.NUMBER]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_DATE]: {
    title: 'Custom Date Field',
    sortType: SortType.DATE,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.DATE]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_SINGLE_SELECT]: {
    title: 'Custom Single Select Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.SINGLE_SELECT]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_MULTI_SELECT]: {
    title: 'Custom Multi Select Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.MULTI_SELECT]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_PERSON]: {
    title: 'Custom Person Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.PERSON]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_STATUS]: {
    title: 'Custom Status Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.STATUS]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_CHECKBOX]: {
    title: 'Custom Checkbox Field',
    sortType: SortType.BOOLEAN,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.CHECKBOX]
  },
  [SortGroup.COMPANY_CUSTOM_FIELD_URL]: {
    title: 'Custom URL Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.WEBSITE]
  },

  // Person custom fields
  [SortGroup.PEOPLE_CUSTOM_FIELD_TEXT]: {
    title: 'Custom Text Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.TEXT]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_NUMBER]: {
    title: 'Custom Number Field',
    sortType: SortType.NUMBER,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.NUMBER]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_DATE]: {
    title: 'Custom Date Field',
    sortType: SortType.DATE,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.DATE]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_SINGLE_SELECT]: {
    title: 'Custom Single Select Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.SINGLE_SELECT]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_MULTI_SELECT]: {
    title: 'Custom Multi Select Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.MULTI_SELECT]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_PERSON]: {
    title: 'Custom Person Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.PERSON]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_STATUS]: {
    title: 'Custom Status Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.STATUS]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_CHECKBOX]: {
    title: 'Custom Checkbox Field',
    sortType: SortType.BOOLEAN,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.CHECKBOX]
  },
  [SortGroup.PEOPLE_CUSTOM_FIELD_URL]: {
    title: 'Custom URL Field',
    sortType: SortType.TEXT,
    isCustomField: true,
    icon: EditFieldTypeOptionIcon[ListCustomFieldType.WEBSITE]
  }
};

export const SORT_TRACTION_METRIC_OPTIONS_METADATA: {
  [key in SortTractionMetricOption]: SortTractionMetricOptionMetadata;
} = {
  [SortTractionMetricOption.TOTAL]: {
    title: 'Total'
  },
  [SortTractionMetricOption.PERCENT]: {
    title: '% change'
  },
  [SortTractionMetricOption.DELTA]: {
    title: '∆ change'
  }
};

export const SORT_TRACTION_METRIC_GRANULARITY_METADATA: {
  [key in SortTractionMetricGranularity]: SortTractionMetricGranularityMetadata;
} = {
  [SortTractionMetricGranularity.FOURTEEN_DAYS]: {
    title: '14d'
  },
  [SortTractionMetricGranularity.THIRTY_DAYS]: {
    title: '30d'
  },
  [SortTractionMetricGranularity.NINETY_DAYS]: {
    title: '90d'
  },
  [SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS]: {
    title: '180d'
  },
  [SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS]: {
    title: '365d'
  }
};

// The final list of sort groups that are shown on the frontend. The order of this array determines the order of the sort groups in the dropdown.
export const ENABLED_COMPANY_SORT_GROUPS = [
  SortGroup.FEATURED,
  SortGroup.MOST_RELEVANT,
  SortGroup.WEB_TRAFFIC,
  SortGroup.MOST_RECENTLY_ADDED,
  SortGroup.DATE_ADDED_TO_LIST,
  SortGroup.HEADCOUNT,
  SortGroup.FUNDING_TOTAL,
  SortGroup.FOUNDING_DATE,
  SortGroup.COMPANY_NAME,
  SortGroup.CITY,
  SortGroup.STATE,
  SortGroup.COUNTRY,
  SortGroup.LAST_FUNDING_DATE,
  SortGroup.LAST_FUNDING_TYPE,
  SortGroup.LAST_FUNDING_TOTAL,
  SortGroup.TWITTER_FOLLOWERS,
  SortGroup.LINKEDIN_FOLLOWERS,
  SortGroup.FACEBOOK_LIKES,
  SortGroup.INSTAGRAM_FOLLOWERS,
  SortGroup.UPDATED_AT,
  SortGroup.LAST_MEETING_DATE,
  SortGroup.LAST_MEETING_WITH,
  SortGroup.LAST_CONTACTED_DATE,
  SortGroup.LAST_CONTACTED_BY
];

// The final list of sort groups that are shown on the frontend. The order of this array determines the order of the sort groups in the dropdown.
export const ENABLED_PEOPLE_SORT_GROUPS = [
  SortGroup.PEOPLE_ID,
  SortGroup.PEOPLE_FULL_NAME,
  SortGroup.PEOPLE_FIRST_NAME,
  SortGroup.PEOPLE_LAST_NAME,
  SortGroup.PEOPLE_DATE_ADDED_TO_LIST,
  SortGroup.PEOPLE_CURRENT_COMPANY,
  SortGroup.PEOPLE_HIGHLIGHTS,
  SortGroup.PEOPLE_CURRENT_TITLE,
  SortGroup.PEOPLE_CURRENT_DEPARTMENT,
  SortGroup.PEOPLE_LOCATION,
  SortGroup.PEOPLE_LINKEDIN_HEADLINE,
  SortGroup.PEOPLE_CURRENT_COMPANY_TAGS,
  SortGroup.PEOPLE_LAST_COMPANY,
  SortGroup.PEOPLE_LAST_COMPANY_TAGS,
  SortGroup.PEOPLE_EDUCATION,
  SortGroup.PEOPLE_PREVIOUS_COMPANIES,
  SortGroup.PEOPLE_LINKEDIN_URL,
  SortGroup.PEOPLE_EMAILS,
  SortGroup.PEOPLE_TEAM_NETWORK,
  SortGroup.PEOPLE_UPDATE_EVENT_DATE,
  SortGroup.PEOPLE_LAST_MEETING_DATE,
  SortGroup.PEOPLE_LAST_MEETING_WITH,
  SortGroup.PEOPLE_LAST_CONTACTED_DATE,
  SortGroup.PEOPLE_LAST_CONTACTED_BY
];

// The final list of sort departments that are shown on the frontend. The order of this array determines the order of the sort departments in the dropdown.
export const ENABLED_SORT_DEPARTMENTS = [
  SortDepartment.ALL,
  SortDepartment.ADVISOR,
  SortDepartment.CUSTOMER_SUCCESS,
  SortDepartment.DATA,
  SortDepartment.DESIGN,
  SortDepartment.ENGINEERING,
  SortDepartment.FINANCE,
  SortDepartment.LEGAL,
  SortDepartment.MARKETING,
  SortDepartment.OPERATIONS,
  SortDepartment.OTHER,
  SortDepartment.PEOPLE,
  SortDepartment.SALES,
  SortDepartment.SUPPORT
];

// The final list of traction metric options that are shown on the frontend. The order of this array determines the order of the traction metric options in the dropdown.
export const ENABLED_SORT_TRACTION_METRIC_OPTIONS = [
  SortTractionMetricOption.TOTAL,
  SortTractionMetricOption.PERCENT,
  SortTractionMetricOption.DELTA
];

// The final list of traction metric granularities that are shown on the frontend. The order of this array determines the order of the traction metric granularities in the dropdown.
export const ENABLED_SORT_TRACTION_METRIC_GRANULARITIES = [
  SortTractionMetricGranularity.FOURTEEN_DAYS,
  SortTractionMetricGranularity.THIRTY_DAYS,
  SortTractionMetricGranularity.NINETY_DAYS,
  SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
  SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
];

export const SORT_TYPE_ORDER_OPTIONS = {
  [SortType.NUMBER]: [
    {
      label: 'High → Low',
      value: SortOrder.DESC
    },
    {
      label: 'Low → High',
      value: SortOrder.ASC
    }
  ],
  [SortType.TEXT]: [
    {
      label: 'A → Z',
      value: SortOrder.ASC
    },
    {
      label: 'Z → A',
      value: SortOrder.DESC
    }
  ],
  [SortType.DATE]: [
    {
      label: 'New → Old',
      value: SortOrder.DESC
    },
    {
      label: 'Old → New',
      value: SortOrder.ASC
    }
  ],
  [SortType.BOOLEAN]: [
    {
      label: 'No → Yes',
      value: SortOrder.DESC
    },
    {
      label: 'Yes → No',
      value: SortOrder.ASC
    }
  ]
};

export const RELEVANCE_SORT_DISABLED_NO_SCORE_AVAILABLE_TOOLTIP_TEXT =
  'Configure relevance score in settings';
export interface SortFieldsMapping {
  [key: string]: {
    sortGroup: SortGroup;
    tractionMetricOption?: SortTractionMetricOption;
    tractionMetricGranularity?: SortTractionMetricGranularity;
    department?: SortDepartment;
  };
}
export const COMPANY_SORT_FIELDS_MAP: SortFieldsMapping = {
  featured: {
    sortGroup: SortGroup.FEATURED
  },
  relevance_score: {
    sortGroup: SortGroup.MOST_RELEVANT
  },
  company_sortable_name: {
    sortGroup: SortGroup.COMPANY_NAME
  },
  company_headcount: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.TOTAL,
    department: SortDepartment.ALL
  },
  web_traffic: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.TOTAL
  },
  company_founding_date: {
    sortGroup: SortGroup.FOUNDING_DATE
  },
  company_created_at: {
    sortGroup: SortGroup.MOST_RECENTLY_ADDED
  },
  company_city: {
    sortGroup: SortGroup.CITY
  },
  company_state: {
    sortGroup: SortGroup.STATE
  },
  company_country: {
    sortGroup: SortGroup.COUNTRY
  },
  company_last_funding_date: {
    sortGroup: SortGroup.LAST_FUNDING_DATE
  },
  company_last_funding_type: {
    sortGroup: SortGroup.LAST_FUNDING_TYPE
  },
  company_last_funding_total: {
    sortGroup: SortGroup.LAST_FUNDING_TOTAL
  },
  company_headcount_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ALL
  },
  company_headcount_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ALL
  },
  web_traffic_real_change_14d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  web_traffic_percent_change_14d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  web_traffic_real_change_30d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  web_traffic_percent_change_30d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  web_traffic_real_change_90d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  web_traffic_percent_change_90d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  web_traffic_real_change_180d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  web_traffic_percent_change_180d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  web_traffic_real_change_365d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  web_traffic_percent_change_365d_ago: {
    sortGroup: SortGroup.WEB_TRAFFIC,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_headcount_advisor_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_advisor_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ADVISOR
  },
  company_headcount_customer_success_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_customer_success_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.CUSTOMER_SUCCESS
  },
  company_headcount_data_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_data_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.DATA
  },
  company_headcount_design_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_design_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.DESIGN
  },
  company_headcount_engineering_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_engineering_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.ENGINEERING
  },
  company_headcount_finance_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_finance_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.FINANCE
  },
  company_headcount_legal_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_legal_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.LEGAL
  },
  company_headcount_marketing_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_marketing_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.MARKETING
  },
  company_headcount_operations_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_operations_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.OPERATIONS
  },
  company_headcount_other_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_other_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.OTHER
  },
  company_headcount_people_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_people_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.PEOPLE
  },
  company_headcount_product_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_headcount_product_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_headcount_product_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_headcount_product_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_headcount_product_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_headcount_product_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_headcount_product_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_headcount_product_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_headcount_product_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_headcount_product_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_headcount_sales_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_sales_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.SALES
  },
  company_headcount_support_real_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_percent_change_14d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_real_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_percent_change_30d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_real_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_percent_change_90d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_real_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_percent_change_180d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_real_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_headcount_support_percent_change_365d_ago: {
    sortGroup: SortGroup.HEADCOUNT,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS,
    department: SortDepartment.SUPPORT
  },
  company_funding_total: {
    sortGroup: SortGroup.FUNDING_TOTAL
  },

  company_funding_total_real_change_14d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_funding_total_percent_change_14d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_funding_total_real_change_30d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_funding_total_percent_change_30d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_funding_total_real_change_90d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_funding_total_percent_change_90d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_funding_total_real_change_180d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_funding_total_percent_change_180d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_funding_total_real_change_365d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_funding_total_percent_change_365d_ago: {
    sortGroup: SortGroup.FUNDING_TOTAL,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_twitter_follower_count: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.TOTAL
  },
  company_twitter_follower_count_real_change_14d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_twitter_follower_count_percent_change_14d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_twitter_follower_count_real_change_30d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_twitter_follower_count_percent_change_30d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_twitter_follower_count_real_change_90d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_twitter_follower_count_percent_change_90d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_twitter_follower_count_real_change_180d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_twitter_follower_count_percent_change_180d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_twitter_follower_count_real_change_365d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_twitter_follower_count_percent_change_365d_ago: {
    sortGroup: SortGroup.TWITTER_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_linkedin_follower_count: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.TOTAL
  },
  company_linkedin_follower_count_real_change_14d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_linkedin_follower_count_percent_change_14d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_linkedin_follower_count_real_change_30d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_linkedin_follower_count_percent_change_30d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_linkedin_follower_count_real_change_90d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_linkedin_follower_count_percent_change_90d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_linkedin_follower_count_real_change_180d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_linkedin_follower_count_percent_change_180d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_linkedin_follower_count_real_change_365d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_linkedin_follower_count_percent_change_365d_ago: {
    sortGroup: SortGroup.LINKEDIN_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_facebook_page_like_count_real_change_14d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_facebook_page_like_count_percent_change_14d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_facebook_page_like_count_real_change_30d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_facebook_page_like_count_percent_change_30d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_facebook_page_like_count_real_change_90d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_facebook_page_like_count_percent_change_90d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_facebook_page_like_count_real_change_180d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_facebook_page_like_count_percent_change_180d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_facebook_page_like_count_real_change_365d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_facebook_page_like_count_percent_change_365d_ago: {
    sortGroup: SortGroup.FACEBOOK_LIKES,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_instagram_follower_count: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.TOTAL
  },
  company_instagram_follower_count_real_change_14d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_instagram_follower_count_percent_change_14d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.FOURTEEN_DAYS
  },
  company_instagram_follower_count_real_change_30d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_instagram_follower_count_percent_change_30d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.THIRTY_DAYS
  },
  company_instagram_follower_count_real_change_90d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_instagram_follower_count_percent_change_90d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity: SortTractionMetricGranularity.NINETY_DAYS
  },
  company_instagram_follower_count_real_change_180d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_instagram_follower_count_percent_change_180d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.ONE_HUNDRED_EIGHTY_DAYS
  },
  company_instagram_follower_count_real_change_365d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.DELTA,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_instagram_follower_count_percent_change_365d_ago: {
    sortGroup: SortGroup.INSTAGRAM_FOLLOWERS,
    tractionMetricOption: SortTractionMetricOption.PERCENT,
    tractionMetricGranularity:
      SortTractionMetricGranularity.THREE_HUNDRED_SIXTY_FIVE_DAYS
  },
  company_updated_at: {
    sortGroup: SortGroup.UPDATED_AT
  },
  company_customers_connections_last_email_person_email: {
    sortGroup: SortGroup.LAST_CONTACTED_BY
  },
  company_customers_connections_last_email_at: {
    sortGroup: SortGroup.LAST_CONTACTED_DATE
  },
  company_customers_connections_last_meeting_person_email: {
    sortGroup: SortGroup.LAST_MEETING_WITH
  },
  company_customers_connections_last_meeting_at: {
    sortGroup: SortGroup.LAST_MEETING_DATE
  },
  company_entry_created_at: {
    sortGroup: SortGroup.DATE_ADDED_TO_LIST
  },
  company_custom_field_text: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_TEXT
  },
  company_custom_field_number: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_NUMBER
  },
  company_custom_field_date: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_DATE
  },
  company_custom_field_single_select: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_SINGLE_SELECT
  },
  company_custom_field_multi_select: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_MULTI_SELECT
  },
  company_custom_field_person: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_PERSON
  },
  company_custom_field_status: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_STATUS
  },
  company_custom_field_checkbox: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_CHECKBOX
  },
  company_custom_field_url: {
    sortGroup: SortGroup.COMPANY_CUSTOM_FIELD_URL
  }
};

export const PEOPLE_SORT_FIELDS_MAP: SortFieldsMapping = {
  person_id: {
    sortGroup: SortGroup.PEOPLE_ID
  },
  person_last_update_event_date: {
    sortGroup: SortGroup.PEOPLE_UPDATE_EVENT_DATE
  },
  person_sortable_full_name: {
    sortGroup: SortGroup.PEOPLE_FULL_NAME
  },
  person_sortable_first_name: {
    sortGroup: SortGroup.PEOPLE_FIRST_NAME
  },
  person_sortable_last_name: {
    sortGroup: SortGroup.PEOPLE_LAST_NAME
  },
  person_sortable_emails: {
    sortGroup: SortGroup.PEOPLE_EMAILS
  },
  person_sortable_linkedin_url: {
    sortGroup: SortGroup.PEOPLE_LINKEDIN_URL
  },
  person_sortable_linkedin_headline: {
    sortGroup: SortGroup.PEOPLE_LINKEDIN_HEADLINE
  },
  person_sortable_location: {
    sortGroup: SortGroup.PEOPLE_LOCATION
  },
  person_highlights_category_list: {
    sortGroup: SortGroup.PEOPLE_HIGHLIGHTS
  },
  person_education_experience_school: {
    sortGroup: SortGroup.PEOPLE_EDUCATION
  },
  person_position_company_name: {
    sortGroup: SortGroup.PEOPLE_CURRENT_COMPANY
  },
  person_position_title: {
    sortGroup: SortGroup.PEOPLE_CURRENT_TITLE
  },
  person_position_department: {
    sortGroup: SortGroup.PEOPLE_CURRENT_DEPARTMENT
  },
  person_position_company_tags: {
    sortGroup: SortGroup.PEOPLE_CURRENT_COMPANY_TAGS
  },
  person_last_company_name: {
    sortGroup: SortGroup.PEOPLE_LAST_COMPANY
  },
  person_last_company_tags: {
    sortGroup: SortGroup.PEOPLE_LAST_COMPANY_TAGS
  },
  person_experience_company_name: {
    sortGroup: SortGroup.PEOPLE_PREVIOUS_COMPANIES
  },
  person_customers_connections_connections_count: {
    sortGroup: SortGroup.PEOPLE_TEAM_NETWORK
  },
  person_customers_connections_last_email_person_email: {
    sortGroup: SortGroup.PEOPLE_LAST_CONTACTED_BY
  },
  person_customers_connections_last_email_at: {
    sortGroup: SortGroup.PEOPLE_LAST_CONTACTED_DATE
  },
  person_customers_connections_last_meeting_person_email: {
    sortGroup: SortGroup.PEOPLE_LAST_MEETING_WITH
  },
  person_customers_connections_last_meeting_at: {
    sortGroup: SortGroup.PEOPLE_LAST_MEETING_DATE
  },
  person_entry_created_at: {
    sortGroup: SortGroup.PEOPLE_DATE_ADDED_TO_LIST
  },
  person_custom_field_text: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_TEXT
  },
  person_custom_field_number: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_NUMBER
  },
  person_custom_field_date: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_DATE
  },
  person_custom_field_single_select: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_SINGLE_SELECT
  },
  person_custom_field_multi_select: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_MULTI_SELECT
  },
  person_custom_field_person: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_PERSON
  },
  person_custom_field_status: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_STATUS
  },
  person_custom_field_checkbox: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_CHECKBOX
  },
  person_custom_field_url: {
    sortGroup: SortGroup.PEOPLE_CUSTOM_FIELD_URL
  }
};

interface ParseSortFieldOutput {
  sortGroup: SortGroup;
  metadata: SortMetadata;
  tractionMetricOption?: SortTractionMetricOption;
  tractionMetricGranularity?: SortTractionMetricGranularity;
  department?: SortDepartment;
}

export const DEFAULT_DESCENDING_SORT_GROUPS = [
  SortGroup.FEATURED,
  SortGroup.WEB_TRAFFIC,
  SortGroup.MOST_RELEVANT,
  SortGroup.MOST_RECENTLY_ADDED,
  SortGroup.FOUNDING_DATE,
  SortGroup.FUNDING_TOTAL,
  SortGroup.LAST_FUNDING_DATE,
  SortGroup.LAST_FUNDING_TOTAL,
  SortGroup.TWITTER_FOLLOWERS,
  SortGroup.LINKEDIN_FOLLOWERS,
  SortGroup.FACEBOOK_LIKES,
  SortGroup.INSTAGRAM_FOLLOWERS,
  SortGroup.UPDATED_AT,
  SortGroup.HEADCOUNT,
  SortGroup.PEOPLE_UPDATE_EVENT_DATE,
  SortGroup.PEOPLE_TEAM_NETWORK
];

const CUSTOM_FIELD_SORT_GROUPS = [
  SortGroup.COMPANY_CUSTOM_FIELD_TEXT,
  SortGroup.COMPANY_CUSTOM_FIELD_NUMBER,
  SortGroup.COMPANY_CUSTOM_FIELD_DATE,
  SortGroup.COMPANY_CUSTOM_FIELD_SINGLE_SELECT,
  SortGroup.COMPANY_CUSTOM_FIELD_MULTI_SELECT,
  SortGroup.COMPANY_CUSTOM_FIELD_PERSON,
  SortGroup.COMPANY_CUSTOM_FIELD_STATUS,
  SortGroup.COMPANY_CUSTOM_FIELD_CHECKBOX,
  SortGroup.COMPANY_CUSTOM_FIELD_URL,
  SortGroup.PEOPLE_CUSTOM_FIELD_TEXT,
  SortGroup.PEOPLE_CUSTOM_FIELD_NUMBER,
  SortGroup.PEOPLE_CUSTOM_FIELD_DATE,
  SortGroup.PEOPLE_CUSTOM_FIELD_SINGLE_SELECT,
  SortGroup.PEOPLE_CUSTOM_FIELD_MULTI_SELECT,
  SortGroup.PEOPLE_CUSTOM_FIELD_PERSON,
  SortGroup.PEOPLE_CUSTOM_FIELD_STATUS,
  SortGroup.PEOPLE_CUSTOM_FIELD_CHECKBOX,
  SortGroup.PEOPLE_CUSTOM_FIELD_URL
];

const COMPANY_CUSTOM_FIELD_SORT_GROUP_BY_TYPE = {
  [ListCustomFieldType.TEXT]: SortGroup.COMPANY_CUSTOM_FIELD_TEXT,
  [ListCustomFieldType.NUMBER]: SortGroup.COMPANY_CUSTOM_FIELD_NUMBER,
  [ListCustomFieldType.DATE]: SortGroup.COMPANY_CUSTOM_FIELD_DATE,
  [ListCustomFieldType.SINGLE_SELECT]:
    SortGroup.COMPANY_CUSTOM_FIELD_SINGLE_SELECT,
  [ListCustomFieldType.MULTI_SELECT]:
    SortGroup.COMPANY_CUSTOM_FIELD_MULTI_SELECT,
  [ListCustomFieldType.PERSON]: SortGroup.COMPANY_CUSTOM_FIELD_PERSON,
  [ListCustomFieldType.STATUS]: SortGroup.COMPANY_CUSTOM_FIELD_STATUS,
  [ListCustomFieldType.CHECKBOX]: SortGroup.COMPANY_CUSTOM_FIELD_CHECKBOX,
  [ListCustomFieldType.WEBSITE]: SortGroup.COMPANY_CUSTOM_FIELD_URL
};

const PEOPLE_CUSTOM_FIELD_SORT_GROUP_BY_TYPE = {
  [ListCustomFieldType.TEXT]: SortGroup.PEOPLE_CUSTOM_FIELD_TEXT,
  [ListCustomFieldType.NUMBER]: SortGroup.PEOPLE_CUSTOM_FIELD_NUMBER,
  [ListCustomFieldType.DATE]: SortGroup.PEOPLE_CUSTOM_FIELD_DATE,
  [ListCustomFieldType.SINGLE_SELECT]:
    SortGroup.PEOPLE_CUSTOM_FIELD_SINGLE_SELECT,
  [ListCustomFieldType.MULTI_SELECT]:
    SortGroup.PEOPLE_CUSTOM_FIELD_MULTI_SELECT,
  [ListCustomFieldType.PERSON]: SortGroup.PEOPLE_CUSTOM_FIELD_PERSON,
  [ListCustomFieldType.STATUS]: SortGroup.PEOPLE_CUSTOM_FIELD_STATUS,
  [ListCustomFieldType.CHECKBOX]: SortGroup.PEOPLE_CUSTOM_FIELD_CHECKBOX,
  [ListCustomFieldType.WEBSITE]: SortGroup.PEOPLE_CUSTOM_FIELD_URL
};

const parseCustomFieldSortField = (
  field: string,
  customFields: CustomFieldsFragment[] | PeopleCustomFieldsFragment[],
  sortContext: SortContextValues
): ParseSortFieldOutput => {
  const customFieldUrn = sortContext.customFieldUrn;
  const customField = (customFields as CustomFieldsFragment[]).find(
    (cf: CustomFieldsFragment | PeopleCustomFieldsFragment) =>
      cf.urn === customFieldUrn
  );
  if (!customField) {
    throw new Error(`Could not find custom field ${customFieldUrn}`);
  }

  let sortGroup;
  if (customFieldUrn?.includes('company_list_custom_field')) {
    sortGroup =
      COMPANY_CUSTOM_FIELD_SORT_GROUP_BY_TYPE[
        customField.type as ListCustomFieldType
      ];
  } else if (customFieldUrn?.includes('person_list_custom_field')) {
    sortGroup =
      PEOPLE_CUSTOM_FIELD_SORT_GROUP_BY_TYPE[
        customField.type as ListCustomFieldType
      ];
  } else {
    throw new Error('Invalid custom field urn');
  }

  return {
    sortGroup,
    metadata: {
      ...SORT_GROUPS_METADATA[sortGroup],
      title: customField.name
    }
  };
};

export const parseSortField = (
  field: string,
  sortFieldMap: SortFieldsMapping,
  customFields: CustomFieldsFragment[] | PeopleCustomFieldsFragment[] = [],
  sortContext?: SortContextValues
): ParseSortFieldOutput => {
  const sortFieldState = sortFieldMap[field];
  if (!sortFieldState) {
    throw new Error(`Could not parse sort field ${field}`);
  }
  const isCustomField = CUSTOM_FIELD_SORT_GROUPS.includes(
    sortFieldState.sortGroup
  );
  if (isCustomField) {
    if (!sortContext) {
      throw new Error('No sort context provided for custom field sort');
    }
    return parseCustomFieldSortField(field, customFields, sortContext);
  }

  return {
    ...sortFieldState,
    metadata: SORT_GROUPS_METADATA[sortFieldState.sortGroup]
  };
};

interface GenerateSortFieldParams {
  sortGroup: SortGroup;
  tractionMetricOption?: SortTractionMetricOption;
  tractionMetricGranularity?: SortTractionMetricGranularity;
  department?: SortDepartment;
}

export const generateSortField = ({
  sortGroup,
  tractionMetricOption,
  tractionMetricGranularity,
  department
}: GenerateSortFieldParams): string => {
  const metadata = SORT_GROUPS_METADATA[sortGroup];

  // Traction Metrics Enabled fields
  let tractionMetricsString = '';
  if (metadata.tractionMetricEnabled) {
    if (
      tractionMetricOption &&
      tractionMetricOption !== SortTractionMetricOption.TOTAL
    ) {
      const tractionMetricGranularityStr = tractionMetricGranularity
        ? tractionMetricGranularity
        : SortTractionMetricGranularity.FOURTEEN_DAYS;
      tractionMetricsString = `_${tractionMetricOption}_${tractionMetricGranularityStr}`;
    }
  }

  // Department Enabled fields
  let departmentStr = '';
  if (metadata.departmentEnabled) {
    if (department && department !== SortDepartment.ALL) {
      departmentStr = `_${department}`;
    }
  }

  // Handle the case when going from department ALL to a specific department and theres no traction metric selected
  if (
    departmentStr &&
    !tractionMetricsString &&
    metadata.tractionMetricEnabled
  ) {
    tractionMetricsString = `_${SortTractionMetricOption.PERCENT}_${SortTractionMetricGranularity.FOURTEEN_DAYS}`;
  }

  // Facebook likes are the only field without total count :')
  if (sortGroup === SortGroup.FACEBOOK_LIKES && !tractionMetricsString) {
    tractionMetricsString = `_${SortTractionMetricOption.PERCENT}_${SortTractionMetricGranularity.FOURTEEN_DAYS}`;
  }

  return `${sortGroup}${departmentStr}${tractionMetricsString}`;
};

export const isValidSortField = (field?: string) => {
  if (!field || typeof field !== 'string') {
    return false;
  }
  const sortGroup = Object.values(SortGroup).find(
    (sortGroup) => field.indexOf(sortGroup) > -1
  );
  if (!sortGroup) {
    return false;
  }
  return true;
};

export const getModifiedCompanySortValueAfterValidationCheck = (
  sortField: string,
  descending: boolean,
  sortContextValues?: SortContextValues
) => {
  if (!isValidSortField(sortField)) {
    return {
      sortField: INITIAL_COMPANY_SORT.sortField,
      descending: INITIAL_COMPANY_SORT.sortDescending,
      sortContextValues
    };
  }
  return { sortField, descending, sortContextValues };
};
export const getModifiedPeopleSortValueAfterValidationCheck = (
  sortField: string,
  descending: boolean,
  sortContextValues?: SortContextValues
) => {
  if (!isValidSortField(sortField)) {
    return {
      sortField: INITIAL_PEOPLE_SORT.sortField,
      descending: INITIAL_PEOPLE_SORT.sortDescending,
      sortContextValues
    };
  }
  return { sortField, descending, sortContextValues };
};

export const generateCustomFieldSortGroups = (
  customFields: CustomFieldsFragment[] | PeopleCustomFieldsFragment[]
) => {
  return customFields?.map((cf) => {
    const isPersonCustomField = cf.__typename === 'PeopleListCustomField';
    const value = isPersonCustomField
      ? PEOPLE_CUSTOM_FIELD_SORT_GROUP_BY_TYPE[cf.type as ListCustomFieldType]
      : COMPANY_CUSTOM_FIELD_SORT_GROUP_BY_TYPE[cf.type as ListCustomFieldType];
    return {
      label: cf.name,
      value,
      icon: SORT_GROUPS_METADATA[value].icon,
      isCustomField: true,
      isPopularField: false,
      customFieldUrn: cf.urn,
      disabled: false,
      tooltip: undefined
    };
  });
};
