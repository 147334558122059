import { SortContextValues } from '__generated__/graphql';
import useFetchSingleWatchlist from 'hooks/useFetchSingleWatchlist';
import useUpsertCompanyWatchlistNamedViews from 'hooks/useUpsertCompanyWatchlistNamedViews';
import { INITIAL_SEARCH_MODEL } from 'interfaces/SearchModel/Search';
import { useMemo } from 'react';
import { useShallowFilterStore } from 'stores/filterStore';
import useStore from 'stores/zustandStore';
import { getSelectedCompanyNamedViewIndex } from 'utils/namedViews';
import {
  SortHookResponse,
  getModifiedCompanySortValueAfterValidationCheck
} from 'utils/sort';

export const useCompanyWatchlistSort = (): SortHookResponse => {
  const { companyWatchlist } = useFetchSingleWatchlist({
    fetchPolicy: 'cache-only'
  });
  const companyListSelectedNamedView = useStore(
    (state) => state.companyListSelectedNamedView
  );
  const selectedNamedViewIndex = useMemo(() => {
    return getSelectedCompanyNamedViewIndex(
      companyWatchlist,
      companyListSelectedNamedView
    );
  }, [companyListSelectedNamedView, companyWatchlist]);
  const currentNamedViews =
    companyWatchlist?.namedViews?.[selectedNamedViewIndex];
  const { upsertCurrentSelectedNamedView } =
    useUpsertCompanyWatchlistNamedViews();

  const { editFilterStore } = useShallowFilterStore(['editFilterStore']);
  const zustandStoreSortKey = 'companyWatchlistPageSort';

  const updateSortDataOnBackend = async (
    sortField: string,
    isDescending: boolean,
    sortContextValues?: SortContextValues
  ) => {
    const updatedSearchQuery = {
      ...INITIAL_SEARCH_MODEL,
      ...(currentNamedViews?.searchQuery ?? {}),
      sort: [
        {
          sortField,
          descending: isDescending,
          sortContextValues
        }
      ]
    };
    upsertCurrentSelectedNamedView({
      variables: {
        searchQuery: updatedSearchQuery
      }
    });
  };
  const changeSort = ({
    field,
    descending,
    sortContextValues
  }: {
    field: string;
    descending: boolean;
    sortContextValues?: SortContextValues;
  }) => {
    editFilterStore(zustandStoreSortKey, {
      sortField: field,
      descending: descending,
      sortContextValues
    });
    updateSortDataOnBackend(field, descending, sortContextValues);
  };

  const sort = getModifiedCompanySortValueAfterValidationCheck(
    currentNamedViews?.searchQuery?.sort?.[0]?.sortField,
    currentNamedViews?.searchQuery?.sort?.[0]?.descending,
    currentNamedViews?.searchQuery?.sort?.[0]?.sortContextValues,
    companyWatchlist?.customFields
  );

  return {
    changeSort,
    sort
  };
};
