import classNames from 'classnames';
import { HarmonicIcon } from './type';

const SettingsSliderVer: HarmonicIcon = ({
  applyCurrentColor = true,
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(applyCurrentColor && 'stroke-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3C7.41421 3 7.75 3.33579 7.75 3.75V5.07014C9.6011 5.421 11 7.04652 11 9C11 10.9535 9.6011 12.579 7.75 12.9299V20.25C7.75 20.6642 7.41421 21 7 21C6.58579 21 6.25 20.6642 6.25 20.25V12.9299C4.3989 12.579 3 10.9535 3 9C3 7.04652 4.3989 5.421 6.25 5.07014V3.75C6.25 3.33579 6.58579 3 7 3ZM17 3C17.4142 3 17.75 3.33579 17.75 3.75V13.0701C19.6011 13.421 21 15.0465 21 17C21 19.2098 19.2098 21 17 21C14.7902 21 13 19.2098 13 17C13 15.0465 14.3989 13.421 16.25 13.0701V3.75C16.25 3.33579 16.5858 3 17 3ZM7 6.5C5.61859 6.5 4.5 7.61859 4.5 9C4.5 10.3814 5.61859 11.5 7 11.5C8.38141 11.5 9.5 10.3814 9.5 9C9.5 7.61859 8.38141 6.5 7 6.5ZM17 14.5C15.6186 14.5 14.5 15.6186 14.5 17C14.5 18.3814 15.6186 19.5 17 19.5C18.3814 19.5 19.5 18.3814 19.5 17C19.5 15.6186 18.3814 14.5 17 14.5Z"
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
    </svg>
  );
};

export default SettingsSliderVer;
