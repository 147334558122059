import { gql } from '@apollo/client';
import { FullCompanySearch } from './companySearchFragment';
import { CustomFieldsFragment } from './getWatchlist';

export const NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment NumberListCustomFieldValue on NumberListCustomFieldValue {
    numberValue: value
  }
`;

export const TEXT_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment TextCustomFieldValue on TextCustomFieldValue {
    textValue: value
  }
`;

export const SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment SingleSelectCustomFieldValue on SingleSelectCustomFieldValue {
    singleSelectValue: value
  }
`;

export const MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment MultiSelectCustomFieldValue on MultiSelectCustomFieldValue {
    multiSelectValue: value
  }
`;

export const PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment PersonSelectCustomFieldValue on PersonSelectCustomFieldValue {
    personSelectValue: value
  }
`;

export const DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment DateListCustomFieldValue on DateListCustomFieldValue {
    dateValue: value
  }
`;

export const WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment WebsiteListCustomFieldValue on WebsiteListCustomFieldValue {
    websiteValue: value
  }
`;

export const CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment CheckboxListCustomFieldValue on CheckboxListCustomFieldValue {
    checkboxValue: value
  }
`;

export const STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment StatusListCustomFieldValue on StatusListCustomFieldValue {
    statusValue: value
  }
`;

export const COMPANY_LIST_CUSTOM_FIELD_VALUE_FRAGMENT = gql`
  fragment CompanyListCustomFieldValue on CompanyListCustomFieldValue {
    customField {
      ...CustomFields
    }
    data {
      __typename
      ... on NumberListCustomFieldValue {
        ...NumberListCustomFieldValue
      }
      ... on TextCustomFieldValue {
        ...TextCustomFieldValue
      }
      ... on SingleSelectCustomFieldValue {
        ...SingleSelectCustomFieldValue
      }
      ... on MultiSelectCustomFieldValue {
        ...MultiSelectCustomFieldValue
      }
      ... on PersonSelectCustomFieldValue {
        ...PersonSelectCustomFieldValue
      }
      ... on DateListCustomFieldValue {
        ...DateListCustomFieldValue
      }
      ... on WebsiteListCustomFieldValue {
        ...WebsiteListCustomFieldValue
      }
      ... on CheckboxListCustomFieldValue {
        ...CheckboxListCustomFieldValue
      }
      ... on StatusListCustomFieldValue {
        ...StatusListCustomFieldValue
      }
    }
    updatedAt
    urn
    createdAt
    __typename
  }
  ${CustomFieldsFragment}
  ${NUMBER_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${TEXT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${SINGLE_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${MULTI_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${PERSON_SELECT_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${DATE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${WEBSITE_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${CHECKBOX_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
  ${STATUS_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;

export const COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT = gql`
  fragment CompanyListEntryNode on CompanyWatchlistEntryNode {
    __typename
    entryUrn
    entryCreatedAt
    customFieldValues {
      ...CompanyListCustomFieldValue
    }
    company {
      ...CompanySearch
    }
    addedBy {
      __typename
      name
      entityUrn
      email
    }
  }
  ${FullCompanySearch}
  ${COMPANY_LIST_CUSTOM_FIELD_VALUE_FRAGMENT}
`;

const COMPANY_WATCHLIST_ENTRIES_FRAGMENT = gql`
  fragment CompanyWatchlistEntries on CompanyWatchlistEntriesConnection {
    edges {
      cursor
      node {
        ...CompanyListEntryNode
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      startCursor
      endCursor
    }
  }
  ${COMPANY_WATCHLIST_ENTRY_NODE_FRAGMENT}
`;

export const WATCHLIST_WITH_COMPANIES = gql`
  query GetWatchlistWithCompanies(
    $idOrUrn: String!
    $first: Int
    $after: String
    $sortField: String
    $sortDescending: Boolean
    $skipExtended: Boolean = false
    $skipPeople: Boolean = false
    $skipAffinityLists: Boolean = false
    $skipUserConnections: Boolean = false
    $skipTractionMetrics: Boolean = false
    $skipInvestors: Boolean = false
    $skipLists: Boolean = true
    $searchQuery: JSON = null
    $skipEntityActions: Boolean = true
    $sortCustomFieldUrn: CompanyListCustomFieldUrn
  ) {
    getCompanyWatchlistByIdOrUrn(idOrUrn: $idOrUrn) {
      id
      companyEntries(
        first: $first
        after: $after
        sortField: $sortField
        sortDescending: $sortDescending
        sortCustomFieldUrn: $sortCustomFieldUrn
        searchQuery: $searchQuery
      ) {
        __typename
        ...CompanyWatchlistEntries
      }
    }
  }
  ${COMPANY_WATCHLIST_ENTRIES_FRAGMENT}
`;
