import { loginWithSSO } from 'actions/authActions';
import { AxiosError } from 'axios';
import Button from 'components/common/lib/Button';
import TextField from 'components/common/lib/TextField';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  UserCredential
} from 'firebase/auth';
import { LoginResponse, UserStatusType } from 'interfaces/Auth';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { updateIntercomWithAuth } from 'utils/auth';
import { logger } from 'utils/logger';
import { displayToast } from 'utils/toasts';
import AuthActionPageWrapper from './AuthActionPageWrapper';

interface IProps {
  actionCode: string;
}

const SignInFromEmailLinkHandler: React.FC<IProps> = () => {
  const auth = getAuth();

  const { update: updateIntercom } = useIntercom();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState<string | null>(null);

  // Email address we will use to sign in. This will be set if user
  // is using same device to sign in that they used to generate email link.
  const [signInEmail, setSignInEmail] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamEmail = queryParams.get('email');

    if (queryParamEmail?.includes('@')) {
      // + is replaced with space in query params
      return queryParamEmail.replaceAll(' ', '+');
    }
    return window.localStorage.getItem('emailForSignIn');
  });

  // Email address that user types in to confirm, if not set in local storage
  const [typedEmail, setTypedEmail] = useState('');

  // Sign in the user if signInEmail is set
  useEffect(() => {
    if (signInEmail) {
      signInWithEmailLink(auth, signInEmail, window.location.href)
        .then((response: UserCredential) => {
          loginWithSSO(
            response,
            (authResult: LoginResponse) => {
              updateIntercomWithAuth(authResult, updateIntercom);
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');
              navigate(`/login`);
            },
            (error?: AxiosError) => {
              if (
                error?.response?.status == 402 ||
                error?.response?.status == 404
              ) {
                navigate(
                  `/user-status?type=${UserStatusType.NOT_ASSIGNED_TO_TEAM}`
                );
              } else if (error?.response?.status == 401) {
                navigate(`/user-status?type=${UserStatusType.PAUSED}`);
              } else {
                displayToast({
                  primaryText: 'There was an error logging you in.',
                  mode: 'error'
                });
                navigate(`/login`);
              }
            }
          );
        })
        .catch((error) => {
          logger.error(`Error signInWithEmailLink: ${error}`);
          setError(error.code);
        });
    }
  }, [signInEmail, auth, updateIntercom, navigate]);

  if (!isSignInWithEmailLink(auth, window.location.href)) {
    navigate('/login');
  }

  return (
    <AuthActionPageWrapper className="bg-auth-gray justify-start min-h-screen">
      {!signInEmail ? (
        <div className="mb-12 bg-white rounded-md shadow-onboardingCard p-12">
          <p>Please confirm the email address you used to sign in:</p>

          <form className="w-96 flex space-x-2 items-center justify-center mt-2">
            <TextField
              placeholder="email@harmonic.ai"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setTypedEmail(event.target.value)
              }
              value={typedEmail}
            />
            <Button
              color="primary"
              className="font-semibold flex items-center text-md w-24 focus:outline-black"
              onClick={() => setSignInEmail(typedEmail)}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </div>
      ) : error ? (
        <div className="flex flex-col justify-center items-center text-center space-y-4 bg-white rounded-md shadow-onboardingCard p-12">
          {error === 'auth/invalid-action-code' ? (
            <div className="flex flex-col space-y-4 justify-center">
              <h1 className="text-xl">⚠️ Sign in link expired</h1>
              <p className="w-px-450">
                Only the most recent sign in link is valid. Please check your
                email for a newer link, or request a new one below.
              </p>
            </div>
          ) : (
            <div className="flex flex-col space-y-4 justify-center">
              <h1 className="text-xl">⚠️ Unable to sign in</h1>
              <p className="w-px-450">
                An unexpected error occurred when signing in. Please try again.
              </p>
            </div>
          )}
          <Button
            color="primary"
            className="font-semibold flex items-center w-36 focus:outline-black"
            onClick={() => navigate('/login')}
          >
            Back to login
          </Button>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center space-y-4 mb-12">
          <div className="flex justify-center w-full mt-16">
            <div className="w-96">
              <div className="absolute onboarding-loading-gradient-animation onboarding-loading-width-animation h-2 rounded-md z-10"></div>
              <div className="absolute bg-filter-hover w-96 h-2 rounded-md"></div>
            </div>
          </div>
        </div>
      )}
    </AuthActionPageWrapper>
  );
};

export default SignInFromEmailLinkHandler;
