import EmailIcon from 'assets/harmonic-icons/email-icon';
import Button from 'harmonic-components/Button/Button';
import IconButton from 'harmonic-components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import React from 'react';
import PersonEmailStaticModal from './PersonEmailStaticModal';

interface ContactButtonProps {
  name: string;
  emailAddress: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({
  name,
  emailAddress
}) => {
  const { show } = useModal();

  const handleOnUnlock = () => {
    show(<PersonEmailStaticModal name={name} emailAddress={emailAddress} />);
  };

  return (
    <>
      <div className="lg:hidden">
        <IconButton
          icon={EmailIcon}
          onClick={handleOnUnlock}
          type="primary"
          emphasis="high"
          size="compact"
          dataTestId="ContactStaticEmailIcon"
        />
      </div>
      <div className="hidden lg:block">
        <Button
          leadingIcon={EmailIcon}
          onClick={handleOnUnlock}
          label="Contact"
          type="primary"
          emphasis="high"
          size="compact"
          dataTestId="ContactStaticEmail"
        />
      </div>
    </>
  );
};

export default ContactButton;
