module.exports = {
  'static-elevation-none': '0px 1px 2px 0px rgba(66, 66, 66, 0.12), 0px 1px 1px -0.5px rgba(66, 66, 66, 0.16)',
  'static-elevation-lifted': '0px 2px 3px 0px rgba(66, 66, 66, 0.12), 0px 1px 2px 0px rgba(66, 66, 66, 0.16)',
  'static-elevation-floating': '0px 4px 8px 0px rgba(66, 66, 66, 0.08), 0px 2px 4px 0px rgba(66, 66, 66, 0.12), 0px 1px 2px 0px rgba(66, 66, 66, 0.12)',
  'static-elevation-strong': '0px 16px 32px 0px rgba(66, 66, 66, 0.08), 0px 12px 24px 0px rgba(66, 66, 66, 0.12), 0px 4px 8px 0px rgba(66, 66, 66, 0.12), 0px 2px 4px 0px rgba(66, 66, 66, 0.12)',
  'int-elevation-hover': '0px 12px 24px 0px rgba(36, 39, 46, 0.08), 0px 2px 4px 0px rgba(36, 39, 46, 0.04), 0px 1px 2px 0px rgba(36, 39, 46, 0.20)',
  'int-elevation-pressed': '0px 2px 3px 0px rgba(66, 66, 66, 0.12), 0px 1px 2px 0px rgba(66, 66, 66, 0.16)',
  'int-elevation-disabled': '0px 1px 2px 0px rgba(66, 66, 66, 0.12), 0px 1px 1px -0.5px rgba(66, 66, 66, 0.16)',
  'int-elevation-enabled': '0px 10px 20px 0px rgba(36, 39, 46, 0.04), 0px 2px 4px 0px rgba(36, 39, 46, 0.08), 0px 1px 2px 0px rgba(36, 39, 46, 0.12)',
  'int-focus-outside': '0px 0px 0px 3px rgba(0, 128, 255, 0.12), 0px 0px 0px 1px #0080FF',
  'int-focus-inside': '0px 0px 0px 3px rgba(0, 128, 255, 0.12) inset, 0px 0px 0px 1px #0080FF inset',
  'int-focus-inverted-outside': '0px 0px 0px 3px rgba(0, 128, 255, 0.76), 0px 0px 0px 1px #9CF',
  'int-focus-inverted-inside': '0px 0px 0px 3px rgba(0, 128, 255, 0.56) inset, 0px 0px 0px 1px #0080FF inset'
};