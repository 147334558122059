export const NUM_EXPRESSIVE_COLORS = 45;
export const getBackgroundColorClassFromShorthandAndIntensity = (color, intensity) => {
  if (intensity === 'subtle') {
    return {
      neutral: 'bg-surface-sentiment-neutral',
      'neutral-inverted': 'bg-surface-inverted-raised',
      positive: 'bg-surface-sentiment-positive',
      negative: 'bg-surface-sentiment-negative',
      informative: 'bg-surface-sentiment-informative',
      cautious: 'bg-surface-sentiment-caution',
      'expressive-1': 'bg-expressive-0-light',
      'expressive-2': 'bg-expressive-8-light',
      'expressive-3': 'bg-expressive-16-light',
      'expressive-4': 'bg-expressive-24-light',
      'expressive-5': 'bg-expressive-32-light',
      'expressive-6': 'bg-expressive-40-light',
      'expressive-7': 'bg-expressive-48-light',
      'expressive-8': 'bg-expressive-56-light',
      'expressive-9': 'bg-expressive-64-light',
      'expressive-10': 'bg-expressive-72-light',
      'expressive-11': 'bg-expressive-80-light',
      'expressive-12': 'bg-expressive-88-light',
      'expressive-13': 'bg-expressive-96-light',
      'expressive-14': 'bg-expressive-104-light',
      'expressive-15': 'bg-expressive-112-light',
      'expressive-16': 'bg-expressive-120-light',
      'expressive-17': 'bg-expressive-128-light',
      'expressive-18': 'bg-expressive-136-light',
      'expressive-19': 'bg-expressive-144-light',
      'expressive-20': 'bg-expressive-152-light',
      'expressive-21': 'bg-expressive-160-light',
      'expressive-22': 'bg-expressive-168-light',
      'expressive-23': 'bg-expressive-176-light',
      'expressive-24': 'bg-expressive-184-light',
      'expressive-25': 'bg-expressive-192-light',
      'expressive-26': 'bg-expressive-200-light',
      'expressive-27': 'bg-expressive-208-light',
      'expressive-28': 'bg-expressive-216-light',
      'expressive-29': 'bg-expressive-224-light',
      'expressive-30': 'bg-expressive-232-light',
      'expressive-31': 'bg-expressive-240-light',
      'expressive-32': 'bg-expressive-248-light',
      'expressive-33': 'bg-expressive-256-light',
      'expressive-34': 'bg-expressive-264-light',
      'expressive-35': 'bg-expressive-272-light',
      'expressive-36': 'bg-expressive-280-light',
      'expressive-37': 'bg-expressive-288-light',
      'expressive-38': 'bg-expressive-296-light',
      'expressive-39': 'bg-expressive-304-light',
      'expressive-40': 'bg-expressive-312-light',
      'expressive-41': 'bg-expressive-320-light',
      'expressive-42': 'bg-expressive-328-light',
      'expressive-43': 'bg-expressive-336-light',
      'expressive-44': 'bg-expressive-344-light',
      'expressive-45': 'bg-expressive-352-light'
    }[color];
  } else {
    // intensity === 'highlight'
    return {
      neutral: 'bg-surface-sentiment-highlight-neutral',
      'neutral-inverted': 'bg-surface-default',
      positive: 'bg-surface-sentiment-highlight-positive',
      negative: 'bg-surface-sentiment-highlight-negative',
      informative: 'bg-surface-sentiment-highlight-informative',
      cautious: 'bg-surface-sentiment-highlight-caution',
      'expressive-1': 'bg-expressive-0-mid',
      'expressive-2': 'bg-expressive-8-mid',
      'expressive-3': 'bg-expressive-16-mid',
      'expressive-4': 'bg-expressive-24-mid',
      'expressive-5': 'bg-expressive-32-mid',
      'expressive-6': 'bg-expressive-40-mid',
      'expressive-7': 'bg-expressive-48-mid',
      'expressive-8': 'bg-expressive-56-mid',
      'expressive-9': 'bg-expressive-64-mid',
      'expressive-10': 'bg-expressive-72-mid',
      'expressive-11': 'bg-expressive-80-mid',
      'expressive-12': 'bg-expressive-88-mid',
      'expressive-13': 'bg-expressive-96-mid',
      'expressive-14': 'bg-expressive-104-mid',
      'expressive-15': 'bg-expressive-112-mid',
      'expressive-16': 'bg-expressive-120-mid',
      'expressive-17': 'bg-expressive-128-mid',
      'expressive-18': 'bg-expressive-136-mid',
      'expressive-19': 'bg-expressive-144-mid',
      'expressive-20': 'bg-expressive-152-mid',
      'expressive-21': 'bg-expressive-160-mid',
      'expressive-22': 'bg-expressive-168-mid',
      'expressive-23': 'bg-expressive-176-mid',
      'expressive-24': 'bg-expressive-184-mid',
      'expressive-25': 'bg-expressive-192-mid',
      'expressive-26': 'bg-expressive-200-mid',
      'expressive-27': 'bg-expressive-208-mid',
      'expressive-28': 'bg-expressive-216-mid',
      'expressive-29': 'bg-expressive-224-mid',
      'expressive-30': 'bg-expressive-232-mid',
      'expressive-31': 'bg-expressive-240-mid',
      'expressive-32': 'bg-expressive-248-mid',
      'expressive-33': 'bg-expressive-256-mid',
      'expressive-34': 'bg-expressive-264-mid',
      'expressive-35': 'bg-expressive-272-mid',
      'expressive-36': 'bg-expressive-280-mid',
      'expressive-37': 'bg-expressive-288-mid',
      'expressive-38': 'bg-expressive-296-mid',
      'expressive-39': 'bg-expressive-304-mid',
      'expressive-40': 'bg-expressive-312-mid',
      'expressive-41': 'bg-expressive-320-mid',
      'expressive-42': 'bg-expressive-328-mid',
      'expressive-43': 'bg-expressive-336-mid',
      'expressive-44': 'bg-expressive-344-mid',
      'expressive-45': 'bg-expressive-352-mid'
    }[color];
  }
};
export const getContentColorClassFromShorthandAndIntensity = (color, intensity) => {
  if (intensity === 'subtle') {
    return {
      neutral: 'text-content-onSentiment-neutral',
      'neutral-inverted': 'text-content-inverted-default',
      positive: 'text-content-onSentiment-positive',
      negative: 'text-content-onSentiment-negative',
      informative: 'text-content-onSentiment-informative',
      cautious: 'text-content-onSentiment-cautious',
      'expressive-1': 'text-expressive-0-dark',
      'expressive-2': 'text-expressive-8-dark',
      'expressive-3': 'text-expressive-16-dark',
      'expressive-4': 'text-expressive-24-dark',
      'expressive-5': 'text-expressive-32-dark',
      'expressive-6': 'text-expressive-40-dark',
      'expressive-7': 'text-expressive-48-dark',
      'expressive-8': 'text-expressive-56-dark',
      'expressive-9': 'text-expressive-64-dark',
      'expressive-10': 'text-expressive-72-dark',
      'expressive-11': 'text-expressive-80-dark',
      'expressive-12': 'text-expressive-88-dark',
      'expressive-13': 'text-expressive-96-dark',
      'expressive-14': 'text-expressive-104-dark',
      'expressive-15': 'text-expressive-112-dark',
      'expressive-16': 'text-expressive-120-dark',
      'expressive-17': 'text-expressive-128-dark',
      'expressive-18': 'text-expressive-136-dark',
      'expressive-19': 'text-expressive-144-dark',
      'expressive-20': 'text-expressive-152-dark',
      'expressive-21': 'text-expressive-160-dark',
      'expressive-22': 'text-expressive-168-dark',
      'expressive-23': 'text-expressive-176-dark',
      'expressive-24': 'text-expressive-184-dark',
      'expressive-25': 'text-expressive-192-dark',
      'expressive-26': 'text-expressive-200-dark',
      'expressive-27': 'text-expressive-208-dark',
      'expressive-28': 'text-expressive-216-dark',
      'expressive-29': 'text-expressive-224-dark',
      'expressive-30': 'text-expressive-232-dark',
      'expressive-31': 'text-expressive-240-dark',
      'expressive-32': 'text-expressive-248-dark',
      'expressive-33': 'text-expressive-256-dark',
      'expressive-34': 'text-expressive-264-dark',
      'expressive-35': 'text-expressive-272-dark',
      'expressive-36': 'text-expressive-280-dark',
      'expressive-37': 'text-expressive-288-dark',
      'expressive-38': 'text-expressive-296-dark',
      'expressive-39': 'text-expressive-304-dark',
      'expressive-40': 'text-expressive-312-dark',
      'expressive-41': 'text-expressive-320-dark',
      'expressive-42': 'text-expressive-328-dark',
      'expressive-43': 'text-expressive-336-dark',
      'expressive-44': 'text-expressive-344-dark',
      'expressive-45': 'text-expressive-352-dark'
    }[color];
  } else {
    // intensity === 'highlight'
    return {
      neutral: 'text-content-inverted-title',
      'neutral-inverted': 'text-content-sentiment-neutral',
      positive: 'text-content-inverted-title',
      negative: 'text-content-inverted-title',
      informative: 'text-content-inverted-title',
      cautious: 'text-content-inverted-title',
      'expressive-1': 'text-expressive-0-light',
      'expressive-2': 'text-expressive-8-light',
      'expressive-3': 'text-expressive-16-light',
      'expressive-4': 'text-expressive-24-light',
      'expressive-5': 'text-expressive-32-light',
      'expressive-6': 'text-expressive-40-light',
      'expressive-7': 'text-expressive-48-light',
      'expressive-8': 'text-expressive-56-light',
      'expressive-9': 'text-expressive-64-light',
      'expressive-10': 'text-expressive-72-light',
      'expressive-11': 'text-expressive-80-light',
      'expressive-12': 'text-expressive-88-light',
      'expressive-13': 'text-expressive-96-light',
      'expressive-14': 'text-expressive-104-light',
      'expressive-15': 'text-expressive-112-light',
      'expressive-16': 'text-expressive-120-light',
      'expressive-17': 'text-expressive-128-light',
      'expressive-18': 'text-expressive-136-light',
      'expressive-19': 'text-expressive-144-light',
      'expressive-20': 'text-expressive-152-light',
      'expressive-21': 'text-expressive-160-light',
      'expressive-22': 'text-expressive-168-light',
      'expressive-23': 'text-expressive-176-light',
      'expressive-24': 'text-expressive-184-light',
      'expressive-25': 'text-expressive-192-light',
      'expressive-26': 'text-expressive-200-light',
      'expressive-27': 'text-expressive-208-light',
      'expressive-28': 'text-expressive-216-light',
      'expressive-29': 'text-expressive-224-light',
      'expressive-30': 'text-expressive-232-light',
      'expressive-31': 'text-expressive-240-light',
      'expressive-32': 'text-expressive-248-light',
      'expressive-33': 'text-expressive-256-light',
      'expressive-34': 'text-expressive-264-light',
      'expressive-35': 'text-expressive-272-light',
      'expressive-36': 'text-expressive-280-light',
      'expressive-37': 'text-expressive-288-light',
      'expressive-38': 'text-expressive-296-light',
      'expressive-39': 'text-expressive-304-light',
      'expressive-40': 'text-expressive-312-light',
      'expressive-41': 'text-expressive-320-light',
      'expressive-42': 'text-expressive-328-light',
      'expressive-43': 'text-expressive-336-light',
      'expressive-44': 'text-expressive-344-light',
      'expressive-45': 'text-expressive-352-light'
    }[color];
  }
};
export const getIconColorClassFromShorthandAndIntensity = (color, intensity) => {
  if (intensity === 'subtle') {
    return {
      neutral: 'text-content-sentiment-neutral',
      'neutral-inverted': 'text-content-inverted-default',
      positive: 'text-content-sentiment-positive',
      negative: 'text-content-sentiment-negative',
      informative: 'text-content-sentiment-informative',
      cautious: 'text-content-sentiment-cautious',
      'expressive-1': 'text-expressive-0-mid',
      'expressive-2': 'text-expressive-8-mid',
      'expressive-3': 'text-expressive-16-mid',
      'expressive-4': 'text-expressive-24-mid',
      'expressive-5': 'text-expressive-32-mid',
      'expressive-6': 'text-expressive-40-mid',
      'expressive-7': 'text-expressive-48-mid',
      'expressive-8': 'text-expressive-56-mid',
      'expressive-9': 'text-expressive-64-mid',
      'expressive-10': 'text-expressive-72-mid',
      'expressive-11': 'text-expressive-80-mid',
      'expressive-12': 'text-expressive-88-mid',
      'expressive-13': 'text-expressive-96-mid',
      'expressive-14': 'text-expressive-104-mid',
      'expressive-15': 'text-expressive-112-mid',
      'expressive-16': 'text-expressive-120-mid',
      'expressive-17': 'text-expressive-128-mid',
      'expressive-18': 'text-expressive-136-mid',
      'expressive-19': 'text-expressive-144-mid',
      'expressive-20': 'text-expressive-152-mid',
      'expressive-21': 'text-expressive-160-mid',
      'expressive-22': 'text-expressive-168-mid',
      'expressive-23': 'text-expressive-176-mid',
      'expressive-24': 'text-expressive-184-mid',
      'expressive-25': 'text-expressive-192-mid',
      'expressive-26': 'text-expressive-200-mid',
      'expressive-27': 'text-expressive-208-mid',
      'expressive-28': 'text-expressive-216-mid',
      'expressive-29': 'text-expressive-224-mid',
      'expressive-30': 'text-expressive-232-mid',
      'expressive-31': 'text-expressive-240-mid',
      'expressive-32': 'text-expressive-248-mid',
      'expressive-33': 'text-expressive-256-mid',
      'expressive-34': 'text-expressive-264-mid',
      'expressive-35': 'text-expressive-272-mid',
      'expressive-36': 'text-expressive-280-mid',
      'expressive-37': 'text-expressive-288-mid',
      'expressive-38': 'text-expressive-296-mid',
      'expressive-39': 'text-expressive-304-mid',
      'expressive-40': 'text-expressive-312-mid',
      'expressive-41': 'text-expressive-320-mid',
      'expressive-42': 'text-expressive-328-mid',
      'expressive-43': 'text-expressive-336-mid',
      'expressive-44': 'text-expressive-344-mid',
      'expressive-45': 'text-expressive-352-mid'
    }[color];
  } else {
    // intensity === 'highlight'
    return {
      neutral: 'text-content-inverted-title',
      'neutral-inverted': 'text-content-sentiment-neutral',
      positive: 'text-content-inverted-title',
      negative: 'text-content-inverted-title',
      informative: 'text-content-inverted-title',
      cautious: 'text-content-inverted-title',
      'expressive-1': 'text-expressive-0-light',
      'expressive-2': 'text-expressive-8-light',
      'expressive-3': 'text-expressive-16-light',
      'expressive-4': 'text-expressive-24-light',
      'expressive-5': 'text-expressive-32-light',
      'expressive-6': 'text-expressive-40-light',
      'expressive-7': 'text-expressive-48-light',
      'expressive-8': 'text-expressive-56-light',
      'expressive-9': 'text-expressive-64-light',
      'expressive-10': 'text-expressive-72-light',
      'expressive-11': 'text-expressive-80-light',
      'expressive-12': 'text-expressive-88-light',
      'expressive-13': 'text-expressive-96-light',
      'expressive-14': 'text-expressive-104-light',
      'expressive-15': 'text-expressive-112-light',
      'expressive-16': 'text-expressive-120-light',
      'expressive-17': 'text-expressive-128-light',
      'expressive-18': 'text-expressive-136-light',
      'expressive-19': 'text-expressive-144-light',
      'expressive-20': 'text-expressive-152-light',
      'expressive-21': 'text-expressive-160-light',
      'expressive-22': 'text-expressive-168-light',
      'expressive-23': 'text-expressive-176-light',
      'expressive-24': 'text-expressive-184-light',
      'expressive-25': 'text-expressive-192-light',
      'expressive-26': 'text-expressive-200-light',
      'expressive-27': 'text-expressive-208-light',
      'expressive-28': 'text-expressive-216-light',
      'expressive-29': 'text-expressive-224-light',
      'expressive-30': 'text-expressive-232-light',
      'expressive-31': 'text-expressive-240-light',
      'expressive-32': 'text-expressive-248-light',
      'expressive-33': 'text-expressive-256-light',
      'expressive-34': 'text-expressive-264-light',
      'expressive-35': 'text-expressive-272-light',
      'expressive-36': 'text-expressive-280-light',
      'expressive-37': 'text-expressive-288-light',
      'expressive-38': 'text-expressive-296-light',
      'expressive-39': 'text-expressive-304-light',
      'expressive-40': 'text-expressive-312-light',
      'expressive-41': 'text-expressive-320-light',
      'expressive-42': 'text-expressive-328-light',
      'expressive-43': 'text-expressive-336-light',
      'expressive-44': 'text-expressive-344-light',
      'expressive-45': 'text-expressive-352-light'
    }[color];
  }
};
export const stringToExpressiveNumber = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash % NUM_EXPRESSIVE_COLORS) + 1;
};