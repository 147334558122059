import classNames from 'classnames';
import { HarmonicIcon } from './type';

const CheckboxIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M3.5 3.5h9v9h-9zM2 3.5A1.5 1.5 0 0 1 3.5 2h9A1.5 1.5 0 0 1 14 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 12.5zm9.02 3.541a.75.75 0 1 0-1.04-1.082L7.376 8.46l-1.357-1.3a.75.75 0 1 0-1.038 1.082l1.875 1.8a.75.75 0 0 0 1.038 0z"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default CheckboxIcon;
