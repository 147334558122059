import classNames from 'classnames';
import { HarmonicIcon } from './type';

const LocationIcon: HarmonicIcon = ({ applyCurrentColor, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={classNames(applyCurrentColor && 'fill-current', className)}
    >
      <path
        fill="#7C8598"
        d="M2.667 6.667a5.333 5.333 0 1 1 10.666 0c0 1.834-.901 3.53-1.898 4.854-1.004 1.331-2.15 2.345-2.736 2.827-.41.336-.988.336-1.398 0-.586-.482-1.732-1.496-2.736-2.828-.997-1.323-1.898-3.019-1.898-4.853m3.25 0a2.083 2.083 0 1 1 4.166 0 2.083 2.083 0 0 1-4.166 0"
        clipRule="evenodd"
        fillRule="evenodd"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default LocationIcon;
