import { Column, IRowNode } from 'ag-grid-community';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Person } from '../../../../../__generated__/graphql';
import PersonAttribute from '../../../../../harmonic-components/PersonAttribute/PersonAttribute';
import TruncatedList from '../../../../../harmonic-components/TruncatedList/TruncatedList';
import { ICompany } from '../../../../../interfaces/DataModel/Company';
import { EventLocations } from '../../../../../utils/analytics';
import { NoDataSubRenderer } from '../CellRenderers';

export const PeopleList: React.FC<{
  value: any;
  column?: Column;
  node?: IRowNode<any>;
}> = (props) => {
  const headerName = props.column?.getDefinition()?.headerName ?? '';
  const shouldLinkToLinkedin = headerName.includes('LinkedIn');
  const company: ICompany = get(props, 'node.data.company');

  const personRelationships = useMemo(() => {
    const relevantPersonRelationships: Person[] = (
      company?.person_relationships ?? []
    ).filter(
      (person: Person): person is Exclude<typeof person, null> => person != null
    );

    return relevantPersonRelationships.map((personRelationship) => {
      if (personRelationship) {
        return {
          ...personRelationship,
          person: {
            id: personRelationship.id,
            full_name: personRelationship.fullName,
            linkedin_url: personRelationship.socials?.linkedin?.url,
            personal_emails: personRelationship.contact?.emails
          },
          person_company_emails: personRelationship?.contact?.emails
        };
      }
    });
  }, [company?.person_relationships]);

  const team = useMemo(() => {
    const founders =
      personRelationships?.filter(
        (relationship) => relationship?.person?.full_name
      ) || [];

    return founders
      .map((person) => {
        const teamMemberHarmonicProfileLink = get(person, 'person.id', null)
          ? `/dashboard/person/${get(person, 'person.id')}`
          : undefined;
        const useLinkToLinkedIn =
          shouldLinkToLinkedin && Boolean(get(person, 'person.linkedin_url'));
        return {
          text: get(person, 'person.full_name'),
          href: useLinkToLinkedIn
            ? get(person, 'person.linkedin_url')
            : teamMemberHarmonicProfileLink,
          openedFromLocation: !useLinkToLinkedIn
            ? EventLocations.GRID_TABLE_TEAM
            : undefined,
          profilePicSrc: get(person, 'profilePictureUrl')
        };
      })
      .filter((listItem) => Boolean(listItem.text));
  }, [personRelationships, shouldLinkToLinkedin]);

  if (team.length === 0) {
    return <NoDataSubRenderer />;
  }

  return (
    <div className="inline-block">
      <TruncatedList wrapperClassName="-ml-p30" height={54}>
        {team.map((person, index) => (
          <PersonAttribute
            key={index}
            src={person.profilePicSrc ?? ''}
            href={person.href ?? ''}
            name={person.text ?? ''}
          />
        ))}
      </TruncatedList>
    </div>
  );
};
