import { CompanyListEntryNodeFragment } from '../../../../__generated__/graphql';
import { LongTextCellRenderer } from './CellRenderers';
import { CustomFieldsCellRendererParams } from './CustomColumnCellRenderers';

export const CustomColumnTextCellRenderer = (
  props: CustomFieldsCellRendererParams<CompanyListEntryNodeFragment>
) => {
  const value = props.value as number | undefined;

  if (props.showEmptyValue && !value) {
    return (
      <p className="typography-paragraph text-content-weak">
        {props.emptyValueText ?? `Add ${props.colDef?.headerName}`}
      </p>
    );
  }

  return <LongTextCellRenderer {...props} />;
};
